<template>
  <div class="msg-line-new">
    <p v-if="message.newDate" class="date-box">
      <span>{{ beautyDate }}</span>
    </p>
    <div
      class="usr-message-new"
      :class="{ sender: !isReceiver, recipient: isReceiver }"
    >
      <div class="avatar">
        <img :src="avatar" alt="username" />
        <div class="add"></div>
      </div>
      <div
        class="msg-new"
        style="
          max-width: 50%;
          text-overflow: ellipsis;
          white-space: break-spaces;
          overflow: hidden;
        "
      >
        <div
          style="
            max-width: 100%;
            display: flex;
            flex-wrap: wrap;
            text-overflow: ellipsis;
            word-break: break-word;
            font-size: 18px;
          "
          v-html="messageWithEmoji"
        ></div>
        <span class="date">{{ messageTime }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { NEW_DATE } from '../../../../../mixins/man_util';
import moment from 'moment';

export default {
  name: 'ChatRow',
  components: {},
  mixins: [NEW_DATE],
  props: {
    user: Object,
    message: Object,
  },
  data() {
    return {};
  },
  computed: {
    messageWithEmoji() {
      // eslint-disable-next-line no-undef
      return EmojiPicker.prototype.appendUnicodeAsImageToElement(
        this.$refs.msg,
        this.message.text
      );
    },
    avatar() {
      if (this.message.sender === this.selfUser.id) {
        return this.selfUser.profile.avatarList.img_small;
      } else {
        return this.user.profile.avatarList.img_small;
      }
    },
    selfUser() {
      return this.$store.getters.user;
    },
    currentChat() {
      return this.$store.getters.dialogsCurrent;
    },
    isReceiver() {
      return this.message.receiver === this.$store.getters.user.id;
    },
    isSender() {
      return this.message.sender === this.$store.getters.user.id;
    },
    displayNewDate() {
      return this.mixinIsNewDate(this.user.id);
    },
    beautyDate() {
      moment.locale(this.$store.getters.locale);
      return moment.unix(this.message.time).format('LL');
    },
    messageTime() {
      moment.locale(this.$store.getters.locale);
      return moment.unix(this.message.time).format('LT');
    },
    sound() {
      return this.$store.getters.settings.sound;
    },
  },
  mounted() {
    this.$emit('msg', this.$el);
  },
  created() {
    /*if (
      this.message.sound &&
      this.sound.sound &&
      this.sound.sound_chat &&
      this.message.type !== 'invite' &&
      this.currentChat.active
    ) {
      if (this.isSender) {
        window.wMessage.play()
        console.log(this.message)
        this.message.sound = false
      } else {
        window.newMessage.play()
        this.message.sound = false
      }
    }*/
  },
};
</script>

<style lang="scss">
.usr-message-new {
  width: 100%;
  display: flex;
  justify-content: flex-start;
}

.recipient {
  flex-direction: row;
  .msg-new {
    background: #fef3f3;
  }
  .avatar {
    margin-right: 15px !important;
  }
}
.sender {
  flex-direction: row-reverse;
  .msg-new {
    background: #f0fbfe;
  }
  .avatar {
    margin-left: 15px;
  }
}

.usr-message-new .msg-new {
  font-size: 14px;
  padding: 5px 15px;

  .date {
    color: #575757;
  }
}

.usr-message-new .avatar {
  margin-right: 10px;
  position: relative;
  width: 45px;
  height: 45px;
  &:after {
    position: absolute;
    content: '';
    top: 2px;
    right: -2px;
    width: 100%;
    height: 100%;
    border: 1px solid transparent;
    transform: rotate(45deg);
    border-right: 1px solid #000000;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
  }
  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
}
</style>
