<template>
  <div>
    <div class="mgifts--item" :class="{ extended }">
      <div class="mgifts--item__img">
        <img :src="gift.photo" :alt="gift.description" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    gift: Object,
    extended: Boolean,
  },
  data() {
    return {};
  },
  computed: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
@import '../../../../assets/scss/vars';

.mgifts--item {
  display: flex;

  &__img {
    img {
      height: 100px;
      width: 100px;
      object-fit: contain;
    }
  }

  &__info {
    margin-left: 10px;
    padding-top: 5px;
    line-height: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: #3e3f42;
    &__price {
      align-items: center;
      color: #a8a8a8;
      span + span {
        margin-left: 5px;
      }
      img {
        height: 18px;
        width: 18px;
      }
    }

    .tip {
      color: $red;
      line-height: 1.5;

      p {
        margin-bottom: 5px;
      }
    }
  }

  &.extended {
    .mgifts--item__img {
      img {
        height: auto;
        width: 200px;
        object-fit: cover;
      }
    }

    .mgifts--item__info {
      margin-left: 20px;

      &__name,
      &__price {
        font-size: 18px;
      }

      &__price {
        img {
          height: 36px;
          width: 36px;
        }

        span + span {
          margin-left: 10px;
        }
      }
    }
  }
}

@media only screen and (max-width: 480px) {
  .mgifts--item {
    &.extended {
      .mgifts--item__img {
        img {
          width: 100px;
        }
      }
    }
  }
}
</style>
