<template>
  <div class="heading profile-new">
    <div
      class="relative-profile"
      :class="{
        'remove-padding': isVideoChat,
        'gallery-opened': galleryDisplayed,
      }"
    >
      <div
        id="default"
        class="info-box d-flex align-items-center justify-content-between"
      >
        <div class="user-info">
          <div class="item photo-item">
            <div
              class="user-avatar user-avatar-underline profile-pointer"
              @click.prevent="openModal(woman.id)"
            >
              <img
                class="user-avatar__img"
                :src="woman.profile.avatarList.img_medium"
                :alt="woman.profile.name + '\'s avatar'"
              />
              <span v-if="isOnline" class="is-online"></span>
              <!--            <span class="stroke hd hidden-lg hidden-md"
              :class="{'webcam-icon':!isWebCam,'hd':isWebCamHd}"></span>-->
            </div>
          </div>
          <div class="item" style="margin-left: 10px">
            <span
              class="name-box profile-pointer"
              @click.prevent="openModal(woman.id)"
            >
              <span class="uname">
                {{ woman.profile.name
                }}<span v-show="womanAge">, {{ womanAge }}</span>
                <span
                  v-show="woman.status"
                  style="margin-left: 10px; font-size: 1rem"
                >
                  ID:{{ woman.id }}
                </span>
              </span>
              <svg class="rbi" style="margin-left: 10px">
                <use
                  xlink:href="/static/assets/images/rbi-icon.svg#rbi-attention"
                />
              </svg>
            </span>
            <blockquote class="custom-status">
              <p v-if="woman.status">{{ woman.status }}</p>
              <p v-else class="uid">ID:{{ woman.id }}</p>
            </blockquote>
          </div>
        </div>
        <div class="user-dropdown hidden-lg hidden-md">
          <div class="dropdown user-info__dropdown">
            <button
              id="submenu"
              type="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <svg class="rbi">
                <use xlink:href="/static/assets/images/rbi-icon.svg#rbi-more" />
              </svg>
            </button>
            <ul
              class="dropdown-menu dropdown-buttons"
              aria-labelledby="submenu"
            >
              <li>
                <button
                  class="btn btn-common"
                  title="Send mail"
                  @click.prevent="sendMail"
                >
                  <svg class="rbi">
                    <use
                      xlink:href="/static/assets/images/rbi-icon.svg#rbi-message"
                    />
                  </svg>
                </button>
              </li>
              <li>
                <button
                  class="btn btn-common"
                  title="Send gift"
                  @click.prevent="sendGift"
                >
                  <svg class="rbi">
                    <use
                      xlink:href="/static/assets/images/rbi-icon.svg#rbi-gift"
                    />
                  </svg>
                </button>
              </li>
              <li>
                <button
                  class="btn btn-common"
                  :class="{ checked: isBookmark }"
                  :title="bookmarkActionTitle"
                  @click.prevent="bookmarkAction"
                >
                  <span v-show="isBookmark" class="checked-icon">
                    <svg class="rbi">
                      <use
                        xlink:href="/static/assets/images/rbi-icon.svg#rbi-check"
                      />
                    </svg>
                  </span>
                  <svg class="rbi">
                    <use
                      xlink:href="/static/assets/images/rbi-icon.svg#rbi-star"
                    />
                  </svg>
                </button>
              </li>
              <li>
                <button
                  class="btn btn-common"
                  :class="{ checked: winked }"
                  title="Send wink"
                  @click.prevent="sendWink"
                >
                  <span v-show="winked" class="checked-icon">
                    <svg class="rbi">
                      <use
                        xlink:href="/static/assets/images/rbi-icon.svg#rbi-check"
                      />
                    </svg>
                  </span>
                  <svg class="rbi">
                    <use
                      xlink:href="/static/assets/images/rbi-icon.svg#rbi-emoji"
                    />
                  </svg>
                </button>
              </li>
              <li>
                <button
                  class="btn btn-common"
                  :class="{ checked: isFavorite }"
                  :title="favoriteActionTitle"
                  @click.prevent="favoriteAction"
                >
                  <span v-show="isFavorite" class="checked-icon">
                    <svg class="rbi">
                      <use
                        xlink:href="/static/assets/images/rbi-icon.svg#rbi-check"
                      />
                    </svg>
                  </span>
                  <svg class="rbi">
                    <use
                      xlink:href="/static/assets/images/rbi-icon.svg#rbi-heart"
                    />
                  </svg>
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <Controls
        :woman="woman"
        @display-gallery-change="galleryDisplayChangeHandler"
      />
    </div>
  </div>
</template>

<script>
import {
  LAST_DIALOGS,
  AGE,
  IS_ONLINE,
  IS_BOOKMARK,
  IS_FAVORITE,
} from '../../../mixins/man_util';
import { GALLERY, PREVIEW_MODAL } from '../../../mixins/utils';
import { USER_ACTIONS } from '../../../mixins/userActions';
import Controls from './Controls';

export default {
  name: 'ProfileBox',
  components: {
    Controls,
  },
  mixins: [
    LAST_DIALOGS,
    AGE,
    IS_ONLINE,
    IS_BOOKMARK,
    IS_FAVORITE,
    GALLERY,
    USER_ACTIONS,
    PREVIEW_MODAL,
  ],
  props: {
    woman: Object,
  },
  gallery: null,
  data() {
    return {
      galleryDisplayed: false,
      enableType: null,
      winked: false,
      alreadySubscribed: false,
    };
  },
  computed: {
    bookmarkActionTitle() {
      if (this.mixinIsBookmark(this.woman.id)) {
        return 'Remove from bookmark';
      } else {
        return 'Add to bookmark';
      }
    },
    favoriteActionTitle() {
      if (this.mixinIsFavorite(this.woman.id)) {
        return 'Remove from favorite';
      } else {
        return 'Add to favorite';
      }
    },
    canPrivate() {
      return (
        this.$store.getters.dialogs.current.user.chat_type === 'video' &&
        this.$store.getters.dialogs.current.user.chat_status === 'active' &&
        this.$store.getters.user.type === 'man'
      );
    },
    womanOnlineCount() {
      return this.$store.getters.onlineWomanList.length;
    },

    lastDialogsUnreadMessage() {
      return this.$store.getters.lastDialogsUnreadMessage;
    },

    isActive() {
      return this.$store.getters.dialogs.current.user.chat_status === 'active';
    },
    isVideoChat() {
      return this.$store.getters.dialogs.current.user.chat_type === 'video';
    },
    isTextChat() {
      return this.$store.getters.dialogs.current.user.chat_type === 'text';
    },
    isOnline() {
      return this.mixinIsOnline(this.woman.last_active);
    },
    womanAge() {
      return this.mixinAge(this.woman.profile.birthday);
    },
    isWebCam() {
      let allOnline = this.$store.getters.onlineWomanList;
      let camera = false;
      allOnline.forEach((woman) => {
        if (
          woman.id == this.woman.id &&
          (woman.camera === 'yes' || woman.camera === 'hd')
        ) {
          camera = true;
        }
      });
      return camera;
    },
    isWebCamHd() {
      return this.woman.profile.camera === 'hd';
    },
    isFavorite() {
      let result = false;
      this.$store.getters.getAllFavorites.forEach((favorite) => {
        if (favorite === this.woman.id) {
          result = true;
        }
      });
      return result;
    },
    isBookmark() {
      let result = false;
      this.$store.getters.getAllBookmarks.forEach((bookmark) => {
        if (bookmark === this.woman.id) {
          result = true;
        }
      });
      return result;
    },
    needType() {
      return this.enableType;
    },
  },
  mounted() {
    this.askProfile(this.woman.id);
  },
  methods: {
    galleryDisplayChangeHandler(e) {
      this.galleryDisplayed = e;
    },
    responsiveShowUserOnline() {
      this.$store.dispatch('setResponsiveShowUserOnline', true);
    },
    responsiveShowLastDialogs() {
      this.$store.dispatch('setResponsiveShowLastDialogs', true);
    },

    favoriteAction() {
      let token = this.$store.getters.token.site_token;
      if (this.isFavorite) {
        this.$http
          .post('v1/favorite/delete', {
            access_token: token,
            favorite_user_id: this.woman.id,
          })
          .then((result) => {
            if (result.body.status) {
              let arrayAllFavorites = this.$store.getters.getAllFavorites;
              arrayAllFavorites.splice(
                arrayAllFavorites.indexOf(this.woman.id),
                1
              );
              this.$store.dispatch('setAllFavorites', arrayAllFavorites);
              this.$store.commit('REMOVE_FAVORITES', this.woman);
            }
          });
      } else {
        this.$http
          .post('v1/favorite/add', {
            access_token: token,
            favorite_user_id: this.woman.id,
          })
          .then((result) => {
            if (result.body.status) {
              let arrayAllFavorites = this.$store.getters.getAllFavorites;
              arrayAllFavorites.push(this.woman.id);
              this.$store.dispatch('setAllFavorites', arrayAllFavorites);
              this.$store.commit('SEND_FAVORITES', this.woman);
            }
          });
      }
    },
    bookmarkAction() {
      let token = this.$store.getters.token.site_token;
      if (this.isBookmark) {
        this.$http
          .post('v1/bookmark/delete', {
            access_token: token,
            bookmark_user_id: this.woman.id,
          })
          .then((result) => {
            if (result.body.status) {
              let arrayAllBookmarks = this.$store.getters.getAllBookmarks;
              arrayAllBookmarks.splice(
                arrayAllBookmarks.indexOf(this.woman.id),
                1
              );
              this.$store.dispatch('setAllBookmarks', arrayAllBookmarks);
              this.$store.commit('REMOVE_BOOKMARKS', this.woman);
            }
          });
      } else {
        this.$http
          .post('v1/bookmark/add', {
            access_token: token,
            bookmark_user_id: this.woman.id,
          })
          .then((result) => {
            if (result.body.status) {
              let arrayAllBookmarks = this.$store.getters.getAllBookmarks;
              arrayAllBookmarks.push(this.woman.id);
              this.$store.dispatch('setAllBookmarks', arrayAllBookmarks);
              this.$store.commit('SEND_BOOKMARKS', this.woman);
            }
          });
      }
    },
    sendWink() {
      this.winked = true;
      let token = this.$store.getters.token.site_token;
      this.$http
        .post('v1/wink/make', {
          access_token: token,
          target_user_id: this.woman.id,
        })
        .then((result) => {
          if (result.body.status) {
            //                        this.$store.commit('SEND_WINK', this.woman.id);
          }
        });
    },
    sendMail() {
      this.previewSendMail(true, {
        avatar: {
          img_small: this.woman.profile.avatarList.img_small,
        },
        name: this.woman.profile.name,
        user_id: this.woman.id,
      });
    },
    sendGift() {
      this.previewSendGift(true, this.woman.id);
    },
  },
};
</script>

<style lang="scss">
@import '../../../assets/scss/vars';

.profile-new {
  height: 100%;
  overflow: hidden;

  .profile-pointer {
    cursor: pointer;
  }

  .relative-profile {
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .remove-padding {
    padding: 0 !important;
  }

  .gallery-opened {
    justify-content: space-between;
    .info-box {
      display: none !important;
    }
    .control-block {
      height: 100%;
      .control-wrap {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
      }
    }
  }

  @media screen and (max-width: 425px) {
    .control-block {
      padding: 0 5px !important;
    }
    .info-box {
      height: 50%;
      overflow: hidden;
      .user-info {
        padding: 0 !important;
        height: 100%;
        overflow: hidden;
      }
      .user-avatar {
        width: 75px !important;
        height: auto !important;
      }
      .item {
        height: 100%;
      }
    }
  }

  .user-info {
    display: flex;
    width: 100%;

    .item {
      + .item {
        flex: 1 1 100%;
        margin-left: 25px;
      }
    }
    .user-avatar {
      width: 100px;
      height: 100px;
    }
  }

  .custom-status {
    margin-top: 20px;
    border-radius: 0;
    max-height: 54px;
    padding: 10px 15px;
    font-weight: normal;
    line-height: 1.6;
    overflow: hidden;
  }

  .name-box {
    display: flex;
    align-items: center;
    line-height: 1;
    font-weight: 700;
    color: $black;
    cursor: pointer;
    font-size: 20px;

    .uname {
      &:first-letter {
        color: $red;
      }
    }

    .rbi {
      font-size: 14px;
      margin-right: 0.714em;
    }
  }

  .control-block {
    padding: 10px 5px;

    .gallery-wrapper {
      position: relative;
      margin-bottom: 5px;
    }
    .slider-gallery {
      overflow: hidden;
    }

    &.no-border {
      position: absolute;
      bottom: 0;
      left: 0;
      right: -1px;
      padding: 0 25px;
      opacity: 1;
      transition: 0.3s;
      background: rgba(255, 255, 255, 0);
      background: -moz-linear-gradient(
        top,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 1) 100%
      );
      background: -webkit-gradient(
        left top,
        left bottom,
        color-stop(0%, rgba(255, 255, 255, 0)),
        color-stop(100%, rgba(255, 255, 255, 1))
      );
      background: -webkit-linear-gradient(
        top,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 1) 100%
      );
      background: -o-linear-gradient(
        top,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 1) 100%
      );
      background: -ms-linear-gradient(
        top,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 1) 100%
      );
      /* background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);*/
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#ffffff', GradientType=0);

      .control-wrap {
        border-top: none;
      }
    }

    .control-wrap {
      /* padding: 25px 0;
      border-top: 1px solid $grey-two; */
      position: relative;
    }

    .slider-btn-wrap {
      position: absolute;
      right: 0;
      bottom: 0;
      display: flex;
      background: $white;

      .slider-btn {
        background: none;
        border: none;
        display: flex;
        font-size: 25px;
      }

      .button-prev {
        .rbi {
          transform: rotate(90deg);
        }
      }

      .button-next {
        .rbi {
          transform: rotate(-90deg);
        }
      }
    }
  }

  .dialog-box .scrollable {
    padding: 0 25px;
  }

  .swiper-slide {
    .image-wrap {
      cursor: pointer;

      &:after {
        content: '';
        display: block;
        position: absolute;
        height: 3px;
        width: 25%;
        background: $red;
        bottom: -3px;
        left: 0;
      }

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .control-buttons {
    justify-content: space-between;
    position: static;

    .btn-common {
      background: transparent;
      box-shadow: none;
      border: 1px solid rgba($black, 0.5);
      border-radius: 50%;
      width: 36px;
      height: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: rgba($black, 0.5);
      position: relative;
      transition: 0.3s;
      margin: 0 8px !important;

      .checked-icon {
        position: absolute;
        top: 0;
        right: 0;
        width: 10px;
        height: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &:hover {
        border: 1px solid $red;
        color: $red;
      }

      &.checked,
      &:disabled {
        border: 1px solid rgba($black, 0.2);
        color: rgba($black, 0.2) !important;
        background: none !important;
      }
      &:disabled {
        cursor: not-allowed;
      }
    }

    .rbi {
      font-size: 16px;
    }

    svg {
      width: 1em;
      height: 1em;
    }

    .btn:disabled {
      background: $grey-two;
      color: $white;
    }

    .btn-primary {
      border: none;
      background: $red;
    }

    .toggle-slider {
      background: none;
      color: $black;
      width: auto;
      height: auto;
      -webkit-border-radius: 0;
      -moz-border-radius: 0;
      border-radius: 0;

      .rbi {
        transition: 0.3s;
      }

      &:active {
        box-shadow: none;
      }

      &.open {
        .rbi {
          transform: scaleY(-1);
        }
      }
    }

    .rounded-box {
      font-size: 16px;

      .active {
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
        background: $white;
        color: $red;
      }
    }
  }

  .heading > .relative {
    padding-bottom: 0;

    &:hover {
      .control-block.no-border {
        opacity: 1;
      }
    }
  }
}
</style>
