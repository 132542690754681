<template>
  <div class="video-container-stream">
    <div v-show="!chatActivated" class="start-video-chat-container-wrapper">
      <transition name="fade">
        <div class="start-video-chat-container">
          <div class="controls-wrapper desktop-controls">
            <WomanControls
              v-if="isWoman && currentDialog"
              :remote-video="chatType === 2 || chatType === 3 || chatType === 4"
              :chat-type="chatType"
            />
            <ManControls
              v-else-if="currentDialog && chatType !== 1 && chatType !== 2"
              :woman="currentDialog.user"
              :remote-video="remoteContainerVisible"
            />
          </div>
          <div class="message-to-start-video">
            <h2
              v-show="showVc"
              style="
                color: #828282;
                display: flex;
                justify-content: center;
                width: 100%;
              "
            >
              To start video chat send a message.
            </h2>
          </div>
        </div>
      </transition>
    </div>

    <div
      v-show="(calling || showLoader) && chatActivated"
      class="loader-wrapper"
    >
      <div style="width: 100%">
        <div
          class="ph-mes__loader loader"
          style="width: 60px; height: 60px"
        ></div>
      </div>
    </div>

    <div v-show="chatActivated" class="video-chat-container">
      <div class="controls-wrapper desktop-controls">
        <WomanControls
          v-if="isWoman && currentDialog"
          :remote-video="chatType === 2 || chatType === 3 || chatType === 4"
          :chat-type="chatType"
        />
        <ManControls
          v-else-if="currentDialog && chatType !== 1 && chatType !== 2"
          :woman="currentDialog.user"
          :remote-video="remoteContainerVisible"
        />
      </div>
      <transition name="fade">
        <div
          v-show="showVc && remoteContainerVisible"
          class="remote-video-container"
        >
          <WatchIndicator
            v-if="isWoman && localContainerVisible"
            :chat-activated="chatActivated"
            :chat-type="chatType"
          />
          <video
            v-show="chatActivated && !remoteVideoStreamEnded"
            id="remote-video"
            autoplay
            playsinline
            class="remote-video"
          ></video></div
      ></transition>
      <transition name="fade"
        ><div
          v-show="showVc && localContainerVisible"
          class="local-video-container"
        >
          <video
            id="local-video"
            autoplay
            playsinline
            muted
            class="local-video"
          ></video>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import WomanControls from '../../woman/chat-box/Controls.vue';
import ManControls from '../../man/boxes/Controls.vue';

import { servers } from '../../../utils/ICEServers';
import { v4 as uuidv4 } from 'uuid';
import WatchIndicator from './WatchIndicator';

const NOT_ACTIVE_CHAT = 0;
const TEXT_CHAT_STANDARD = 1;
const TEXT_CHAT_MAN_STREAM = 2;
const VIDEO_CHAT_WOMAN_STREAM = 3;
const VIDEO_CHAT_WOMAN_AND_MAN_STREAM = 4;

export default {
  name: 'VideoContainer',

  components: {
    WatchIndicator,
    WomanControls,
    ManControls,
  },

  data: () => ({
    localVideoContainer: null,
    remoteVideoContainer: null,
    absoluteContainer: null,

    localStream: undefined,
    remoteStream: undefined,
    frontCamera: true,

    calling: false,

    configuration: servers,
    constraints: {
      audio: false,
      video: {
        width: 1280,
        height: 720,
        facingMode: 'user',
      },
    },

    offerOptions: {
      offerToReceiveAudio: false,
      offerToReceiveVideo: true,
      iceRestart: true,
    },

    answerOptions: {
      iceRestart: true,
    },

    retryAttemptsCounter: 0,
    maxRetryAttemptsCounter: 6,

    showVc: false,

    reconnectInterval: null,
  }),

  computed: {
    peerConnections() {
      return this.$store.getters.peerConnections;
    },

    currentDialog() {
      return this.$store.getters.dialogs.current;
    },

    username() {
      return this.$store.getters.user
        ? this.$store.getters.user.profile.name
        : null;
    },

    signaling() {
      return this.$store.getters.signaling;
    },

    iceCandidate() {
      return this.$store.getters.iceCandidate;
    },

    collocutor() {
      return this.$store.getters.dialogs.current
        ? this.$store.getters.dialogs.current.user.id
        : null;
    },

    user() {
      return this.$store.getters.user ? this.$store.getters.user : null;
    },

    isWoman() {
      return this.$store.getters.user.type === 'woman';
    },

    peerRemoteStreamIndex() {
      return this.peerConnections.findIndex(
        (peerConnection) =>
          peerConnection.type === 'incoming' &&
          peerConnection.userId === this.collocutor
      );
    },

    remoteVideoStreamEnded() {
      if (!this.remoteStream) return true;

      const tracks = this.remoteStream.getVideoTracks();

      if (!tracks || tracks.length < 1) return true;

      return tracks[0].readyState === 'ended';
    },

    connectedToStreamServer() {
      return this.$store.getters.getStreamServerConnected;
    },

    connectedToSocketIO() {
      return this.$store.getters.connectedToSocket === true;
    },

    collocutorOnline() {
      if (!this.activeUsers || this.activeUsers.length < 1) return false;

      const user = this.activeUsers.find(
        (user) => user.userId === this.collocutor
      );
      return !!user;
    },

    collocutorCameraOn() {
      if (!this.activeUsers) return false;

      const user = this.activeUsers.find(
        (user) => user.userId === this.collocutor && user.userCameraActive
      );

      return user && user.userCameraActive;
    },

    dialogChatType() {
      return this.currentDialog ? this.currentDialog.user.chat_type : 'text';
    },

    chatType() {
      if (
        !this.currentDialog ||
        !this.chatActivated ||
        !this.connectedToStreamServer ||
        !this.connectedToSocketIO
      )
        return NOT_ACTIVE_CHAT;

      if (!this.collocutorOnline) return TEXT_CHAT_STANDARD;

      if (this.isWoman) {
        return this.generateWomanChatType();
      } else {
        return this.generateManChatType();
      }
    },

    localContainerVisible() {
      if (this.isWoman) {
        return (
          (this.chatType === TEXT_CHAT_MAN_STREAM && this.cameraOn) ||
          this.chatType === VIDEO_CHAT_WOMAN_STREAM ||
          this.chatType === VIDEO_CHAT_WOMAN_AND_MAN_STREAM
        );
      } else {
        return this.chatType === VIDEO_CHAT_WOMAN_AND_MAN_STREAM;
      }
    },

    remoteContainerVisible() {
      if (!this.isWoman) return true;

      return this.chatType === 4 || this.chatType === 2;
    },

    chatActivated() {
      return (
        this.$store.getters.dialogs.current &&
        this.$store.getters.dialogs.current.active
      );
    },

    cameraOn() {
      return this.$store.getters.getUserCameraIsActive;
    },

    activeUsers() {
      return this.$store.getters.activeUsersList.length > 0
        ? this.$store.getters.activeUsersList
        : null;
    },

    showLoader() {
      if (this.isWoman) {
        if (this.chatType === 4 || this.chatType === 2) {
          return this.chatActivated && !this.remoteStream;
        } else {
          return false;
        }
      } else {
        return this.chatActivated && !this.remoteStream;
      }
    },

    devMode() {
      return this.$store.getters.devModeEnabled;
    },
  },

  watch: {
    connectedToSocketIO(connected) {
      if (connected) {
        this.connectToRTCServer();
      } else {
        this.$store.dispatch('disconnectFromRTCServerSocket');
      }
    },

    connectedToStreamServer(newConnectedValue) {
      if (newConnectedValue === false)
        this.reconnectInterval = setInterval(() => {
          if (!this.connectedToStreamServer && this.connectedToSocketIO) {
            this.connectToRTCServer();
          } else {
            clearInterval(this.reconnectInterval);
            this.reconnectInterval = null;
          }
        }, 10000);
    },

    cameraOn: async function (newVal, oldVal) {
      this.$log.info('On camera status change', oldVal, newVal);
      if (newVal === false) {
        await this.resetConnectionFull();
        await this.$store.dispatch('emitEventToStreamServer', {
          event: 'user_camera_deactivation',
          data: {
            userId: this.$store.getters.user.id,
            chatToken: this.$store.getters.token.chat_token,
          },
        });
        if (this.isWoman) this.$socket.emit('disable_camera', {});
        else {
          this.$socket.emit('man_camera_status_change', false);
        }
      } else {
        this.getUserMedia()
          .then((mediaResult) => {
            this.$log.info('Get user media result', mediaResult);
            this.$store.dispatch('emitEventToStreamServer', {
              event: 'user_camera_activation',
              data: {
                userId: this.$store.getters.user.id,
                chatToken: this.$store.getters.token.chat_token,
              },
            });
            if (!this.isWoman) {
              this.$socket.emit('man_camera_status_change', true);
            }
          })
          .catch((err) => {
            this.$log.error(err);
            this.$store.commit('updateGetUserMediaModal', {
              open: true,
            });
            this.$store.commit('setUserCameraActive', false);
            this.$store.dispatch('emitEventToStreamServer', {
              event: 'user_camera_deactivation',
              data: {
                userId: this.$store.getters.user.id,
                chatToken: this.$store.getters.token.chat_token,
              },
            });
            if (this.isWoman) this.$socket.emit('disable_camera', {});
            else {
              this.$socket.emit('man_camera_status_change', false);
            }
          });
      }
    },

    chatType: async function (newChatType, oldChatType) {
      this.$log.info('On chat type change', oldChatType, newChatType);

      if (!this.isWoman) {
        await this.initVideo(newChatType);
        if (
          newChatType === TEXT_CHAT_STANDARD ||
          newChatType === NOT_ACTIVE_CHAT
        ) {
          await this.$store.dispatch('closePeerConnection', {
            userId: this.collocutor,
            type: 'incoming',
          });
          await this.$store.dispatch('closePeerConnection', {
            userId: this.collocutor,
            type: 'outcoming',
          });
        } else if (newChatType === TEXT_CHAT_MAN_STREAM) {
          await this.$store.dispatch('closePeerConnection', {
            userId: this.collocutor,
            type: 'incoming',
          });
        }
      }
    },

    collocutor: async function (newVal, oldVal) {
      this.$log.info('Collocutor change', oldVal, newVal);
      if (!this.isWoman) await this.initVideo(this.chatType);
    },

    peerRemoteStreamIndex: function (newVal, oldVal) {
      this.$log.info('On peer remote stream.', oldVal, newVal);

      if (newVal === -1) {
        this.remoteStream = null;
        return;
      }

      try {
        const receivers =
          this.peerConnections[newVal].connection.getReceivers();

        const tracks = [];

        receivers.forEach((rtp) => {
          tracks.push(rtp.track);
        });

        this.remoteStream = new MediaStream(tracks);
        this.remoteVideoContainer.srcObject = this.remoteStream;
      } catch (e) {
        this.$log.error(e);
        this.remoteStream = null;
      }
    },

    iceCandidate: function (newVal) {
      setTimeout(() => {
        const candidate = newVal.candidate;
        const type = newVal.peerType;
        const from = newVal.from;
        const peerId = newVal.peerId;

        const userFrom = this.activeUsers.find((item) => item.userId === from);

        const userFromSocketId = userFrom.socketId;

        this.$log.info('SOCKET!=>>>', userFromSocketId, type, peerId);

        const usersWithSameSocket = this.activeUsers.filter(
          (item) => item.socketId === userFromSocketId
        );

        let existingPeerIndex = this.peerConnections.findIndex(
          (peer) => peer.userId === from && peer.peerId === peerId
        );

        if (existingPeerIndex === -1) {
          usersWithSameSocket.forEach((user) => {
            const peerIndexLookUp = this.peerConnections.findIndex(
              (peer) => peer.userId === user.userId
            );
            if (peerIndexLookUp !== -1) existingPeerIndex = peerIndexLookUp;
          });
        }

        if (this.devMode) {
          this.$store.commit('addAlert', {
            type: 'success',
            text: `CANDIDATE INCOME! From: ${from}. Peer index: ${existingPeerIndex}`,
            info: 'Stream socket connected',
          });
        }

        if (existingPeerIndex !== -1) {
          this.$log.info(candidate);
          this.peerConnections[existingPeerIndex].connection
            .addIceCandidate(candidate)
            .then(() => {
              if (this.devMode)
                this.$log.info(
                  'ON INCOMING ICE CANDIDATE PEER:',
                  this.peerConnections[existingPeerIndex]
                );
            })
            .catch((e) => {
              this.$log.error(e);
            });
        }
      }, 1000);
    },

    signaling: function (newVal) {
      const desc = newVal.remoteDesc;
      const from = newVal.from;
      const type = newVal.peerType;
      const peerId = newVal.peerId;

      const userFrom = this.activeUsers.find((item) => item.userId === from);

      const userFromSocketId = userFrom.socketId;

      this.$log.info('SOCKET!=>>>', userFromSocketId);

      const usersWithSameSocket = this.activeUsers.filter(
        (item) => item.socketId === userFromSocketId
      );

      let existingPeerIndex = this.peerConnections.findIndex(
        (peer) => peer.userId === from && peer.peerId === peerId
      );

      if (existingPeerIndex === -1) {
        usersWithSameSocket.forEach((user) => {
          const peerIndexLookUp = this.peerConnections.findIndex(
            (peer) => peer.userId === user.userId && peer.peerId === peerId
          );
          if (peerIndexLookUp !== -1) existingPeerIndex = peerIndexLookUp;
        });
      }

      if (desc.type === 'offer') {
        if (existingPeerIndex === -1)
          this.handleAnswer(desc, from, type, peerId);
      } else if (desc.type === 'answer') {
        if (existingPeerIndex !== -1) {
          this.peerConnections[
            existingPeerIndex
          ].connection.setRemoteDescription(desc);
          if (this.devMode)
            this.$log.info('PEER:', this.peerConnections[existingPeerIndex]);
        }
      } else {
        this.$log.error('Unknown desc type.');
      }
    },
  },

  async mounted() {
    this.localVideoContainer = document.getElementById('local-video');
    this.remoteVideoContainer = document.getElementById('remote-video');
    this.absoluteContainer = document.getElementById('absolute-video');
    this.showVc = true;

    if (this.connectedToSocketIO) await this.connectToRTCServer();
  },

  beforeDestroy() {
    clearInterval(this.reconnectInterval);
    this.$store.commit('setUserCameraActive', false);
    this.resetConnectionFull();
  },

  methods: {
    async connectToRTCServer() {
      try {
        await this.$store.dispatch('connectToRTCServerSocket', false);
        setTimeout(() => {
          this.$store.dispatch('registerOnVideoServer');
        }, 2000);
      } catch (err) {
        this.$log.error(err);
      }
    },

    generateWomanChatType() {
      if (this.dialogChatType === 'text') {
        if (this.collocutorCameraOn) {
          // This condition has difference between man/woman methods
          // If man camera on
          return TEXT_CHAT_MAN_STREAM;
        } else {
          return TEXT_CHAT_STANDARD;
        }
      }

      if (this.dialogChatType === 'video') {
        if (this.cameraOn && this.collocutorCameraOn) {
          // If woman camera on and man is watching woman stream
          return VIDEO_CHAT_WOMAN_AND_MAN_STREAM;
        } else if (this.cameraOn) {
          // This condition has difference between man/woman methods
          // Man camera is off and man is watching woman stream
          return VIDEO_CHAT_WOMAN_STREAM;
        } else {
          return TEXT_CHAT_STANDARD;
        }
      }
    },

    generateManChatType() {
      if (this.dialogChatType === 'text') {
        // Man selected text-chat
        if (this.cameraOn) {
          // This condition has difference between man/woman methods
          // If man camera on
          return TEXT_CHAT_MAN_STREAM;
        } else {
          return TEXT_CHAT_STANDARD;
        }
      }

      if (this.dialogChatType === 'video') {
        // Man selected video-chat
        if (this.cameraOn && this.collocutorCameraOn) {
          // If man camera on and man is watching woman stream
          return VIDEO_CHAT_WOMAN_AND_MAN_STREAM;
        } else if (this.collocutorCameraOn) {
          // This condition has difference between man/woman methods
          // Man camera is off and man is watching woman stream
          return VIDEO_CHAT_WOMAN_STREAM;
        } else if (this.cameraOn) {
          return TEXT_CHAT_MAN_STREAM; // Protect from outcoming peer closing on chat type change from server.
        } else {
          return TEXT_CHAT_STANDARD;
        }
      }
    },

    async handleAnswer(desc, from, offerType, peerId) {
      await this.createAnswer(desc, from, offerType, peerId);
    },

    async getUserMedia() {
      if (this.devMode)
        this.$log.info(`Requesting ${this.username} video stream`);

      if ('mediaDevices' in navigator) {
        try {
          const stream = await navigator.mediaDevices.getUserMedia(
            this.constraints
          );

          if (stream) {
            this.localVideoContainer.srcObject = stream;

            this.absoluteContainer.srcObject = stream;

            this.localStream = stream;
            return 'Received local video stream';
          } else {
            throw new Error('Failed to get camera stream');
          }
        } catch (error) {
          throw new Error(`Get user media fail: ${error.toString()}`);
        }
      } else {
        throw new Error('Can not find any media device.');
      }
    },

    async initVideo(chatType) {
      const OUTCOMING = 'outcoming';
      const INCOMING = 'incoming';

      if (chatType === 4) {
        if (!this.checkPeerAlreadyExists(this.collocutor, INCOMING)) {
          await this.createOffer(INCOMING, this.collocutor);
        }
        if (!this.checkPeerAlreadyExists(this.collocutor, OUTCOMING)) {
          setTimeout(async () => {
            await this.createOffer(OUTCOMING, this.collocutor);
          }, 2000);
        }
      } else if (chatType === 3) {
        // Request to get woman stream
        if (!this.checkPeerAlreadyExists(this.collocutor, INCOMING)) {
          setTimeout(async () => {
            await this.createOffer(INCOMING, this.collocutor);
          }, 2000);
        }
      } else if (chatType === 2) {
        if (!this.checkPeerAlreadyExists(this.collocutor, OUTCOMING)) {
          setTimeout(async () => {
            await this.createOffer(OUTCOMING, this.collocutor);
          }, 2000);
        }
      } else {
        if (this.devMode) this.$log.info('Simple chat.');
      }
    },

    peerRestart(userId, type) {
      if (this.connectedToStreamServer) {
        this.retryAttemptsCounter++;
        if (this.retryAttemptsCounter !== this.maxRetryAttemptsCounter) {
          this.calling = true;
          if (this.checkPeerAlreadyExists(userId, type)) {
            this.$store
              .dispatch('closePeerConnection', { userId, type })
              .then(() => {
                this.createOffer(type, userId);
              });
          } else {
            this.createOffer(type, userId);
          }
        } else {
          this.retryAttemptsCounter = 0;

          this.$store.commit('updateBadConnectionModal', { open: true });

          this.calling = false;

          if (type === 'outcoming')
            this.$store.commit('setUserCameraActive', false);
          else {
            const dialog = this.$store.getters.dialogs.list.find(
              (dialog) => dialog.user.id === userId
            );

            this.$socket.emit('change_chat_type', {
              token: this.$store.getters.session.site_token,
              collocutor: this.woman.id,
              new_chat_type: 'text',
              roomId: dialog.roomId,
            });
          }
        }
      } else {
        setTimeout(() => {
          this.peerRestart(userId, type);
        }, 15000);
      }
    },

    createPeerConnection(userId, type) {
      const peer = new RTCPeerConnection(this.configuration);
      const context = this;
      peer.addEventListener('iceconnectionstatechange', (event) => {
        this.$log.info('Event:', event.currentTarget.iceConnectionState);

        if (context.devMode) {
          this.$log.info('Ice connection state changed.', peer);
          this.$store.commit('addAlert', {
            type: 'success',
            text: `Ice connection state changed -> ${peer.iceConnectionState} ${type}`, // TODO: Make initialization repeat on failed/closed
            info: 'ice connection state change.',
          });
        }

        if (peer.iceConnectionState === 'checking') {
          context.calling = true;
        }

        if (peer.iceConnectionState === 'connected') {
          context.calling = false;
        }

        if (
          peer.iceConnectionState === 'failed' ||
          peer.iceConnectionState === 'closed' ||
          peer.iceConnectionState === 'disconnected'
        ) {
          this.$log.info('RESTART!');

          if (!this.isWoman) {
            this.peerRestart(userId, type);
          }

          this.$store.dispatch('closePeerConnection', { userId, type });
        }

        this.$log.info(userId, type, peer.iceConnectionState);
      });
      peer.addEventListener('icegatheringstatechange', () => {
        this.$log.info('GATHERING===>', peer.iceGatheringState, peer);
      });
      peer.addEventListener('negotiationneeded', () => {
        this.$log.info('NEGOTIAION NEEDED!!!!');
      });
      return peer;
    },

    checkPeerAlreadyExists(userId, peerType) {
      const existingIncomingConnectionIndex =
        this.$store.getters.peerConnections.findIndex(
          (peer) => peer.userId === userId && peer.type === peerType
        );

      if (existingIncomingConnectionIndex !== -1) {
        this.$log.info('Exists');
        return true;
      } else {
        this.$log.info('Not exists');
        const userSocketRecord = this.activeUsers.find(
          (user) => user.userId === userId
        );

        this.$log.info('userSocketRecord', userSocketRecord);

        const socketsWithSameId = this.activeUsers.filter(
          (item) =>
            item.socketId === userSocketRecord.socketId &&
            item.userId !== userId
        );

        this.$log.info('socketsWithSameId', socketsWithSameId);

        if (!socketsWithSameId || socketsWithSameId.length < 1) return false;

        let socketsWithSameIdHasTheSamePeerType = false;

        socketsWithSameId.forEach((item) => {
          if (
            this.$store.getters.peerConnections.findIndex(
              (peer) => peer.type === peerType && item.userId === peer.userId
            ) !== -1
          ) {
            this.$log.info('Socket with same id and peer type', item);
            socketsWithSameIdHasTheSamePeerType = true;
          }
        });

        return socketsWithSameIdHasTheSamePeerType;
      }
    },

    async createOffer(peerType, userId) {
      this.calling = true;

      this.$log.info(
        `${this.username} attempt to create ${peerType} offer to ${userId} start.`
      );

      const peerConnection = this.createPeerConnection(userId, peerType);
      const peerId = uuidv4();

      let offer;
      if (peerType === 'incoming') {
        offer = await peerConnection.createOffer(this.offerOptions);
      } else {
        if (this.localStream) {
          peerConnection.addStream(this.localStream);
          offer = await peerConnection.createOffer(this.answerOptions);
        } else {
          throw new Error('No local stream');
        }
      }

      await peerConnection.setLocalDescription(offer);

      this.sendSignalingMessage(
        peerConnection.localDescription,
        peerType,
        userId,
        peerId
      );

      this.onIceCandidates(peerConnection, peerType, userId, peerId);

      this.$store.commit('addPeerConnection', {
        peerId,
        userId,
        type: peerType,
        connection: peerConnection,
      });
    },

    async createAnswer(desc, from, offerType, offerPeerId) {
      try {
        const peerType = offerType === 'incoming' ? 'outcoming' : 'incoming';
        const peerConnection = this.createPeerConnection(from, peerType);

        if (offerType === 'outcoming') {
          this.calling = true;
        }

        this.onIceCandidates(peerConnection, offerType, from, offerPeerId);

        this.$log.info(this.localStream);

        if (offerType === 'incoming') {
          if (this.localStream) peerConnection.addStream(this.localStream);
          else {
            throw new Error('Failed to get stream');
          }
        }

        await peerConnection.setRemoteDescription(desc);

        const answer = await peerConnection.createAnswer(this.answerOptions);

        await peerConnection.setLocalDescription(answer);

        this.sendSignalingMessage(
          peerConnection.localDescription,
          offerType,
          from,
          offerPeerId
        );

        this.$store.commit('addPeerConnection', {
          userId: from,
          type: peerType,
          connection: peerConnection,
          peerId: offerPeerId,
        });

        this.$log.info('PEER:', peerConnection);
      } catch (error) {
        this.$log.error(
          `Error creating the answer from ${from}. Error: ${error}`
        );
      }
    },

    sendSignalingMessage(desc, peerType, to, peerId) {
      this.$store.dispatch('emitEventToStreamServer', {
        event: 'signaling',
        data: {
          desc: desc,
          to: to,
          from: this.user.id,
          peerType,
          peerId,
        },
      });
    },

    onIceCandidates(peer, type, user, peerId) {
      peer.onicecandidate = ({ candidate }) => {
        if (candidate !== null) {
          this.$store.dispatch('emitEventToStreamServer', {
            event: 'ice',
            data: {
              candidate,
              to: user,
              from: this.user.id,
              peerType: type,
              peerId,
            },
          });
        }
      };
      return peer;
    },

    resetConnectionFull() {
      return new Promise((res) => {
        this.$store.dispatch('closeOutcomingPeerConnections');

        if (this.localStream) {
          const tracks = this.localStream.getTracks();
          if (tracks.length > 0) {
            tracks.forEach((track) => {
              this.$log.info('On track stop');
              track.stop();
            });
          }
          this.localStream = null;
        }

        res(true);
      });
    },
  },
};
</script>

<style lang="scss">
@import '../../../assets/scss/vars';

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.video-container-stream {
  position: relative;
  height: 100%;
  overflow: hidden;
  z-index: 30;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.start-video-chat-container {
  .controls-wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
    background: transparent;

    .control-buttons {
      justify-content: space-between;
      position: static;

      .btn-common {
        background: transparent;
        box-shadow: none;
        border: 1px solid rgba($black, 0.5);
        border-radius: 50%;
        width: 36px;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: rgba($black, 0.5);
        position: relative;
        transition: 0.3s;
        margin: 0 8px !important;

        .checked-icon {
          position: absolute;
          top: 0;
          right: 0;
          width: 10px;
          height: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        &:hover {
          border: 1px solid $red;
          color: $red;
        }

        &.checked,
        &:disabled {
          border: 1px solid rgba($black, 0.2);
          color: rgba($black, 0.2) !important;
          background: none !important;
        }
        &:disabled {
          cursor: not-allowed;
        }
      }

      .rbi {
        font-size: 16px;
      }

      svg {
        width: 1em;
        height: 1em;
      }

      .btn:disabled {
        background: $grey-two;
        color: $white;
      }

      .btn-primary {
        border: none;
        background: $red;
      }

      .toggle-slider {
        background: none;
        color: $black;
        width: auto;
        height: auto;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        border-radius: 0;

        .rbi {
          transition: 0.3s;
        }

        &:active {
          box-shadow: none;
        }

        &.open {
          .rbi {
            transform: scaleY(-1);
          }
        }
      }

      .rounded-box {
        font-size: 16px;
        background: #efefef;

        .active {
          -webkit-box-shadow: none;
          -moz-box-shadow: none;
          box-shadow: none;
          background: $white;
          color: $red;
        }
      }
    }
  }
}

.controls-wrapper {
  padding: 5px 0;
  z-index: 25;
  background: rgba(0, 0, 0, 0.32);
  border-radius: 30px;

  .gallery-opened {
    justify-content: space-around;
    .info-box {
      display: none !important;
    }
    .control-block {
      height: 100%;
      .control-wrap {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
      }
    }
  }

  @media screen and (max-width: 425px) {
    .control-block {
      padding: 0 5px !important;
    }
  }

  .control-block {
    padding: 2px 5px;

    .gallery-wrapper {
      position: relative;
      // margin-bottom: 5px;
    }
    .slider-gallery {
      overflow: hidden;
    }

    &.no-border {
      position: absolute;
      bottom: 0;
      left: 0;
      right: -1px;
      padding: 0 25px;
      opacity: 1;
      transition: 0.3s;
      background: rgba(255, 255, 255, 0);
      background: -moz-linear-gradient(
        top,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 1) 100%
      );
      background: -webkit-gradient(
        left top,
        left bottom,
        color-stop(0%, rgba(255, 255, 255, 0)),
        color-stop(100%, rgba(255, 255, 255, 1))
      );
      background: -webkit-linear-gradient(
        top,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 1) 100%
      );
      background: -o-linear-gradient(
        top,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 1) 100%
      );
      background: -ms-linear-gradient(
        top,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 1) 100%
      );
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#ffffff', GradientType=0);

      .control-wrap {
        border-top: none;
      }
    }

    .control-wrap {
      position: relative;
    }

    .slider-btn-wrap {
      position: absolute;
      right: 0;
      bottom: 0;
      display: flex;
      background: $white;

      .slider-btn {
        background: none;
        border: none;
        display: flex;
        font-size: 25px;
      }

      .button-prev {
        .rbi {
          transform: rotate(90deg);
        }
      }

      .button-next {
        .rbi {
          transform: rotate(-90deg);
        }
      }
    }
  }

  .swiper-slide {
    .image-wrap {
      cursor: pointer;

      &:after {
        content: '';
        display: block;
        position: absolute;
        height: 3px;
        width: 25%;
        background: $red;
        bottom: -3px;
        left: 0;
      }

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .control-buttons {
    justify-content: space-between;
    position: static;

    .btn-common {
      background: transparent;
      box-shadow: none;
      border: 1px solid rgba($white, 0.5);
      border-radius: 50%;
      width: 36px;
      height: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: rgba($white, 0.5);
      position: relative;
      transition: 0.3s;
      margin: 0 8px !important;

      .checked-icon {
        position: absolute;
        top: 0;
        right: 0;
        width: 10px;
        height: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &:hover {
        border: 1px solid $red;
        color: $red;
      }

      &.checked,
      &:disabled {
        border: 1px solid rgba($white, 0.5);
        color: rgba($white, 0.5) !important;
        background: none !important;
      }
      &:disabled {
        cursor: not-allowed;
      }
    }

    .rbi {
      font-size: 16px;
    }

    svg {
      width: 1em;
      height: 1em;
    }

    .btn:disabled {
      background: $grey-two;
      color: $white;
    }

    .btn-primary {
      border: none;
      background: $red;
    }

    .toggle-slider {
      background: none;
      color: $black;
      width: auto;
      height: auto;
      -webkit-border-radius: 0;
      -moz-border-radius: 0;
      border-radius: 0;

      .rbi {
        transition: 0.3s;
      }

      &:active {
        box-shadow: none;
      }

      &.open {
        .rbi {
          transform: scaleY(-1);
        }
      }
    }

    .rounded-box {
      font-size: 16px;
      background: rgba(255, 255, 255, 0.3);

      .btn-primary {
        color: $red;
      }

      .active {
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
        background: $white;
        color: $red;
      }
    }
  }
}
.desktop-controls {
  position: absolute;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.chat-type-switch-video {
  position: absolute;
  bottom: 15px;
  left: 15px;
  z-index: 300;
  display: none !important;
}
.video-chat-container {
  background: rgba(0, 0, 0, 0.75);
  position: relative;
  display: flex;
  height: 100%;
  overflow: hidden;
  width: 100%;
  justify-content: center;
}
.local-video-container {
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 15px;
  right: 15px;
  height: 25%;
  overflow: hidden;
  background: rgba(26, 26, 26, 0.55);
  border: 1px solid #b6b6b6;
  border-radius: 5px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
}

.local-video {
  max-width: 100%;
  height: 100%;
}

.local-single-woman {
  position: relative;
  right: auto;
  left: auto;
  top: 0px;
}

.local-video-single {
  border: 1px solid #b6b6b6;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  max-width: 100%;
  width: auto;
  height: auto;
}

.remote-video-container {
  display: flex;
  justify-content: center;
  min-height: 100%;
}

.remote-video {
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  border-bottom: 1px solid transparent;
  border-top: none;
  max-width: 100%;
  max-height: 100%;
}

.flip-camera-wrapper {
  display: none;
}

.loader-wrapper {
  z-index: 10;
  position: absolute;
  top: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  height: 100%;
}

.start-video-chat-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.start-video-chat-container-wrapper {
  height: 100%;
  overflow: hidden;
  width: 100%;
}

.non-desktop-controls {
  display: none;
}

@media screen and (max-width: 992px) {
  .desktop-controls {
    // display: none;
    bottom: 0;
    justify-content: flex-end;
  }
  .video-container-stream {
    width: 100%;

    .control-wrap {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .rounded-box {
        background: rgba(0, 0, 0, 0.47);
        .btn {
          svg {
            height: 15px;
            width: 15px;
          }
        }
      }

      .control-buttons {
        height: 100%;
        margin: 0;
        padding: 0;
        .sidebar-controls {
          height: 100%;
          .online-list-btn,
          .last-chats-button {
            .icon {
              background: rgba(0, 0, 0, 0.48) !important;
              border-color: white !important;
              color: white;
              svg {
                fill: white;
              }
            }
            .controls-label {
              display: none !important;
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 426px) and (max-width: 992px) {
  .controls-wrapper {
    width: 100%;
    background: transparent;
  }
  .chat-type-switch-video {
    position: absolute;
    bottom: 5px;
    left: 5px;
    opacity: 0.6;
    background: transparent;
    width: 100%;
    display: flex !important;
    justify-content: center;
  }
  .chat-type-switch-video .rounded-box {
    background: transparent;
  }
  .remote-video {
    border-top: 1px solid white;
    overflow: hidden;
  }
  .local-video-container {
    bottom: 10px;
  }

  .video-container-stream {
    margin-top: 0px;
    overflow: hidden;
    z-index: 30;
    height: 100%;
  }
}

@media screen and (max-height: 425px) and (max-width: 992px) and (min-width: 425px) {
  /*Horizontal mobile*/

  .remote-video-container {
    position: relative;
    bottom: 0;
    height: 100%;
    z-index: -15;
  }
  .remote-video {
    width: 100%;
    height: 100%;
    overflow-y: hidden;
  }

  .local-video-container {
    background: transparent;
    border: none;
    box-shadow: none;
    height: 40%;
    max-width: 20%;
    right: 5px;
    top: 5px;
    display: flex;
    justify-content: center;
  }
  .local-video {
    width: auto;
  }
  .local-single-woman {
    position: relative;
    right: auto;
    left: auto;
    top: auto;
    bottom: 0;
    min-height: unset;
    z-index: -15;
  }

  .local-video-single {
    border: 1px solid #b6b6b6;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    width: 100%;
    height: 100%;
    min-height: unset;
    overflow-y: hidden;
  }
  .video-chat-container {
    z-index: 2;
    background: black;
  }
  .video-container-stream {
    margin-top: 1px;
    z-index: 1;
    width: 100%;
    .controls-wrapper {
      padding: 2px 0;
    }
    .control-buttons {
      .rounded-box {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        padding: 4px !important;
        background: transparent !important;
        .btn {
          height: 30px;
          width: 30px;
          svg {
            height: 15px;
            width: 15px;
          }
        }
      }
    }
  }
  .controls-wrapper {
    top: auto;
    bottom: 0;
  }
  .chat-type-switch-video {
    width: auto;
    left: auto;
    right: auto;
  }
  .start-video-chat-container {
    position: relative;
    z-index: 2;
    flex-direction: row;
  }
  .start-video-chat-container .message-to-start-video {
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
  }
}

@media screen and (max-width: 426px) {
  /*Mobile*/
  .chat-type-switch-video {
    display: flex !important;
    position: absolute;
    bottom: 15px;
    left: 15px;
    opacity: 0.6;
    z-index: 300;
  }
  .controls-wrapper {
    position: absolute;
    width: 100%;
    background: transparent;
  }
  .loader-wrapper {
    z-index: 10;
    position: absolute;
    top: 10px;
  }
  .remote-video {
    border: none;
    border-radius: unset;
  }
  .video-container-stream {
    margin-top: 0;
    overflow: hidden;
    z-index: 30;
  }
  .local-video {
    box-shadow: none;
    border: none;
    max-height: 100%;
  }
  .local-video-container {
    height: 100px;
    top: 5px;
    right: 5px;
    bottom: unset;
  }
  .local-single-woman {
    position: relative;
    height: auto;
    right: auto;
    left: auto;
    top: 0px;
  }
  .video-chat-container {
    position: relative;
    display: flex;
    height: 100%;
    overflow: hidden;
    width: 100%;
    justify-content: center;
  }
  .flip-camera-wrapper {
    display: flex;
    justify-content: flex-end;
    position: absolute;
    width: 100%;
    bottom: 2px;
    right: 2px;
    z-index: 15000;
  }
  .flip-camera-wrapper button {
    background: none;
    outline: none;
    border: none;
  }
}
</style>
