<template>
  <div class="virtual-gifts">
    <div class="virtual-gifts__heading">
      <h2 class="virtual-gifts__header">
        {{ categoryName }} <span>({{ gifts.length }})</span>
      </h2>
      <div class="virtual-gifts__nav">
        <button
          slot="button-prev"
          class="nav-btn prev-btn"
          :class="{ hidden: gifts.length < 5 }"
        >
          <svg class="rbi flip-left">
            <use
              xlink:href="/static/assets/images/rbi-icon.svg#rbi-arrow"
            ></use>
          </svg>
        </button>
        <button
          slot="button-next"
          class="nav-btn next-btn"
          :class="{ hidden: gifts.length < 5 }"
        >
          <svg class="rbi flip-right">
            <use
              xlink:href="/static/assets/images/rbi-icon.svg#rbi-arrow"
            ></use>
          </svg>
        </button>
      </div>
    </div>
    <div class="virtual-gifts__body">
      <div class="swiper-container virtual-gifts__slider">
        <div class="swiper-wrapper">
          <div v-for="gift in gifts" :key="gift.id" class="swiper-slide">
            <div class="img-wrapper" @click="selectGift(gift)">
              <img :src="gift.photo" :alt="gift.name" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper, { Navigation } from 'swiper';

export default {
  swiper: null,
  name: 'VirtualGiftsSlider',
  props: {
    categoryName: {
      type: String,
    },
    gifts: {
      type: Array,
    },
    update: {
      type: Boolean,
    },
  },
  data() {
    return {
      swiper: null,
      viewSlide: {
        desktop: 5,
        tablet: 5,
        mobile768: 3,
        mobile: 1,
      },
    };
  },
  computed: {
    width() {
      return this.$store.getters.deviceWidth;
    },
  },
  mounted() {
    this.initSwiper();
  },
  methods: {
    selectGift(gift) {
      this.$emit('selected', gift);
    },
    initSwiper() {
      const nextEl = this.$el.querySelector('.next-btn');
      const prevEl = this.$el.querySelector('.prev-btn');
      const el = this.$el.querySelector('.virtual-gifts__slider');

      this.swiper = new Swiper(el, {
        slidesPerView: 5,
        spaceBetween: 0,
        modules: [Navigation],
        navigation: {
          nextEl: nextEl,
          prevEl: prevEl,
        },
        breakpoints: {
          992: {
            slidesPerView: 5,
          },
          768: {
            slidesPerView: 3,
          },
          500: {
            slidesPerView: 2,
          },
          300: {
            slidesPerView: 1,
          },
        },
        /* paginationClickable: true,
         updateOnImagesReady: true,
         observer: true,*/
        on: {
          /*   init: function () {
             self.gallery.slideTo(1, 0);
             self.gallery.slidePrev(0);
           },*/
          imagesReady: () => {
            this.sliderInitialized = true;
          },
        },
      });

      /*   const el = this.$el.querySelector('.virtual-gifts__slider');
      const nextEl = this.$el.querySelector('.next-btn');
      const prevEl = this.$el.querySelector('.prev-btn');
      setTimeout(() => {
        this.swiper = new Swiper(el, {
          slidesPerView: 5,
          nextButton: nextEl,
          prevButton: prevEl,
        });
      }, 20);*/
    },
  },
};
</script>

<style scoped lang="scss">
@import '../../../../assets/scss/vars';
.virtual-gifts {
  .img-wrapper {
    cursor: pointer;
    height: 70%;
    padding-top: 15px;
    img {
      height: 100%;
    }
  }
  .swiper-slide {
    display: flex;
    justify-content: center;
  }

  @media screen and (max-width: 992px) {
  }

  &__heading {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid $grey-two;
    padding-bottom: 15px;
    position: relative;
    &:before {
      content: '';
      position: absolute;
      width: 15%;
      max-width: 30px;
      height: 3px;
      bottom: -3px;
      left: 0;
      background: $red;
    }
  }
  &__header {
    font-size: 16px;
    color: $black;
    span {
      color: #a4a4a4;
    }
  }
  &__nav {
    display: flex;
    .nav-btn {
      background: none;
      border: none;
      font-size: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      &:last-child {
        margin-left: 15px;
      }
      &.hidden {
        display: none;
      }
    }
  }
  &__slider {
    height: 210px;
  }
}
</style>
