var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"chatBox",staticClass:"chat-wrapper-new",class:{
    'chat-wrapper-extended': _vm.hasCurrentChat,
    'white-box-start': !_vm.hasCurrentChat && !_vm.isIphone,
    'white-box-extended': !_vm.isIphone && _vm.hasCurrentChat,
    'input-active': _vm.inputActive,
    'input-active-horizontal': _vm.inputActive && _vm.isHorizontalIphone,
    isHorizontalIphone: _vm.isHorizontalIphone,
  },attrs:{"id":"chatBox"}},[_c('header-box',{staticClass:"headerBoxWrap"}),(_vm.hasCurrentChat)?[_c('transition',{attrs:{"name":"custom-classes-transition","enter-active-class":"animated fadeIn"}},[_c('dialog-box',{directives:[{name:"show",rawName:"v-show",value:(_vm.showContent),expression:"showContent"}],ref:"dialogBox",staticClass:"dialogBoxWrap"})],1),_c('transition',{attrs:{"name":"custom-classes-transition","enter-active-class":"animated bounceInLeft"}},[_c('chat-input',{directives:[{name:"show",rawName:"v-show",value:(_vm.showContent),expression:"showContent"}],ref:"inputBox",staticClass:"inputBoxWrap",on:{"focus-input":_vm.focusInputHandler,"blur-input":_vm.blurInputHandler}})],1)]:_vm._e(),(!_vm.hasCurrentChat)?[_c('choose-user',{staticClass:"noUserBox"}),_c('div')]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }