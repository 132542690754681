<template>
  <div
    class="user-item"
    :class="{ active: isCurrent, disabled: !isOnline }"
    @click="setCurrentChat"
  >
    <!--removeFromLastDialogs-->
    <div class="user-avatar">
      <button
        v-if="!chatStatusActive"
        class="remove-item"
        @click.prevent.stop="removeFromLastDialogs"
      >
        <svg class="rbi">
          <use xlink:href="/static/assets/images/rbi-icon.svg#rbi-close" />
        </svg>
      </button>
      <img
        class="user-avatar__img"
        :src="woman.profile.avatarList.img_small"
        :alt="woman.profile.name + '\'s avatar'"
      />
      <span v-if="isOnline" class="is-online"></span>
      <span v-if="mixinIsNew(woman.created_at)" class="is-new">new</span>
    </div>
    <div class="user-detail">
      <span class="uname" :title="woman.profile.name">{{
        woman.profile.name
      }}</span>
      <span class="uid">ID:{{ woman.id }}</span>
      <span
        v-if="collocutorCameraOn"
        class="webcam"
        :class="{ hd: isWebCamHd }"
      >
        <svg class="rbi">
          <use xlink:href="/static/assets/images/rbi-icon.svg#rbi-webcam" />
        </svg>
      </span>
    </div>
    <div v-show="showChatControls && isOnline" class="chat-controls">
      <ul
        :class="{ unread: mixinCalcUnreadMessage(woman.id) }"
        class="list-unstyled notification"
        :data-notification-count="mixinCalcUnreadMessage(woman.id)"
      >
        <li>
          <span v-show="chatType === 1">
            <svg class="rbi" :class="{ fadeAnim: chatStatusActive }">
              <use
                xlink:href="/static/assets/images/rbi-icon.svg#rbi-comment-text"
              />
            </svg>
          </span>
          <span v-show="chatType === 2 && isMan">
            <svg class="rbi" :class="{ fadeAnim: chatStatusActive }">
              <use xlink:href="/static/assets/images/rbi-icon.svg#rbi-webcam" />
            </svg>
          </span>
          <span v-show="chatType === 3 || (chatType === 2 && !isMan)">
            <svg class="rbi" :class="{ fadeAnim: chatStatusActive }">
              <use xlink:href="/static/assets/images/rbi-icon.svg#rbi-video" />
            </svg>
          </span>
          <span v-show="chatType === 4">
            <svg class="rbi" :class="{ fadeAnim: chatStatusActive }">
              <use
                xlink:href="/static/assets/images/rbi-icon.svg#rbi-double_video"
              ></use>
            </svg>
          </span>
        </li>
        <li v-if="!isMan" class="statusChat">
          <span v-show="chatStatusActive">
            <svg class="rbi fadeAnim">
              <use xlink:href="/static/assets/images/rbi-icon.svg#rbi-play" />
            </svg>
          </span>
          <span v-show="!chatStatusActive">
            <svg class="rbi">
              <use xlink:href="/static/assets/images/rbi-icon.svg#rbi-pause" />
            </svg>
          </span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import store from '../../../store/store';
import {
  CHAT_STATUS,
  IS_NEW,
  IS_ONLINE,
  UNREAD_MESSAGE,
  AGE,
} from '../../../mixins/man_util';

export default {
  store,
  name: 'DialogItem',
  components: {},
  mixins: [CHAT_STATUS, IS_ONLINE, IS_NEW, UNREAD_MESSAGE, AGE],
  props: {
    woman: Object,
  },
  data() {
    return {};
  },
  computed: {
    sound() {
      return this.$store.getters.settings.sound.sound === 1;
    },
    activeUsers() {
      return this.$store.getters.activeUsersList;
    },
    cameraOn() {
      return this.$store.getters.getUserCameraIsActive;
    },
    collocutorCameraOn() {
      if (this.activeUsers) {
        const user = this.activeUsers.find(
          (user) => user.userId === this.woman.id
        );
        if (user) {
          return user.userCameraActive;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    connectedToStreamServer() {
      return this.$store.getters.getStreamServerConnected;
    },
    connectedToSocketIO() {
      return this.$store.getters.connectedToSocket === true;
    },
    chatType() {
      const userCameraOn = this.cameraOn;
      const collocutorCameraOn = this.collocutorCameraOn;
      let type;
      if (this.getChatType) {
        if (!this.connectedToStreamServer || !this.connectedToSocketIO) {
          type = 1;
        } else {
          switch (this.getChatType) {
            // Types:
            // 1 - standart text chat
            // 2 - man stream
            // 3 - woman stream
            // 4 - woman and man stream

            case 'video':
              if (this.isMan) {
                if (userCameraOn && collocutorCameraOn) {
                  // If man camera on
                  type = 4;
                } else if (collocutorCameraOn) {
                  type = 3;
                } else {
                  type = 1;
                }
              } else {
                if (collocutorCameraOn) {
                  type = 4;
                } else if (userCameraOn) {
                  // If woman camera on
                  type = 3;
                } else type = 1;
              }
              break;
            case 'text':
              if (this.isMan) {
                if (userCameraOn) {
                  // If man camera on
                  type = 2;
                } else {
                  type = 1;
                }
              } else {
                if (collocutorCameraOn) {
                  // Check man camera on
                  type = 2;
                } else type = 1;
              }
              break;
            default:
              type = 1;
              break;
          }
        }
      } else {
        type = 0;
      }
      return type;
    },
    token() {
      return this.$store.getters.token;
    },
    currentChat() {
      return this.$store.getters.dialogsCurrent;
    },
    showChatControls() {
      return (
        this.mixinCalcUnreadMessage(this.woman.id) != 0 ||
        this.mixinCalcMessage(this.woman.id)
      );
    },
    chatStatusStop() {
      return this.mixinGetChatStatus(this.woman.id) === 'stop';
    },
    chatStatusActive() {
      return this.mixinGetChatStatus(this.woman.id) === 'active';
    },
    chatStatusPause() {
      return this.mixinGetChatStatus(this.woman.id) === 'pause';
    },
    getChatType() {
      return this.mixinGetChatType(this.woman.id);
    },
    isCurrent() {
      return (
        this.$store.getters.dialogs.current != null &&
        this.$store.getters.dialogs.current.user.id === this.woman.id
      );
    },
    isWebCam() {
      return this.woman.camera === 'yes' || this.woman.camera === 'hd';
    },
    isWebCamHd() {
      return this.woman.camera === 'hd';
    },
    isNew() {
      return this.mixinIsNew(this.woman.created_at);
    },
    isMan() {
      return this.$store.getters.user.type === 'man';
    },

    isOnline() {
      return this.mixinIsOnline(this.woman.last_active);
    },
  },
  watch: {
    chatType: function (newVal, oldVal) {
      if (newVal !== oldVal && this.chatStatusActive && this.sound) {
        if (!this.isMan) {
          if (newVal === 3 && oldVal !== 4) {
            window.sounds.streamWatchStart.track.play();
          } else if (newVal === 4 && oldVal !== 3) {
            window.sounds.streamWatchStart.track.play();
          }
        }
      }
    },
    collocutorCameraOn: function (newVal, oldVal) {
      if (oldVal !== newVal) {
        if (newVal === true && this.chatStatusActive && this.sound)
          window.sounds.manStartStream.track.play();
      }
    },
  },
  methods: {
    loadCurrentChatMessage() {
      this.$http
        .post(this.config.socket_url + '/api/messages/last-chat', {
          interlocutor: this.currentChat.user.id,
          access_token: this.token.site_token,
        })
        .then((result) => {
          // console.log('arr', _.uniqWith([...result.data.messages, ...this.currentChat.messages], (i, y) => i.created === 1563209404 ))
          // console.log(result.data.messages, this.currentChat)
          this.$store.dispatch('getLastDialog', result.data.messages.reverse());
        });
    },
    setCurrentChat() {
      if (!this.isOnline) return;
      this.$store.dispatch('setCurrentChat', this.woman);
      this.$store.dispatch('setResponsiveShowLastDialogs', false);
      // this.loadCurrentChatMessage()
    },
    removeFromLastDialogs() {
      let curentDialog = this.$store.getters.dialogs.current;
      if (curentDialog === null) {
        this.$store.commit('MANUAL_CHAT_STOP', { user_id: this.woman.id });
        this.$socket.emit('remove_contact', {
          token: this.$store.getters.session.chat_token,
          contact_id: this.woman.id,
        });
      } else if (curentDialog.user.chat_status !== 'active') {
        this.$store.commit('MANUAL_CHAT_STOP', { user_id: this.woman.id });
        this.$socket.emit('remove_contact', {
          token: this.$store.getters.session.chat_token,
          contact_id: this.woman.id,
        });
      } else {
        this.$store.commit('MANUAL_CHAT_STOP', { user_id: this.woman.id });
        this.$socket.emit('remove_contact', {
          token: this.$store.getters.session.chat_token,
          contact_id: this.woman.id,
        });
        this.$http
          .post(this.config.socket_url + '/api/chat/close', {
            user_id: this.$store.getters.user.id,
            companion_id: this.woman.id,
            token: this.$store.getters.session.chat_token,
          })
          .then(() => {
            this.$store.commit('MANUAL_CHAT_STOP', { user_id: this.woman.id });
          })
          .catch(() => {
            console.warn('Can not chat close');
          });
      }
    },
  },
};
</script>

<style></style>
