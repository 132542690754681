<template>
  <div
    class="user-item"
    :class="{ notification: woman.is_notification, active: isActive }"
    @click="makeCurrent"
  >
    <div class="user-avatar">
      <img
        class="user-avatar__img"
        :src="woman.profile.avatarList.img_small"
        :alt="woman.profile.name + '\'s avatar'"
      />
      <span v-if="mixinIsOnline(woman.last_active)" class="is-online"></span>
      <span v-if="mixinIsNew(woman.created_at)" class="is-new">new</span>
      <span class="is-notify">
        <svg class="rbi">
          <use
            xlink:href="/static/assets/images/rbi-icon.svg#rbi-attention"
          ></use></svg
      ></span>
    </div>
    <div class="user-detail">
      <span class="uname" :title="woman.profile.name">
        {{ woman.profile.name }}
      </span>
      <span class="uid"> ID:{{ woman.id }} </span>
      <span v-if="isWebCam" class="webcam">
        <svg class="rbi">
          <use xlink:href="/static/assets/images/rbi-icon.svg#rbi-webcam"></use>
        </svg>
      </span>
    </div>
  </div>
</template>

<script>
import store from '../../../store/store';
import { IS_NEW, IS_ONLINE, AGE } from '../../../mixins/man_util';

export default {
  name: 'WomanItem',
  store,

  components: {},
  mixins: [IS_NEW, IS_ONLINE, AGE],
  props: {
    woman: Object,
  },
  data: function () {
    return {};
  },
  computed: {
    isActive() {
      return (
        this.$store.getters.dialogs.current != null &&
        this.$store.getters.dialogs.current.user.id === this.woman.id
      );
    },
    isWebCam() {
      if (this.$store.getters.activeUsersList.length > 0) {
        const data = this.$store.getters.activeUsersList;
        let hasStream = false;

        data.forEach((user) => {
          if (user.userId === this.woman.id && user.userCameraActive) {
            hasStream = true;
          }
        });

        return hasStream;
      } else return false;
    },
  },
  created: function () {
    //console.log(this.woman)
  },
  methods: {
    makeCurrent() {
      this.$store.dispatch('setCurrentChat', this.woman);
      this.$store.dispatch('setResponsiveShowUserOnline', false);
      this.$store.commit('SET_MAN_Q', '');
      document.querySelector('.findInOnline').value = '';
    },
  },
};
</script>

<style></style>
