import { IS_FAVORITE, IS_BOOKMARK } from './man_util';
import { USER_ACTIONS } from './userActions';

const womanControlsMixin = {
  mixins: [IS_FAVORITE, IS_BOOKMARK, USER_ACTIONS],
  data: () => ({
    winked: false,
  }),
  computed: {
    bookmarkActionTitle() {
      if (this.mixinIsBookmark(this.user.id)) {
        return 'Remove from bookmark';
      } else {
        return 'Add to bookmark';
      }
    },
    favoriteActionTitle() {
      if (this.mixinIsFavorite(this.user.id)) {
        return 'Remove from favorite';
      } else {
        return 'Add to favorite';
      }
    },
    isFavorite() {
      let result = false;
      this.$store.getters.getAllFavorites.forEach((favorite) => {
        if (favorite === this.user.id) {
          result = true;
        }
      });
      return result;
    },
    isBookmark() {
      let result = false;
      this.$store.getters.getAllBookmarks.forEach((bookmark) => {
        if (bookmark === this.user.id) {
          result = true;
        }
      });
      return result;
    },
  },
  methods: {
    favoriteAction() {
      let token = this.$store.getters.token.site_token;

      if (this.isFavorite) {
        let url = 'v1/favorite/delete';
        this.$http
          .post('v1/favorite/is-favorite-array', {
            access_token: token,
            target_user_array: JSON.stringify([this.user.id]),
          })
          .then((result) => {
            if (result.body.status) {
              if (result.body.result[0].favorite === 'invite_sent') {
                url = 'v1/favorite/undo-invite';
              } else if (result.body.result[0].favorite === 'ignored') {
                url = 'v1/favorite/unfollow';
              }
            }
            return url;
          })
          .then((url) => {
            return this.$http.post(url, {
              access_token: token,
              favorite_user_id: this.user.id,
            });
          })
          .then((result) => {
            if (result.body.status) {
              let arrayAllFavorites = this.$store.getters.getAllFavorites;
              arrayAllFavorites.splice(
                arrayAllFavorites.indexOf(this.user.id),
                1
              );
              this.$store.dispatch('setAllFavorites', arrayAllFavorites);
              this.$store.commit('REMOVE_FAVORITES', this.user);
            }
          })
          .catch((error) => {
            this.$log.error(error);
          });
      } else {
        this.$http
          .post('v1/favorite/add', {
            access_token: token,
            favorite_user_id: this.user.id,
          })
          .then((result) => {
            if (result.body.status) {
              let arrayAllFavorites = this.$store.getters.getAllFavorites;
              arrayAllFavorites.push(this.user.id);
              this.$store.dispatch('setAllFavorites', arrayAllFavorites);
              this.$store.commit('SEND_FAVORITES', this.user);
            }
          });
      }
    },
    bookmarkAction() {
      let token = this.$store.getters.token.site_token;
      if (this.isBookmark) {
        this.$http
          .post('v1/bookmark/delete', {
            access_token: token,
            bookmark_user_id: this.user.id,
          })
          .then((result) => {
            if (result.body.status) {
              let arrayAllBookmarks = this.$store.getters.getAllBookmarks;
              arrayAllBookmarks.splice(
                arrayAllBookmarks.indexOf(this.user.id),
                1
              );
              this.$store.dispatch('setAllBookmarks', arrayAllBookmarks);
              this.$store.commit('REMOVE_BOOKMARKS', this.user);
            }
          });
      } else {
        this.$http
          .post('v1/bookmark/add', {
            access_token: token,
            bookmark_user_id: this.user.id,
          })
          .then((result) => {
            if (result.body.status) {
              let arrayAllBookmarks = this.$store.getters.getAllBookmarks;
              arrayAllBookmarks.push(this.user.id);
              this.$store.dispatch('setAllBookmarks', arrayAllBookmarks);
              this.$store.commit('SEND_BOOKMARKS', this.user);
            }
          });
      }
    },
    sendWink() {
      this.winked = true;
      let token = this.$store.getters.token.site_token;
      this.$http
        .post('v1/wink/make', {
          access_token: token,
          target_user_id: this.user.id,
        })
        .then((result) => {
          if (result.body.status) {
            this.$store.commit('SEND_WINK', this.user.id);
          }
        });
    },
    sendMail() {
      this.previewSendMail(true, {
        avatar: {
          img_small: this.user.profile.avatarList.img_small,
        },
        user_id: this.user.id,
        name: this.user.profile.name,
      });
    },
    sendGift() {
      this.previewSendVirtualGift(true, this.user.id);
    },
  },
};

export default womanControlsMixin;
