<template>
  <!--	<div class="sidebar-invite-item" ref="invite">
          <div class="responsive-header">
              <p>
                  <strong>
                      {{man.profile.name}}
                  </strong> | ID: {{man.id}}</p>
          </div>
          <div class="align">
              <div class="img-container is-online" :class="{'is-new': isNew}">
                  <img :src="man.profile.avatarList.img_small" class="user-avatar" :alt="man.profile.name">
              </div>
              <div class="blockquote">
                  <p v-html="messageFromShort(invite.params.text)"></p>
              </div>
          </div>
          <div class="row-mobile">
              <button class="btn btn-primary" @click="acceptInvite">
                  <span class="btn-wrap">
                      <span class="visible-lg visible-md">{{inviteType.full}}</span>
                      <span class="hidden-lg hidden-md">{{inviteType.small}}</span>
                  </span>
              </button>
              <div class="life-indicator" :id="'line-' + inviteUID">
              </div>
          </div>
  </div>-->

  <div
    ref="invite"
    class="user-item user-item_invite"
    style="cursor: pointer"
    @click="acceptInvite"
  >
    <div class="invite-wrap">
      <div class="user-avatar">
        <img
          class="user-avatar__img"
          :src="man.profile.avatarList.img_small"
          :alt="man.profile.name + '\'s avatar'"
        />
        <span v-if="isNew" class="is-new">new</span>
      </div>
      <div class="user-detail">
        <span class="uname" :title="man.profile.name">{{
          man.profile.name
        }}</span>
        <span class="uid">ID:{{ man.id }}</span>
      </div>
      <div class="chat-controls">
        <button class="btn btn-invite">
          <svg v-if="chatType === 1" class="rbi">
            <use
              xlink:href="/static/assets/images/rbi-icon.svg#rbi-comment-text"
            />
          </svg>

          <svg v-if="chatType !== 1 && chatType !== 4" class="rbi">
            <use xlink:href="/static/assets/images/rbi-icon.svg#rbi-video" />
          </svg>

          <svg
            v-if="chatType === 4"
            class="rbi"
            :class="{ fadeAnim: chatStatusActive }"
          >
            <use
              xlink:href="/static/assets/images/rbi-icon.svg#rbi-double_video"
            ></use>
          </svg>
        </button>
      </div>
    </div>
    <div class="invite-wrap">
      <div class="blockquote">
        <p v-html="messageFromShort(invite.params.text)"></p>
      </div>
    </div>
    <div class="invite-wrap">
      <div :id="'line-' + inviteUID" class="life-indicator"></div>
    </div>
  </div>
</template>

<script>
function guid() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }

  return (
    s4() +
    s4() +
    '-' +
    s4() +
    '-' +
    s4() +
    '-' +
    s4() +
    '-' +
    s4() +
    s4() +
    s4()
  );
}

import ProgressBar from 'progressbar.js';

export default {
  name: 'ChatInvite',
  props: ['invite'],
  data() {
    return {
      inviteUID: guid(),
      inviteTimeout: null,
      showTime: this.$store.getters.settings.chat.invite_show_time_for_woman, //default 20
      man: {
        profile: {
          avatar: '',
          avatarList: {},
        },
      },
    };
  },
  computed: {
    isNew() {
      return this.man.new;
    },
    inviteType() {
      if (this.invite.params.inviteType === 'videoInvite') {
        return {
          full: 'Видео чат',
          small: 'Видео',
        };
      } else {
        return {
          full: 'Текстовый чат',
          small: 'Текстовый',
        };
      }
    },
    prettyInviteTypeForChat() {
      if (this.invite.params.inviteType === 'videoInvite') {
        return 'video';
      } else {
        return 'text';
      }
    },
    sound() {
      return this.$store.getters.settings.sound;
    },
    activeUsers() {
      return this.$store.getters.activeUsersList;
    },
    cameraOn() {
      return this.$store.getters.getUserCameraIsActive;
    },
    collocutorCameraOn() {
      if (this.activeUsers) {
        const user = this.activeUsers.find(
          (user) => user.userId === this.man.id
        );
        if (user) {
          return user.userCameraActive;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    connectedToStreamServer() {
      return this.$store.getters.getStreamServerConnected;
    },
    connectedToSocketIO() {
      return this.$store.getters.connectedToSocket === true;
    },
    chatType() {
      const userCameraOn = this.cameraOn;
      const collocutorCameraOn = this.collocutorCameraOn;
      let type;
      if (this.prettyInviteTypeForChat) {
        if (!this.connectedToStreamServer || !this.connectedToSocketIO) {
          type = 1;
        } else {
          switch (this.prettyInviteTypeForChat) {
            // Types:
            // 1 - standart text chat
            // 2 - man stream
            // 3 - woman stream
            // 4 - woman and man stream

            case 'video':
              if (collocutorCameraOn) {
                type = 4;
              } else if (userCameraOn) {
                // If woman camera on
                type = 3;
              } else type = 1;

              break;
            case 'text':
              if (collocutorCameraOn) {
                // Check man camera on
                type = 2;
              } else type = 1;

              break;
            default:
              type = 1;
              break;
          }
        }
      } else {
        type = 0;
      }
      return type;
    },
    token() {
      return this.$store.getters.token;
    },
    currentChat() {
      return this.$store.getters.dialogsCurrent;
    },
  },
  watch: {
    invite: function () {
      let bar = new ProgressBar.Line('#line-' + this.inviteUID, {
        strokeWidth: 2,
        easing: 'linear',
        duration: this.showTime * 1000,
        trailColor: '#fff',
        trailWidth: 2,
        svgStyle: { width: '100%', height: '100%', 'border-radius': '2px' },
        from: { color: '#FFEA82' },
        to: { color: '#05ca6b' },
        step(state, bar) {
          bar.path.setAttribute('stroke', state.color);
        },
      });
      bar.set(1);
      bar.animate(0);

      clearTimeout(this.inviteTimeout);
      this.inviteTimeout = setTimeout(() => {
        //                console.log('Destroy invite: ', this.invite.params.notificationId);
        this.$store.commit('DESTROY_INVITES', this.invite);
      }, this.showTime * 1000);

      if (this.sound.sound) {
        window.sounds.fInv.track.play();
      }
    },
  },
  beforeMount() {
    let dialogs = this.$store.getters.dialogs.list,
      refDialog = null,
      refUser = null,
      allOnline = this.$store.getters.onlineWomanList;
    let createDialog = new Promise((resolve, reject) => {
      dialogs.forEach((dialog) => {
        if (dialog && dialog.id === this.invite.params.senderId) {
          refDialog = dialog;
          refUser = dialog.user;
          resolve({
            dialog: refDialog,
            user: refUser,
          });
        }
      });

      if (!refDialog) {
        if (!refUser) {
          let askUser = new Promise((resolve, reject) => {
            allOnline.forEach((user) => {
              if (user && user.id === this.invite.params.senderId) {
                refUser = user;
                resolve();
              }
            });
            if (!refUser) {
              this.$http
                .post('v1/profile/profile', {
                  access_token: this.$store.getters.session.site_token,
                  target_user_id: this.invite.params.senderId,
                })
                .then(
                  (result) => {
                    let body = result.body;
                    if (body.status) {
                      let data = body.result;
                      refUser = {
                        id: data.user_id,
                        chat_type: 'text',
                        chat_time: 0,
                        chat_status: 'stop',
                        profile: {
                          avatar:
                            this.config.site_url + '/' + data.avatar.medium,
                          avatarList: data.avatar,
                          name: data.name,
                          birthday: data.birthday,
                        },
                        camera: data.camera,
                        count_message: 0,
                        created_at: data.created_at,
                        isNew: data.isNew,
                        is_notification: false,
                        last_active: data.last_active,
                        status: data.status,
                        webCamStream: null,
                      };
                      resolve();
                    }
                  },
                  (err) => {
                    reject(err);
                  }
                );
            }
          });

          askUser
            .then(() => {
              refDialog = {
                active: false,
                chat_start: 0,
                last_active: refUser.last_active,
                messages: [],
                time_ranges: [],
                type: 'text',
                user: refUser,
              };
              this.$store.dispatch('addNewDialog', refDialog);
              resolve({
                dialog: refDialog,
                user: refUser,
              });
            })
            .catch((e) => {
              this.$log.error(e);
              reject();
            });
        }
      }
    });
    createDialog
      .then((r) => {
        this.man = r.user;
      })
      .catch(() => {
        this.$log.error('cant fnd profile || user');
      });
  },
  mounted() {
    let bar = new ProgressBar.Line('#line-' + this.inviteUID, {
      strokeWidth: 2,
      easing: 'linear',
      duration: this.showTime * 1000,
      trailColor: '#fff',
      trailWidth: 2,
      svgStyle: { width: '100%', height: '100%', 'border-radius': '2px' },
      from: { color: '#FFEA82' },
      to: { color: '#05ca6b' },
      step(state, bar) {
        bar.path.setAttribute('stroke', state.color);
      },
    });
    bar.set(1);
    bar.animate(0);

    this.inviteTimeout = setTimeout(() => {
      //                console.log('Destroy invite: ', this.invite.params.notificationId);
      this.$store.commit('DESTROY_INVITES', this.invite);
    }, this.showTime * 1000);

    if (this.sound.sound) {
      window.sounds.fInv.track.play();
    }
  },
  methods: {
    messageFromShort(text) {
      // eslint-disable-next-line no-undef
      return EmojiPicker.prototype.appendUnicodeAsImageToElement(
        this.$refs.invite,
        text
      );
    },
    acceptInvite() {
      let allOnline = this.$store.getters.user.all_online;
      let found = false;

      for (let i = 0; i < allOnline.length; i++) {
        if (allOnline[i].id === this.invite.params.senderId) {
          found = true;
          break;
        }
      }
      if (found) {
        this.$set(this.man, 'chat_time', 0);
        this.$set(this.man, 'objectFrom', 'invite, fl');
        this.$set(this.man, 'chatActivated', false);
        this.$store.commit('SET_CURRENT_CHAT', this.man);
        //                    console.log('Destroy invite: ', this.invite.params.notificationId);
        setTimeout(() => {
          this.$store.commit('DESTROY_INVITES', this.invite);
        }, 100);
      } else {
        let pretty = this.prettyInviteTypeForChat();
        this.$http
          .post('v1/profile/profile', {
            access_token: this.$store.getters.session.site_token,
            target_user_id: this.invite.params.senderId,
          })
          .then(
            (result) => {
              let data = result.body;
              let user = {
                id: data.id,
                last_active: data.last_active,
                created_at: data.created_at,
                is_notification: false,
                count_message: 0,
                chat_time: 0,
                objectFrom: 'Chat-invites, fl',
                chatActivated: false,
                chat_status: 'stop',
                chat_type: pretty,
                isNew: data.new,
                camera: data.camera,
                status: data.status,
                profile: {
                  name: data.profile.name,
                  avatar: this.config.site_url + '/' + data.profile.avatar,
                  avatarList: data.profile.avatarList,
                },
              };
              this.$store.commit('ADD_TO_ALL_ONLINE', user);
              this.$store.commit('SET_CURRENT_CHAT', user);
            },
            () => {
              //                        console.log('Error', err);
              setTimeout(() => {
                //                            console.log('Destroy invite: ', this.invite.params.notificationId);
                setTimeout(() => {
                  this.$store.commit('DESTROY_INVITES', this.invite);
                }, 100);
              }, this.showTime * 1000);
            }
          );
      }
      this.$store.dispatch('setResponsiveShowLastDialogs', false);
    },
  },
};
</script>

<style></style>
