<template>
  <transition v-if="modal.open" name="modal">
    <div class="vue-modal modal-mask mgifts" @click.self="closeModal">
      <div class="vue-modal--wrapper" @click.self="closeModal">
        <div
          class="vue-modal--container mgifts--container"
          :class="{ wide: preview }"
        >
          <div class="modal-header">
            <h4 v-if="preview" class="modal-title">Gifts</h4>
            <rb-button
              v-else
              class="btn go-back"
              type="button"
              @mouseup.native="goBack"
            >
              <svg class="rbi flip-left">
                <use
                  xlink:href="/static/assets/images/rbi-icon.svg#rbi-arrow"
                ></use>
              </svg>
              <span>Back</span>
            </rb-button>
            <div class="modal-control">
              <button
                v-if="preview"
                class="btn-filter hidden-lg hidden-md"
                @mouseup="showOptions = !showOptions"
              >
                <svg class="rbi">
                  <use
                    xlink:href="/static/assets/images/rbi-icon.svg#rbi-sort"
                  ></use>
                </svg>
              </button>
              <button
                type="button"
                class="close"
                @click="closeModal(false, true)"
              >
                <svg class="rbi">
                  <use
                    xlink:href="/static/assets/images/rbi-icon.svg#rbi-close"
                  ></use>
                </svg>
              </button>
            </div>
          </div>
          <div class="modal-body">
            <div v-show="preview">
              <div
                v-show="showOptions"
                class="mgifts--main__controls hidden-lg hidden-md"
              >
                <form class="d-flex" @submit.prevent="">
                  <div class="form-group">
                    <multiselect
                      v-model="category"
                      :allow-empty="false"
                      :options="sortCategories"
                      :searchable="false"
                      track-by="k"
                      label="v"
                      :show-labels="false"
                    >
                      <span slot="caret" class="multiselect-caret"
                        ><svg class="rbi">
                          <use
                            xlink:href="/static/assets/images/rbi-icon.svg#rbi-arrow"
                          ></use></svg
                      ></span>
                    </multiselect>
                  </div>
                  <div class="form-group">
                    <multiselect
                      v-model="sortValue"
                      :allow-empty="false"
                      :options="sortTypes"
                      :searchable="false"
                      track-by="name"
                      label="text"
                      :show-labels="false"
                    >
                      <span slot="caret" class="multiselect-caret"
                        ><svg class="rbi">
                          <use
                            xlink:href="/static/assets/images/rbi-icon.svg#rbi-arrow"
                          ></use></svg
                      ></span>
                    </multiselect>
                  </div>
                  <div class="search-wrap hidden-xs form-group">
                    <input
                      v-model="searchValue"
                      type="search"
                      placeholder="Gift name"
                      class="form-control"
                    />
                    <div>
                      <button type="button" class="search_btn">
                        <svg class="rbi">
                          <use
                            xlink:href="/static/assets/images/rbi-icon.svg#rbi-search"
                          ></use>
                        </svg>
                      </button>
                    </div>
                  </div>
                </form>
              </div>

              <div class="mgifts--main">
                <div
                  class="mgifts--main__head d-flex align-items-center justify-content-between"
                >
                  <ul class="nav nav-tabs" role="tablist">
                    <li
                      v-if="data.rec.length"
                      role="presentation"
                      :class="{ active: data.rec.length }"
                      class="active"
                      @mouseup="toggleTab(true)"
                    >
                      <a
                        href="#mgift-rec"
                        aria-controls="mgift-rec"
                        role="tab"
                        data-toggle="tab"
                      >
                        <span>Recommended</span>
                        <span v-show="data.rec.length" class="text-muted"
                          >({{ data.rec.length }})</span
                        >
                      </a>
                    </li>
                    <li
                      role="presentation"
                      :class="{ active: !data.rec.length }"
                    >
                      <a
                        href="#mgift-all"
                        aria-controls="mgift-all"
                        role="tab"
                        data-toggle="tab"
                        @mouseup="toggleTab(false)"
                      >
                        <span>All</span>
                        <span v-show="data.all.length" class="text-muted"
                          >({{ data.all.length }})</span
                        >
                      </a>
                    </li>
                  </ul>
                  <div class="mgifts--main__controls d-none d-lg-block">
                    <form class="d-flex" @submit.prevent="">
                      <div class="form-group">
                        <multiselect
                          v-model="category"
                          :allow-empty="false"
                          :options="sortCategories"
                          :searchable="false"
                          track-by="k"
                          label="v"
                          :show-labels="false"
                        >
                          <span slot="caret" class="multiselect-caret"
                            ><svg class="rbi">
                              <use
                                xlink:href="/static/assets/images/rbi-icon.svg#rbi-arrow"
                              ></use></svg
                          ></span>
                        </multiselect>
                      </div>
                      <div class="form-group">
                        <multiselect
                          v-model="sortValue"
                          :allow-empty="false"
                          :options="sortTypes"
                          :searchable="false"
                          track-by="name"
                          label="text"
                          :show-labels="false"
                        >
                          <span slot="caret" class="multiselect-caret"
                            ><svg class="rbi">
                              <use
                                xlink:href="/static/assets/images/rbi-icon.svg#rbi-arrow"
                              ></use></svg
                          ></span>
                        </multiselect>
                      </div>
                      <div class="search-wrap hidden-xs form-group">
                        <input
                          v-model="searchValue"
                          type="search"
                          placeholder="Gift name"
                          class="form-control"
                        />
                        <div>
                          <button type="button" class="search_btn">
                            <svg class="rbi">
                              <use
                                xlink:href="/static/assets/images/rbi-icon.svg#rbi-search"
                              ></use>
                            </svg>
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div class="mgifts--main__body">
                  <div class="tab-content">
                    <div id="mgift-rec" role="tabpanel" class="tab-pane active">
                      <div ref="scrollRec" class="simplebar">
                        <div class="gift-container">
                          <div class="row">
                            <gift
                              v-for="item in displayedData"
                              :key="item.id"
                              :click-credits-handler="selectGiftCredits"
                              :click-euro-handler="selectGiftEuro"
                              class="col-lg-4 col-md-6 col-sm-12 col-12 mgifts--item"
                              :gift="item"
                              :chosen-gift-id="chosenData ? chosenData.id : null"
                              :loading="loading"
                            ></gift>
                          </div>
                          <div v-show="showLoadBtn" class="button-wrap">
                            <rb-button
                              v-show="showLoadBtn"
                              @mouseup.native="loadMore"
                              >Load ({{ defaultLimit }}) more</rb-button
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                    <div id="mgift-all" role="tabpanel" class="tab-pane">
                      <div ref="scrollAll" class="simplebar">
                        <div class="gift-container">
                          <div class="row">
                            <gift
                              v-for="item in displayedData"
                              :key="item.id"

                              class="col-lg-4 col-md-6 col-sm-12 col-12 mgifts--item"
                              :gift="item"

                            ></gift>
                          </div>
                          <div v-show="showLoadBtn" class="button-wrap">
                            <rb-button
                              v-show="showLoadBtn"
                              @mouseup.native="loadMore"
                              >Load ({{ defaultLimit }}) more</rb-button
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      v-show="!isSearching && !displayedGifts.length"
                      class="alert alert-info"
                    >
                      No data to display
                    </div>
                    <div
                      v-show="isSearching && !search.result.length"
                      class="alert alert-warning"
                    >
                      No match found. Check your input
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-show="!preview" class="mgifts--single">
              <div class="mgifts--single__calc">
                <gift
                    :gift="chosenData"
                    :extended="true"
                    :click-euro-handler="selectGiftEuro"
                    :click-credits-handler="selectGiftCredits"
                ></gift>
                <div class="calculate item d-flex align-items-center">
                  <div class="calculate__price">
                    <div v-if="this.payMethod === 1">
                      <svg class="rbi">
                        <use
                            xlink:href="/static/assets/images/rbi-icon.svg#rbi-money"
                        ></use>
                      </svg>
                      <span>{{ chosenData.sumPrice }}</span>
                    </div>
                    <div v-else>
                      <span class="rbi">€</span>
                      <span>{{
                          chosenData.sumPriceEur ? chosenData.sumPriceEur.toFixed(2) : ''
                        }}</span>
                    </div>
                  </div>
                  <div class="calculate__multiply">
                    <svg class="rbi">
                      <use
                          xlink:href="/static/assets/images/rbi-icon.svg#rbi-close"
                      ></use>
                    </svg>
                  </div>
                  <div class="calculate__calc" :class="{ error: incorrectAmount }">
                    <button class="calc-btn flip" @mouseup.prevent="changeSum(-1)">

                      <svg class="rbi">
                        <use
                            xlink:href="/static/assets/images/rbi-icon.svg#rbi-minus"
                        ></use>
                      </svg>
                    </button>
                    <span>{{ chosenData.amount }}</span>
                    <button class="calc-btn plus" @mouseup.prevent="changeSum(1)">
                      <svg class="rbi">
                        <use
                            xlink:href="/static/assets/images/rbi-icon.svg#rbi-plus"
                        ></use>
                      </svg>
                    </button>
                  </div>
                  <div class="calculate__equal">
                    <svg class="rbi">
                      <use
                          xlink:href="/static/assets/images/rbi-icon.svg#rbi-equal"
                      ></use>
                    </svg>
                  </div>
                  <div class="calculate__result">
                    <div v-if="this.payMethod === 1">
                      <svg class="rbi">
                        <use
                            xlink:href="/static/assets/images/rbi-icon.svg#rbi-money"
                        ></use>
                      </svg>
                      <span>{{ sum }}</span>
                    </div>
                    <div v-else>
                      <span class="rbi">€</span>
                      <span>{{ payMethod === 2 ? sumEur.toFixed(2) : '' }}</span>
                    </div>
                  </div>
                </div>
                <div>
                  <div>
                    <gift-pay-system-select
                        v-show="chosenData.sumPriceEur !== 0 && this.payMethod === 2"
                        :payments-status="paymentsStatus"
                        :pay-system-click-handler="selectPaySystem"
                        :loading="loading"
                    />
                  </div>
                </div>
              </div>
              <form
                  class="mgifts--single__form"
                  @submit.prevent="payMethod === 1 ? sendGiftCredits() : sendGiftEur()"
              >
                <div class="form-group set-flex set-flex-y-center">
                  <p>To:</p>
                  <div class="usr-selection">
                    <div class="small-img-wrap">
                      <img
                          :src="setPhotoSource(user.avatar.img_small)"
                          :alt="user.name + 's avatar'"
                      />
                    </div>
                    <span>{{ user.name }}</span>
                    <span style="margin-left: 7px; color: #e50000"
                    >( Your message will be written in a postcard and delivered with the gift
                      )</span
                    >
                  </div>
                </div>
                <div class="form-group" :class="{ 'has-error': err.first('giftComment') }">
                  <textarea
                      v-model="comment"
                      class="form-control"
                      name="giftComment"
                      placeholder="Message"
                  />
                  <div v-show="err.first('giftComment')" class="error">
                    <i class="fa fa-warning" />
                    <span>{{ err.first('giftComment') }}</span>
                  </div>
                </div>
                <div style="display: flex; justify-content: space-between">
                  <div class="clearfix">
                    <rb-button class="btn-black" type="submit">
                     Send
                    </rb-button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { LOW_CREDITS_MODAL } from '../../../mixins/formsActions';
import SimpleBar from 'simplebar';
import Gift from './parts/GiftModalItem.vue';
import RbButton from '../Button';
import { Validator } from 'vee-validate';
import { PHOTO_SRC } from '../../../mixins/utils'
import GiftPaySystemSelect from './GiftPaySystemSelect';
export default {
  scrollRec: null,
  scrollAll: null,
  validator: null,
  components: {
    GiftPaySystemSelect,
    gift: Gift,

    RbButton,
  },
  mixins: [LOW_CREDITS_MODAL,PHOTO_SRC],
  data() {
    return {
      showOptions: false,
      sortValue: { name: 'name', ask: 0, text: 'Sort' },
      sortTypes: [
        { name: 'name/0', ask: 0, text: 'Sort' },
        { name: 'name/true', ask: true, text: 'Name - A-Z' },
        { name: 'name/false', ask: false, text: 'Name - Z - A' },
        { name: 'sumPrice/true', ask: true, text: 'Ascending price' },
        { name: 'sumPrice/false', ask: false, text: 'Descending price' },
      ],
      sortCategories: [
        {
          k: '',
          v: 'Categories',
        },
        {
          k: 'flowers',
          v: 'Flowers',
        },
        {
          k: 'jewelry',
          v: 'Jewelry',
        },
        {
          k: 'perfume',
          v: 'Perfumes',
        },
        {
          k: 'electronic_devices',
          v: 'Electronic devices',
        },
        {
          k: 'toys',
          v: 'Toys',
        },
        {
          k: 'food_and_beverage',
          v: 'Food & beverage',
        },
        {
          k: 'season_tickets_and_certificates',
          v: 'Tickets & certificates',
        },
        {
          k: 'balloons',
          v: 'Baloons',
        },
        {
          k: 'clothing',
          v: 'Clothing',
        },
      ],
      searchValue: '',
      activeTab: true, // true - recommend, false - all
      isSearching: false,
      search: {
        result: [],
      },
      err: [],
      preview: true,
      incorrectAmount: false,
      chosenData: {
        amount: 1,
      },
      categories: [],
      category: { k: '', v: 'Categories' },
      comment: '',
      limit: 9,
      defaultLimit: 9,
      loading: false,
      paymentsStatus: {
        ccbill_gift_online: { id: 1, status: 0, userGroupsId: 4 },
        epayments_gift_online: { id: 2, status: 0, userGroupsId: 3 },
        multicard_gift_online: { id: 3, status: 0, userGroupsId: 1 },
        paymentstrust_gift_online: { id: 4, status: 0, userGroupsId: 5 },
        segpay_gift_online: { id: 5, status: 0, userGroupsId: 2 },
        billline_gift_online: {
          id: 6,
          status: 0,
          userGroupsId: 6,
        },
        billline_usd_gift_online: {
          id: 7,
          status: 0,
          userGroupsId: 7,
        },
      },
      actionPayment: 0,
    };
  },
  computed: {
    token() {
      return this.$store.getters.token.site_token;
    },
    showLoadBtn() {
      return this.isSearching
        ? this.displayedData.length < this.search.result.length
        : this.displayedData.length < this.displayedGifts.length;
    },
    displayedData() {
      return this.isSearching
        ? this.search.result.slice(0, this.limit)
        : this.displayedGifts.slice(0, this.limit);
    },
    displayedGifts() {
      if (this.activeTab) {
        return this.data.rec.filter((i) => {
          return this.category.k === '' ? true : i.category === this.category.k;
        }, this);
      } else {
        return this.data.all.filter((i) => {
          return this.category.k === '' ? true : i.category === this.category.k;
        }, this);
      }
    },
    modal() {
      return this.$store.getters.giftModal;
    },
    data() {
      return {
        rec: this.modal.rec,
        all: this.modal.all,
      };
    },
    user() {
      return this.modal.currentUser;
    },
    open() {
      return this.modal.open;
    },
    sum() {
      return this.chosenData.sumPrice * this.chosenData.amount;
    },
    sumEur() {
      return this.chosenData.sumPriceEur * this.chosenData.amount;
    },
  },
  watch: {
    open(v) {
      if (v) {
        if (this.modal.rec.length) {
          this.toggleTab(true);
        } else {
          this.toggleTab(false);
        }
        this.initScrolls();
      }
    },
    activeTab() {
      this.clearParams();
      this.searchValue = '';
      this.sortValue = { name: 'name/0', ask: 0, text: 'Sort' };
    },
    searchValue(v) {
      if (v.length) {
        this.searching(v);
        this.isSearching = true;
      } else {
        this.isSearching = false;
        this.clearParams();
      }
    },
    preview(v) {
      this.clearParams();
      if (v)
        this.chosenData = {
          amount: 1,
        };
      this.incorrectAmount = false;
      this.category = { k: '', v: 'Categories' };
      this.searchValue = '';
      this.sortValue = { name: 'name/0', ask: 0, text: 'Sort' };
    },
    sortValue(val) {
      this.sorting(val);
    },
    comment(v) {
      this.validator.validate('giftComment', v).catch(() => {});
    },
  },
  created() {
    this.validator = new Validator();
    this.validator.attach({
      name: 'giftComment',
      rules: 'max:255',
    });
    this.$set(this, 'err', this.validator.errors);
  },
  methods: {
    closeModal() {
      this.comment = '';
      this.category = { k: '', v: 'Categories' };
      this.searchValue = '';
      this.sortValue = { name: 'name/0', ask: 0, text: 'Sort' };
      this.chosenData = {
        amount: 1,
      };
      this.preview = true;
      this.incorrectAmount = false;
      this.$store.commit('updateGiftModal', {
        open: false,
        user_id: 0,
        all: [],
        rec: [],
      });
    },
    selectPaySystem(paySystemId) {
      this.actionPayment = parseInt(paySystemId);
    },
    clearParams() {
      this.limit = this.defaultLimit;
    },
    sorting(sort) {
      let tmp = sort.name.split('/');
      const info = {
        name: tmp[0] || 'price',
        ask: tmp[1] === 'true',
      };
      let sortFunc = function (a, b) {
        if (info.ask) {
          return a[info.name] > b[info.name] ? 1 : -1;
        }
        return a[info.name] > b[info.name] ? -1 : 1;
      };
      if (this.isSearching) {
        this.search.result.sort(sortFunc);
      } else {
        if (this.activeTab) {
          this.data.rec.sort(sortFunc);
        } else {
          this.data.all.sort(sortFunc);
        }
      }
    },
    toggleTab(v) {
      this.activeTab = v;
    },
    searching(val) {
      val = val.toLowerCase();
      this.search.result = this.displayedGifts.filter((item) => {
        return item.name.toLowerCase().includes(val.toLowerCase());
      });
    },
    selectGift(item) {
      this.chosenData = Object.assign({}, item, {
        amount: 1,
      });
      this.preview = false;
    },
    changeSum(num) {
      if (this.chosenData.amount === 1 && num < 0) {
        this.incorrectAmount = true;
      } else {
        this.incorrectAmount = false;
        this.chosenData.amount += num;
      }
    },
    goBack() {
      this.preview = true;
      this.payMethod = 1
    },
    /**
     * провалидировать комментарий и отправить подарок
     */
    sendGiftCredits() {
      console.log(this)
      this.validator
          .validateAll({
            giftComment: this.comment,
          })
          .then((success) => {
            console.log(success)
            if (success && !this.error) {
              this.sending();
            }
          })
          .catch(() => {});
    },
    sendGiftEur() {
      this.validator
          .validateAll({
            giftComment: this.comment,
          })
          .then((success) => {
            if (success && !this.error) {
              if (this.actionPayment !== 0) this.sendingEur();
              else {
                this.$store.commit('addAlert', {
                  type: 'error',
                  text: 'To send a gift, select a payment system.',
                  info: 'no pay system.',
                });
              }
            }
          })
          .catch(() => {});
    },
    initScrolls() {
      let self = this;
      setTimeout(() => {
        self.scrollRec = new SimpleBar(self.$refs.scrollRec);
        self.scrollAll = new SimpleBar(self.$refs.scrollAll);
      }, 100);
    },
    sendGift() {
      this.validator
        .validateAll({
          giftComment: this.comment,
        })
        .then((success) => {
          if (success && !this.error) {
            this.sending();
          }
        })
        .catch(() => {});
    },
    /**
     * фукция отправки подарка (оплата в кредистах)
     */
    sending() {
      if (!this.loading) {
        this.loading = true;
        console.log(this.$http)
        this.$http
            .post('v1/gift/send', {
              access_token: window.localStorage['access-token'],
              target: this.user.user_id,
              comment: this.comment,
              gift: this.chosenData.id,
              count: this.chosenData.amount,
            })
            .then(
                (r) => {
                  if (r.body.status) {
                    this.$store.commit('addAlert', {
                      type: 'success',
                      text: 'Gift Was already sent',
                    });
                    this.loading = false;
                    this.closeModal();
                  } else {
                    const { code } = r.body;
                    if (code === 10161 || code === 10025 || !r.body.status || !r.body.status) {
                      this.loading = false;
                      this.openCreditsModal();
                    } else {
                      this.loading = false;
                      this.$store.commit('addAlert', {
                        type: 'error',
                        text: "Something goes wrong during request execution",
                        info: 'gift sending failed',
                      });
                    }
                  }
                },
                () => {
                  this.loading = false;
                  this.$store.commit('addAlert', {
                    type: 'error',
                    text: 'Something goes wrong during request execution',
                    info: 'gift sending failed',
                  });
                }
            );
      }
    },
    /**
     * фукция отправки подарка (оплата в евро)
     */
    sendingEur() {
      if (!this.loading) {
        this.loading = true;
        this.$http
            .post('v1/gift/send-money', {
              access_token: window.localStorage['access-token'],
              target: this.user.user_id,
              payment: this.actionPayment,
              comment: this.comment,
              gift: this.chosenData.id,
              count: this.chosenData.amount,
            })
            .then(
                (r) => {
                  if (r.body.status) {
                    this.$store.commit('addAlert', {
                      type: 'success',
                      text: 'Gift pay process started on new tab.' /* this.$t('alerts.giftSentSuccess') */,
                    });
                    this.loading = false; // new tab (ios don't work) // same tab
                    /* window.open(r.body.result, '_blank') */ /* window.location.assign(r.body.result) */
                    window.location = r.body.result;
                    this.closeModal();
                  } else {
                    this.$store.commit('addAlert', {
                      type: 'error',
                      text: this.$t('alerts.someErr'),
                      info: 'Gift sending failed',
                    });
                    this.loading = false;
                    this.payMethod = 2;
                    /* let code = r.body.code
                    if (
                      code === 10161 ||
                      code === 10025 ||
                      !r.body.status ||
                      !r.body.status
                    ) {
                      this.openCreditsModal()
                    } else {
                      this.$store.commit('addAlert', {
                        type: 'error',
                        text: this.$t('alerts.someErr'),
                        info: 'gift sending failed',
                      })
                    } */
                  }
                },
                () => {
                  this.$store.commit('addAlert', {
                    type: 'error',
                    text: this.$t('alerts.someErr'),
                    info: 'gift sending failed',
                  });
                  this.loading = false;
                  this.payMethod = 2;
                }
            );
      }
    },
    loadMore() {
      this.limit += this.defaultLimit;
    },
    selectGiftCredits(item) {
      this.chosenData = { ...item, amount: 1 };
      this.payMethod = 1;
      this.preview = false;
    },
    selectGiftEuro(item, amount) {
      console.log(this.payMethod);
      this.chosenData = { ...item, amount: amount || 1 };
      if (this.payMethod !== 2) {
        this.payMethod = 2;
        this.loading = true;
        this.$http
            .post('v1/gift/send-money', {
              access_token: window.localStorage['access-token'],
              target: this.user.user_id,
              gift: this.chosenData.id,
              count: this.chosenData.amount,
            })
            .then((response) => {
              if (response.body.status) {
                const { payments } = response.body.result;

                this.paymentsStatus.ccbill_gift_online.status =
                    payments.ccbill_gift_online !== 0 ? payments.ccbill_gift_online : 0;
                this.paymentsStatus.multicard_gift_online.status =
                    payments.multicard_gift_online !== 0 ? payments.multicard_gift_online : 0;
                this.paymentsStatus.segpay_gift_online.status =
                    payments.segpay_gift_online !== 0 ? payments.segpay_gift_online : 0;
                this.paymentsStatus.epayments_gift_online.status =
                    payments.epayments_gift_online !== 0 ? payments.epayments_gift_online : 0;
                this.paymentsStatus.paymentstrust_gift_online.status =
                    payments.paymentstrust_gift_online !== 0 ? payments.paymentstrust_gift_online : 0;
                this.paymentsStatus.billline_gift_online.status =
                    payments.billline_gift_online !== 0 ? payments.billline_gift_online : 0;
                this.paymentsStatus.billline_usd_gift_online.status =
                    payments.billline_usd_gift_online !== 0 ? payments.billline_usd_gift_online : 0;

                this.preview = false;
                this.loading = false;
              } else {
                this.$store.commit('addAlert', {
                  type: 'error',
                  text: this.$t('alerts.someErr'),
                  info: 'Gift sending process failed to start.',
                });
              }
            })
            .catch((err) => {
              console.log('Error', err);
            });
      }
    },

  },
};
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/vars';
.gift-container {
  padding: 0 15px;
}
.nav-tabs {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
  background: #f6f6f6;
  height: 50px;

  li {
    position: relative;

    &:after {
      content: '';
      display: block;
      width: 100%;
      height: 2px;
      background: #e21919;
      bottom: 0;
      left: 0;
      transform: scale(0);
      transform-origin: 50% 50%;
      transition: 0.3s;
    }

    &.active {
      &:after {
        transform: scale(1);
      }

      &:before {
        content: '';
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: -12px;
        border: 6px solid transparent;
        border-top: 6px solid #e21919;
      }
    }

    a {
      height: 50px;
      padding: 10px 30px;
      color: #000000;
      font-size: 12px;
      font-weight: 300;
      line-height: 50px;
      text-transform: uppercase;
      letter-spacing: 1.2px;
      text-decoration: none;
      position: relative;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: flex;
      align-items: center;
      justify-content: center;

      .text-muted {
        margin-left: 0.3em;
      }
    }
  }
}

.button-wrap {
  padding: 20px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal {
  &-header {
    padding: 20px 30px 20px;
    border-bottom: none;

    .go-back {
      padding: 10px 15px;
      font-size: 12px;

      .rbi {
        margin-right: 0.3em;
      }
    }
  }

  &-body {
    padding: 0 15px 20px 30px;
  }

  &-control {
    display: flex;

    .btn-filter {
      background: none;
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 1em;
    }
  }
}

textarea.form-control {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /*border: 1px solid #d9d9d9 !important;*/
}

.mgifts {
  .simplebar {
    max-height: 540px;

    &-wrapper {
      padding-right: 15px;
    }

    &-track {
      &.vertical {
        width: 3px;
        background: #e9e9e9;
      }

      .simplebar-scrollbar {
        width: 3px;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        border-radius: 0;
        background: $red;
        right: 0;
      }
    }
  }

  &--container {
    width: 700px;
    max-width: 100%;
    min-width: 290px;
    padding: 0;

    &.wide {
      width: 1030px;
    }
  }

  &--main {
    &__body {
      margin-top: 20px;
    }

    &__controls {
      .form-group {
        width: 164px;
        margin-left: 15px;
        position: relative;

        &.search-wrap {
          .form-control {
            padding-right: 40px;
          }
        }

        .form-control {
          line-height: inherit;
          width: 100%;
        }

        .search_btn {
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          font-size: 20px;
          border: none;
          background: none;
          display: flex;
        }
      }
    }
  }

  &--single {
    &__form {
      .form-group {
        margin-bottom: 30px;

        &:last-child {
          margin-bottom: 15px;
        }
      }

      .usr-selection {
        display: flex;
        align-items: center;

        .small-img-wrap {
          padding: 3px;
          position: relative;
          margin-right: 10px;

          &:before {
            position: absolute;
            content: '';
            display: block;
            width: 100%;
            height: 100%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) rotate(45deg);
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            border-radius: 50%;
            border: 1px solid transparent;
            border-right-color: $black;
          }

          img {
            width: 45px;
            height: 45px;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            border-radius: 50%;
          }
        }
      }

      textarea.form-control {
        width: 100%;
        height: 200px;
      }
    }
  }
}

.calculate {
  margin: 20px 0;

  &__calc {
    border: 1px solid $grey-two;
    display: flex;
    align-items: center;
    margin: 0 15px;

    button {
      background: $light-grey;
      border: none;
      font-size: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 30px;
      height: 30px;
      line-height: 30px;
      cursor: pointer;

      &:first-child {
        border-right: 1px solid $grey-two;
      }

      &:last-child {
        border-left: 1px solid $grey-two;
      }
    }

    > span {
      min-width: 30px;
      height: 30px;
      display: inline-block;
      text-align: center;
      line-height: 30px;
      font-size: 18px;
      padding: 0 10px;
    }
  }

  &__price,
  &__result {
    font-size: 20px;
    display: flex;
    align-items: center;
    margin-right: 15px;

    .rbi {
      font-size: 25px;
      margin-right: 0.3em;
    }
  }

  &__equal {
    margin-right: 15px;
    display: flex;
  }

  &__result {
    margin-right: 0;
  }

  &__multiply {
    display: flex;
    font-size: 12px;
  }
}
.vue-modal--container{
  width: 1180px!important;
}
/*  .mgifts {
      .mgifts--container {
        max-width: 100%;
        width: 700px;
        padding: 0;

        &.wide {
          width: 900px;
        }

        .simplebar-track.horizontal {
          display: none;
        }

        .row {
          padding-bottom: 5px;
        }
      }

      .modal-body {
        padding: 0;
      }

      .modal-header {
        border: none;
        display: flex;
        justify-content: space-between;
        align-items: center;


        .item {
          flex: 0 0 50%;
          display: flex;

          &:last-child {
            justify-content: flex-end;
          }
        }
      }

      .form-control {
        box-shadow: none;
      }

      &--main {
        .mgifts--item {
          margin-top: 15px;
          cursor: pointer;

          &:hover {
            .mgifts--item__img {
              img {
                filter: brightness(1.1);
              }
            }
          }

        }

        &__controls {
          svg {
            fill: #bdbdbd;
            height: 16px;
            width: 16px;
          }

          form {
            > div + div {
              margin-left: 10px;
            }
          }
        }

        &__head {
          justify-content: space-between;
          padding: 0 15px;
        }

        li {
          span {
            font-weight: normal !important;
          }

          a {
            font-size: 13px;
            padding: 10px 15px;
            border: 1px solid transparent;
          }

          &.active {
            a {
              border: 1px solid #e2dfdf;
              border-bottom: none;
            }
          }
        }

        .search-wrap {
          position: relative;

          > div {
            position: absolute;
            top: 0;
            right: 0;
            height: 100%;
          }

          .btn {
            background: none;

            &:active {
              -webkit-box-shadow: none;
              -moz-box-shadow: none;
              box-shadow: none;
            }
          }

          .form-control {
            width: 200px;
            padding-right: 30px;
          }
        }

        &__body {
          margin-top: -1px;
          border-top: 1px solid #e2dfdf;
          padding: 0 15px;

          .tab-content {
            padding-bottom: 15px;
          }
        }

        .simplebar {
          max-height: 500px;
        }

        .simplebar-content {
          margin-bottom: 0 !important;
        }

      }

      &--single {
        .btn-nav {
          background: none;
          border: 1px solid #dcdcdc;
          color: #bdbdbd;

          svg {
            height: 16px;
            width: 16px;
            fill: currentColor;

            + span {
              margin-left: 5px;
            }
          }
        }

        .modal-body {
          padding: 15px;
        }

        &__calc {
          display: flex;
          align-items: center;
          justify-content: center;
          color: #d3d3d3;

          svg {
            fill: currentColor;
            height: 20px;
            width: 20px;
          }

          .item {
            display: flex;
            align-items: center;
            flex: 1 1;
            margin-left: 1%;
            justify-content: space-between;
          }

          .calc {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            height: 105px;
            width: 36px;
            border: 1px solid #d3d3d3;
            padding: 1px;
            font-size: 18px;
            border-radius: 50px;

            &.error {
              border-color: #d9534f;
            }

            &-btn {
              display: flex;
              align-items: center;
              justify-content: center;
              line-height: 0;
              border-radius: 50%;
              height: 32px;
              width: 32px;
              background-color: #d3d3d3;
              color: #fff;
              cursor: pointer;
              user-select: none;

              &:active {
                background-color: darken(#d3d3d3, 10%);
              }

              svg {
                height: 10px;
                width: 10px;
              }

              &.flip {
                svg {
                  transform: scaleY(-1);
                }
              }
            }

            &__result {
              display: flex;
              align-items: center;
              font-size: 18px;

              img {
                height: 36px;
                width: 36px;
              }

              span + span {
                margin-left: 10px;
              }
            }
          }
        }

        &__form {
          margin-top: 30px;

          p {
            color: #bdbdbd;
          }

          textarea {
            resize: none;
            height: 160px;
          }

          .btn-primary {
            padding: 6px 40px;
            font-size: 12px;
            text-transform: uppercase;
          }

          .usr-selection {
            display: flex;
            align-items: center;
            color: #3e3f42;
            margin-left: 10px;
            background-color: #ededed;
            border-radius: 50px;
            padding-right: 20px;

            img {
              height: 36px;
              width: 36px;
              border-radius: 50%;
            }

            div + span {
              margin-left: 10px;
            }
          }
        }

        .load-more {
          margin-top: 15px;
          flex: 0 0 40px;
        }

      }

      .alert {
        margin-top: 15px;
      }
    }*/

@media screen and (max-width: 991px) {
  .vue-modal {
    display: block;
  }
  .mgifts {
    .simplebar {
      max-height: 400px;
    }
  }
  .nav-tabs {
    width: 100%;

    li {
      width: 100%;

      &:first-child {
        margin-left: 0;
      }
    }
  }
  .responsive-control {
    padding-right: 15px;

    .form-group {
      &:first-child {
        margin-left: 0;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .modal-header {
    padding: 20px;
  }
  .modal-body {
    padding: 0 20px 20px;
  }
  .mgifts {
    .simplebar {
      max-height: 350px;
    }
  }
  .nav-tabs {
    width: 100%;

    li {
      width: 100%;

      a {
        padding: 10px 10px;
        width: 100%;
      }

      &:first-child {
        margin-left: 0;
      }
    }
  }
  .mgifts--item__img img {
    width: 75px !important;
    height: 75px !important;
  }
  .mgifts--item__info__price {
    font-size: 18px !important;

    .rbi {
      font-size: 22px !important;
    }
  }
}

.flip-left {
  transform: rotate(90deg);
}
@media screen and (max-width: 400px) {
  .mgifts--item.extended .info {
    flex-direction: column;

    > .tip {
      margin-top: 20px;
    }
  }

  .calculate__price {
    display: none;
  }

  .mgifts--single__form .form-group {
    margin-bottom: 15px;
  }
  .mgifts--single__form textarea.form-control {
    height: 150px;
  }
}
</style>
