<template>
  <transition name="modal">
    <div v-if="modal && show" class="vue-modal modal-mask credits-modal">
      <div class="vue-modal--wrapper">
        <div class="vue-modal--container credits-modal--container">
          <button
            v-if="lastSlideReached"
            type="button"
            class="close"
            style="z-index: 300"
            @click.prevent="closeModal"
          >
            <svg class="rbi">
              <use
                xlink:href="/static/assets/images/rbi-icon.svg#rbi-close"
              ></use>
            </svg>
          </button>
          <div class="modal-body credits-modal--body">
            <div class="noselect" style="margin-bottom: 15px">
              <transition name="bounce"
                ><h4 v-if="showModalTitle" class="modal-title">New version!</h4>
              </transition>
              Step {{ currentSlide + 1 }} / 3
            </div>

            <div style="display: flex">
              <div
                class="navigation-version-modal"
                :style="{ opacity: currentSlide === 0 ? 0.2 : 1 }"
                @click.prevent="prevSlide"
              >
                <span class="icon">
                  <svg class="rbi flip-left navigation-version-modal-icon">
                    <use
                      xlink:href="/static/assets/images/rbi-icon.svg#rbi-arrow"
                    ></use>
                  </svg>
                </span>
              </div>
              <div class="noselect" style="min-width: 80%">
                <transition name="slide-fade">
                  <div v-if="showContent">
                    <h3 class="modal-header" style="justify-content: center">
                      {{ slides[currentSlide].header }}
                    </h3>
                    <div
                      v-if="slides[currentSlide].type === 0"
                      style="margin: 15px 0 15px 0"
                    >
                      <img
                        style="
                          height: 75px;
                          max-height: 100px;
                          border: 1px solid transparent;
                          border-radius: 5px;
                        "
                        :src="slides[currentSlide].image"
                      />
                    </div>
                    <div
                      v-else-if="slides[currentSlide].type === 1"
                      style="
                        margin: 15px 0 15px 0;
                        display: flex;
                        justify-content: space-around;
                      "
                    >
                      <img
                        style="
                          height: 75px;
                          max-height: 100px;
                          border: 1px solid transparent;
                          border-radius: 5px;
                        "
                        :src="slides[currentSlide].image_0"
                      />
                      <span
                        style="
                          display: flex;
                          justify-content: center;
                          flex-direction: column;
                        "
                        >or</span
                      >
                      <img
                        style="
                          height: 75px;
                          max-height: 100px;
                          border: 1px solid transparent;
                          border-radius: 5px;
                        "
                        :src="slides[currentSlide].image_1"
                      />
                    </div>
                    <span> {{ slides[currentSlide].desc }}</span>
                  </div>
                </transition>

                <div v-if="lastSlideReached || true" style="margin-top: 15px">
                  <rb-button
                    class="btn"
                    type="button"
                    @click.native="closeModal"
                  >
                    <span> Ok </span>
                  </rb-button>
                </div>
              </div>
              <div
                class="navigation-version-modal"
                :style="{
                  opacity: currentSlide === slides.length - 1 ? 0.2 : 1,
                }"
                @click.prevent="nextSlide"
              >
                <span class="icon">
                  <svg class="rbi flip-right navigation-version-modal-icon">
                    <use
                      xlink:href="/static/assets/images/rbi-icon.svg#rbi-arrow"
                    ></use>
                  </svg>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import RbButton from '../Button';
export default {
  name: 'VersionChangeModal',
  components: { RbButton },
  data() {
    return {
      show: false,
      showModalTitle: false,
      showContent: false,
      currentSlide: 0,
      lastSlideReached: false,
      slides: [
        {
          type: 0,
          header: 'We have made some updates in chat',
          desc: 'Now you can turn on your camera with this button in the top right corner and lady’s will see you after that chat session will start',
          image: '/static/assets/images/version_change_slide_1.png',
        },
        {
          type: 1,
          header: 'Here are some more tips on how to watch lady’s camera',
          desc: 'If you see these icons, that means lady’s camera is offline, all you can do is to ask her turn on her camera  :)',
          image_0: '/static/assets/images/disabled_camera_button_single.png',
          image_1: '/static/assets/images/disabled_camera_button_double.png',
        },
        {
          type: 1,
          header:
            'After the lady will turn on her camera you will see these icons',
          desc: 'Click it! And you will see lady’s camera stream.',
          image_0: '/static/assets/images/enabled_camera_button_single.png',
          image_1: '/static/assets/images/enabled_camera_button_double.png',
        },
      ],
    };
  },
  computed: {
    modalViewed() {
      return localStorage.getItem('versionChangeModalViewed') === '1';
    },
    modal() {
      return this.$store.getters.versionChangeModal.open;
    },
  },
  watch: {
    modal(v) {
      if (v) {
        setTimeout(() => {
          this.show = true;
        }, 1000);

        setTimeout(() => {
          this.showModalTitle = true;
        }, 1200);

        setTimeout(() => {
          this.showContent = true;
        }, 1600);
      }
    },
  },
  mounted() {
    /* const modalViewed =
      localStorage.getItem('versionChangeModalViewed') === '1';
    if (modalViewed) this.lastSlideReached = true;
    else {
      this.$store.commit('updateVersionChangeModal', { open: true });
    }*/
  },
  methods: {
    closeModal() {
      // localStorage.setItem('versionChangeModalViewed', '1');
      this.$store.commit('updateVersionChangeModal', {
        open: false,
      });
    },
    nextSlide() {
      if (this.currentSlide < this.slides.length - 1) {
        this.showContent = false;
        this.currentSlide = this.currentSlide + 1;

        if (this.currentSlide === this.slides.length - 1)
          this.lastSlideReached = true;
        setTimeout(() => {
          this.showContent = true;
        }, 500);
      }
    },
    prevSlide() {
      if (this.currentSlide !== 0) {
        this.showContent = false;
        this.currentSlide = this.currentSlide - 1;
        setTimeout(() => {
          this.showContent = true;
        }, 500);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-red {
  width: auto;
  padding: 10px 30px !important;
  font-size: 12px !important;
  .rbi {
    margin-right: 0.5em;
  }
}
.navigation-version-modal {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 20%;
  cursor: pointer;
}
.navigation-version-modal:hover {
  color: #9e0101;
}
.navigation-version-modal-icon {
  height: 1.4em;
  width: 1.4em;
}
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
/* Анимации появления и исчезновения могут иметь */
/* различные продолжительности и динамику.       */
.slide-fade-enter-active {
  transition: all 0.8s ease;
}
.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
    /* .slide-fade-leave-active до версии 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.bounce-enter-active {
  animation: bounce-in 0.5s;
}
.bounce-leave-active {
  animation: bounce-in 0.5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}
.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  border: 1px solid #000;
  background: none;
  white-space: nowrap;
  padding: 10px 20px;
  font-size: 12px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: relative;
  margin: 0 3px 3px 0;
  text-transform: uppercase;
  -webkit-border-radius: 0 !important;
  -moz-border-radius: 0 !important;
  border-radius: 0 !important;

  &:after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    border-bottom: 1px solid #000;
    border-right: 1px solid #000;
    bottom: -4px;
    right: -4px;
    transition: 0.3s;
  }

  &:hover {
    &:after {
      width: 10px;
      height: 10px;
      left: auto;
      right: -4px;
      top: auto;
      bottom: -4px;
    }
  }

  &-black {
    background: #000;
    color: #fff;
    &:disabled {
      background: lighten(#000, 50%);
      border-color: lighten(#000, 50%);
      cursor: default;
      &:after {
        border-color: lighten(#000, 50%);
      }
      &:hover {
        &:after {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}

.credits-modal {
  &--container {
    width: 550px;
    position: relative;
    padding: 35px 10px;
  }

  @media (max-width: 992px) {
    &--container {
      width: 60vw;
      position: relative;
      padding: 5px;
    }
  }

  @media (max-width: 425px) {
    &--container {
      width: 90vw;
    }
  }

  h4 {
    font-weight: bold;
    color: #000;
  }

  .close {
    position: absolute;
    top: 20px;
    right: 20px;
  }

  &--body {
    text-align: center;
    p {
      margin: 20px 0;
    }
  }

  .btn-success {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
    border: none !important;
    padding: 6px 20px;

    span + span {
      margin-left: 10px;
    }

    svg {
      height: 20px;
      width: 20px;
      fill: currentColor;
    }
  }
}
</style>
