<template>
  <span class="zodiac-icon">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
    >
      <path
        d="M1.78 19.263h6.97c.983 0 1.78-.796 1.78-1.778l-.002-.352c-.015-1.36-.046-4.196 1.523-5.784.853-.862 2.102-1.28 3.82-1.28 2.496 0 4.065.884 4.94 2.78.77 1.68.77 3.692.77 4.634 0 .98.797 1.777 1.78 1.777h6.855c.983 0 1.78-.795 1.78-1.776 0-.982-.797-1.78-1.78-1.78h-5.132c-.092-1.237-.348-2.824-1.043-4.34-1.02-2.217-3.266-4.856-8.173-4.856-2.68 0-4.816.786-6.35 2.338-2.02 2.042-2.454 4.886-2.537 6.857h-5.2c-.983 0-1.78.796-1.78 1.78s.797 1.78 1.78 1.78zM30.22 21.934H1.78c-.983 0-1.78.796-1.78 1.778 0 .983.797 1.78 1.78 1.78h28.44c.983 0 1.78-.797 1.78-1.78s-.797-1.778-1.78-1.778z"
      />
    </svg>
  </span>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  computed: {},
  methods: {},
};
</script>

<style></style>
