export const servers = {
  iceServers: [
    {
      urls: 'stun:stun.redbini.com',
    },
    {
      urls: 'turn:turn.redbini.com',
      username: '5aMUmacrun2Sud',
      credential: 'sti3ohugUpri0u',
    },
  ],
};
