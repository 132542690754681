<template>
  <div class="rounded-box" :class="{ 'remote-video-controls': remoteVideo }">
    <button
      class="btn btn-primary"
      :class="{ active: !isTextChat }"
      @click.prevent="switchToTextInvite"
    >
      <span v-if="!cameraOn">
        <svg class="rbi">
          <use
            xlink:href="/static/assets/images/rbi-icon.svg#rbi-comment-text"
          />
        </svg>
      </span>
      <span v-else>
        <svg class="rbi">
          <use xlink:href="/static/assets/images/rbi-icon.svg#rbi-webcam" />
        </svg>
      </span>
    </button>
    <!--<button
      class="btn btn-danger"
      :class="{ active: !isVideoChat }"
      @click.prevent="switchToVideoInvite"
    >
      <span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="28"
          height="28"
          viewBox="0 0 28 28"
        >
          <path
            d="M23.898 4.1C21.254 1.457 17.738 0 14 0c-3.74 0-7.255 1.456-9.9 4.1C1.457 6.746 0 10.26 0 14s1.456 7.255 4.1 9.898C6.747 26.544 10.26 28 14 28c3.738 0 7.255-1.456 9.898-4.102C26.544 21.255 28 17.738 28 14s-1.456-7.255-4.102-9.9zM3 14c0-2.938 1.144-5.7 3.222-7.778C8.3 4.145 11.062 3 14 3c2.432 0 4.734.794 6.64 2.24l-15.4 15.4C3.795 18.735 3 16.43 3 14zm18.777 7.78C19.7 23.856 16.937 25 14 25c-2.432 0-4.735-.793-6.638-2.24L22.758 7.364c1.447 1.902 2.24 4.207 2.24 6.638.002 2.937-1.143 5.7-3.22 7.777z"
          />
        </svg>
      </span>
    </button>-->
    <button
      class="btn btn-primary"
      :disabled="!isWebCam || !connectedToStreamServer"
      :class="{
        active: isWebCam && connectedToStreamServer,
        videoChatActive: isVideoChat,
      }"
      @click.prevent="switchToVideoInvite"
    >
      <span v-if="!cameraOn">
        <svg class="rbi">
          <use xlink:href="/static/assets/images/rbi-icon.svg#rbi-video" />
        </svg>
      </span>
      <span v-else>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="svg"
          version="1.1"
          x="0px"
          y="0px"
          viewBox="0 0 90.5 81.5"
          xml:space="preserve"
          enable-background="new 0 0 90.5 81.5"
        >
          <switch>
            <g>
              <path
                d="M28.9 49.5c-6.1 0-11.1-5-11.1-11.1v-1.8h-5.4c-5.7 0-10.3 4.6-10.3 10.3v23c0 5.7 4.6 10.3 10.3 10.3h30.2c5.7 0 10.3-4.6 10.3-10.3V68l11.5 6.6c1.9 1.1 4.2-0.3 4.2-2.4V49.5H28.9z"
              />
              <path
                d="M22.6 37.3V9.6c0-2.1 2.3-3.5 4.2-2.4l11.5 6.6v-1.9c0-5.7 4.6-10.3 10.3-10.3h30.2c5.7 0 10.3 4.6 10.3 10.3v23c0 5.7-4.6 10.3-10.3 10.3H48.6c-5.7 0-10.3-4.6-10.3-10.3V33l-11.5 6.6C24.9 40.7 22.6 39.4 22.6 37.3L22.6 37.3zM22.6 37.3"
              />
            </g>
          </switch>
        </svg>
      </span>
    </button>
    <!--<button class="btn btn-danger" @click="" title="End video chat">
      <span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="28"
          height="28"
          viewBox="0 0 28 28"
        >
          <path
            d="M23.898 4.1C21.254 1.457 17.738 0 14 0c-3.74 0-7.255 1.456-9.9 4.1C1.457 6.746 0 10.26 0 14s1.456 7.255 4.1 9.898C6.747 26.544 10.26 28 14 28c3.738 0 7.255-1.456 9.898-4.102C26.544 21.255 28 17.738 28 14s-1.456-7.255-4.102-9.9zM3 14c0-2.938 1.144-5.7 3.222-7.778C8.3 4.145 11.062 3 14 3c2.432 0 4.734.794 6.64 2.24l-15.4 15.4C3.795 18.735 3 16.43 3 14zm18.777 7.78C19.7 23.856 16.937 25 14 25c-2.432 0-4.735-.793-6.638-2.24L22.758 7.364c1.447 1.902 2.24 4.207 2.24 6.638.002 2.937-1.143 5.7-3.22 7.777z"
          />
        </svg>
      </span>
    </button>-->
    <!--  <button
      id="videochat"
      class="btn btn-primary"
      @click.prevent="switchToDoubleVideoChat"
      :disabled="!isWebCam"
      :class="{ active: !isVideoDoubleChat }"
    >
      <span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="svg"
          version="1.1"
          x="0px"
          y="0px"
          viewBox="0 0 90.5 81.5"
          xml:space="preserve"
          enable-background="new 0 0 90.5 81.5"
        >
          <switch>
            <g>
              <path
                d="M28.9 49.5c-6.1 0-11.1-5-11.1-11.1v-1.8h-5.4c-5.7 0-10.3 4.6-10.3 10.3v23c0 5.7 4.6 10.3 10.3 10.3h30.2c5.7 0 10.3-4.6 10.3-10.3V68l11.5 6.6c1.9 1.1 4.2-0.3 4.2-2.4V49.5H28.9z"
              />
              <path
                d="M22.6 37.3V9.6c0-2.1 2.3-3.5 4.2-2.4l11.5 6.6v-1.9c0-5.7 4.6-10.3 10.3-10.3h30.2c5.7 0 10.3 4.6 10.3 10.3v23c0 5.7-4.6 10.3-10.3 10.3H48.6c-5.7 0-10.3-4.6-10.3-10.3V33l-11.5 6.6C24.9 40.7 22.6 39.4 22.6 37.3L22.6 37.3zM22.6 37.3"
              />
            </g>
          </switch>
        </svg>
      </span>
    </button>-->
  </div>
</template>

<script>
export default {
  name: 'ChatTypeSwitch',
  props: ['collocutor', 'remoteVideo'],
  computed: {
    userBalance() {
      return this.$store.getters.myBalance;
    },

    connectedToStreamServer() {
      return this.$store.getters.getStreamServerConnected;
    },
    connectedToSocketIO() {
      return this.$store.getters.connectedToSocket === true;
    },

    hasMoneyToChangeType() {
      const { price_man_video_stream, price_text_chat, price_video_chat } =
        this.$store.getters.settings.chat;

      const chatTypePrice = this.isTextChat
        ? price_text_chat + price_video_chat
        : price_text_chat;

      if (this.cameraOn) {
        return this.userBalance >= chatTypePrice + price_man_video_stream;
      } else return this.userBalance >= chatTypePrice;
    },

    isActive() {
      return this.$store.getters.dialogs.current
        ? this.$store.getters.dialogs.current.user.chat_status === 'active'
        : false;
    },
    /* isVideoDoubleChat() {
      return this.$store.getters.dialogs.current
        ? this.$store.getters.dialogs.current.user.chat_type === 'videoDouble'
        : false
    },*/
    isVideoChat() {
      return this.$store.getters.dialogs.current
        ? this.$store.getters.dialogs.current.user.chat_type === 'video'
        : false;
    },
    isTextChat() {
      return this.$store.getters.dialogs.current
        ? this.$store.getters.dialogs.current.user.chat_type === 'text'
        : false;
    },

    isWebCam() {
      if (this.$store.getters.activeUsersList.length > 0) {
        const data = this.$store.getters.activeUsersList;
        let hasStream = false;

        data.forEach((user) => {
          if (user.userId === this.collocutor.id && user.userCameraActive) {
            hasStream = true;
          }
        });

        return hasStream;
      } else return false;
    },
    cameraOn() {
      return this.$store.getters.getUserCameraIsActive;
    },
  },
  methods: {
    switchToDoubleVideoChat() {
      // deprecated
      if (this.isWebCam) {
        this.$store.dispatch('changeChatType', 'videoDouble');
        this.$socket.emit('change_chat_type', {
          token: this.$store.getters.session.site_token,
          new_chat_type: 'videoDoubleChat',
          roomId: this.$store.getters.dialogs.current.roomId,
        });
      }
    },
    switchToVideoInvite() {
      if (
        this.isWebCam &&
        this.hasMoneyToChangeType &&
        this.connectedToStreamServer &&
        this.connectedToSocketIO &&
        !this.isVideoChat
      ) {
        this.$socket.emit('change_chat_type', {
          token: this.$store.getters.session.site_token,
          collocutor: this.collocutor.id,
          new_chat_type: 'video',
          roomId: this.$store.getters.dialogs.current.roomId,
        });
        this.$store.dispatch('changeChatType', 'video');
      }
    },
    switchToTextInvite() {
      if (this.hasMoneyToChangeType && !this.isTextChat) {
        this.$socket.emit('change_chat_type', {
          token: this.$store.getters.session.site_token,
          collocutor: this.collocutor.id,
          new_chat_type: 'text',
          roomId: this.$store.getters.dialogs.current.roomId,
        });
        this.$store.dispatch('changeChatType', 'text');
      }
    },
  },
};
</script>

<style scoped>
@media (max-width: 425px) {
  .remote-video-controls .btn {
    width: 28px !important;
    height: 28px !important;
  }
}
.videoChatActive {
  background: red !important;
  color: white !important;
}
</style>
