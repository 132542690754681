<template>
  <div>
    <h4 class="modal-title">Private chat will be ended</h4>
    <div class="center-block">
      <button class="btn btn-success" @click="reloadPage">Go to page</button>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  computed: {},
  methods: {
    reloadPage() {
      window.location.reload();
    },
  },
};
</script>

<style scoped>
.modal-title {
  margin-top: 20px;
}
.center-block {
  margin-top: 30px;
}

.btn {
  padding: 10px 20px;
  font-size: 13px;
}
</style>
