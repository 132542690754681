const config = {
    api_url: 'https://api.redbini.fun',
    socket_url: 'https://chatsocket.redbini.fun',
    wss_url_view: 'wss://chatstreamview.redbini.fun',
    wss_url: 'wss://chatstream.redbini.fun',
    site_url: 'https://redbini.fun',
    googleAnalyticsKey: 'UA-99609233-1'
};

module.exports = config;
