<template>
  <component
    :is="message.type"
    :message="message"
    @msg="passScrollEvent($event)"
  ></component>
</template>

<script>
import info from './info.vue';
import message from './message.vue';
import photo from './photo.vue';
import freephoto from './freephoto';

export default {
  components: {
    info,
    message,
    photo,
    freephoto,
  },
  props: {
    message: Object,
  },
  data() {
    return {};
  },
  computed: {},
  methods: {
    passScrollEvent(e) {
      this.$emit('msg', e);
    },
  },
};
</script>

<style lang="scss">
.msg-line {
  width: 100%;
  padding: 5px 12px;

  .invite-indicator {
    font-size: 0.65rem !important;
    max-width: 30%;
  }
}
</style>
