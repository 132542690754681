import store from '../store';
import configProd from '../../config/config-production';
import Logger from '../../utils/Logger';

const devModeEnabled = !configProd.isProduction;

const logger = new Logger(devModeEnabled);

let socket;

function connect() {
  return new Promise((resolve, reject) => {
    let interval;

    if (!socket || socket.readyState === 3)
      socket = new WebSocket(configProd.wss_url);

    socket.onerror = () => {
      reject('Video socket error.');
    };
    socket.onopen = function () {
      clearInterval(interval);
      store.commit('RTC_SOCKET_CONNECT');
      if (devModeEnabled)
        store.commit('addAlert', {
          type: 'success',
          text: `Stream socket connected.`,
          info: 'Stream socket connected',
        });
      interval = setInterval(() => {
        if (socket.readyState !== 3 || socket.readyState !== 2)
          sendEvent('ping', 'polling');
      }, 20000);

      socket.onmessage = function (e) {
        const payload = JSON.parse(e.data);

        if (payload.event) {
          switch (payload.eventType) {
            case 'action':
              store
                .dispatch(`stream_socket_${payload.event}`, payload.data)
                .then(() => {})
                .catch((err) => {
                  logger.error(err);
                });
              break;
            case 'mutation':
              store.commit(`stream_socket_${payload.event}`, payload.data);
              break;
            case 'transport':
              if (devModeEnabled) logger.info('WS transport pong.');
              break;
            default:
              break;
          }
        }
      };

      socket.onclose = function (e) {
        store.commit('RTC_SOCKET_DISCONNECT', e);

        if (devModeEnabled)
          store.commit('addAlert', {
            type: 'error',
            text: `Stream socket disconnect.`,
            info: 'stream socket disconnect',
          });

        store
          .dispatch('cleanUp')
          .then(() => {
            if (devModeEnabled) logger.info('Clean up ended');
          })
          .catch((err) => {
            logger.error(err);
          });

        clearInterval(interval);
      };

      resolve(true);
    };
  });
}

const sendEvent = (event, data) => {
  if (devModeEnabled) logger.info('Sending event: ', event, data);
  try {
    if (!socket.readyState === 1) throw new Error('Socket is not opened.');
    socket.send(JSON.stringify({ event, data }));
  } catch (e) {
    logger.error(event + 'failed', e);
  }
};

const state = {
  userCameraIsActive: false,
  userSocketID: null,
  streamServerConnected: false,
  activeUsersList: [],
  peerConnections: [],
  signaling: undefined,
  iceCandidate: undefined,
};

const getters = {
  devModeEnabled() {
    return devModeEnabled;
  },
  getStreamServerConnected(state) {
    return state.streamServerConnected;
  },
  getUserCameraIsActive(state) {
    return state.userCameraIsActive;
  },
  getUserSocketID(state) {
    return state.userSocketID;
  },
  activeUsersList(state) {
    return state.activeUsersList;
  },
  signaling(state) {
    return state.signaling;
  },
  iceCandidate(state) {
    return state.iceCandidate;
  },
  peerConnections(state) {
    return state.peerConnections;
  },
};

const mutations = {
  setUserCameraActive(state, newCameraState) {
    state.userCameraIsActive = newCameraState;
  },

  signaling(state, payload) {
    state.signaling = payload;
  },

  setIceCandidate(state, payload) {
    state.iceCandidate = payload;
  },

  addPeerConnection(state, peerConnectionObject) {
    state.peerConnections.push(peerConnectionObject);
  },

  clearActiveUsersList(state) {
    state.activeUsersList = [];
  },

  closePeerConnection(state, index) {
    state.peerConnections[index].connection.close();
    state.peerConnections.splice(index, 1);
  },

  RTC_SOCKET_CONNECT(state) {
    state.streamServerConnected = true;
    logger.info('Video server web socket connection success.');
  },

  RTC_SOCKET_DISCONNECT(state, event) {
    if (event) {
      logger.info(event);
    }
    logger.info('WebSocket disconnect.');
    state.streamServerConnected = false;

    if (event.code === 4000 || event.code === 4001) {
      // ! Existing socket user disconnect

      if (devModeEnabled) {
        store.commit('addAlert', {
          type: 'error',
          text: `${event.reason}`,
          info: `${event.code}`,
        });
      }
    } else {
      /*setTimeout(async () => {
        await store.dispatch('connectToRTCServerSocket', true)
      }, 10000)*/
    }
    /* setTimeout(async () => {
      await store.dispatch('connectToRTCServerSocket', true);
    }, 10000); */
  },

  stream_socket_users_list_update(state, payload) {
    state.activeUsersList = payload;
  },

  stream_socket_registration(state, payload) {
    state.userSocketID = payload;
  },
};

const actions = {
  async connectToRTCServerSocket(context, reconnect) {
    return connect(reconnect);
  },

  disconnectFromRTCServerSocket(context, reason) {
    if (socket && socket.readyState === 1) socket.close(4001, reason);
  },

  emitEventToStreamServer(context, payload) {
    sendEvent(payload.event, payload.data);
  },

  registerOnVideoServer(context) {
    sendEvent('register_user_id', {
      userId: context.getters.user.id,
      chatToken: context.getters.token.chat_token,
    });
  },

  stream_socket_ice(ctx, { candidate, from, peerType, peerId }) {
    if (candidate) {
      ctx.commit('setIceCandidate', {
        from,
        candidate,
        peerType,
        peerId,
      });
    }
  },

  stream_socket_signaling(ctx, { desc, from, peerType, peerId }) {
    if (desc) {
      ctx.commit('signaling', { remoteDesc: desc, from, peerType, peerId });
    }
  },

  stream_socket_user_disconnect(context, usersIds) {
    logger.info('Disconnect,', usersIds);
    usersIds.forEach((userId) => {
      context.dispatch('closeAllPeerConnectionsWithUser', userId);
    });
  },

  stream_socket_user_camera_deactivation(context, userId) {
    context.dispatch('closePeerConnection', { userId, type: 'incoming' });
  },

  cleanUp(context) {
    context.commit('clearActiveUsersList');

    context.commit('setUserCameraActive', false);

    context.dispatch('closeAllPeerConnections').then(() => {
      context.getters.dialogs.list.forEach((dialog) => {
        dialog.user.chat_type = 'text';
        dialog.type = 'text';
      });
    });
  },

  closeOutcomingPeerConnections(context) {
    context.getters.peerConnections.forEach((peerConnection, index) => {
      if (peerConnection.type === 'outcoming') {
        context.commit('closePeerConnection', index);
      }
    });
  },

  closeAllPeerConnections(context) {
    context.getters.peerConnections.forEach((peerConnection, index) => {
      context.commit('closePeerConnection', index);
    });
  },

  closeAllPeerConnectionsWithUser(context, userId) {
    context.getters.peerConnections.forEach((peerConnection, index) => {
      if (peerConnection.userId === userId) {
        context.commit('closePeerConnection', index);
      }
    });
  },

  closePeerConnection(context, payload) {
    if ('type' in payload && 'userId' in payload) {
      context.getters.peerConnections.forEach((peerConnection, peerIndex) => {
        if (
          peerConnection.userId === payload.userId &&
          peerConnection.type === payload.type
        ) {
          context.commit('closePeerConnection', peerIndex);
        }
      });
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
