<template>
  <div ref="msg" class="msg-line">
    <div
      class="usr-message"
      :class="{
        recipient: !isReceiver,
        sender: isReceiver,
        invite: message.isInvite,
        error: message.sessionError,
      }"
    >
      <span>
        <img v-if="isReceiver" :src="myAvatar" :alt="user.profile.name" />
        <img
          v-if="!isReceiver"
          :src="collocutor.profile.avatarList.img_small"
          :alt="user.profile.name"
        />
      </span>
      <div class="msg">
        <p v-if="message.sessionError" style="color: darkred">
          Ошибка отправки
        </p>
        <p class="invite-indicator">Приглашение</p>
        <p v-show="!translated">
          <span v-html="messageWithEmoji"></span>
          <span class="date test">{{ time }}</span>
        </p>
        <p v-show="translated">
          <span v-html="translatedText"></span>
          <span class="date">{{ time }}</span>
        </p>
        <div class="clearfix">
          <div
            v-if="!isReceiver"
            class="flex-center btn-trnsl-box"
            @click="translate"
          >
            <button class="btn is-translating" type="button">
              <span class="flex-center">
                <svg
                  v-if="!translated"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 490 490"
                  @click="switchTranslate"
                >
                  <path
                    d="M245 490c135.1 0 245-109.9 245-245S380.1 0 245 0 0 109.9 0 245s109.9 245 245 245zm72.3-77.3c14.9-20.8 29.3-47 39.2-79.2h48.2c-19.5 35.1-50.3 63.1-87.4 79.2zm102.8-219.5c4.9 16.4 7.5 33.8 7.5 51.8s-2.7 35.4-7.5 51.8h-55c2.7-16.1 4.3-33.3 4.3-51.8s-1.6-35.7-4.3-51.8h55zm-15.4-36.7h-48.2c-9.9-32.2-24.3-58.4-39.2-79.2 37.1 16.1 67.9 44.1 87.4 79.2zM263.3 70.4c17.7 17.9 39.9 46.3 54.3 86.1h-54.3V70.4zm0 122.8h64.4c3.1 15.9 5 33.1 5 51.8s-1.8 35.9-4.9 51.8h-64.5V193.2zm0 140.3h54.5c-14.4 40.2-36.7 68.6-54.5 86.4v-86.4zm-178 0h48.2c9.9 32.2 24.3 58.4 39.2 79.2-37.1-16.1-67.9-44.1-87.4-79.2zm141.4 86.1c-17.7-17.9-39.9-46.3-54.3-86.1h54.3v86.1zm0-122.8h-64.4c-3.1-15.9-5-33.1-5-51.8s1.8-35.9 4.9-51.8h64.5v103.6zm0-140.3h-54.5c14.4-40.2 36.7-68.6 54.5-86.4v86.4zm-54-79.2c-14.9 20.8-29.3 47-39.2 79.2H85.3c19.5-35.1 50.3-63.1 87.4-79.2zM62.4 245c0-18 2.7-35.4 7.5-51.8h55c-2.7 16.1-4.3 33.3-4.3 51.8s1.6 35.7 4.3 51.8h-55C65 280.4 62.4 263 62.4 245z"
                  />
                </svg>
                <svg
                  v-else
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 408.631 408.631"
                  @click="switchTranslate"
                >
                  <path
                    d="M346.63 195.316c-41-41-99-64-171-67v-63c0-15-19-25-31-13l-139 139c-8 8-7 20 0 26l139 139c12 12 31 2 31-13v-64h16c53 0 147 9 183 73 3 6 10 9 16 9 11 0 18-9 18-18 0-3 0-86-62-148zm-155 47c-22 0-35 2-36 2-9 1-17 9-17 18v37l-94-95 94-95v37c0 10 9 18 19 18 134 0 184 69 203 121-52-36-124-43-169-43z"
                  />
                </svg>
                <span class="text-underline hidden-xs">
                  <b v-if="!translated" @click="switchTranslate">Перевести</b>
                  <b v-else @click="switchTranslate">Оригинал</b>
                </span>
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-undef */
import moment from 'moment';

export default {
  name: 'MessageItem',
  props: {
    message: Object,
  },
  data() {
    return {
      translated: false,
      translatedText: null,
    };
  },
  computed: {
    messageWithEmoji() {
      return EmojiPicker.prototype.appendUnicodeAsImageToElement(
        this.$refs.msg,
        this.message.text
      );
    },
    originalMesage() {
      return this.message.text;
    },
    time() {
      return moment().format('HH:mm:ss');
    },
    user() {
      return this.$store.getters.appUser;
    },
    collocutor() {
      return this.$store.getters.dialogs.current.user;
    },
    isReceiver() {
      return this.message.sender === this.user.id;
    },
    myAvatar() {
      return this.user.profile.avatarList.img_small;
    },
    sound() {
      return this.$store.getters.settings.sound;
    },
  },
  mounted() {
    this.$emit('msg', this.$el);
  },
  created() {
    /*if (
      this.message.sound &&
      this.sound.sound &&
      this.sound.sound_chat &&
      this.sound.sound_chat_message_active &&
      this.message.type !== 'invite' &&
      this.collocutor.chatActivated
    ) {
      if (this.isReceiver) {
        window.wMessage.play()
        this.message.sound = false
      } else {
        window.newMessage.play()
        this.message.sound = false
      }
    }*/
  },
  methods: {
    messageFromShort(text) {
      return EmojiPicker.prototype.appendUnicodeAsImageToElement(
        this.$refs.msg,
        text
      );
    },
    translate() {},
    switchTranslate() {
      if (this.translated) {
        this.translated = false;
      } else {
        if (this.translatedText === null) {
          this.translatedText = 'Ожидайте. Перевод в процессе...';
          this.$http
            .post('v1/chat/translate', {
              access_token: this.$store.getters.token.site_token,
              sentence: this.originalMesage,
              from: 'en',
              to: 'ru',
            })
            .then((translatedText) => {
              if (translatedText.body.status) {
                this.translatedText = this.messageFromShort(
                  translatedText.body.text
                );
                this.translated = true;
              } else {
                //								console.log( 'Translate error' );
              }
            });
        } else {
          this.translated = true;
        }
      }
    },
  },
};
</script>

<style lang="scss">
.msg {
  max-width: 75% !important;
  @media screen and (max-width: 425px) {
    max-width: 70% !important;
  }
}
.error .msg {
  background-color: rgb(255, 169, 169) !important;
  color: white !important;
}
</style>
