<template>
  <div class="mooeditable-wrap emoji-picker-container">
    <div ref="tmp"></div>
    <textarea
      ref="textarea"
      class="form-control textarea-control"
      rows="3"
      :placeholder="placeholder"
      data-emojiable="true"
      @input="passMessage"
    ></textarea>
  </div>
</template>

<script>
/* eslint-disable no-useless-escape,no-undef */
import MediumEditor from 'medium-editor';
import SimpleBar from 'simplebar';

export default {
  name: 'ContentEditable',
  editable: null,
  emojis: null,
  props: {
    value: String,
    placeholder: String,
    clear: [Boolean, Number, String],
  },
  data() {
    return {
      msg: '',
      test: '',
      image: '',
      el: {},
      startHeight: 0,
      scrollEventExists: false,
      scrollEventWorked: false,
      offset: 0,
    };
  },
  computed: {
    iphoneVersion() {
      return this.$store.getters.iphoneVersion;
    },
    translatedMsg() {
      return this.$store.getters.getCurrentTypedMessage;
    },
    currentEditingTemplate() {
      return this.$store.getters.currentEditingTemplate;
    },
  },
  watch: {
    msg() {
      // console.log(this.replaceEmojis(v)) TODO: Convert to colons
      let msg = this.$refs.textarea.value.trim();
      this.$emit('input', msg);
    },
    clear() {
      this.msg = '';
      if (this.editable) {
        this.$refs.textarea.value = '';
        this.editable.setContent('');
      }
    },
    translatedMsg(v) {
      if (v) {
        let msg = this.convert(v);
        this.editable.setContent(msg);
        this.$refs.textarea.value = v;
        this.$emit('input', v);
      }
    },
    currentEditingTemplate(v) {
      if (v) {
        let msg = this.convert(v);
        this.editable.setContent(msg);
        this.$refs.textarea.value = msg;
        this.$emit('input', msg);
      }
    },
  },
  mounted() {
    this.initEmoji();

    this.startHeight = window.innerHeight;
    if (this.$store.getters.iphoneVersion !== 'not-an-ip-safari') {
      this.offset = 70;
      if (
        this.$store.getters.iphoneVersion === 'iPhone6-6s-7-8' ||
        this.$store.getters.iphoneVersion ===
          'iPhone5-5C-5S-SE-6-6s-7-8-display-zoom'
      ) {
        this.offset = 50;
      }
    }
  },
  methods: {
    triggerBlur() {
      this.$el.blur();
    },
    replaceEmojis(elementHtml) {
      return elementHtml.replace(/<img.*?src="(.*?)"[^\>]+>/g, (match) => {
        const title = match.match(/alt="([^"]*)"/);
        if (title[1]) {
          return title[1];
        }
      });
    },
    convert(msg) {
      if (msg) {
        return EmojiPicker.prototype.appendUnicodeAsImageToElement(
          this.$refs.tmp,
          msg
        );
      }
      return EmojiPicker.prototype.appendUnicodeAsImageToElement(
        this.$refs.tmp,
        this.$refs.textarea.value
      );
    },
    initEmoji() {
      // Initializes and creates emoji set from sprite sheet
      let self = this;
      setTimeout(() => {
        self.emojis = new EmojiPicker({
          emojiable_selector: self.$refs.textarea,
          assetsPath: '/static/assets/images/newEmoji',
          popupButtonClasses: 'fa fa-smile-o',
        });
        self.emojis.discover();
        self.initTextArea();
        self.initScroll();
      }, 100);
    },
    initTextArea() {
      let self = this,
        area = this.$refs.textarea,
        el = $(area).parent().find('.emoji-wysiwyg-editor')[0];
      el.classList.add('message');
      this.el = el;
      this.$el = el;
      self.editable = new MediumEditor(el, {
        toolbar: false,
        anchorPreview: false,
        placeholder: {
          hideOnClick: false,
        },
        paste: {
          cleanPastedHTML: false,
          cleanTags: [
            'meta',
            'pre',
            'script',
            'button',
            'img',
            'svg',
            'canvas',
            'label',
            'li',
            'ul',
            'ol',
            'a',
            'iframe',
            'table',
          ],
          cleanAttrs: ['class', 'style', 'dir'],
          autoLink: false,
          imageDragging: false,
          keyboardCommands: false,
          disableExtraSpaces: true,
          ref: 'test',
        },
      });

      self.editable.origElements.addEventListener('focus', () => {
        this.$emit('focus-input');
      });
      self.editable.origElements.addEventListener('blur', () => {
        this.$emit('blur-input');
      });

      self.editable.subscribe('editableInput', function () {
        $(self.editable.origElements).scrollTo('max');
        self.msg = self.editable.getContent();
      });

      self.editable.subscribe('editableKeydownEnter', function (e) {
        e.preventDefault();
        e.stopPropagation();
        /*setTimeout(() => {
          self.el.focus()
        }, 0)*/
        self.$emit('submit', self.convert());
      });
    },
    passMessage() {
      this.msg = this.editable.getContent();
    },
    initScroll() {
      let self = this,
        area = this.$refs.textarea,
        el = $(area).parent().find('.emoji-items-wrap')[0];
      el.classList.add('simplebar');
      self.scroll = new SimpleBar(el);
    },
    cleanHtml(html) {
      html = html.replace(/<p><br><\/p>/, '');
      html = html.replace(/<html[^>]*?>(.*)/gim, '$1');
      html = html.replace(/<\/html>/gi, '');
      html = html.replace(/<script[^>]*?>(.*)/gim, '$1');
      html = html.replace(/<\/script>/gi, '');
      html = html.replace(/<iframe[^>]*?>(.*)/gim, '$1');
      html = html.replace(/<\/iframe>/gi, '');
      html = html.replace(/<body[^>]*?>(.*)/gi, '$1');
      html = html.replace(/<\/body>/gi, '');
      html = html.replace(/<img[^>]*?>(.*)/gim, '$1');
      html = html.replace(/<pre[^>]*?>(.*)/gim, '$1');
      html = html.replace(/<\/pre>/gi, '');
      html = html.replace(/<ol[^>]*?>(.*)/gim, '$1');
      html = html.replace(/<\/ol>/gi, '');
      html = html.replace(/<ul[^>]*?>(.*)/gim, '$1');
      html = html.replace(/<\/ul>/gi, '');
      html = html.replace(/<li[^>]*?>(.*)/gim, '$1');
      html = html.replace(/<\/li>/gi, '');
      html = html.replace(/<a[^>]*?>(.*)/gim, '$1');
      html = html.replace(/<\/a>/gi, '');

      // remove style, meta and link tags
      html = html.replace(/<style[^>]*?>[\s\S]*?<\/style[^>]*>/gi, '');
      html = html.replace(/<(?:meta|link)[^>]*>\s*/gi, '');

      // remove XML elements and declarations
      html = html.replace(/<\\?\?xml[^>]*>/gi, '');

      // remove w: tags with contents.
      html = html.replace(/<w:[^>]*>[\s\S]*?<\/w:[^>]*>/gi, '');

      // remove tags with XML namespace declarations: <o:p><\/o:p>
      html = html.replace(/<o:p>\s*<\/o:p>/g, '');
      html = html.replace(/<o:p>[\s\S]*?<\/o:p>/g, '&nbsp;');
      html = html.replace(/<\/?\w+:[^>]*>/gi, '');

      // remove comments [SF BUG-1481861].
      html = html.replace(/<\!--[\s\S]*?-->/g, '');
      html = html.replace(/<\!\[[\s\S]*?\]>/g, '');

      // remove mso-xxx styles.
      html = html.replace(/\s*mso-[^:]+:[^;"']+;?/gi, '');

      // remove styles.
      html = html.replace(/<(\w[^>]*) style='([^\']*)'([^>]*)/gim, '<$1$3');
      html = html.replace(/<(\w[^>]*) style="([^\"]*)"([^>]*)/gim, '<$1$3');

      // remove margin styles.
      html = html.replace(/\s*margin: 0cm 0cm 0pt\s*;/gi, '');
      html = html.replace(/\s*margin: 0cm 0cm 0pt\s*"/gi, '"');

      html = html.replace(/\s*text-indent: 0cm\s*;/gi, '');
      html = html.replace(/\s*text-indent: 0cm\s*"/gi, '"');

      html = html.replace(/\s*text-align: [^\s;]+;?"/gi, '"');

      html = html.replace(/\s*page-break-before: [^\s;]+;?"/gi, '"');

      html = html.replace(/\s*font-variant: [^\s;]+;?"/gi, '"');

      html = html.replace(/\s*tab-stops:[^;"']*;?/gi, '');
      html = html.replace(/\s*tab-stops:[^"']*/gi, '');

      // remove font face attributes.
      html = html.replace(/\s*face="[^"']*"/gi, '');
      html = html.replace(/\s*face=[^ >]*/gi, '');

      html = html.replace(/\s*font-family:[^;"']*;?/gi, '');
      html = html.replace(/\s*font-size:[^;"']*;?/gi, '');

      // remove class attributes
      html = html.replace(/<(\w[^>]*) class=([^ |>]*)([^>]*)/gi, '<$1$3');

      // remove "display:none" attributes.
      html = html.replace(
        /<(\w+)[^>]*\sstyle="[^"']*display\s?:\s?none[\s \S]*?<\/\1>/gi,
        ''
      );

      // remove empty styles.
      html = html.replace(/\s*style='\s*'/gi, '');
      html = html.replace(/\s*style="\s*"/gi, '');

      html = html.replace(/<span\s*[^>]*>\s*&nbsp;\s*<\/span>/gi, '&nbsp;');

      html = html.replace(/<span\s*[^>]*><\/span>/gi, '');

      // remove align attributes
      html = html.replace(/<(\w[^>]*) align=([^ |>]*)([^>]*)/gi, '<$1$3');

      // remove lang attributes
      html = html.replace(/<(\w[^>]*) lang=([^ |>]*)([^>]*)/gi, '<$1$3');

      html = html.replace(/<span([^>]*)>([\s\S]*?)<\/span>/gi, '$2');

      html = html.replace(/<font\s*>([\s\S]*?)<\/font>/gi, '$1');

      html = html.replace(/<(u|i|strike)>&nbsp;<\/\1>/gi, '&nbsp;');

      html = html.replace(/<h\d>\s*<\/h\d>/gi, '');

      // remove language attributes
      html = html.replace(/<(\w[^>]*) language=([^ |>]*)([^>]*)/gi, '<$1$3');

      // remove onmouseover and onmouseout events (from MS word comments effect)
      html = html.replace(
        /<(\w[^>]*) onmouseover="([^\"']*)"([^>]*)/gi,
        '<$1$3'
      );
      html = html.replace(
        /<(\w[^>]*) onmouseout="([^\"']*)"([^>]*)/gi,
        '<$1$3'
      );

      // the original <Hn> tag sent from word is something like this: <Hn style="margin-top:0px;margin-bottom:0px">
      html = html.replace(/<h(\d)([^>]*)>/gi, '<h$1>');

      // word likes to insert extra <font> tags, when using IE. (Weird).
      html = html.replace(
        /<(h\d)><font[^>]*>([\s\S]*?)<\/font><\/\1>/gi,
        '<$1>$2</$1>'
      );
      html = html.replace(/<(h\d)><em>([\s\S]*?)<\/em><\/\1>/gi, '<$1>$2</$1>');

      // i -> em, b -> strong - doesn't match nested tags e.g <b><i>some text</i></b> - not possible in regexp
      // @see - http://stackoverflow.com/questions/1721223/php-regexp-for-nested-div-tags etc.
      html = html.replace(/<b\b[^>]*>(.*?)<\/b[^>]*>/gi, '<strong>$1</strong>');
      html = html.replace(/<i\b[^>]*>(.*?)<\/i[^>]*>/gi, '<em>$1</em>');

      // remove "bad" tags
      html = html.replace(/<\s+[^>]*>/gi, '');

      // remove empty <span>s (ie. no attributes, no reason for span in pasted text)
      // done twice for nested spans
      html = html.replace(/<span>([\s\S]*?)<\/span>/gi, '$1');
      html = html.replace(/<span>([\s\S]*?)<\/span>/gi, '$1');

      // remove empty <div>s (see span)
      html = html.replace(/<div>([\s\S]*?)<\/div>/gi, '$1');
      html = html.replace(/<div>([\s\S]*?)<\/div>/gi, '$1');

      // remove empty tags (three times, just to be sure - for nested empty tags).
      // This also removes any empty anchors
      html = html.replace(/<([^\s>]+)(\s[^>]*)?>\s*<\/\1>/g, '');
      html = html.replace(/<([^\s>]+)(\s[^>]*)?>\s*<\/\1>/g, '');
      html = html.replace(/<([^\s>]+)(\s[^>]*)?>\s*<\/\1>/g, '');

      html = html.trim();

      // Make it valid xhtml
      html = html.replace(/<br>/gi, '<br />');

      // remove <br>'s that end a paragraph here.
      html = html.replace(/<br[^>]*><\/p>/gim, '</p>');

      // remove empty paragraphs - with just a &nbsp; (or whitespace) in (and tags again for good measure)
      html = html.replace(/<p>&nbsp;<\/p>/gi, '');
      html = html.replace(/<p>\s<\/p>/gi, '');
      html = html.replace(/<([^\s>]+)(\s[^>]*)?>\s*<\/\1>/g, '');
      html = html.trim();

      return html;
    },
  },
};
</script>

<style lang="scss">
.mooeditable-wrap .medium-editor-element {
  caret-color: red;
  min-height: 30px !important;

  &:before {
    cursor: text;
  }

  @media screen and (max-height: 425px) and (max-width: 992px) and (min-width: 425px) {
    min-height: 20px !important;
  }
}
.emoji-picker-container {
  position: relative;
  /*border: #d5d5d5 solid 1px;*/

  height: 100%;
  /*height: 100px;*/

  .emojione {
    width: 16px;
    pointer-events: none;
    margin: 0 1px;
    display: inline-block;
    vertical-align: -3px;
  }

  .form-control {
    padding: 20px 32px !important;
    /*padding: 0 !important;*/
    height: 100%;
    border: #d0d0d0 1px solid;
    border-radius: 3px;
    // min-height: 7vh;
  }
  @media screen and (max-width: 992px) {
    .form-control {
      // min-height: 5vh;
    }
  }
  @media screen and (max-width: 450px) {
    .form-control {
      padding: 14px 5px !important;
      /*padding: 0 !important;*/
      height: 100%;
      border: #d0d0d0 1px solid;
      border-radius: 3px;
      // min-height: 5vh;
    }
  }

  @media screen and (max-height: 425px) and (max-width: 992px) and (min-width: 425px) {
    .form-control {
      padding: 8px 32px !important;
      /*padding: 0 !important;*/
      height: 100%;
      border: #d0d0d0 1px solid;
      border-radius: 3px;
      //  min-height: 5vh;
    }
  }

  .form-control p {
    margin: 0 !important;
    /*padding: 10px 25px;*/
  }

  .emoji-menu {
    top: auto;
    bottom: 100%;
    right: 0;
    left: auto;
    overflow: visible;
  }

  .emoji-items-wrap {
    overflow: hidden;
    box-shadow: -1px -1px 1px rgba(0, 0, 0, 0.1);
    /*border: 1px #dfdfdf solid;*/
  }

  .emoji-items-wrap {
    position: absolute;
    bottom: 100%;
    right: 0;
    height: 200px;
  }
  .emoji-menu-tabs {
    margin: 0;
  }

  .emoji-picker-icon {
    color: #e21919 !important;
    opacity: 0.5;
    margin: 5px;
  }

  .emoji-items {
    background: #fff;
    &:before,
    &:after {
      content: ' ';
      display: table;
      clear: both;
    }
  }
}

@media only screen and (max-width: 992px) {
  .emoji-picker-container {
    .emoji-picker-icon,
    .emoji-menu {
      display: none;
    }
  }
}
</style>
