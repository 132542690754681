<template>
  <div class="new-header-box">
    <div class="header-box__item logo-item">
      <a href="#" class="logo"></a>
    </div>

    <div class="header-box__item togglers-item">
      <div class="chat-time">
        <div class="clock rbi" :class="{ start: chatStatus.active }">
          <span class="clock--short-hand"></span>
          <span class="clock--long-hand"></span>
          <span class="clock--dot"></span>
        </div>
        <span
          style="margin-left: 5px"
          :class="{
            'status-start': chatStatus.tip === 'start',
            'status-stop': chatStatus.tip === 'spec-stop',
          }"
          >{{ chatStatus.text }}</span
        >
      </div>
      <div class="wmn_toggle toggle-sound">
        <button class="btn-reset" @click="toggleSound">
          <svg v-if="soundSettings.sound" class="rbi">
            <use xlink:href="/static/assets/images/rbi-icon.svg#rbi-volume" />
          </svg>
          <svg v-else class="rbi">
            <use
              xlink:href="/static/assets/images/rbi-icon.svg#rbi-volume_mute"
            />
          </svg>
        </button>
      </div>
      <div class="toggle-camera">
        <camera />
      </div>
    </div>
  </div>
</template>

<script>
import { BEAUTY_TIME } from '../../mixins/woman_util';
import { SOUND } from '../../mixins/utils';
import Camera from './video/camera';

export default {
  name: 'HeaderBox',
  components: { Camera },
  mixins: [BEAUTY_TIME, SOUND],
  data() {
    return {};
  },
  computed: {
    cameraInProcess() {
      return this.$store.getters.user.cameraProcess;
    },
    ifSound() {
      return this.$store.getters.notification;
    },
    camera() {
      let camera = this.$store.getters.appUser.camera;
      return camera !== 'no';
    },
    chatTime() {
      return this.$store.getters.chatTime;
    },
    chatStatus() {
      if (this.currentDialog) {
        if (this.currentDialog.active)
          return {
            active: true,
            text: 'Чат',
            tip: 'current',
          };
        else {
          return {
            active: false,
            text: 'Стоп',
            tip: 'spec-stop',
          };
        }
      } else {
        return {
          active: false,
          text: '--:--',
          tip: 'spec-stop',
        };
      }
    },
    currentDialog() {
      return this.$store.getters.dialogsCurrent;
    },
  },

  mounted() {},
  beforeMount() {},
  methods: {
    soundOff() {
      this.$store.commit('SOUND_OFF', {});
    },
    soundOn() {
      this.$store.commit('SOUND_ON', {});
    },
  },
};
</script>

<style lang="scss" scoped>
/*.wmn_toggle {
  margin: 0 40px;
}*/
/*.webcam-wrap {
  font-size: 12px;
  padding: 10px 15px;
  .rbi {
    margin-right: 0.5em;
  }
}*/
.new-header-box {
  color: white;
  display: none;
}
.new-header-box .header-box__item {
  display: flex;
  justify-content: flex-start;
}

.logo-item {
  width: 30%;
  padding-left: 10px;
}
.togglers-item {
  width: 80%;
  justify-content: flex-end !important;
  padding-right: 10px;
}

.new-header-box .logo {
  margin: 0 !important;
  height: 30px !important;
}
.header-box__item .chat-time,
.currency-wrap,
.toggle-sound,
.toggle-camera {
  width: 25%;
  display: flex;
  justify-content: center !important;
}

.toggle-camera {
  max-height: 40px;
  padding-top: 5px;
}
.toggle-camera button {
  padding: 8px !important;
}
.chat-time .rbi {
  margin: 0 !important;
}
.currency-wrap {
}
.toggle-sound {
}
.webcam-toggler .webcam-wrap {
  width: 25px;
}
.webcam-toggler {
}
@media screen and (max-width: 992px) {
  .new-header-box {
    display: flex;
    justify-content: space-between;
  }
}
</style>
