<template>
  <div
    :id="displayedText === ' ' ? 'msgDivider' : ''"
    class="msg-line info-message"
  >
    <span>{{ displayedText }}</span>
    <div class="line"></div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    message: Object,
  },
  data() {
    return {};
  },
  computed: {
    displayedText() {
      if (this.message.action === 'disconnect') {
        return 'Disconnected';
      } else if (this.message.action === 'connect') {
        return 'Connected';
      } else if (this.message.action === 'message_load_divider') {
        return ' ';
      } else {
        return ' ';
      }
    },
  },
  mounted() {
    this.$emit('msg', this.$el);
  },
  methods: {},
};
</script>

<style></style>
