<template>
  <div
    :id="displayedText === ' ' ? 'msgDivider' : ''"
    class="msg-line info-message"
  >
    <span>{{ displayedText }}</span>
    <div class="line"></div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    message: Object,
  },
  data() {
    return {};
  },
  computed: {
    displayedText() {
      if (this.message.action === 'start') {
        return 'Чат начался';
      } else if (this.message.action === 'change') {
        let tmp = 'Текущий тип чата: текстовый';

        switch (this.message.chatType) {
          case 'video':
            tmp = 'Текущий тип чата: видео';
            break;
          case 'videoDouble':
            tmp = 'Текущий тип чата: двойной видео-чат';
            break;
        }
        return tmp;
      } else if (this.message.action === 'end') {
        return 'Чат завершен';
      } else if (this.message.action === 'disconnect') {
        return 'Соединение потеряно';
      } else if (this.message.action === 'connect') {
        return 'Соединение восстановлено';
      } else if (this.message.action === 'message_load_divider') {
        return ' ';
      } else return ' ';
    },
  },
  mounted() {
    this.$emit('msg', this.$el);
  },
  methods: {},
};
</script>

<style>
@media screen and (max-height: 425px) and (max-width: 992px) and (min-width: 425px) {
  .info-message {
    display: none;
  }
}
</style>
