<template>
  <component
    :is="message.type"
    :user="user"
    :message="message"
    @msg="passScrollEvent($event)"
  ></component>
</template>

<script>
import message from './message.vue';
import photo from './photo.vue';
import info from './info.vue';
import freephoto from './freephoto';

export default {
  name: 'MessageTemplate',
  components: {
    message,
    photo,
    info,
    freephoto,
  },
  props: {
    message: Object,
    user: Object,
  },
  data() {
    return {};
  },
  methods: {
    passScrollEvent(e) {
      this.$emit('msg', e);
    },
  },
};
</script>

<style lang="scss">
.ph-mes__preview img {
  height: 120px;
  width: 120px;
}

.msg-line-new {
  &.info-message {
    position: relative;
    text-align: center;
    color: #aeb1b7;

    .line {
      position: absolute;
      top: 50%;
      left: 0;
      height: 1px;
      width: 100%;
      background-color: #f6f6f6;
      z-index: -1;
    }

    span {
      padding: 0 10px;
      background-color: #ffffff;
      font-size: 11px;
      z-index: 10;
    }
  }

  &.photo-message {
    .sender {
      .ph-mes {
        &__attach {
          justify-content: flex-end;
        }

        &__seen {
          right: auto;
          left: 5px;
          top: 5px;
        }
      }
    }

    .ph-mes {
      &__attach {
        margin-bottom: 5px;
        color: #aeaeb1;
        font-size: 11px;

        span + span {
          margin-left: 5px;
        }

        svg {
          height: 10px;
          width: 10px;
          fill: currentColor;
        }
      }
      &__img {
        width: 120px;
        height: 120px;
      }

      &__preview {
        position: relative;
        cursor: pointer;
      }

      &__loader {
        display: block;

        &--wrap {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 120px;
          height: 120px;
          background: rgba(#000, 0.5);
        }
      }

      &__seen {
        display: none;
        position: absolute;
        top: 0;
        right: 5px;

        &.confirm {
          display: block;
        }

        .cls-1 {
          font-size: 7px;
        }
        .cls-1,
        .cls-2 {
          fill: #28a745ff;
        }
        .cls-3 {
          fill: #fff;
        }

        svg {
          height: 30px;
          width: 30px;
        }
      }
    }
  }
  + .msg-line {
    margin-top: 10px;
  }
}

.msg-line-new .date-box {
  margin: 40px 0;
  text-align: center;
  color: #aeb1b7;
  font-size: 13px;
  overflow: hidden;
}

.msg-line-new .date-box span {
  position: relative;
}

.msg-line-new .date-box span:before,
.msg-line-new .date-box span:after {
  content: '';
  position: absolute;
  top: 50%;
  width: 300px;
  border-top: 1px solid #f1f1f1;
  right: calc(100% + 10px);
}

.msg-line-new .date-box span:after {
  right: auto;
  left: calc(100% + 10px);
}

.msg-line-new {
  margin-top: 5px;
  padding: 5px;
  // border: 1px solid red;
  width: 100%;
  overflow: hidden;
}
</style>
