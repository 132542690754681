function findObjectById(array, id) {
  for (let idx = 0; idx < array.length; idx++) {
    if (array[idx] && array[idx].id == id) {
      return true;
    }
  }
  return false;
}

function findUnreadMessageFromWoman(array, woman_id) {
  let counter = 0;
  array.forEach((dialog) => {
    if (dialog && dialog.user.id == woman_id) {
      dialog.messages.forEach((message) => {
        if (message && !message.reade) {
          counter++;
        }
      });
    }
  });
  return counter;
}

export const AGE = {
  methods: {
    mixinAge(timestamp) {
      let birthday = new Date(Math.floor(timestamp * 1000)),
        ageDifMs = Date.now() - birthday.getTime(),
        ageDate = new Date(ageDifMs);
      return Math.abs(ageDate.getFullYear() - 1970);
    },
  },
};

export const IS_NEW = {
  methods: {
    mixinIsNew(created_at) {
      return (
        Date.now() - created_at * 1000 <
        this.$store.getters.siteSettings.time_while_woman_is_new * 1000
      );
    },
  },
};

export const IS_ONLINE = {
  methods: {
    mixinIsOnline(last_active) {
      return (
        Date.now() - last_active * 1000 <
        this.$store.getters.siteSettings.time_while_woman_is_online * 1000
      );
    },
  },
};

export const IS_FAVORITE = {
  methods: {
    mixinIsFavorite(woman_id) {
      if (this.$store.getters.favoriteWomanList == undefined) {
        return false;
      }
      return findObjectById(this.$store.getters.favoriteWomanList, woman_id);
    },
  },
};

export const IS_BOOKMARK = {
  methods: {
    mixinIsBookmark(woman_id) {
      if (this.$store.getters.bookmarkWomanList == undefined) {
        return false;
      }
      return findObjectById(this.$store.getters.bookmarkWomanList, woman_id);
    },
  },
};

export const UNREAD_MESSAGE = {
  methods: {
    mixinCalcUnreadMessage(woman_id) {
      // console.log(findUnreadMessageFromWoman(this.$store.getters.dialogs.list, woman_id));
      if (
        findUnreadMessageFromWoman(
          this.$store.getters.dialogs.list,
          woman_id
        ) === 0
      ) {
        return '';
      } else {
        return findUnreadMessageFromWoman(
          this.$store.getters.dialogs.list,
          woman_id
        );
      }
    },
    mixinCalcMessage(woman_id) {
      let count = 0;
      this.$store.getters.dialogs.list.forEach((dialog) => {
        if (dialog && dialog.user != undefined && dialog.user.id == woman_id) {
          if (
            dialog.messages != undefined &&
            dialog.messages instanceof Array
          ) {
            count = dialog.messages.length;
          } else {
            count = 0;
          }
        }
      });
      return count;
    },
  },
};

export const NEW_DATE = {
  methods: {
    mixinIsNewDate(woman_id) {
      let array = this.$store.getters.dialogsList;
      let last_time = 0;

      for (let i = 0; i < array.length; i++) {
        if (array[i] && array[i].user.id == woman_id) {
          let messageArray = array[i].messages;
          if (messageArray.length > 0) {
            for (let k = messageArray.length; k > 0; k--) {
              if (messageArray[i] && messageArray[i].time) {
                last_time = messageArray[i].time;
                break;
              }
            }
            break;
          } else {
            return true;
          }
        }
      }
      let lastDate = new Date(last_time * 1000),
        todayDay = new Date();
      return (
        lastDate.getFullYear() != todayDay.getFullYear() ||
        lastDate.getMonth() != todayDay.getMonth() ||
        lastDate.getDate() != todayDay.getDate()
      );
    },
  },
};
import moment from 'moment';

export const BEAUTY_TIME = {
  methods: {
    mixinBeautyTime(time) {
      return moment.utc(time * 1000).format('HH:mm:ss');
    },
  },
};

export const LAST_DIALOGS = {
  methods: {
    mixinGetUnreadMessageFromLastDialogs() {
      let counter = 0,
        arrayWomanId = [],
        arrayLastDialogs = this.$store.getters.lastDialogs,
        arrayDialogs = this.$store.getters.dialogs.list;

      arrayLastDialogs.forEach((elem) => {
        if (elem) arrayWomanId.push(elem.id);
      });
      arrayDialogs.forEach((dialog) => {
        if (
          dialog &&
          arrayWomanId.indexOf(dialog.user.id) &&
          dialog.messages &&
          dialog.messages instanceof Array &&
          dialog.messages.length > 0
        ) {
          dialog.messages.forEach((message) => {
            if (message && !message.reade) {
              counter++;
            }
          });
        }
      });
      return counter;
    },
  },
};

export const CHAT_STATUS = {
  methods: {
    mixinGetChatStatus(woman_id) {
      let result = 'stop';
      this.$store.getters.dialogs.list.forEach((dialog) => {
        if (dialog && dialog.user && dialog.user.id == woman_id) {
          result = dialog.user.chat_status;
        }
      });
      return result;
    },
    mixinGetChatType(woman_id) {
      let result = 'text';
      this.$store.getters.dialogs.list.forEach((dialog) => {
        if (
          dialog &&
          dialog.user &&
          dialog.user.id == woman_id &&
          dialog.user.chat_type
        ) {
          result = dialog.user.chat_type;
        }
      });
      return result;
    },
  },
};
