<template>
  <div class="wrap">
    <div class="speeding-wheel"></div>
  </div>
</template>

<script>
export default {
  name: 'CameraSpinner',
};
</script>

<style scoped>
.speeding-wheel {
  width: 1em;
  height: 1em;
  margin: 0 auto;
  border: 2px solid currentColor;
  border-radius: 50%;
  border-left-color: transparent;
  border-right-color: transparent;
  animation: load-spin 0.87s infinite cubic-bezier(0.42, 0, 0.58, 1);
  -o-animation: load-spin 0.87s infinite cubic-bezier(0.42, 0, 0.58, 1);
  -ms-animation: load-spin 0.87s infinite cubic-bezier(0.42, 0, 0.58, 1);
  -webkit-animation: load-spin 0.87s infinite cubic-bezier(0.42, 0, 0.58, 1);
  -moz-animation: load-spin 0.87s infinite cubic-bezier(0.42, 0, 0.58, 1);
}

@keyframes load-spin {
  100% {
    transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-o-keyframes load-spin {
  100% {
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-ms-keyframes load-spin {
  100% {
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes load-spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-moz-keyframes load-spin {
  100% {
    -moz-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.wrap {
  font-size: 20px;
}
</style>
