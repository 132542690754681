<template>
  <div id="favourites" role="tabpanel" class="tab-pane">
    <SimpleBar
      class="users-favorite-block-simplebar"
      data-simplebar-auto-hide="true"
    >
      <woman-item
        v-for="(woman, key) in favoriteWomanList"
        :key="key"
        :woman="woman"
      ></woman-item>
    </SimpleBar>
  </div>
</template>

<script>
import store from '../../../store/store';
import WomanItem from '../items/woman-item.vue';
export default {
  name: 'FavoriteWoman',
  store,
  components: {
    'woman-item': WomanItem,
  },
  computed: {
    favoriteWomanList() {
      return this.$store.getters.favorites;
    },
  },
};
</script>
<style lang="scss" scoped>
@media screen and (max-width: 450px) {
  .sidebar__search {
    display: none;
  }
}
.users-favorite-block-simplebar {
  position: relative;
  height: 100%;
  overflow-x: hidden;
}

.ios-safari {
  .users-favorite-block-simplebar {
    height: 100%;
    overflow-x: hidden;
  }
  .isIos14Up {
    .users-favorite-block-simplebar {
      height: 100%;
      overflow-x: hidden;
    }
  }
}

.sidebar__content {
  position: relative;
  overflow: hidden;
  .tab-content,
  .tab-pane {
    position: relative;
    height: 100%;
    overflow: hidden;
  }
}
.users-online-block {
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
}
</style>
