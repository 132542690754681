<!--<template>
  <transition name="modal">
    <div class="vue-modal modal-mask mail-modal" v-if="mailModal.open" @click="closeModal($event.target, false)">
      <div class="vue-modal&#45;&#45;wrapper">
        <div class="vue-modal&#45;&#45;container mail-modal&#45;&#45;body">
          <div class="modal-header">
            <h4 class="modal-title">Write letter</h4>
            <button type="button" class="close" @click="closeModal(false, true)">
              <svg class="rbi">
                <use xlink:href="/static/assets/images/rbi-icon.svg#rbi-close"></use>
              </svg>
            </button>
          </div>
          <div class="modal-body">
            <form class="new-mail-form" @submit.prevent="submitLetter">
              <div class="form-group destination-users"
                   :class="{ 'has-error': error }"
                   v-if="user.user_id">
                <p class="destination-users__item">To:</p>
                <div class="destination-users__item user-addressee">
									<span>
										<img :src="user.avatar.img_small"
                         :alt="user.name + '\'s avatar'">{{ user.name }}
									</span>
                </div>
                <div class="error" v-show="error">
                  <i class="fa fa-warning"></i>
                  <span>{{ error }}</span>
                </div>
              </div>
              <div class="form-group" v-else :class="{ 'has-error': error }">
                <div class="error" v-show="error">
                  <i class="fa fa-warning"></i>
                  <span>{{ error }}</span>
                </div>
              </div>
              <div class="form-group" :class="{ 'has-error': err.first('Subject') }">
                <input v-model="subject"
                       type="text"
                       name="mMailSubject"
                       data-vv-name="Subject"
                       class="form-control"
                       placeholder="Subject">
                <div class="error" v-show="err.first('Subject')">
                  <i class="fa fa-warning"></i>
                  <span>{{ err.first( 'Subject' ) }}</span>
                </div>
              </div>
              <div class="form-group" :class="{ 'has-error': err.first('Message') }">
                <content-editable v-if="mailModal.open" :name="'mMailMessage'"
                                  v-model="message"
                                  @clearText="clearText = $event"
                                  :placeholder="'Message'"
                                  data-vv-name="Message"
                                  class="message form-control"></content-editable>
                <p class="small text-muted" v-if="sex.isWoman" v-show="clearText">Количество введенных символов:
                  {{clearText.length}}</p>
                <div class="error" v-show="err.first('Message')">
                  <i class="fa fa-warning"></i>
                  <span>{{ err.first( 'Message' ) }}</span>
                </div>
              </div>
              <div class="send-box d-flex form-group">
                <attach-box @attached="attachments = $event"
                            :photos="attachments" :clear="clear"></attach-box>
                <button type="submit" class="btn btn-primary mail-submit">Send</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>-->

<template>
  <transition name="modal">
    <div
      v-show="mailModal.open"
      class="vue-modal modal-mask mail-modal"
      @click="closeModal($event.target, false)"
    >
      <div class="vue-modal--wrapper">
        <div class="vue-modal--container mail-modal--body">
          <div class="modal-header">
            <h4 class="modal-title">Write letter</h4>
            <button
              type="button"
              class="close"
              @click="closeModal(false, true)"
            >
              <svg class="rbi">
                <use
                  xlink:href="/static/assets/images/rbi-icon.svg#rbi-close"
                ></use>
              </svg>
            </button>
          </div>
          <div class="modal-body">
            <form class="new-mail-form">
              <div
                v-if="user.user_id"
                class="form-group destination-users"
                :class="{ 'has-error': error }"
              >
                <p class="destination-users__item">To:</p>
                <div class="destination-users__item user-addressee">
                  <div class="small-img-wrap">
                    <img
                      :src="user.avatar.img_small"
                      :alt="user.name + '\'s avatar'"
                    />
                  </div>
                  <span>{{ user.name }}</span>
                </div>
                <div v-show="error" class="error">
                  <i class="fa fa-warning"></i>
                  <span>{{ error }}</span>
                </div>
              </div>
              <div
                class="form-group"
                :class="{ 'has-error': err.first('Subject') }"
              >
                <input
                  v-model="subject"
                  type="text"
                  name="subject"
                  data-vv-name="Subject"
                  class="form-control"
                  placeholder="Subject"
                />
                <div v-show="err.first('Subject')" class="error">
                  <i class="fa fa-warning"></i>
                  <span>{{ err.first('Subject') }}</span>
                </div>
              </div>
              <div
                class="form-group"
                :class="{ 'has-error': err.first('Message') }"
              >
                <textarea
                  v-if="mailModal.open"
                  v-model="message"
                  name="'mMailMessage'"
                  placeholder="Message"
                  data-vv-name="Message"
                  class="message form-control"
                ></textarea>
                <p
                  v-if="sex.isWoman"
                  v-show="message.length > 0"
                  class="small text-muted"
                >
                  Количество введенных символов: {{ message.length }}
                </p>
                <div v-show="err.first('Message')" class="error">
                  <i class="fa fa-warning"></i>
                  <span>{{ err.first('Message') }}</span>
                </div>
              </div>
              <div v-show="attachments.length" class="attach-box--photos">
                <div
                  v-for="(photo, index) in attachments"
                  :key="photo.id"
                  class="attach-box--photos__item"
                >
                  <div class="image-wrap">
                    <img
                      class="img-respons"
                      :src="photo._photo"
                      alt="preview photo"
                      @load="photo.loading = false"
                    />
                    <div
                      v-show="!photo.loading"
                      class="del-btn"
                      @mouseup="unAttachPhoto(index)"
                    >
                      <svg class="rbi">
                        <use
                          xlink:href="/static/assets/images/rbi-icon.svg#rbi-close"
                        ></use>
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
              <div class="send-box d-flex form-group">
                <rb-button
                  :disabled="isSending"
                  class="btn-black"
                  @click.prevent.native="submitLetter"
                  >Send
                </rb-button>
                <attach-box
                  :photos="attachments"
                  :clear="clear"
                  @attached="attachments = $event"
                ></attach-box>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
/* eslint-disable no-undef */
import { LOW_CREDITS_MODAL } from '../../../mixins/formsActions';
import AttachBox from './parts/AttachPhotosBox.vue';
import { Validator } from 'vee-validate';
import RbButton from '../Button';

export default {
  name: 'WriteMailModal',
  validator: null,
  components: {
    AttachBox,
    RbButton,
  },
  mixins: [LOW_CREDITS_MODAL],
  data() {
    return {
      isSending: false,
      message: '',
      clearText: '',
      subject: '',
      error: '',
      attachments: [],
      clear: 0,
      keepUser: false,
      err: {},
    };
  },
  computed: {
    mailModal() {
      return this.$store.getters.mailModal;
    },
    user() {
      return this.mailModal.to;
    },
    token() {
      return this.$store.getters.token.site_token;
    },
    sex() {
      return this.$store.getters.sex;
    },
  },
  watch: {
    message(v) {
      this.validator.validate('Message', v).catch(() => {});
    },
    subject(v) {
      this.validator.validate('Subject', v).catch(() => {});
    },
    user(n, o) {
      if (o && o.user_id !== n.user_id && !this.keepUser) {
        this.clearData();
      }
    },
  },
  created() {
    this.validator = new Validator();
    let titleValidation = this.sex.isMan
        ? 'max:255'
        : 'required|min:10|max:255',
      letterValidation = this.sex.isMan
        ? 'required|max:5000'
        : 'required|min:200|max:5000';
    this.validator.attach({
      name: 'Subject',
      rules: titleValidation,
    });
    this.validator.attach({
      name: 'Message',
      rules: letterValidation,
    });
    this.$set(this, 'err', this.validator.errors);
  },
  methods: {
    unAttachPhoto(index) {
      this.attachments.splice(index, 1);
    },
    closeModal(e, clear) {
      if (!$(e).closest('.mail-modal--body').length || !e) {
        if (clear) {
          this.clearData();
          this.clearUser();
        }
        this.$store.commit('updateMailModal', {
          open: false,
        });
      }
    },
    clearData() {
      this.message = '';
      this.clearText = '';
      this.subject = '';
      this.keepUser = false;
      this.clear++;
      this.attachments = [];
      this.error = '';

      let self = this;
      setTimeout(() => {
        self.err.clear();
      }, 0);
    },
    userSelected(user) {
      this.$store.commit('updateMailModal', {
        to: user,
      });
      this.error = '';
    },
    clearUser() {
      this.keepUser = true;
      this.$store.commit('updateMailModal', {
        to: '',
      });
    },
    getBalance() {
      this.$http
        .post('v1/credit/balance', {
          access_token: this.token,
        })
        .then((response) => {
          if (response.body.status)
            this.$store.dispatch('setMyBalance', response.body.credits);
        });
    },
    sendLetter() {
      const attachments = this.attachments.map((i) => i.id) || [];
      this.$http
        .post('v1/message/make', {
          access_token: this.token,
          target_user_id: this.user.user_id,
          message: this.message,
          subject: this.subject,
          attachments: JSON.stringify(attachments),
        })
        .then(
          (r) => {
            if (r.body.status) {
              if (this.sex.isMan) this.getBalance();
              this.closeModal(false, true);
              this.clearData();
              this.$store.commit('addAlert', {
                type: 'success',
                text: 'Letter sent',
              });
            } else {
              let code = r.body.code;
              if (code === 10028 || code === 10161 || code === 10025) {
                this.openCreditsModal();
              } else {
                this.$store.commit('addAlert', {
                  type: 'error',
                  text: 'Some error happened during request execution',
                });
              }
            }
          },
          () => {
            this.$store.commit('addAlert', {
              type: 'error',
              text: 'Some error happened during request execution',
            });
          }
        );
    },
    submitLetter() {
      !this.user.user_id ? (this.error = 'No user selected') : '';
      this.validator
        .validateAll({
          Subject: this.subject,
          Message: this.message,
        })
        .then((success) => {
          if (success && !this.error) {
            this.sendLetter();
          }
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/vars';

.attach-box--inner {
  .btn {
    font-size: 14px !important;
  }
}
.mail-modal {
  .q-search {
    display: block !important;
    &--input {
      width: 100% !important;
    }
  }
  .form-control {
    width: 100%;
  }

  .modal-header {
    padding: 20px 20px 15px;
    border-bottom: 0;
  }
  .send-box {
    margin-top: 30px;
  }

  .destination-users {
    display: flex;
    align-items: center;

    .small-img-wrap {
      padding: 3px;
      position: relative;
      margin-right: 10px;

      &:before {
        position: absolute;
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(45deg);
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border-radius: 50%;
        border: 1px solid transparent;
        border-right-color: $black;
      }
      img {
        width: 45px;
        height: 45px;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border-radius: 50%;
      }
    }

    .btn-close {
      background: transparent;
      border: none;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      border-radius: 50%;
      color: $black;
      font-size: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      align-self: flex-start;
    }
    .user-addressee {
      display: flex;
      align-items: center;
    }
  }

  .form-group {
    margin-bottom: 20px;

    .message {
      border: 1px solid $grey-two;
    }
  }

  .attach-box--photos {
    display: flex;
    flex-wrap: wrap;
    margin: 25px -2px 0;
    &__item {
      width: 20%;
      position: relative;
      padding: 0 2px;

      .image-wrap {
        position: relative;
        padding-bottom: 88%;
        &:before {
          position: absolute;
          bottom: -3px;
          width: 25%;
          left: 0;
          height: 3px;
          display: block;
          content: '';
          background: #e21919;
        }

        .img-respons {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .del-btn {
        position: absolute;
        top: 5px;
        right: 5px;
        background: #ffffff;
        -webkit-border-radius: 50px;
        -moz-border-radius: 50px;
        border-radius: 50px;
        width: 25px;
        height: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        cursor: pointer;
        transition: 0.3s;
      }
    }
  }

  &--body {
    max-width: 100%;
    padding: 0;
  }

  .modal-body {
    padding: 0 20px 20px;
  }

  .send-box {
    flex-wrap: wrap;
    .btn {
      font-size: 12px;
      margin-right: 15px;
    }
    .attach-box {
      &--inner {
        .btn {
          font-size: 12px;
        }
      }
    }
  }
}

.has-error .form-control {
  border-color: $red !important;
  box-shadow: none;
}
</style>

<!--<style lang="scss">
  .mail-modal {

    &&#45;&#45;body {
      width: 600px;
    }


    .modal-header {
      border: none;
      margin-bottom: 15px;
    }

    .modal-header,
    .modal-body {
      padding: 0;
    }

    h4 {
      font-size: 18px;
      font-weight: bold;
    }

    input {
      appearance: none; //iOS
    }

    .destination-users,
    .destination-users__item,
    .destination-users__item > span {
      display: flex;
      justify-content: flex-start;
      align-items: center
    }

    .destination-users__item {
      margin-top: 10px;

      + .destination-users__item {
        margin-left: 10px;
      }
    }

    p {
      color: #bdbdbd;
    }

    .user-addressee {
      position: relative;
      height: 36px;
      border-radius: 88px;
      background-color: #ededed;

      > span {
        margin-right: 10px;
        color: #3e3f42;
        white-space: nowrap;

        &:hover {
          text-decoration: none;
        }
      }

      img {
        width: 36px;
        height: 36px;
        border-radius: 50%;
        margin-right: 5px;
      }

      > .btn {
        margin: 2px 2px 2px 0;
        height: 32px;
        width: 32px;
        padding: 0;
        line-height: 1;
        background-color: #d3d3d3;
        border-radius: 50%;
        border: none;
      }

      svg {
        stroke: #f1f1f1;
        fill: #f1f1f1;
        height: 12px;
        width: 12px;
      }
    }

    .btn-success {
      height: 36px;
      font-size: 14px;
      padding: 0 12px;

      > span {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .hidden-xs {
        margin-right: 10px;
      }

      svg {
        height: 16px;
        width: 16px;
        fill: currentColor;
      }
    }

    .form-group {
      margin: 0;

      + .form-group {
        margin-top: 10px;
      }
    }

    textarea {
      resize: none;
      min-height: 150px;
    }


    .uploaded-list__item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #aeaeb1;
      border-bottom: 1px solid transparent;
      padding: 5px 0 2px;

      &:hover {
        border-bottom: 1px solid #aeaeb1;

        .btn-delete {
          opacity: 1;
        }
      }

      svg {
        height: 13px;
        width: 13px;
        fill: #aeaeb1;
        stroke: #aeaeb1;
      }
    }

    .btn-delete {
      opacity: 0;
      background: none;
      border: none;
      height: 16px;
      width: 16px;
      padding: 0;
      margin-left: 10px;
      transition: opacity .2s ease-in-out;
    }

    .send-box {
      justify-content: flex-end;
      align-items: center;
    }

    .attach-box + .btn {
      margin-left: 15px;
    }

    .mail-submit {
      padding: 10px 20px;
      font-size: 11px;
      line-height: 1;
      text-transform: uppercase;
    }
  }

  @media only screen and (max-width: 992px) {
    .mail-modal {

      .vue-modal&#45;&#45;wrapper {
        flex: 1 1;
      }

      &&#45;&#45;body {
        width: auto;
      }
    }
  }

  @media only screen and (max-width: 480px) {
    .mail-modal {
      .send-box {
        flex-direction: column;
        align-items: flex-end;
      }

      .attach-box {
        &&#45;&#45;inner {
          display: block;
        }

        &&#45;&#45;photos {
          margin-bottom: 10px;

          &__item {
            flex: 1 1 30% !important;
            max-width: 80px;
          }
        }

        &&#45;&#45;btn {
          margin: 0 0 10px !important;
          justify-content: flex-end !important;
        }
      }

    }
  }
</style>-->
