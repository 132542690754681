<template>
  <div id="app" :class="{ isIos14Up: isIos14Up }">
    <div
      v-if="!connectedToSocketIO"
      class="lds-dual-ring"
      style="
        position: absolute;
        top: 0;
        width: 100%;
        background: white;
        z-index: 3000;
      "
    >
      Connecting to a messaging server.
    </div>
    <div
      v-if="$store.getters.devModeEnabled"
      style="
        position: absolute;
        left: 0;
        top: 0;
        z-index: 300;
        border: #cf9e22 1px dashed;
        background: rgba(42, 42, 42, 0.55);
        color: #b0b0b0;
      "
    >
      {{ frontendVersion }}
      <div>
        <button class="btn btn-default" @click.prevent="disconnectFromSocket">
          Disconnect!
        </button>
        <button class="btn btn-default" @click.prevent="duplicateSession">
          Duplicate!
        </button>
      </div>
    </div>
    <div
      v-if="!$store.getters.getStreamServerConnected"
      style="
        position: absolute;
        left: 0;
        top: 0;
        z-index: 300;
        border: #cf9e22 1px dashed;
        background: rgba(42, 42, 42, 0.55);
        color: #b0b0b0;
      "
      @click.prevent="showNotConnectedToStreamServerNotification()"
    >
      <img
        v-if="!$store.getters.getStreamServerConnected && currentChat"
        src="/static/assets/images/warning_icon.png"
        alt="Video server not connected"
        style="height: 30px; width: auto; padding: 2px; cursor: pointer"
        title="This device is not connected to video server. Try to refresh this page or check internet connection."
      />
    </div>

    <app-woman
      v-if="isWoman && this.$store.getters.session.site_token"
    ></app-woman>

    <app-man
      v-if="!isWoman && this.$store.getters.session.site_token"
    ></app-man>

    <ProfileModal></ProfileModal>
    <Alerts></Alerts>
    <AlertModal></AlertModal>
  </div>
</template>

<script>
/* eslint-disable no-undef */
import appMan from './components/app/app-man.vue';
import AppWoman from './components/app/app-woman.vue';
import { VIDEO_SIZE } from './mixins/utils';
import { version } from '../package.json';
import AlertModal from './components/shared/modals/Alert';
import Alerts from './components/shared/Alerts';
import ProfileModal from './components/shared/modals/ProfileModal';
import config from './config/config-production';
let reconnectInterval = null;
import { LOAD_PROFILE } from './mixins/static';
export default {
  name: 'App',
  components: {
    ProfileModal,
    Alerts,
    AlertModal,
    'app-man': appMan,
    'app-woman': AppWoman,
  },
  mixins: [VIDEO_SIZE,LOAD_PROFILE],
  data() {
    return {
      frontendVersion: version,
      disconnectTime: 0,
      isDisconnect: false,
      authorizedInChat: false,
      lastDialogsMessagesFetched: false,
      onlineFirstFetchDone: false,
      updateInterval: null,
    };
  },
  computed: {
    registered() {
      return this.$store.getters.registered;
    },
    isMan() {
      return this.$store.getters.user.type === 'man';
    },
    selfProfile() {
      return this.$store.getters.user;
    },
    token() {
      return this.$store.getters.token;
    },
    connectedToSocketIO() {
      return this.$store.getters.connectedToSocket === true;
    },
    currentChat() {
      return this.$store.getters.dialogsCurrent;
    },
    isWoman() {
      return this.$store.getters.user.type === 'woman';
    },
    mutatedPositioning() {
      return this.isWoman && this.isPrivateChat;
    },
    onlineListArray() {
      return this.$store.getters.online.length > 0
        ? this.$store.getters.online
        : null;
    },
    isIos14Up() {
      return this.$store.getters.isIos14Up;
    },
  },
  sockets: {
    session_success() {
      setTimeout(() => {
        this.allBookmarks();
        this.allFavorites();

        this.onlineFavorites();
        this.onlineBookmarks();
      }, 2000);
    },
    connect() {
      if (reconnectInterval) clearInterval(reconnectInterval);

      let chatLoginParams = this.getQueryParams(document.location.search);
      let localStorageAccessToken = localStorage.getItem('access-token');

      if (
        chatLoginParams.token !== undefined &&
        localStorageAccessToken === chatLoginParams.token
      ) {
        localStorage.setItem('access-token', chatLoginParams.token);
      } else if (
        chatLoginParams.token !== undefined &&
        localStorageAccessToken !== chatLoginParams.token
      ) {
        localStorage.setItem('access-token', chatLoginParams.token);
      } else {
        window.location = this.$store.getters.mediaServerUrl;
      }

      this.access_token = chatLoginParams.token;

      this.$http
        .post('v1/profile/profile', {
          access_token: this.access_token,
        })
        .then((result) => {
          let body = result.body;
          if (body.status) {
            this.$store.dispatch('registerUserSession', {
              token: this.access_token,
              frontendVersion: this.frontendVersion,
            });

            let favorites = this.$store.getters.favorites;
            let bookmarks = this.$store.getters.bookmarks;
            let all_online = this.$store.getters.online;
            let data = body.result;
            window.userType = data.user_type;

            this.$store.dispatch('setCurrentAppUser', {
              id: data.user_id,
              profile: {
                avatar: this.$store.getters.mediaServerUrl + data.avatar.medium,
                avatarList: data.avatar,
                name: data.name,
              },
              wallet: {},
              type: data.user_type,
              favorites: favorites,
              bookmarks: bookmarks,
              all_online: all_online,
              cameraProcess: false,
              camera: 'no',
              webCamStream: null,
            });
          } else {
            /*window.location = this.$store.getters.mediaServerUrl*/
            this.$store.commit('addAlert', {
              type: 'error',
              text: `Sorry. We couldn't sign you in. Please try again. `,
              info: 'reg error ',
            });
            this.$store.commit('addAlert', {
              type: 'error',
              text: `This page will be reloaded automatically after 5 seconds.`,
              info: 'reg error ',
            });
            setTimeout(() => {
              window.location.reload();
            }, 5000);
          }
        }, ()=>{
          this.$store.commit('addAlert', {
            type: 'error',
            text: `Sorry. We couldn't sign you in. Please try again. `,
            info: 'reg error ',
          });
          window.location.href = this.$store.getters.mediaServerUrl;
        })

    },
    disconnect() {
      reconnectInterval = setInterval(() => {
        this.$log.info('Attempt to reconnect.');
        this.$socket.open();
      }, 5000);

      this.$store.dispatch(
        'disconnectFromRTCServerSocket',
        'Messaging server disconnect.'
      );

      this.$log.info('socket disconnect');

      this.$store.getters.dialogs.list.map((dialog) => {
        dialog.active = false;
        dialog.roomStatus = 'stop';
        dialog.user.chatActivated = false;
        dialog.user.chat_status = 'stop';
      });

      this.disconnectTime = Math.floor(Date.now() / 1000);
      this.isDisconnect = true;
      this.$store.dispatch('setConnectedToSocket', false);
    },
  },
  watch: {
    connectedToSocketIO(newVal) {
      if (newVal === true) {
        this.$socket.emit('get_delivery_templates', {
          token: this.token.chat_token,
          user_id: this.selfProfile.id,
        });
        this.updateInterval = setInterval(
          function () {
            this.lastDialogs();
            this.onlineFavorites();
            this.onlineBookmarks();
            this.allBookmarks();
            this.allFavorites();

            this.onlineList();

            this.$http.post('v1/active/set', {
              access_token: this.$store.getters.token.site_token,
            });
          }.bind(this),
          15 * 1000
        );
      } else {
        clearInterval(this.updateInterval);
      }
    },
    isWoman(v) {
      if (v) document.title = `Chat: ${this.selfProfile.id}`;
    },
    onlineListArray: function (newVal) {
      if (newVal.length > 0 && !this.onlineFirstFetchDone) {
        let chatLoginParams = this.getQueryParams(document.location.search);

        if ('id' in chatLoginParams) {
          this.$socket.emit('load_message_history', {
            token: this.$store.getters.session.site_token,
            targetUser: chatLoginParams.id,
            limit: 20,
          });
        }

        this.onlineFirstFetchDone = true;
      }
    },
  },
  mounted() {
    console.log('test TEST test')

    if (this.updateInterval) clearInterval(this.updateInterval);

    window.addEventListener('gesturestart', (e) => {
      e.preventDefault();
    });

    this.$store.commit('INIT_APP_CONFIG', this.config);
    window.socketIo = this.$socket;

    let self = this;
    setTimeout(() => {
      self.initEmojiPicker();
    }, 100);
  },
  beforeDestroy() {
    clearInterval(this.updateInterval);
  },

  methods: {
    getSiteType() {
      fetch(config.api_url+'/v1/site-settings/get-is-prod')
          .then(response => response.json())
          .then(({status,isProd}) => {
            console.log(status,isProd)
            if (!isProd && !status)
              window.location = 'https://redbini.com/';
          })
    },
    showNotConnectedToStreamServerNotification() {
      this.$store.commit('addAlert', {
        type: 'error',
        text: 'This device is not connected to video server. Try to refresh this page or check internet connection.',
        info: 'not connected',
      });
    },
    initEmojiPicker() {
      try {
        if (EmojiPicker) {
          this.emojis = new EmojiPicker({
            assetsPath: '/static/assets/images/newEmoji',
          });
          this.emojis.discover();
        } else {
          throw new Error('not loaded yet');
        }
      } catch (e) {
        let self = this;
        setTimeout(() => {
          self.initEmojiPicker();
        }, 300);
      }
    },
    lastDialogs() {
      let access_token = this.$store.getters.token.site_token;
      this.$http
        .post('v1/chat/dialogs/last', {
          access_token: access_token,
        })
        .then(
          (lastDialogs) => {
            if (lastDialogs.body.length > 0) {
              const prettyArray = [];
              let users = lastDialogs.body;
              const userIds = [];
              users.sort((a, b) => {
                if (a.online && !b.online) {
                  return -1;
                } else return 1;
              });
              let chatLoginParams = this.getQueryParams(
                document.location.search
              );
              let targetUserFromInvite = null;
              if ('id' in chatLoginParams) {
                targetUserFromInvite = parseInt(chatLoginParams.id);
              }
              for (let i = 0; i < users.length; i++) {
                if (users[i].user_id !== targetUserFromInvite) {
                  userIds.push(users[i].user_id);
                }

                prettyArray.push({
                  id: users[i].user_id,
                  last_active: users[i].last_active,
                  created_at: users[i].created_at,
                  is_notification: false,
                  count_message: 0,
                  chat_time: 0,
                  objectFrom: 'setLastDialogs',
                  chatActivated: false,
                  chat_status: 'stop',
                  chat_type: 'text',
                  isNew: users[i].new,
                  camera: users[i].camera,
                  status: users[i].status,
                  profile: {
                    birthday: users[i].birthday,
                    name: users[i].name,
                    avatar:
                      this.$store.getters.mediaServerUrl +
                      users[i].avatar.medium,
                    avatarList: users[i].avatar,
                  },
                });
              }

              this.$store.dispatch('setLastDialogs', prettyArray);
              if (!this.lastDialogsMessagesFetched) {
                /* this.$socket.emit('fetchLastChatMessages', {
                token: this.$store.getters.token.chat_token,
                userIds,
              })*/

                this.$socket.emit('fetchLastChatsStatus', {
                  token: this.$store.getters.token.site_token,
                  userIds,
                });

                this.lastDialogsMessagesFetched = true;
              }
            } else {
              this.$store.commit('SET_LAST_DIALOGS_LOADED', true);
            }
          },
          (error) => {
            this.$log.error(error);
            setTimeout(() => {
              this.lastDialogs();
            }, 10000);
          }
        );
    },
    async onlineList() {
      const listPromise = new Promise((resolve, reject) => {
        let access_token = this.$store.getters.token.site_token;
        //          console.log('access_token', access_token);
        if (access_token) {
          this.$http
            .post('v1/chat/online/list', {
              access_token: access_token,
            })
            .then((onlineResponse) => {
              if (onlineResponse.status) {
                let prettyArray = [];
                let users = onlineResponse.body.result;
                for (let i = 0; i < users.length; i++) {
                  prettyArray.push({
                    id: parseInt(users[i].user_id),
                    last_active: users[i].last_active,
                    created_at: users[i].created_at,
                    is_notification: false,
                    count_message: 0,
                    chat_status: 'stop',
                    chat_type: 'text',
                    chat_time: 0,
                    objectFrom: 'online list',
                    chatActivated: false,
                    isNew: users[i].new,
                    camera: users[i].camera,
                    status: users[i].status,
                    profile: {
                      birthday: users[i].birthday,
                      name: users[i].name,
                      avatar:
                        this.$store.getters.mediaServerUrl +
                        users[i].avatar.medium,
                      avatarList: users[i].avatar,
                    },
                  });
                }
                // prettyArray = prettyArray.sort(function (a, b) {
                //   return b.id - a.id;
                // });
                this.$store.dispatch('setOnlineList', prettyArray);
                resolve(true);
              }
            })
            .catch(() => {
              reject('error');
            });
        } else {
          reject('Failed to get online list');
        }
      });
      listPromise.catch((err) => {
        console.error('Error : ', err);
      });
    },
    shuffle(array) {
      let m = array.length,
        t,
        i;

      // While there remain elements to shuffle…
      while (m) {
        // Pick a remaining element…
        i = Math.floor(Math.random() * m--);

        // And swap it with the current element.
        t = array[m];
        array[m] = array[i];
        array[i] = t;
      }

      return array;
    },
    onlineFavorites() {
      let access_token = this.$store.getters.token.site_token;
      this.$http
        .post('v1/chat/load/favorites', {
          access_token: access_token,
        })
        .then((favoritesResult) => {
          let f = favoritesResult.body.result;
          let prettyFArray = [];
          for (let i = 0; i < f.length; i++) {
            prettyFArray.push({
              id: parseInt(f[i].id),
              last_active: parseInt(f[i].last_active),
              created_at: parseInt(f[i].created_at),
              is_notification: false,
              count_message: 0,
              chat_status: 'stop',
              chat_type: 'text',
              isNew: f[i].new,
              camera: f[i].camera,
              status: f[i].status,
              profile: {
                birthday: parseInt(f[i].profile.birthday),
                name: f[i].profile.name,
                avatarList: f[i].profile.avatarList,
              },
            });
          }
          this.$store.commit('SET_FAVORITES_ONLINE', prettyFArray);
        })
        .catch(() => {
          /*  setTimeout(() => {
                   this.onlineFavorites()
                   }, 2000)*/
        });
    },
    allFavorites() {
      // TODO use new endpoint for load all accept invite and followed
      let access_token = this.$store.getters.token.site_token;
      this.$http
        .post('v1/favorite/load', {
          access_token: access_token,
        })
        .then((favoritesResult) => {
          let arrayId = [];
          if (favoritesResult.body.status) {
            favoritesResult.body.result.favorite_accept.forEach((item) => {
              if (arrayId.indexOf(item.favorite_user_id) === -1) {
                arrayId.push(item.favorite_user_id);
              }
            });
            favoritesResult.body.result.favorite_invite_send.forEach((item) => {
              if (arrayId.indexOf(item.favorite_user_id) === -1) {
                arrayId.push(item.favorite_user_id);
              }
            });
          }
          this.$store.dispatch('setAllFavorites', arrayId);
        })
        .catch(() => {
          /* setTimeout(() => {
                   this.allFavorites()
                   }, 2000)*/
        });
    },
    allBookmarks() {
      let access_token = this.$store.getters.token.site_token;
      this.$http
        .post('v1/bookmark/load', {
          access_token: access_token,
        })
        .then((bookmarksResult) => {
          let arrayId = [];
          if (bookmarksResult.body.status) {
            bookmarksResult.body.result.forEach((bookmark) => {
              if (bookmark && 'user_id' in bookmark) {
                arrayId.push(bookmark.user_id);
              }
            });
          }
          this.$store.dispatch('setAllBookmarks', arrayId);
        })
        .catch(() => {
          /*  setTimeout(() => {
                   this.allBookmarks()
                   }, 2000)*/
        });
    },
    onlineBookmarks() {
      let access_token = this.$store.getters.token.site_token;
      this.$http
        .post('v1/chat/load/bookmarks', {
          access_token: access_token,
        })
        .then((bookmarksResult) => {
          let b = bookmarksResult.body.result;
          let prettyBArray = [];
          for (let i = 0; i < b.length; i++) {
            prettyBArray.push({
              id: parseInt(b[i].id),
              last_active: parseInt(b[i].last_active),
              created_at: parseInt(b[i].created_at),
              is_notification: false,
              count_message: 0,
              chat_status: 'stop',
              chat_type: 'text',
              isNew: b[i].new,
              camera: b[i].camera,
              status: b[i].status,
              profile: {
                birthday: parseInt(b[i].profile.birthday),
                name: b[i].profile.name,
                avatarList: b[i].profile.avatarList,
              },
            });
          }
          this.$store.commit('SET_BOOKMARKS_ONLINE', prettyBArray);
        })
        .catch(() => {
          /* setTimeout(() => {
                   this.onlineBookmarks()
                   }, 2000)*/
        });
    },
    checkChatSession() {},
    getQueryParams(qs) {
      qs = qs.split('+').join(' ');
      let params = {},
        tokens,
        re = /[?&]?([^=]+)=([^&]*)/g;

      while ((tokens = re.exec(qs))) {
        params[decodeURIComponent(tokens[1])] = decodeURIComponent(tokens[2]);
      }
      return params;
    },
    loadCurrentChatMessage() {
      // this.currentChat.messages = []
      this.$http
        .post(this.config.socket_url + '/api/messages/last-chat', {
          interlocutor: this.currentChat.user.id,
          access_token: this.token.site_token,
        })
        .then((result) => {
          this.$store
            .dispatch('getLastDialog', result.data.messages.reverse())
            .then(() => {
              if (this.isDisconnect) {
                // this.$store.commit('setInfoMessage', {
                //   type: 'info',
                //   action: 'disconnect',
                //   reade: true,
                //   key: Date.now() + 'disconnect'
                // })
                this.isDisconnect = false;
              }
              /*this.$store.commit('setInfoMessage', {
                type: 'info',
                action: 'connect',
                reade: true,
                key: Date.now() + 'connect',
              })*/
            });
        });
    },
    disconnectFromSocket() {
      this.$socket.close(4666, 'Manual disconnect');
    },
    duplicateSession() {
      this.$store.dispatch('registerUserSession', {
        token: this.token.site_token,
        frontendVersion: this.frontendVersion,
      });
    },
  },
};
</script>
<style lang="scss">
@import 'assets/scss/common';
@import '~animate.css/animate.compat.css';

.simplebar-vertical {
  .simplebar-scrollbar:before {
    // opacity: 1 !important;
    width: 3px !important;
    border-radius: 0 !important;
    background: #e21919 !important;
  }

  .simplebar-visible:before {
    opacity: 1 !important;
  }
}

.lds-dual-ring {
  padding-top: 5px;
  display: flex;
  justify-content: center;
}
.lds-dual-ring:after {
  content: ' ';
  display: block;
  width: 15px;
  height: 15px;
  margin: 8px;
  border-radius: 50%;
  border: 2px solid #fff;
  border-color: #a00000 transparent #a00000 transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
