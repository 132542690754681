<template>
  <div>
    <form
      class="send-form stickySendBox"
      :class="{ 'no-invites': !invitesLength }"
      @submit.prevent.stop="sendMessage"
    >
      <div class="form-wrap">
        <div class="content-editable translate-active">
          <div class="input-wrapper-woman">
            <div class="input-buttons-wrapper">
              <div class="attach-button attach-button-background">
                <button
                  class="btn attach-button-background"
                  :class="{ 'translate-active': !messageEmpty }"
                  :disabled="messageEmpty"
                  type="button"
                  @click.prevent="translate"
                >
                  <svg class="rbi">
                    <use
                      xlink:href="/static/assets/images/rbi-icon.svg#rbi-language"
                    />
                  </svg>
                  <!--<span
                 style="font-size: small"
                 class="d-none d-lg-block"
                 v-if="!inProcess"
                 >Перевести</span
               >-->
                  <span v-if="inProcess" class="load d-none d-lg-block">
                    <span class="lds-ellipsis">
                      <span></span>
                      <span></span>
                      <span></span>
                      <span></span>
                    </span>
                  </span>
                </button>
              </div>
            </div>

            <div class="input-buttons-wrapper">
              <div class="attach-button">
                <button
                  class="btn attach-button-background"
                  @click.prevent="attachOpen = true"
                >
                  <svg class="rbi">
                    <use
                      xlink:href="/static/assets/images/rbi-icon.svg#rbi-clip"
                    />
                  </svg>
                </button>
              </div>
            </div>

            <content-editable
              ref="messageInput"
              v-model="message"
              class="text-input"
              :clear="clear"
              :placeholder="'Напишите сообщение'"
              @focus-input="$emit('focus-input')"
              @blur-input="$emit('blur-input')"
              @submit="sendMessage($event)"
            ></content-editable>

            <div class="input-buttons-wrapper">
              <div class="send-button" @click="sendMessage($event)">
                <div class="btn">
                  <div class="btn-black btn-send-form">
                    <svg class="rbi d-block">
                      <use
                        xlink:href="/static/assets/images/rbi-icon.svg#rbi-paperplane"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--<div class="send-box wmn">
         <div class="send-box__actions">
           <div class="btn-group">
             <rb-button
               class="btn-outline"
               :class="{ 'translate-active': !messageEmpty }"
               :disabled="messageEmpty"
               type="button"
               @click.native="translate"
             >
               <svg class="rbi">
                 <use
                   xlink:href="/static/assets/images/rbi-icon.svg#rbi-language"
                 />
               </svg>
               <span class="text-uppercase d-none d-lg-block" v-if="!inProcess"
                 >Перевести</span
               >
               <div class="load d-none d-lg-block" v-if="inProcess">
                 <div class="lds-ellipsis">
                   <div></div>
                   <div></div>
                   <div></div>
                   <div></div>
                 </div>
               </div>
             </rb-button>
             <button
               class="btn btn-outline d-lg-none"
               @click.prevent="attachOpen = true"
               v-if="isActive"
               style="height: 6vh"
             >
               <svg class="rbi">
                 <use xlink:href="/static/assets/images/rbi-icon.svg#rbi-clip" />
               </svg>
               <span class="hidden-sm hidden-xs">Прикрепить</span>
             </button>
             <rb-button type="submit" class="btn-black d-none d-lg-flex">
               <span>
                 <svg class="rbi d-block d-lg-none">
                   <use
                     xlink:href="/static/assets/images/rbi-icon.svg#rbi-paperplane"
                   />
                 </svg>
                 <span class="d-none d-lg-block">Отправить</span>
               </span>
             </rb-button>
           </div>
           <button
             class="btn-attach d-none d-lg-flex"
             @click.prevent="attachOpen = true"
           >
             <svg class="rbi">
               <use xlink:href="/static/assets/images/rbi-icon.svg#rbi-clip" />
             </svg>
             <span class="hidden-sm hidden-xs">Прикрепить</span>
           </button>
           <rb-button
             type="submit"
             class="btn-black d-flex d-lg-none"
             style="width: 10vw; position: absolute; right: 0"
           >
             <span>
               <svg class="rbi d-block d-lg-none">
                 <use
                   xlink:href="/static/assets/images/rbi-icon.svg#rbi-paperplane"
                 />
               </svg>
               <span class="d-none d-lg-block">Отправить</span>
             </span>
           </rb-button>
         </div>
       </div>-->
      </div>
      <attach-photos
        :user-id="receiver"
        :limit="1"
        :passed-count="0"
        :sell="true"
        :open="attachOpen"
        @close="attachOpen = false"
        @selected="getSelectedPhotos($event)"
      ></attach-photos>
    </form>
  </div>
</template>

<script>
import contentEditable from '../shared/ContentEditable.vue';
import attachPhotos from '../shared/modals/PhotosModal.vue';

export default {
  components: {
    contentEditable,
    attachPhotos,
  },
  //        scroll: null,
  data() {
    return {
      isIphone: false,
      attachOpen: false,
      message: '',
      //                placeholder: true,
      //                emoji: {},
      //                currentOffset: 0,
      //                latestUsedEmoji: [],
      clear: false,
      inProcess: false,
    };
  },
  computed: {
    token() {
      return this.$store.getters.token;
    },
    currentUser() {
      return this.$store.getters.dialogs.current
        ? this.$store.getters.dialogs.current.user
        : null;
    },
    isCollocutorOnline() {
      if (this.currentUser) {
        const foundUser = this.$store.getters.online.filter(
          (user) => this.currentUser.id === user.id
        );
        if (foundUser.length > 0) {
          return true;
        } else return false;
      } else return false;
    },
    receiver() {
      return this.$store.getters.dialogs.current.user.id;
    },
    isActive() {
      return this.$store.getters.dialogs.current.user.chat_status === 'active';
    },
    isVideoChat() {
      return this.$store.getters.dialogs.current.user.chat_type === 'video';
    },
    isTextChat() {
      return this.$store.getters.dialogs.current.user.chat_type === 'text';
    },
    isVideoDoubleChat() {
      return (
        this.$store.getters.dialogs.current.user.chat_type === 'videoDouble'
      );
    },
    messageEmpty() {
      return this.message && this.message.length < 2;
    },
    invitesLength() {
      return this.$store.getters.invites.length;
    },
  },
  watch: {
    receiver() {
      this.focusInput();
      this.clearMessage();
    },
  },
  mounted() {
    this.clear = !this.clear;
    this.focusInput();
  },
  methods: {
    focusInput() {
      if (window.innerWidth > 450) {
        this.$refs.messageInput.$el.focus();
      }
    },
    triggerInputBlur() {
      this.$refs.messageInput.triggerBlur();
    },
    getSelectedPhotos(e) {
      let id = e[0].id;
      this.$http
        .post('v1/chat/open-photo-to-buy', {
          access_token: this.token.site_token,
          photo_id: id,
          target_user_id: this.receiver,
        })
        .then(
          (r) => {
            if (r.body.status) {
              this.$socket.emit('message', {
                token: this.token.site_token,
                receiver: this.receiver,
                text: 'Photo',
                photoSell: true,
                photoId: id,
                type: this.isVideoChat ? 'video' : 'text',
                inviteTime:
                  this.$store.getters.settings.chat.invite_show_time_for_woman,
              });
              this.$store.commit('SET_CURRENT_TYPED_MESSAGE', '');
            } else {
              console.error('unable to send photo');
            }
          },
          () => {
            console.error('unable to send photo');
          }
        );
    },
    responsiveShowLastDialogs() {
      if (this.invitesLength) {
        this.$store.dispatch('setResponsiveShowLastDialogs', true);
      }
    },
    clearMessage() {
      this.clear = !this.clear;
    },
    sendMessage() {
      if (this.message.length > 0) {
        let oldMessage = this.message;
        this.message = '';
        this.clearMessage();
        let type = 'text';
        if (this.isVideoChat) {
          type = 'video';
        }
        if (this.isTextChat) {
          type = 'text';
        }

        this.$socket.emit('message', {
          token: this.token.site_token,
          receiver: this.receiver,
          text: oldMessage,
          type: type,
          isChatActive: this.isActive, // need to reactivate chat from node.js on disconnect
          isCompanionOnline: this.isCollocutorOnline, // not to start on node.js if offline
        });

        if (!this.isCollocutorOnline) {
          this.$store.commit('addAlert', {
            type: 'error',
            text: `${this.currentUser.profile.name} is offline. Messages will not be delivered to offline users`,
            info: 'Companion offline ',
          });
        }
        this.$store.commit('SET_CURRENT_TYPED_MESSAGE', '');
      }
    },
    translate() {
      this.inProcess = true;
      this.$http
        .post('v1/chat/translate', {
          access_token: this.token.site_token,
          sentence: this.message,
          from: 'ru',
          to: 'en',
        })
        .then(
          (translatedText) => {
            if (translatedText.data.status) {
              this.$store.commit(
                'SET_CURRENT_TYPED_MESSAGE',
                translatedText.data.text
              );
              this.inProcess = false;
            } else {
              //                        console.log('Translate error');
              this.inProcess = false;
            }
          },
          () => {
            //                    console.log(err);
            this.inProcess = false;
          }
        );
    },
  },
};
</script>

<style lang="scss">
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 40px;
  height: 6px;
}

.lds-ellipsis span {
  position: absolute;
  top: 0;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #c00404;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis span:nth-child(1) {
  left: 6px;
  animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis span:nth-child(2) {
  left: 6px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis span:nth-child(3) {
  left: 16px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis span:nth-child(4) {
  left: 28px;
  animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(10px, 0);
  }
}

.send-form {
  width: 100%;
  z-index: 100;
  padding-left: 5px;
  padding-right: 5px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-bottom: calc(1em + env(safe-area-inset-bottom));
}

.form-wrap {
  z-index: 1000;
}

.input-active {
  .send-form {
    background: #ffffff;
  }
}

.form-wrap {
  width: 100% !important;
}

.input-wrapper-woman {
  display: grid;
  grid-template-columns: 10% 10% 70% 10%;
  height: 100%;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(23, 23, 23, 0.15);
  background-color: rgba(255, 255, 255, 1);
  padding: 5px;

  .text-input {
    height: 100%;
    width: 100%;

    border: 1px solid rgba(227, 227, 227, 0.35);
    border-radius: 5px 5px;
    padding: 0 5px;

    .message {
      font-size: 17px !important;
    }
  }
}

.input-wrapper-woman {
  .input-buttons-wrapper {
    height: 100% !important;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    padding: 15px 0;
  }

  .send-button,
  .attach-button {
    height: 30px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    flex-direction: column;
    .btn {
      background: transparent;
      display: flex !important;
      justify-content: center !important;
      align-items: center;

      &:disabled svg {
        fill: #000000a8;
      }
      &:active {
        box-shadow: none !important;
      }
    }
  }

  .send-button {
    .rbi {
      color: #e21919 !important;
      width: 25px;
      height: 25px;
    }
  }

  .attach-button {
    .rbi {
      color: #e21919 !important;
      width: 17px;
      height: 17px;
    }
  }
}

.input-wrapper-woman .btn-send-form {
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 450px) {
  .input-wrapper-woman {
    .input-buttons-wrapper {
      padding: 7px 0;
    }
    grid-template-columns: 12% 12% 63% 15%;
    .send-button,
    .attach-button {
      .btn {
        padding: 10px 0 !important;
      }
    }
  }
}

@media screen and (max-height: 425px) and (max-width: 992px) and (min-width: 425px) {
  .input-wrapper-woman {
    .input-buttons-wrapper {
      padding: 2px 0;
    }
  }
}

.content_text {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /*min-height: 26px;*/
  p {
    margin: 0;
  }
  .error_message {
    font-size: 12px;
    color: red;
  }
  .text_right {
    margin: 0 0 0 auto;
    text-align: right;
    font-size: 12px;
  }
}
</style>
