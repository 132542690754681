import Vue from 'vue';
import configProd from '../../config/config-production';
import Logger from '../../utils/Logger';

const devModeEnabled = !configProd.isProduction;

const logger = new Logger(devModeEnabled);

const state = {};

const getters = {};

const mutations = {};

const actions = {
  registerUserSession(context, payload) {
    const ua = window.navigator.userAgent.toLowerCase();

    window.socketIo.emit('registration', {
      site_token: payload.token,
      type: 'chat',
      front_v: payload.frontendVersion,
      user_agent: ua,
    });
  },

  socket_session_error(context) {
    window.location = context.getters.mediaServerUrl;
  },
  socket_session_success(context, { siteToken, chatToken }) {
    context.dispatch('setConnectedToSocket', true);

    logger.debug('socket_session_success!!!!', siteToken);

    if (!siteToken || !chatToken) window.location.reload();

    context.dispatch('setToken', {
      site_token: siteToken,
      chat_token: chatToken,
    });

    context.commit('CLEAN_DIALOGS_LIST');
    context.dispatch('fetchLastDialogs');
    context.dispatch('fetchOnlineList');

    /* context
      .dispatch('connectToRTCServerSocket', false)
      .then(() => {
        setTimeout(() => {
          context.dispatch('registerOnVideoServer');
        }, 2000);
      })
      .catch((err) => {
        logger.error(err);
        alert('Failed to connect video server');
      }); */

    window.socketIo.emit('get_settings', {});

    context.dispatch('getUserSettings', siteToken);

    if (context.getters.sex.isWoman) {
      window.socketIo.emit('load_personal_config', {
        token: context.getters.token.chat_token,
        user: context.getters.user.id,
      });
    }
  },

  fetchLastDialogs(context) {
    let access_token = context.getters.token.site_token;
    Vue.http
      .post('v1/chat/dialogs/last', {
        access_token: access_token,
      })
      .then((lastDialogs) => {
        if (lastDialogs.body.length > 0) {
          const prettyArray = [];
          const userIds = [];

          let users = lastDialogs.body;

          users.sort((a, b) => {
            if (a.online && !b.online) {
              return -1;
            } else return 1;
          });

          for (let i = 0; i < users.length; i++) {
            userIds.push(users[i].user_id);
            prettyArray.push({
              id: users[i].user_id,
              last_active: users[i].last_active,
              created_at: users[i].created_at,
              is_notification: false,
              count_message: 0,
              chat_time: 0,
              objectFrom: 'setLastDialogs',
              chatActivated: false,
              chat_status: 'stop',
              chat_type: 'text',
              isNew: users[i].new,
              camera: users[i].camera,
              status: users[i].status,
              profile: {
                birthday: users[i].birthday,
                name: users[i].name,
                avatar: context.getters.mediaServerUrl + users[i].avatar.medium,
                avatarList: users[i].avatar,
              },
            });
          }

          context.dispatch('setLastDialogs', prettyArray);

          context.commit('SET_LAST_DIALOGS_LOADED', false);

          window.socketIo.emit('fetchLastChatMessages', {
            token: context.getters.token.site_token,
            userIds,
            limit: 20,
          });

          window.socketIo.emit('fetchLastChatsStatus', {
            token: context.getters.token.site_token,
            userIds,
          });

          /*if (!context.getters.lastDialogsLoaded) {
            window.socketIo.emit('fetchLastChatMessages', {
              token: context.getters.token.chat_token,
              userIds,
            })

            context.commit('SET_LAST_DIALOGS_LOADED', true)
          }*/

          /*   setTimeout(() => {
            if (context.getters.dialogs.prevDialogUser) {
              context.dispatch(
                'setCurrentChat',
                context.getters.dialogs.prevDialogUser
              )
            }
          }, 1000)*/
        } else {
          context.commit('SET_LAST_DIALOGS_LOADED', true);
        }
      })
      .catch((error) => {
        logger.error(error);
      });
  },
  fetchOnlineList(context) {
    const listPromise = new Promise((resolve, reject) => {
      let access_token = context.getters.token.site_token;

      if (access_token) {
        Vue.http
          .post('v1/chat/online/list', {
            access_token: access_token,
          })
          .then((onlineResponse) => {
            if (onlineResponse.status) {
              let prettyArray = [];
              let users = onlineResponse.body.result;
              for (let i = 0; i < users.length; i++) {
                prettyArray.push({
                  id: parseInt(users[i].user_id),
                  last_active: users[i].last_active,
                  created_at: users[i].created_at,
                  is_notification: false,
                  count_message: 0,
                  chat_status: 'stop',
                  chat_type: 'text',
                  chat_time: 0,
                  objectFrom: 'online list',
                  chatActivated: false,
                  isNew: users[i].new,
                  camera: users[i].camera,
                  status: users[i].status,
                  profile: {
                    birthday: users[i].birthday,
                    name: users[i].name,
                    avatar:
                      context.getters.mediaServerUrl + users[i].avatar.medium,
                    avatarList: users[i].avatar,
                  },
                });
              }
              // prettyArray = prettyArray.sort(function (a, b) {
              //   return b.id - a.id;
              // });
              context.dispatch('setOnlineList', prettyArray);
              resolve(true);
            }
          })
          .catch(() => {
            reject('error');
          });
      } else {
        reject('Failed to get online list');
      }
    });
    listPromise
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        logger.error('Error : ', err);
      });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
