<template>
  <aside
    id="users-online"
    class="online-box accordion-inner wmn sidebar"
    :class="{ open: responsiveShowUserOnline }"
  >
    <div class="header-box__item">
      <div class="logo-wrapper" style="width: 100%">
        <div class="logo"></div>
      </div>
    </div>

    <div
      v-show="responsiveShowUserOnline"
      class="sb-dummy left"
      @click="responsiveCloseUserOnline"
    ></div>
    <div class="ovh white-box">
      <div class="sidebar__header sidebar__header-wmn">
        <h2 class="sidebar__header_h"><span>Мужчины</span></h2>
        <!--        <div class="flex-center">
          <div class="buttons-wrapper">
            <button
              type="button"
              class="btn btn-sending active"
              :disabled="!ifNotPrepared"
              @click="toggleDelivery"
              :title="currentDeliveryButtonAction"
            >
              <span :class="{ 'sending-animation': deliveryRun }">
                <svg class="rbi">
                  <use
                    xlink:href="/static/assets/images/rbi-icon.svg#rbi-mailing"
                  ></use>
                </svg>
              </span>
            </button>
            <button
              type="button"
              class="btn btn-settings"
              disabled="true"
              title="Открыть настройки авторассылки"
              @click="openDispatchModal"
            >
              <span>
                <svg class="rbi">
                  <use
                    xlink:href="/static/assets/images/rbi-icon.svg#rbi-gear"
                  ></use>
                </svg>
              </span>
            </button>
          </div>
        </div>-->
        <button class="btn-close" @click="responsiveCloseUserOnline">
          <svg class="rbi">
            <use
              xlink:href="/static/assets/images/rbi-icon.svg#rbi-close"
            ></use>
          </svg>
        </button>
      </div>
      <!--      <div class="heading">
              <h2>
                <span class="visible-md visible-lg">Мужчины</span>
                <span class="flex-center hidden-lg hidden-md">
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 612 612">
                              <path
                    d="M306 325.988c90.564-.005 123.148-90.682 131.68-165.167C448.188 69.06 404.8 0 306 0c-98.78 0-142.194 69.055-131.678 160.82 8.54 74.484 41.114 165.175 131.68 165.168z"/>
                              <path
                    d="M550.98 541.908c-.99-28.904-4.376-57.94-9.42-86.393-6.11-34.47-13.89-85.002-43.983-107.465-17.404-12.988-39.94-17.25-59.865-25.08-9.697-3.81-18.384-7.595-26.537-11.902-27.518 30.176-63.4 45.962-105.186 45.964-41.775 0-77.653-15.786-105.168-45.964-8.153 4.308-16.84 8.093-26.537 11.9-19.924 7.833-42.46 12.093-59.863 25.082-30.096 22.463-37.873 72.996-43.983 107.465-5.046 28.454-8.434 57.49-9.423 86.393-.766 22.387 10.288 25.525 29.017 32.284 23.453 8.458 47.666 14.737 72.04 19.884 47.078 9.94 95.604 17.582 143.922 17.924 48.318-.343 96.844-7.983 143.92-17.924 24.376-5.145 48.59-11.424 72.042-19.884 18.736-6.757 29.79-9.895 29.023-32.284z"/>
                          </svg>
                          <span>Онлайн</span>
                      </span>
              </h2>
              <div class="flex-center">
                <div class="buttons-wrapper">
                  <button type="button" class="btn btn-sending active" :disabled="!ifNotPrepared"
                          @click="toggleDelivery"
                          :title="currentDeliveryButtonAction">
                                  <span :class="{'sending-animation': deliveryRun}">
                                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 37 33">
                                          <path
                                            d="M32.5 12.5v2.1c0 1.1-.9 2-2 2h-4c-1.1 0-2-.9-2-2v-2.1h-2.1c-1.1 0-2-.9-2-2v-4c0-1.1.9-2 2-2h2.1V2.9c-2.1-.8-4.4-1.2-6.8-1.2C8 1.7.1 8.4.1 16.6c0 4 1.8 7.6 4.8 10.2l-1.6 6 8.4-2.2c1.9.6 3.9.9 6 .9 9.7 0 17.6-6.7 17.6-14.9 0-1.4-.3-2.8-.7-4.1h-2.1z"></path>
                                          <path d="M34.6 6.5h-4.1V2.4h-4v4.1h-4v4h4v4h4v-4h4.1"></path>
                                      </svg>
                                  </span>
                  </button>
                  <button type="button" class="btn btn-settings" title="Открыть настройки авторассылки"
                          @click="openDispatchModal">
                              <span>
                                 <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 54 54"><path
                                   d="M53.188 23.518l-3.128-.602c-1.842-.354-3.35-1.607-4.035-3.354-.686-1.745-.433-3.69.677-5.203l1.964-2.68c.292-.398.25-.95-.1-1.3l-4.242-4.24c-.34-.34-.87-.39-1.268-.12l-2.638 1.785c-.91.616-1.958.942-3.033.942-2.713 0-4.98-1.94-5.393-4.617L31.487.847C31.414.36 30.994 0 30.5 0h-6c-.48 0-.892.34-.982.812l-.777 4.04c-.49 2.56-2.744 4.417-5.355 4.417-1.075 0-2.125-.327-3.033-.943L10.945 6.02c-.397-.268-.93-.218-1.268.12l-4.243 4.243c-.35.35-.39.9-.1 1.3l1.964 2.678c1.11 1.514 1.362 3.46.677 5.205-.686 1.745-2.194 3-4.036 3.353l-3.13.602c-.47.088-.81.5-.81.98v6c0 .493.36.913.848.988l3.283.505c1.855.285 3.41 1.48 4.16 3.2.75 1.72.57 3.673-.483 5.226L6.02 43.055c-.27.396-.218.93.12 1.268l4.243 4.242c.35.348.9.393 1.298.1l2.68-1.964c.945-.692 2.05-1.06 3.198-1.06 2.613 0 4.867 1.858 5.36 4.417l.6 3.13c.09.47.504.81.983.81h6c.495 0 .914-.36.99-.85l.354-2.31c.466-3.03 3.067-4.616 5.396-4.616 1.145 0 2.25.366 3.195 1.06l1.884 1.38c.397.294.95.25 1.296-.1l4.242-4.24c.338-.34.39-.872.12-1.27l-1.786-2.637c-1.052-1.553-1.232-3.506-.482-5.226.75-1.72 2.304-2.914 4.157-3.2l3.282-.504c.487-.074.847-.494.847-.987v-6c0-.48-.34-.893-.812-.983zM36 27.5c0 4.687-3.813 8.5-8.5 8.5S19 32.187 19 27.5s3.813-8.5 8.5-8.5 8.5 3.813 8.5 8.5z"/><path
                                   d="M27.5 22c-3.033 0-5.5 2.468-5.5 5.5s2.467 5.5 5.5 5.5 5.5-2.468 5.5-5.5-2.467-5.5-5.5-5.5z"/></svg>
                              </span>
                  </button>
                </div>
                <button class="btn-close" @click="responsiveCloseUserOnline">
                          <span>
                              <svg class="svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11.88 11.88"><line
                                class="cls-1" x1="0.61" y1="11.26" x2="11.26" y2="0.61"/><line class="cls-1" x1="0.61"
                                                                                               y1="0.61" x2="11.26"
                                                                                               y2="11.26"/></svg>
                          </span>
                </button>
              </div>
            </div>-->
      <div class="sidebar__content">
        <div class="sidebar__nav">
          <ul class="nav nav-tabs" role="tablist">
            <li role="presentation" class="active" @click="currentTab = 0">
              <a
                href="#all"
                aria-controls="all"
                role="tab"
                data-toggle="tab"
                title="All users"
              >
                Все
              </a>
            </li>
            <li role="presentation" @click="currentTab = 1">
              <a
                href="#bookmarks"
                aria-controls="bookmarks"
                role="tab"
                data-toggle="tab"
                title="Bookmarks"
              >
                <svg class="rbi">
                  <use
                    xlink:href="/static/assets/images/rbi-icon.svg#rbi-star"
                  ></use>
                </svg>
                <span>({{ bookmarkUsersCount }})</span>
              </a>
            </li>
            <li role="presentation" @click="currentTab = 2">
              <a
                href="#favourites"
                aria-controls="favourites"
                role="tab"
                data-toggle="tab"
                title="Favourites"
              >
                <svg class="rbi">
                  <use
                    xlink:href="/static/assets/images/rbi-icon.svg#rbi-heart"
                  ></use>
                </svg>
                <span>({{ favoriteUsersCount }})</span>
              </a>
            </li>
          </ul>
        </div>
        <div class="tab-content">
          <div
            v-if="currentTab === 0"
            id="all"
            role="tabpanel"
            class="tab-pane active"
          >
            <div class="sidebar__search">
              <div class="form-group">
                <input
                  type="text"
                  class="form-control findInOnline"
                  placeholder="Name or ID"
                  @keyup="type"
                />
              </div>
            </div>
            <div class="users-online-block">
              <SimpleBar
                data-simplebar-auto-hide="true"
                class="users-online-block-simplebar"
              >
                <div v-if="needSearch">
                  <user
                    v-for="(man, key) in womanlistFound"
                    :key="key"
                    :user="man"
                  ></user>
                </div>
                <div v-else>
                  <user
                    v-for="(man, key) in onlineUsers"
                    :key="key"
                    :user="man"
                  ></user></div
              ></SimpleBar>
            </div>
          </div>
          <div
            v-if="currentTab === 1"
            id="bookmarks"
            role="tabpanel"
            class="tab-pane"
          >
            <div class="users-online-block">
              <SimpleBar
                data-simplebar-auto-hide="true"
                class="users-online-block-simplebar"
              >
                <user
                  v-for="(man, key) in onlineBookmarks"
                  :key="key"
                  :user="man"
                ></user>
              </SimpleBar>
            </div>
          </div>
          <div
            v-if="currentTab === 2"
            id="favourites"
            role="tabpanel"
            class="tab-pane"
          >
            <div class="users-online-block">
              <SimpleBar
                data-simplebar-auto-hide="true"
                class="users-online-block-simplebar"
              >
                <user
                  v-for="(man, key) in onlineFavorites"
                  :key="key"
                  :user="man"
                ></user
              ></SimpleBar>
            </div>
          </div>
        </div>
      </div>
    </div>
  </aside>
</template>

<script>
import manUserItem from './man-user';
import store from '../../store/store';

export default {
  scrollAll: null,
  scrollFavs: null,
  scrollBookmarks: null,
  name: 'WomanLeftSide',
  store,
  components: {
    user: manUserItem,
  },
  data() {
    return {
      currentTab: 0,
      currentDeliveryButtonAction: 'Начать рассылку',
      distributionRun: false,
      needSearch: false,
      womanlistFound: this.onlineUsers,
    };
  },
  computed: {
    search() {
      return this.$store.getters.getManQ;
    },
    responsiveShowUserOnline() {
      return this.$store.getters.settings.responsive.showUserOnline;
    },
    ifNotPrepared() {
      return this.$store.getters.delivery.prepared;
    },
    deliveryRun() {
      return this.$store.getters.delivery.run;
    },
    onlineUsers() {
      return this.$store.getters.online;
    },
    onlineBookmarks() {
      return this.$store.getters.bookmarks;
    },
    onlineFavorites() {
      return this.$store.getters.favorites;
    },
    onlineUsersCount() {
      return this.$store.getters.countAllOnline;
    },
    bookmarkUsersCount() {
      return this.$store.getters.bookmarks.length;
    },
    favoriteUsersCount() {
      return this.$store.getters.favorites.length;
    },
  },
  watch: {
    onlineUsers(newOnline) {
      this.womanlistFound = newOnline;
    },
    search(value) {
      this.womanlistFound = this.onlineUsers;
      if (value === '') {
        this.needSearch = false;
        this.womanlistFound = this.$store.getters.onlineWomanList;
      } else {
        this.needSearch = true;
        this.womanlistFound = this.womanlistFound.filter(this.searchInArray);
      }
    },
  },
  mounted() {
    this.womanlistFound = this.onlineUsers;
    /*this.$options.sockets.delivery_in_process = (data) => {
      if (data.status) {
        //          console.log('Active distribution')
      } else {
        //          console.log('Inactive distribution');
      }
    }*/
  },
  methods: {
    type(value) {
      this.$store.commit('SET_MAN_Q', value.target.value);
    },
    searchInArray(womanItem) {
      let q = this.search.toString().toUpperCase();
      return (
        womanItem.id.toString().match(q) ||
        womanItem.profile.name.toString().toUpperCase().match(q)
      );
    },
    responsiveCloseUserOnline() {
      this.$store.dispatch('setResponsiveShowUserOnline', false);
    },
    openDispatchModal() {
      /*this.$store.commit('updateDispatchModal', {
        open: true,
      })*/
    },
    toggleDelivery() {
      this.$store.commit('addAlert', {
        type: 'error',
        text: 'Этот сервис в данный момент не доступен.',
        info: 'disable',
      });
      /*if (!this.$store.getters.delivery.run) {
        //          console.log('Need delivery start');
        let templates = this.$store.getters.delivery.templates.active
        let preparedForDeliveryArray = []
        for (let i = 0; i < templates.length; i++) {
          preparedForDeliveryArray.push(templates[i].template)
        }
        this.$socket.emit('delivery', {
          token: this.$store.getters.token.chat_token,
          ignored_user: this.$store.getters.delivery.ignored,
          ignoreFavorites: this.$store.getters.delivery.ignore_favorites,
          ignoreBookmarks: this.$store.getters.delivery.ignore_bookmarks,
          deliveryFilters: [],
          templates: preparedForDeliveryArray,
        })
      } else {
        //          console.log('Need to delivery stop');
        this.$socket.emit('delivery_stop', {})
      }
      this.$store.commit('TOGGLE_DELIVERY_STATUS', {})*/
    },
  },
};
</script>

<style lang="scss" scoped>
.header-box__item {
  color: white;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
}

.sidebar {
  height: 100%;
  width: 100%;
  overflow: hidden;
  @media screen and (min-width: 992px) {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .header-box__item {
      color: white;
      padding: 1rem;
      display: flex;
      justify-content: space-between;
    }

    .online-box {
      /*  border-right: 1px solid #d9d9d9;*/
      position: relative;
    }
  }
}

.sidebar__content {
  background: white;
  display: flex;
  flex-direction: column;
  height: 100%;
}

@media screen and (max-width: 992px) {
  .header-box__item {
    display: none;
  }
  .tab-content-bookmarks {
    margin-top: 53px;
  }
}
.users-online-block-simplebar {
  position: relative;
  height: 100%;
  overflow-x: hidden;
}

.ios-safari {
  .users-online-block-simplebar {
    height: 100%;
    overflow-x: hidden;
  }
  .isIos14Up {
    .users-online-block-simplebar {
      height: 100%;
      overflow-x: hidden;
    }
  }
}

.sidebar__content {
  position: relative;
  overflow: hidden;
  .tab-content,
  .tab-pane {
    position: relative;
    height: 100%;
    overflow: hidden;
  }

  .tab-pane {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
.users-online-block {
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
}
</style>
