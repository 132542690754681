<template>
  <div id="bookmarks" role="tabpanel" class="tab-pane">
    <SimpleBar
      class="users-online-block-simplebar"
      data-simplebar-auto-hide="true"
    >
      <woman-item
        v-for="(woman, key) in bookmarkWomanList"
        :key="key"
        :woman="woman"
      ></woman-item>
    </SimpleBar>
  </div>
</template>

<script>
import WomanItem from '../items/woman-item.vue';

export default {
  scroll: null,
  name: 'BookmarkWoman',
  components: {
    'woman-item': WomanItem,
  },
  computed: {
    bookmarkWomanList() {
      return this.$store.getters.bookmarkWomanList;
    },
  },
  mounted() {
    //            $('#bookmarks').simplebar();
  },
};
</script>

<style lang="scss" scoped>
@media screen and (max-width: 450px) {
  .sidebar__search {
    display: none;
  }
}
.users-online-block-simplebar {
  position: relative;
  height: 100%;
  overflow-x: hidden;
}

.ios-safari {
  .users-online-block-simplebar {
    height: 100%;
    overflow-x: hidden;
  }
  .isIos14Up {
    .users-online-block-simplebar {
      height: 100%;
      overflow-x: hidden;
    }
  }
}

.sidebar__content {
  position: relative;
  overflow: hidden;
  .tab-content,
  .tab-pane {
    position: relative;
    height: 100%;
    overflow: hidden;
  }
}
.users-online-block {
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
}
</style>
