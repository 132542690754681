<template>
  <transition name="modal">
    <div
      v-if="modal.open"
      class="vue-modal modal-mask credits-modal"
      @click="closeModal($event.target)"
    >
      <div class="vue-modal--wrapper">
        <div class="vue-modal--container credits-modal--container">
          <button type="button" class="close" @click="closeModal(false)">
            <svg class="rbi">
              <use
                xlink:href="/static/assets/images/rbi-icon.svg#rbi-close"
              ></use>
            </svg>
          </button>
          <div class="modal-body credits-modal--body">
            <h4 class="modal-title">Credits level is low</h4>
            <p>You can choose your credit plan on credits page</p>
            <a
              :href="app.config.site_url + '/credits'"
              target="_blank"
              class="btn btn-black"
              @click="closeModal(false)"
            >
              <span>Buy credits</span>
            </a>
            <div><small>New window will be opened</small></div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'LowCreditsModal',
  data() {
    return {};
  },
  computed: {
    modal() {
      return this.$store.getters.lowCreditsModal;
    },
    app() {
      return this.$store.getters.app;
    },
  },
  methods: {
    closeModal(e) {
      // eslint-disable-next-line no-undef
      if (!$(e).closest('.mail-modal--body').length || !e) {
        this.$store.commit('updateLowCreditModal', {
          open: false,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  border: 1px solid #000;
  background: none;
  white-space: nowrap;
  padding: 10px 20px;
  font-size: 12px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: relative;
  margin: 0 3px 3px 0;
  text-transform: uppercase;
  -webkit-border-radius: 0 !important;
  -moz-border-radius: 0 !important;
  border-radius: 0 !important;

  &:after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    border-bottom: 1px solid #000;
    border-right: 1px solid #000;
    bottom: -4px;
    right: -4px;
    transition: 0.3s;
  }

  &:hover {
    &:after {
      width: 10px;
      height: 10px;
      left: auto;
      right: -4px;
      top: auto;
      bottom: -4px;
    }
  }

  &-black {
    background: #000;
    color: #fff;
    &:disabled {
      background: lighten(#000, 50%);
      border-color: lighten(#000, 50%);
      cursor: default;
      &:after {
        border-color: lighten(#000, 50%);
      }
      &:hover {
        &:after {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}

.credits-modal {
  &--container {
    width: 320px;
    position: relative;
  }

  h4 {
    font-weight: bold;
    color: #000;
  }

  .close {
    position: absolute;
    top: 20px;
    right: 20px;
  }

  &--body {
    text-align: center;
    p {
      margin: 20px 0;
    }
  }

  .btn-success {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
    border: none !important;
    padding: 6px 20px;

    span + span {
      margin-left: 10px;
    }

    svg {
      height: 20px;
      width: 20px;
      fill: currentColor;
    }
  }
}
</style>
