<template>
  <div
      :style="{ cursor: allPayTypesOnPresent ? 'initial' : 'pointer' }"
      @click.stop="
      !allPayTypesOnPresent &&
        (gift.sumPriceEur !== 0 ? clickEuroHandler(gift) : clickCreditsHandler(gift))
    "
  >
    <div class="mgifts--item noselect-for-gift" :class="{ extended }">
      <div class="mgifts--item__img">
        <img :src="setPhotoSource(gift.photo)" :alt="gift.description" />
      </div>
      <div class="mgifts--item__info">
        <div style="display: flex; justify-content: space-between">
          <div style="width: 100%">
            <p class="mgifts--item__info__name">
              {{ gift.name }}
            </p>
          </div>
          <div v-if="chosenGiftId === gift.id && loading">
            <preloader-mini />
          </div>
        </div>

        <div class="info d-flex align-items-start">
          <div
              class="set-flex mgifts--item__info__price"
              style="justify-content: space-between; width: 100%; flex-wrap: wrap;display: flex"
          >
            <!--<span v-show="gift.sumPrice !== 0" class="rbi rbi-money"></span>
            <span v-show="gift.sumPrice !== 0">{{ gift.sumPrice }}</span>
            <span v-show="gift.sumPriceEur !== 0" class="rbi">€</span>
            <span v-show="gift.sumPriceEur !== 0">{{
              gift.sumPriceEur ? gift.sumPriceEur.toFixed(2) : ''
            }}</span>-->
            <div
                v-show="gift.sumPrice !== 0"
                class="gift-price-credits-in-flex"
                @click.prevent="clickCreditsHandler(gift)"
            >
              <svg class="rbi">
                <use
                    xlink:href="/static/assets/images/rbi-icon.svg#rbi-money"
                ></use>
              </svg>
              <span v-show="gift.sumPrice !== 0">{{ gift.sumPrice }}</span>
            </div>
            <div
                v-show="+gift.sumPriceEur !== 0"
                class="gift-price-eur-in-flex"
                @click.prevent="clickEuroHandler(gift)"
            >
              <span class="rbi" style="color: black">€</span>
              <span v-show="gift.sumPriceEur !== 0">{{
                  gift.sumPriceEur ? gift.sumPriceEur.toFixed(2) : ''
                }}</span>
            </div>
            <div v-show="extended" class="tip" @click.prevent="clickHandler(gift)">
              <p>* Price includes:</p>
              <ul class="list-unstyled tip">
                <li>- Air delivery</li>
                <li>- Courier delivery</li>
                <li>- Customs clearance</li>
                <li>- Insurance</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { PHOTO_SRC } from '../../../../mixins/utils';
import PreloaderMini from './PreloaderMini'
export default {
  components: { PreloaderMini },
  mixins: [PHOTO_SRC],
  props: {
    gift: Object,
    extended: Boolean,
    chosenGiftId: Number,
    clickCreditsHandler: Function,
    clickEuroHandler: Function,
    loading: Boolean,
  },
  data() {
    return {};
  },
  computed: {
    allPayTypesOnPresent() {
      return this.gift.sumPrice !== 0 && this.gift.sumPriceEur !== 0;
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
@import '../../../../assets/scss/variables-bootstrap';

@import '../../../../assets/scss/vars';
.noselect-for-gift {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.gift-price-credits-in-flex {
  display: flex;
  min-height: 40px;
  align-items: center;
  cursor: pointer;
}
.gift-price-eur-in-flex {
  display: flex;
  min-height: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  align-items: center;
  cursor: pointer;
}
.gift-price-credits-in-flex:hover {
  color: $red;
}
.gift-price-eur-in-flex:hover {
  color: $red;
}
.mgifts--item {
  display: flex;
  width: 100%;
  align-items: center;
  margin-bottom: 20px;
  /*cursor: pointer;*/
  &:hover {
    .mgifts--item__info__name {
      color: $red;
      &:after {
        width: 100%;
        max-width: 100%;
      }
    }
  }

  &__img {
    img {
      height: 110px;
      width: 110px;
      object-fit: contain;
    }
  }

  &__info {
    margin-left: 10px;
    line-height: 1;
    flex: 1 1;
    display: flex;
    flex-direction: column;
    color: #3e3f42;

    &__price {
      align-items: center;
      color: $black;
      font-size: 22px;
      margin-top: 20px;

      span + span {
        margin-left: 5px;
      }

      .rbi {
        font-size: 28px;
        margin-right: 0.2em;
      }
    }

    &__name {
      color: $black;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0.7px;
      padding-bottom: 20px;
      border-bottom: 1px solid $grey-two;
      position: relative;
      transition: 0.3s;

      &:after {
        content: '';
        display: block;
        height: 3px;
        width: 15%;
        max-width: 30px;
        position: absolute;
        bottom: -3px;
        left: 0;
        background: $red;
        transition: 0.3s;
      }
    }

    .tip {
      color: $brand-primary;
      line-height: 1.5;

      p {
        margin-bottom: 5px;
      }
    }
  }

  &.extended {
    align-items: flex-start;

    .mgifts--item__img {
      img {
        height: auto;
        width: 130px;
        object-fit: cover;
      }
    }

    .mgifts--item__info {
      margin-left: 20px;

      &__name,
      &__price {
        font-size: 18px;
      }

      &__price {
        img {
          height: 36px;
          width: 36px;
        }

        span + span {
          margin-left: 10px;
        }
      }
    }

    .info {
      margin-top: 20px;
      .mgifts--item__info__price {
        margin-top: 0;
        margin-right: 30px;
      }
    }
  }
}

@media only screen and (max-width: 480px) {
  .mgifts--item {
    &.extended {
      .mgifts--item__img {
        img {
          width: 100px;
        }
      }
    }
  }
}
</style>
