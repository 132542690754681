<template>
  <div class="absolute-video-wrapper">
    <transition name="fade"
      ><div v-show="show && cameraOn" class="absolute-video-container">
        <video
          id="absolute-video"
          autoplay
          playsinline
          muted
          class="absolute-video"
        ></video>

        <WatchIndicator :chat-activated="isActive" :chat-type="chatType" />
      </div>
    </transition>
  </div>
</template>

<script>
import WatchIndicator from './WatchIndicator';
export default {
  name: 'AbsoluteVideoContainer',
  components: { WatchIndicator },
  props: {
    chatType: { type: Number, default: 0 },
  },
  data: () => ({
    show: false,
  }),
  computed: {
    cameraOn() {
      return this.$store.getters.getUserCameraIsActive;
    },
    isActive() {
      return this.$store.getters.dialogs.current
        ? this.$store.getters.dialogs.current.active
        : false;
    },
    isWoman() {
      return this.$store.getters.user.type === 'woman';
    },
  },
  mounted() {
    this.show = true;
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
  opacity: 0;
}
.absolute-video-wrapper {
  position: absolute;
  z-index: 200;
  right: 15px;
  left: auto;
  top: 15px;
  bottom: auto;
}
.absolute-video {
  border: 1px solid #c9c9c9;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
  /*background: white;*/
  border-radius: 5px;
  height: 13vh;
  width: auto;
}

.absolute-video-container {
  display: flex;
  flex-direction: column;
}

@media screen and (min-width: 425px) and (max-width: 992px) {
  .absolute-video-wrapper {
    display: block;
  }
  .absolute-video {
    opacity: 1;
    border: 1px solid #c9c9c9;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
    /*background: white;*/
    border-radius: 5px;
    height: 12vh;
    width: auto;
  }
}
@media screen and (max-height: 425px) and (max-width: 992px) and (min-width: 425px) {
  .absolute-video-wrapper {
    display: block;

    right: 16vw;
    top: 10px;
  }
  .absolute-video {
    opacity: 1;
    border: 1px solid #c9c9c9;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
    /*background: white;*/
    border-radius: 5px;
    height: 20vh;
    width: auto;
  }
}
@media screen and (max-width: 425px) {
  .absolute-video-wrapper {
    top: 20px;
    right: 20px;
    z-index: 45;
  }
  .absolute-video {
    z-index: 0;
    opacity: 0.9;
    border: 1px solid #c9c9c9;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
    /*background: white;*/
    border-radius: 5px;
    height: 14vh;
    width: auto;
  }
}
</style>
