<template>
  <div v-if="gallery.open" class="vue-gallery">
    <div class="vue-gallery__wrap">
      <div class="vue-gallery__body">
        <div class="itemGal vue-gallery__image">
          <div class="itemGal image-wrap">
            <img
              v-show="!showPreloader"
              ref="image-inside"
              class="gallery-image-inside"
              :src="currentPhotoURL"
              @load="showPreloader = false"
            />

            <div v-show="showPreloader" class="preloader"></div>
            <rb-button
              v-if="
                gallery.photoID && !gallery.bought && !currentPhotoPurchased
              "
              type="button"
              class="btn-red photo-buy"
              :disabled="buying"
              @click.native="buyPhoto"
              >Buy picture
            </rb-button>
            <div class="vue-gallery__header">
              <div
                v-if="
                  gallery.photos &&
                  gallery.photos.length &&
                  gallery.photos.length > 1
                "
                class="itemGal count"
              >
                <svg class="rbi">
                  <use
                    xlink:href="/static/assets/images/rbi-icon.svg#rbi-camera"
                  ></use>
                </svg>
                <span class="count"
                  >{{ gallery.index + 1 }}/{{ gallery.photos.length }}</span
                >
              </div>
            </div>
          </div>
          <div
            class="itemGal controls controls--prev"
            @click="changeIndex(false)"
          >
            <svg class="rbi flip-left">
              <use
                xlink:href="/static/assets/images/rbi-icon.svg#rbi-arrow"
              ></use>
            </svg>
          </div>
          <div
            class="itemGal controls controls--next"
            @click="changeIndex(true)"
          >
            <svg class="rbi flip-right">
              <use
                xlink:href="/static/assets/images/rbi-icon.svg#rbi-arrow"
              ></use>
            </svg>
          </div>
          <div class="close-gallery" @click="close">
            <svg class="rbi">
              <use
                xlink:href="/static/assets/images/rbi-icon.svg#rbi-close"
              ></use>
            </svg>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { LOW_CREDITS_MODAL } from '../../mixins/formsActions';

import RbButton from '../shared/Button';

export default {
  name: 'Gallery',
  components: {
    RbButton,
  },
  mixins: [LOW_CREDITS_MODAL],
  data() {
    return {
      showPreloader: true,
      watcher: false,
      buying: false,
    };
  },
  computed: {
    token() {
      return this.$store.getters.token;
    },
    currentChat() {
      return this.$store.getters.dialogsCurrent;
    },
    selfUser() {
      return this.$store.getters.user;
    },
    currentPhotoURL() {
      return this.gallery.photos[this.gallery.index].img_origin;
    },
    currentPhotoPurchased() {
      return this.gallery.photos[this.gallery.index].purchased === 1;
    },
    nextPhoto() {
      if (this.gallery.index === this.gallery.photos.length - 1) {
        return this.gallery.photos[0];
      }
      return this.gallery.photos[this.gallery.index + 1];
    },
    prevPhoto() {
      if (this.gallery.index === 0) {
        return this.gallery.photos[this.gallery.photos.length - 1];
      }
      return this.gallery.photos[this.gallery.index - 1];
    },
    gallery() {
      return this.$store.getters.gallery;
    },
  },
  watch: {
    watcher() {
      let img = new Image();
      img.src = this.currentPhotoURL;
      if (img.complete) {
        this.showPreloader = false;
      }
    },
  },
  methods: {
    buyPhoto() {
      if (!this.buying) {
        this.buying = true;
        let photo = this.gallery.photoID,
          target = this.currentChat.user.id;
        this.$http
          .post('v1/chat/photo-buy', {
            access_token: this.token.site_token,
            photo_id: photo,
          })
          .then(
            (r) => {
              if (r.body.status) {
                this.socketBuy(target, photo);
                this.$store.commit('addAlert', {
                  type: 'success',
                  text: 'Photo successfully purchased. You can view it in your "Saved photos" album',
                });
              } else {
                if (
                  r.body.code === 10025 ||
                  r.body.code === 10028 ||
                  r.body.code === 10161
                ) {
                  this.openCreditsModal();
                }
              }
              this.buying = false;
            },
            () => {
              this.buying = false;
            }
          );
      }
    },
    socketBuy(target, photo) {
      this.$socket.emit('photo_buy', {
        token: this.token.chat_token,
        user_id: this.selfUser.id,
        target_user_id: target,
        photo_id: photo,
      });
      this.close();
    },
    toggle() {
      this.$store.commit('updateGallery', {
        open: !this.gallery.open,
      });
    },
    close() {
      this.showPreloader = true;
      this.buying = false;
      this.$store.commit('updateGallery', {
        open: false,
        index: 0,
        photos: [],
        bought: false,
        photoID: '',
      });
    },
    changeIndex(inc) {
      let tmp = this.gallery.index;
      if (tmp === 0) {
        inc ? tmp : (tmp = this.gallery.photos.length);
      }
      inc ? tmp++ : tmp--;
      if (this.gallery.photos.length === tmp) {
        tmp = 0;
      }
      this.showPreloader = true;
      this.$store.commit('updateGallery', {
        index: tmp,
      });
      this.watcher = !this.watcher;
    },
    cachePhoto(photo) {
      let tmp = new Image();
      tmp.src = photo.img_origin;
    },
  },
  /*beforeUpdate() {
		if ( this.gallery.photos.length ) {
			this.cachePhoto( this.nextPhoto );
			this.cachePhoto( this.prevPhoto );
		}
	}*/
};
</script>

<style lang="scss" scoped>
.count {
  font-size: 14px;
  .rbi {
    font-size: 18px;
    margin-right: 0.5em;
  }
}
.vue-gallery {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  height: 100%;
  bottom: 0;
  right: 0;
  z-index: 1500;
  background: rgba(0, 0, 0, 0.7);
  .close-gallery {
    position: absolute;
    right: 22px;
    top: 0;
    padding: 30px;
    cursor: pointer;
    color: #ffffff;

    &:hover {
      .close-gallery--inner {
        opacity: 1;
      }
    }
  }
  &__header {
    position: absolute;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.38);
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: auto;
    padding: 10px 15px;
    .image-wrap {
      max-width: 80%;
    }
    .itemGal {
      display: flex;
      align-items: center;
      svg {
        height: 24px;
        fill: white;
      }
      span {
        color: white;
      }
      span + span {
        margin-left: 8px;
      }
      button {
        user-select: none;
        display: flex;
        align-items: center;
        color: white;
        background: transparent;
        border: none;
      }
    }
    .gallery-close {
      display: flex;
      justify-content: flex-end;
      svg {
        fill: white;
      }
    }
  }

  &__wrap {
    max-height: 100%;
    width: 100%;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  &__body {
    /*display: flex;
			flex-wrap: wrap;*/
    max-height: 100%;
    height: 100%;
    .controls {
      position: absolute;
      top: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 130px;
      cursor: pointer;
      font-size: 26px;
      color: #ffffff;

      &:hover {
        > .rbi {
          opacity: 1;
        }
      }

      > .rbi {
        opacity: 0.5;
      }

      &--next {
        right: 0;
        > .btn {
          background-position: 50% -62px;
        }
      }

      &--prev {
        left: 0;

        > .btn {
          background-position: 50% -24px;
        }
      }
    }
    .item-wrap {
      display: flex;
    }
    .image-wrap {
      //display: flex;
      max-width: 100%;
      height: 100%;
      max-height: 100%;
    }

    .photo-buy {
      border: none;
      padding: 10px 25px;
      font-size: 13px;
      position: absolute;
      left: 50%;
      bottom: 60px;
      -webkit-transform: translateX(-50%);
      -moz-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      -o-transform: translateX(-50%);
      transform: translateX(-50%);
    }
  }

  &__image {
    position: relative;
    padding: 10px 10px;
    width: 100%;
    height: 100%;
    max-height: 100%;

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      -moz-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      -o-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      //display: flex;
      margin: auto;
      max-width: 100%;
      max-height: 100%;
    }
  }

  .preloader {
    position: absolute;
    top: calc(50% - 30px);
    left: calc(50% - 30px);
  }
}

@media only screen and (max-width: 1200px) {
  .vue-gallery {
    &__body {
      .controls {
        width: 55px;
      }

      .close-gallery {
        padding: 20px;
        right: 0;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .vue-gallery {
    &__header {
      margin-bottom: 0;
      left: 0;
    }
    &__wrap {
      width: 100%;
    }
    .itemgal {
      display: flex;
      align-items: center;
    }
    &__image {
      padding: 0;
      align-items: center;
      img {
        height: auto;
        max-width: 100%;
      }
    }
  }
}
</style>
