<template>
  <div class="control-block">
    <div class="control-wrap">
      <div class="gallery-wrapper">
        <div
          v-show="displayGallery"
          class="slider-btn-wrap"
          :class="{ 'gallery-video-chat-controls': !isTextChat }"
        >
          <button class="slider-btn button-prev">
            <svg class="rbi">
              <use xlink:href="/static/assets/images/rbi-icon.svg#rbi-arrow" />
            </svg>
          </button>
          <button class="slider-btn button-next">
            <svg class="rbi">
              <use xlink:href="/static/assets/images/rbi-icon.svg#rbi-arrow" />
            </svg>
          </button>
        </div>
        <transition
          name="custom-classes-transition"
          enter-active-class="animated fadeInDown"
          leave-active-class="animated slideOutRight"
        >
          <div
            v-show="displayGallery && photos.length"
            class="slider-gallery"
            :class="{ 'gallery-video-chat': !isTextChat }"
          >
            <div v-show="!sliderInitialized" class="preload-wrap">
              <div class="preloader"></div>
            </div>
            <div
              class="gallery-swiper-new"
              :class="{ invisible: !sliderInitialized }"
            >
              <div id="photos-container" class="swiper-wrapper">
                <div
                  v-for="(item, i) in photos"
                  :key="i"
                  class="swiper-slide"
                  @click.prevent="takePhotos(true, photos, i)"
                >
                  <div class="image-wrap">
                    <img
                      :src="item.img_preview"
                      :alt="woman.profile.name + '\'s photo'"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </transition>
      </div>
      <div class="control-buttons">
        <div
          class="item sidebar-controls"
          :class="{ 'remote-video-controls-wrapper': remoteVideo }"
        >
          <button
            class="btn-open online-list-btn"
            @click="responsiveShowUserOnline"
          >
            <template v-if="false">
              <!--remove counter displaying, add styles on button-->
              <span class="counter">({{ womanOnlineCount }})</span>
            </template>
            <span class="controls-label"> Women</span>
            <span class="icon">
              <svg class="rbi flip-right">
                <use
                  xlink:href="/static/assets/images/rbi-icon.svg#rbi-arrow"
                />
              </svg>
            </span>
          </button>
          <!-- v-if="isActive && isTextChat || isActive && isVideoChat || (!isActive && isVideoChat)" -->

          <ChatTypeSwitch
            class="chat-type-switch"
            :remote-video="remoteVideo"
            :collocutor="woman"
          ></ChatTypeSwitch>

          <button
            class="btn-open last-chats-button"
            data-target="#last-dialogs"
            @click="responsiveShowLastDialogs"
          >
            <span class="controls-label">Chats</span>
            <span class="icon">
              <svg class="rbi flip-left">
                <use
                  xlink:href="/static/assets/images/rbi-icon.svg#rbi-arrow"
                />
              </svg>
              <span class="counter">
                <span
                  v-if="lastDialogsUnreadMessage"
                  class="unread label label-primary"
                  >{{ lastDialogsUnreadMessage }}</span
                >
              </span>
            </span>
          </button>
        </div>
        <div class="item sub-controls">
          <button
            class="btn btn-common"
            title="Send mail"
            @click.prevent="sendMail"
          >
            <svg class="rbi">
              <use
                xlink:href="/static/assets/images/rbi-icon.svg#rbi-message"
              />
            </svg>
          </button>
          <button
            class="btn btn-common"
            title="Send gift"
            @click.prevent="sendGift"
          >
            <svg class="rbi">
              <use xlink:href="/static/assets/images/rbi-icon.svg#rbi-gift" />
            </svg>
          </button>
          <button
            class="btn btn-common"
            :class="{ checked: isBookmark }"
            :title="bookmarkActionTitle"
            @click.prevent="bookmarkAction"
          >
            <span v-show="isBookmark" class="checked-icon">
              <svg class="rbi">
                <use
                  xlink:href="/static/assets/images/rbi-icon.svg#rbi-check"
                />
              </svg>
            </span>
            <svg class="rbi">
              <use xlink:href="/static/assets/images/rbi-icon.svg#rbi-star" />
            </svg>
          </button>
          <button
            class="btn btn-common"
            :class="{ checked: winked }"
            title="Send wink"
            @click.prevent="sendWink"
          >
            <span v-show="winked" class="checked-icon">
              <svg class="rbi">
                <use
                  xlink:href="/static/assets/images/rbi-icon.svg#rbi-check"
                />
              </svg>
            </span>
            <svg class="rbi">
              <use xlink:href="/static/assets/images/rbi-icon.svg#rbi-emoji" />
            </svg>
          </button>
          <button
            class="btn btn-common"
            :class="{ checked: isFavorite }"
            :title="favoriteActionTitle"
            @click.prevent="favoriteAction"
          >
            <span v-show="isFavorite" class="checked-icon">
              <svg class="rbi">
                <use
                  xlink:href="/static/assets/images/rbi-icon.svg#rbi-check"
                />
              </svg>
            </span>
            <svg class="rbi">
              <use xlink:href="/static/assets/images/rbi-icon.svg#rbi-heart" />
            </svg>
          </button>
          <button
            class="btn btn-primary"
            title="Toggle Gallery"
            @click="displayGallery = !displayGallery"
          >
            <svg v-if="!displayGallery" class="rbi">
              <use
                xlink:href="/static/assets/images/rbi-icon.svg#rbi-gallery-new"
              />
            </svg>
            <svg v-else class="rbi">
              <use xlink:href="/static/assets/images/rbi-icon.svg#rbi-close" />
            </svg>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  LAST_DIALOGS,
  AGE,
  IS_ONLINE,
  IS_BOOKMARK,
  IS_FAVORITE,
} from '../../../mixins/man_util';
import { GALLERY, PREVIEW_MODAL } from '../../../mixins/utils';
import { USER_ACTIONS } from '../../../mixins/userActions';
import ChatTypeSwitch from './ChatTypeSwitch';

export default {
  name: 'ManControls',
  components: {
    ChatTypeSwitch,
  },
  mixins: [
    LAST_DIALOGS,
    AGE,
    IS_ONLINE,
    IS_BOOKMARK,
    IS_FAVORITE,
    GALLERY,
    USER_ACTIONS,
    PREVIEW_MODAL,
  ],
  props: {
    woman: Object,
    remoteVideo: { type: Boolean, default: false },
  },
  data() {
    return {
      enableType: null,
      winked: false,
      alreadySubscribed: false,
      windowWidth: window.innerWidth,
      showGallery: false,
    };
  },
  computed: {
    bookmarkActionTitle() {
      if (this.mixinIsBookmark(this.woman.id)) {
        return 'Remove from bookmark';
      } else {
        return 'Add to bookmark';
      }
    },

    favoriteActionTitle() {
      if (this.mixinIsFavorite(this.woman.id)) {
        return 'Remove from favorite';
      } else {
        return 'Add to favorite';
      }
    },

    womanOnlineCount() {
      return this.$store.getters.onlineWomanList.length;
    },

    lastDialogsUnreadMessage() {
      return this.$store.getters.lastDialogsUnreadMessage;
    },

    isActive() {
      return this.$store.getters.dialogs.current.user.chat_status === 'active';
    },
    isVideoDoubleChat() {
      return (
        this.$store.getters.dialogs.current.user.chat_type === 'videoDouble'
      );
    },
    isVideoChat() {
      return this.$store.getters.dialogs.current.user.chat_type === 'video';
    },
    isTextChat() {
      return this.$store.getters.dialogs.current.user.chat_type === 'text';
    },
    isOnline() {
      return this.mixinIsOnline(this.woman.last_active);
    },
    womanAge() {
      return this.mixinAge(this.woman.profile.birthday);
    },
    isWebCam() {
      if (this.$store.getters.activeUsersList.length > 0) {
        const data = this.$store.getters.activeUsersList;
        let hasStream = false;

        data.forEach((user) => {
          if (user.userId === this.woman.id && user.userCameraActive) {
            hasStream = true;
          }
        });

        return hasStream;
      } else return false;
    },
    cameraOn() {
      return this.$store.getters.getUserCameraIsActive;
    },

    isFavorite() {
      let result = false;
      this.$store.getters.getAllFavorites.forEach((favorite) => {
        if (favorite === this.woman.id) {
          result = true;
        }
      });
      return result;
    },
    isBookmark() {
      let result = false;
      this.$store.getters.getAllBookmarks.forEach((bookmark) => {
        if (bookmark === this.woman.id) {
          result = true;
        }
      });
      return result;
    },
    needType() {
      return this.enableType;
    },
  },
  watch: {
    displayGallery(display) {
      this.$emit('display-gallery-change', display);
    },
    woman: function (newVal, oldVal) {
      if (newVal !== oldVal) this.askProfile(this.woman.id);
    },
  },
  mounted() {
    this.askProfile(this.woman.id);
    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth;
    });
  },
  methods: {
    switchToDoubleVideoChat() {
      if (this.isWebCam) {
        this.$store.dispatch('changeChatType', 'videoDouble');
        this.$socket.emit('change_chat_type', {
          token: this.$store.getters.session.site_token,
          new_chat_type: 'videoDoubleChat',
          roomId: this.$store.getters.dialogs.current.roomId,
        });
      }
    },
    switchToVideoInvite() {
      if (this.isWebCam) {
        this.$socket.emit('change_chat_type', {
          token: this.$store.getters.session.site_token,
          collocutor: this.woman.id,
          new_chat_type: 'video',
          roomId: this.$store.getters.dialogs.current.roomId,
        });
        this.$store.dispatch('changeChatType', 'video');
      }
    },
    switchToTextInvite() {
      this.$socket.emit('change_chat_type', {
        token: this.$store.getters.session.site_token,
        collocutor: this.woman.id,
        new_chat_type: 'text',
        roomId: this.$store.getters.dialogs.current.roomId,
      });
      this.$store.dispatch('changeChatType', 'text');
    },
    responsiveShowUserOnline() {
      this.$store.dispatch('setResponsiveShowUserOnline', true);
    },
    responsiveShowLastDialogs() {
      this.$store.dispatch('setResponsiveShowLastDialogs', true);
    },

    favoriteAction() {
      let token = this.$store.getters.token.site_token;
      if (this.isFavorite) {
        this.$http
          .post('v1/favorite/delete', {
            access_token: token,
            favorite_user_id: this.woman.id,
          })
          .then((result) => {
            if (result.body.status) {
              let arrayAllFavorites = this.$store.getters.getAllFavorites;
              arrayAllFavorites.splice(
                arrayAllFavorites.indexOf(this.woman.id),
                1
              );
              this.$store.dispatch('setAllFavorites', arrayAllFavorites);
              this.$store.commit('REMOVE_FAVORITES', this.woman);
            }
          });
      } else {
        this.$http
          .post('v1/favorite/add', {
            access_token: token,
            favorite_user_id: this.woman.id,
          })
          .then((result) => {
            if (result.body.status) {
              let arrayAllFavorites = this.$store.getters.getAllFavorites;
              arrayAllFavorites.push(this.woman.id);
              this.$store.dispatch('setAllFavorites', arrayAllFavorites);
              this.$store.commit('SEND_FAVORITES', this.woman);
            }
          });
      }
    },
    bookmarkAction() {
      let token = this.$store.getters.token.site_token;
      if (this.isBookmark) {
        this.$http
          .post('v1/bookmark/delete', {
            access_token: token,
            bookmark_user_id: this.woman.id,
          })
          .then((result) => {
            if (result.body.status) {
              let arrayAllBookmarks = this.$store.getters.getAllBookmarks;
              arrayAllBookmarks.splice(
                arrayAllBookmarks.indexOf(this.woman.id),
                1
              );
              this.$store.dispatch('setAllBookmarks', arrayAllBookmarks);
              this.$store.commit('REMOVE_BOOKMARKS', this.woman);
            }
          });
      } else {
        this.$http
          .post('v1/bookmark/add', {
            access_token: token,
            bookmark_user_id: this.woman.id,
          })
          .then((result) => {
            if (result.body.status) {
              let arrayAllBookmarks = this.$store.getters.getAllBookmarks;
              arrayAllBookmarks.push(this.woman.id);
              this.$store.dispatch('setAllBookmarks', arrayAllBookmarks);
              this.$store.commit('SEND_BOOKMARKS', this.woman);
            }
          });
      }
    },
    sendWink() {
      this.winked = true;
      let token = this.$store.getters.token.site_token;
      this.$http
        .post('v1/wink/make', {
          access_token: token,
          target_user_id: this.woman.id,
        })
        .then((result) => {
          if (result.body.status) {
            //                        this.$store.commit('SEND_WINK', this.woman.id);
          }
        });
    },
    sendMail() {
      this.previewSendMail(true, {
        avatar: {
          img_small: this.woman.profile.avatarList.img_small,
        },
        name: this.woman.profile.name,
        user_id: this.woman.id,
      });
    },
    sendGift() {
      this.previewSendGift(true, this.woman.id);
    },
  },
};
</script>

<style scoped>
.gallery-video-chat {
  position: absolute;
  bottom: 0;
  width: 100%;
}
.gallery-video-chat-controls {
  z-index: 30;
  opacity: 0.7;
}
.slider-btn:focus {
  border: none !important;
  outline: none !important;
}
.slider-btn-wrap {
  opacity: 0.7;
  z-index: 30;
}
/*.chat-type-switch {
  display: flex;
}
@media screen and (max-width: 992px) {
  .chat-type-switch {
    display: none;
  }
}*/

@media (min-width: 767px) and (max-width: 992px) {
  .last-chats-button {
    flex-direction: row-reverse !important;
  }
}

@media (max-width: 767px) {
  .last-chats-button {
    flex-direction: column-reverse !important;
  }
}

.icon {
  color: #000000 !important;
  border: #b6b6b6 !important;
  background: white !important;
  box-shadow: inset 0 0 1px 1px rgb(204 204 204) !important;
}

.remote-video-controls-wrapper .icon {
  color: #000000;
  border: #b6b6b6;
  background: white;
  box-shadow: inset 0 0 1px 1px rgb(204 204 204);
  opacity: 0.7;
}

@media screen and (max-width: 992px) {
  .remote-video-controls-wrapper .controls-label {
    color: transparent;
  }
}
</style>
