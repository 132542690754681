/**
 * Created by Work_2 on 20.02.2017.
 */

export const MAIL_MODAL = {
  computed: {
    mailModal() {
      return this.$store.getters.mailModal;
    },
  },
  methods: {
    toggleMailModal(user, scope) {
      let self = scope || this; // allow to use it in other mixins with scope ONLY to OPEN modal
      self.$store.commit('updateMailModal', {
        open: !scope ? !this.mailModal.open : true,
        to: user || '',
      });
    },
    toggleLoadMailUser(user, scope) {
      let self = scope || this; // allow to use it in other mixins with scope ONLY to OPEN modal
      self.$store.dispatch('loadMailUser', {
        open: !scope ? !this.mailModal.open : true,
        to: user || '',
      });
    },
  },
};

export const LOW_CREDITS_MODAL = {
  computed: {
    creditsModal() {
      return this.$store.getters.lowCreditsModal;
    },
  },
  methods: {
    toggleCreditsModal() {
      this.$store.commit('updateLowCreditModal', {
        open: !this.creditsModal.open,
      });
    },
    openCreditsModal() {
      this.$store.commit('updateLowCreditModal', {
        open: true,
      });
    },
  },
};
