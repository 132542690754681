/* @touchend="scrollHandler()" */
<template>
  <div
    class="chat-box"
    :class="{
      'video-chat': activeVideo || isVideoChat,
    }"
  >
    <left-side class="grid-left-sidebar"></left-side>
    <ChatBox class="grid-chat-box"></ChatBox>
    <last-dialogs
      class="grid-right-sidebar"
      :class="{ open: responsiveShowLastDialogs }"
    ></last-dialogs>

    <invite-list></invite-list>
    <buy-credit-modal v-show="noMoney"></buy-credit-modal>
    <gift-modal />
    <version-change-modal />
    <write-mail-modal></write-mail-modal>
    <get-user-media-error-modal />
    <bad-connection-modal />
    <duplicate-user-media-modal />
    <LowCreditsModal />
    <gallery />
  </div>
</template>

<script>
/* eslint-disable no-undef */
import LeftSide from '../man/left-side';
import LastDialogs from '../man/last-dialogs';
import InviteList from '../man/invite-list.vue';
import ChatBox from '../man/boxes/ChatBox';
import BuyCreditModal from '../man/buy-credit-modal';
import GiftModal from '../shared/modals/GiftModal';
import VersionChangeModal from '../shared/modals/VersionChangeModal';
import WriteMailModal from '../shared/modals/WriteMailModal';
import GetUserMediaErrorModal from '../shared/modals/GetUserMediaErrorModal';
import BadConnectionModal from '../shared/modals/BadConnectionModal';
import DuplicateUserMediaModal from '../shared/modals/DuplicateUserMediaModal';
import LowCreditsModal from '../shared/modals/LowCredits';
import Gallery from '../shared/Gallery';

export default {
  name: 'ManApp',
  components: {
    Gallery,
    LowCreditsModal,
    DuplicateUserMediaModal,
    BadConnectionModal,
    GetUserMediaErrorModal,
    WriteMailModal,
    VersionChangeModal,
    GiftModal,
    BuyCreditModal,
    ChatBox,
    'left-side': LeftSide,
    'last-dialogs': LastDialogs,
    'invite-list': InviteList,
  },

  computed: {
    iphoneVersion() {
      return this.$store.getters.iphoneVersion;
    },
    noMoney() {
      return this.$store.getters.myBalance == 0;
    },
    isPrivate() {
      return this.$store.getters.app.current_state === 'app-private';
    },
    responsiveShowUserOnline() {
      return this.$store.getters.settings.responsive.showUserOnline;
    },
    responsiveShowLastDialogs() {
      return this.$store.getters.settings.responsive.showLastDialogs;
    },
    activeVideo() {
      return (
        this.$store.getters.dialogs.current != undefined &&
        this.$store.getters.dialogs.current.user.chat_type === 'video' &&
        this.$store.getters.dialogs.current.user.chat_status === 'active'
      );
    },
    isVideoChat() {
      return (
        this.$store.getters.dialogs.current != undefined &&
        this.$store.getters.dialogs.current.user.chat_type === 'video'
      );
    },
  },

  watch: {
    iphoneVersion(val) {
      document.body.classList.add(val);
    },
  },

  beforeMount() {
    //            this.getMyBalance();
    let myBalanceIterator = setInterval(() => {
      this.getMyBalance();
    }, 10 * 1000);
    this.$store.dispatch('setMyBalanceIterator', myBalanceIterator);
  },
  mounted() {
    if (!(this.$store.getters.iphoneVersion === 'not-an-ip-safari')) {
      document.documentElement.classList.add('ios-safari');
      document.body.classList.add(this.$store.getters.iphoneVersion);
    }
  },
  methods: {
    scrollHandler() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
      document.activeElement.blur();
      $('input').blur();
      /* document.getElementsByTagName("input").blur(); */
    },
    getMyBalance() {
      this.$http
        .post('v1/credit/balance', {
          access_token: this.$store.getters.session.site_token,
        })
        .then(
          (response) => {
            if (response.body.status) {
              this.$store.dispatch('setMyBalance', response.body.credits);
            }
          },
          () => {
            this.getMyBalance();
          }
        );
    },
  },
};
</script>

<style scoped lang="scss">
.chat-box {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: 100%;
  grid-template-columns: 7.5% 20% 45% 20% 7.5%;
  grid-template-areas: ' . left-sidebar chat-box right-sidebar . ';

  overflow: hidden;

  .grid-left-sidebar {
    grid-area: left-sidebar;
  }
  .grid-right-sidebar {
    grid-area: right-sidebar;
  }
  .grid-chat-box {
    grid-area: chat-box;
  }

  @media screen and (max-width: 1440px) {
    display: grid;
    grid-template-columns: 20% 60% 20%;
    grid-template-areas: 'left-sidebar chat-box right-sidebar';
  }
  @media screen and (max-width: 992px) {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: column wrap;
    flex-flow: column wrap;
  }
  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    height: 305px;
    width: 100%;
    background: url('/static/assets/images/body-page-bg.jpg') no-repeat center
      center;
    -webkit-background-size: cover;
    background-size: cover;
    z-index: -10;
    @media screen and (max-width: 425px) {
      background-size: cover;
      height: 80px;
    }
  }
}

/* @media (max-width: 425px) and (min-height: 625px) {
  .ios-chat-box {
    max-height: 72vh;
    overflow: hidden;
  }
}
@media (max-width: 321px) {
  .ios-chat-box {
    max-height: 76vh;
  }
} */
</style>
