<template>
  <div class="choose-user">
    <!--responsive-->
    <div class="no-user-header visible-xs visible-sm">
      <div class="user-info">
        <div
          class="item"
          style="
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            width: 100%;
          "
        >
          <div class="avatar-replace">?</div>
        </div>
        <div
          class="item"
          style="
            width: 100%;
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
          "
        >
          <span class="name-box">
            <span class="uname">Выберите мужчину</span>
          </span>
        </div>
      </div>
      <div class="control-block">
        <div class="control-wrap">
          <div class="control-buttons">
            <!--changed sidebar controls-->
            <div class="sidebar-controls">
              <button class="btn-open" @click="responsiveShowUserOnline">
                <template v-if="false">
                  <!--remove counter displaying, add styles on button-->
                  <span class="counter">({{ womanOnlineCount }})</span>
                </template>
                Онлайн
                <span class="icon">
                  <svg class="rbi flip-right">
                    <use
                      xlink:href="/static/assets/images/rbi-icon.svg#rbi-arrow"
                    ></use>
                  </svg>
                </span>
              </button>
              <div class="rounded-box">
                <button class="btn btn-danger" disabled>
                  <svg class="rbi">
                    <use
                      xlink:href="/static/assets/images/rbi-icon.svg#rbi-comment-text"
                    ></use>
                  </svg>
                </button>
                <button class="btn btn-primary" disabled>
                  <span>
                    <svg class="rbi">
                      <use
                        xlink:href="/static/assets/images/rbi-icon.svg#rbi-video"
                      ></use>
                    </svg>
                  </span>
                </button>
              </div>
              <button
                class="btn-open"
                data-target="#last-dialogs"
                @click="responsiveShowLastDialogs"
              >
                <span class="icon">
                  <svg class="rbi flip-left">
                    <use
                      xlink:href="/static/assets/images/rbi-icon.svg#rbi-arrow"
                    ></use>
                  </svg>
                  <span class="counter">
                    <span
                      v-if="lastDialogsUnreadMessage"
                      class="unread label label-primary"
                      >{{ lastDialogsUnreadMessage }}</span
                    >
                  </span>
                </span>

                Чаты
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="no-user-caption">
      <p>No messages</p>
    </div>
  </div>
</template>
<script>
import {
  LAST_DIALOGS,
  AGE,
  IS_ONLINE,
  IS_BOOKMARK,
  IS_FAVORITE,
} from '../../mixins/man_util';
export default {
  name: 'ChooseMan',
  components: {},
  mixins: [LAST_DIALOGS, AGE, IS_ONLINE, IS_BOOKMARK, IS_FAVORITE],
  data() {
    return {};
  },
  computed: {
    lastDialogsCount() {
      return this.$store.getters.lastDialogs.length;
    },
    lastDialogsUnreadMessage() {
      return this.$store.getters.lastDialogsUnreadMessage;
    },
    manOnlineCount() {
      return this.$store.getters.countAllOnline;
    },
  },
  methods: {
    responsiveShowUserOnline() {
      this.$store.dispatch('setResponsiveShowUserOnline', true);
    },
    responsiveShowLastDialogs() {
      this.$store.dispatch('setResponsiveShowLastDialogs', true);
    },
  },
};
</script>

<style scoped>
.choose-user {
  padding: 10px;
  background: #ffffff;
  overflow: hidden;
}
.no-user-caption {
  overflow: hidden;
  height: 100%;
  width: 100%;
  justify-content: center;
  flex-direction: column;
}
</style>
