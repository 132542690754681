<template>
  <div>
    <h4 class="modal-title">Go to private chat?</h4>
    <p class="text-muted">All chats will be ended</p>
    <div class="center-block">
      <button class="btn btn-success">Go to private</button>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  computed: {},
  methods: {},
};
</script>

<style scoped>
.modal-title {
  margin-top: 20px;
}

.text-muted {
  margin-top: 10px;
}

.center-block {
  margin-top: 30px;
}

.btn {
  padding: 10px 20px;
  font-size: 13px;
}
</style>
