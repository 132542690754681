<template>
  <div
    class="chat-box"
    :class="{
      'video-chat':
        ((activeVideo || isVideoChat) && collocutorCameraOn) ||
        collocutorCameraOn,
    }"
  >
    <woman-left-side class="grid-left-sidebar"></woman-left-side>
    <ChatBox class="grid-chat-box"></ChatBox>
    <woman-right-side class="grid-right-sidebar"></woman-right-side>

    <gallery></gallery>
    <mail-modal></mail-modal>
    <virtual-gifts-modal />
    <get-user-media-modal></get-user-media-modal>
    <bad-connection-modal></bad-connection-modal>
    <duplicate-user-media-modal></duplicate-user-media-modal>
  </div>
</template>
<script>
import RightSide from '../woman/right-side.vue';
import Gallery from '../shared/Gallery.vue';
import MailModal from '../shared/modals/WriteMailModal.vue';
import GetUserMediaModal from '../shared/modals/GetUserMediaErrorModal.vue';
import BadConnectionModal from '../shared/modals/BadConnectionModal.vue';
import DuplicateUserMediaModal from '../shared/modals/DuplicateUserMediaModal.vue';
import VirtualGiftsModal from '../shared/modals/VirtualGiftsModal';
import WomanLeftSide from '../woman/left-side.vue';
import ChatBox from '../woman/ChatBox.vue';

export default {
  name: 'AppWoman',
  components: {
    ChatBox,
    'woman-right-side': RightSide,
    'woman-left-side': WomanLeftSide,
    'get-user-media-modal': GetUserMediaModal,
    'duplicate-user-media-modal': DuplicateUserMediaModal,
    MailModal,
    Gallery,
    BadConnectionModal,
    VirtualGiftsModal,
  },
  computed: {
    iphoneVersion() {
      return this.$store.getters.iphoneVersion;
    },
    activeVideo() {
      return (
        this.$store.getters.dialogs.current != undefined &&
        this.$store.getters.dialogs.current.user.chat_type === 'video' &&
        this.$store.getters.dialogs.current.user.chat_status === 'active'
      );
    },
    isVideoChat() {
      return (
        this.$store.getters.dialogs.current != undefined &&
        this.$store.getters.dialogs.current.user.chat_type === 'video'
      );
    },

    activeUsers() {
      return this.$store.getters.activeUsersList;
    },

    hasCurrentChat() {
      return this.$store.getters.dialogsCurrent !== null;
    },

    collocutor() {
      return this.$store.getters.dialogs.current
        ? this.$store.getters.dialogs.current.user.id
        : null;
    },

    collocutorCameraOn() {
      if (this.activeUsers && this.hasCurrentChat) {
        const user = this.activeUsers.find(
          (user) => user.userId === this.collocutor && user.userCameraActive
        );
        if (user) {
          return user.userCameraActive;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
  },
  watch: {
    iphoneVersion(val) {
      document.body.classList.add(val);
    },
  },
  mounted() {
    if (!(this.$store.getters.iphoneVersion === 'not-an-ip-safari')) {
      document.documentElement.classList.add('ios-safari');
      document.body.classList.add(this.$store.getters.iphoneVersion);
    }
  },
};
</script>

<style scoped lang="scss">
.chat-box {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: 100%;
  grid-template-columns: 7.5% 20% 45% 20% 7.5%;
  grid-template-areas: ' . left-sidebar chat-box right-sidebar . ';

  overflow: hidden;

  .grid-left-sidebar {
    grid-area: left-sidebar;
  }
  .grid-right-sidebar {
    grid-area: right-sidebar;
  }
  .grid-chat-box {
    grid-area: chat-box;
  }

  @media screen and (max-width: 1440px) {
    display: grid;
    grid-template-columns: 20% 60% 20%;
    grid-template-areas: 'left-sidebar chat-box right-sidebar';
  }
  @media screen and (max-width: 992px) {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: column wrap;
    flex-flow: column wrap;
  }
  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    height: 305px;
    width: 100%;
    background: url('/static/assets/images/body-page-bg.jpg') no-repeat center
      center;
    -webkit-background-size: cover;
    background-size: cover;
    z-index: -10;
    @media screen and (max-width: 425px) {
      background-size: cover;
      height: 80px;
    }
  }
}

/* @media (max-width: 425px) and (min-height: 625px) {
  .ios-chat-box {
    max-height: 72vh;
    overflow: hidden;
  }
}
@media (max-width: 321px) {
  .ios-chat-box {
    max-height: 76vh;
  }
} */
</style>
