import Vue from 'vue';
import App from './App.vue';
import store from './store/store';
import VueSocketIo from 'vue-socket.io';
import { io } from 'socket.io-client';

import VueResource from 'vue-resource';
import { directive as clickAway } from 'vue-clickaway';
// import VueGtag from 'vue-gtag';
import VueScrollTo from 'vue-scrollto';
import VueAwesomeSwiper from 'vue-awesome-swiper';
import VueAnalytics from 'vue-analytics';
import resize from 'vue-resize-directive';

import configDev from './config/config-develop';
import configProd from './config/config-production';

import Multiselect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.min.css';
import 'swiper/swiper-bundle.min.css';

import 'webrtc-adapter';
import simplebar from 'simplebar-vue';
import 'simplebar/dist/simplebar.min.css';
import Logger from './utils/Logger';

// Register components
Vue.component('Multiselect', Multiselect);
Vue.component('SimpleBar', simplebar);

let config = {};

let production = configProd.isProduction;
Vue.config.devtools = !production;

window.soundsInit = false;

const tinyMp3 =
  'data:audio/mpeg;base64,SUQzBAAAAAABEVRYWFgAAAAtAAADY29tbWVudABCaWdTb3VuZEJhbmsuY29tIC8gTGFTb25vdGhlcXVlLm9yZwBURU5DAAAAHQAAA1N3aXRjaCBQbHVzIMKpIE5DSCBTb2Z0d2FyZQBUSVQyAAAABgAAAzIyMzUAVFNTRQAAAA8AAANMYXZmNTcuODMuMTAwAAAAAAAAAAAAAAD/80DEAAAAA0gAAAAATEFNRTMuMTAwVVVVVVVVVVVVVUxBTUUzLjEwMFVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVf/zQsRbAAADSAAAAABVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVf/zQMSkAAADSAAAAABVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVV';

window.sounds = {
  notification: {
    src: '/static/audio/fInv.mp3',
    track: new Audio(tinyMp3),
  },
  newMessage: {
    src: '/static/audio/incoming_message.mp3',
    track: new Audio(tinyMp3),
  },
  fInv: {
    src: '/static/audio/fInv.mp3',
    track: new Audio(tinyMp3),
  },
  wMessage: {
    src: '/static/audio/w_message.mp3',
    track: new Audio(tinyMp3),
  },
  manStartStream: {
    src: '/static/audio/man_start_stream.mp3',
    track: new Audio(tinyMp3),
  },
  streamWatchStart: {
    src: '/static/audio/stream_watch_start.mp3',
    track: new Audio(tinyMp3),
  },
};

const AudioContext = window.AudioContext || window.webkitAudioContext;

const audioCtx = new AudioContext();

function assignSrcToTrack(mobile = false) {
  if (!window.soundsInit) {
    for (let key in window.sounds) {
      window.sounds[key].track.src = window.sounds[key].src;

      if (mobile) {
        const track = audioCtx.createMediaElementSource(
          window.sounds[key].track
        );
        track.connect(audioCtx.destination);
      }
    }
    window.soundsInit = true;
  }
}

if (window.innerWidth <= 986) {
  document.body.addEventListener(
    'touchstart',
    function () {
      assignSrcToTrack(true);
    },
    false
  );
} else {
  assignSrcToTrack();
}

// BE SURE YOU CHANGED PRODUCTION VARIABLE IN STORE.JS

if (production) {
  config = configProd;
  Vue.use(VueAnalytics, {
    id: config.googleAnalyticsKey,
    autoTracking: {
      pageviewTemplate: function (route) {
        return {
          path: route.path,
          location: window.location.href,
        };
      },
    },
  });
} else {
  config = configDev;
}

Vue.prototype.$log = production ? new Logger(false) : new Logger(true);

Vue.use(
  new VueSocketIo({
    connection: io(config.socket_url, {}),
    debug: !production,
    vuex: { store, actionPrefix: 'socket_', mutationPrefix: 'socket_' },
  })
);

Vue.use(VueResource);

Vue.directive('clickAway', clickAway);
Vue.directive('resize', resize);
Vue.use(VueScrollTo);
Vue.use(VueAwesomeSwiper);

Vue.http.options.root = config.api_url;
Vue.http.options.emulateJSON = true;
Vue.http.options.emulateHTTP = true;
Vue.prototype.config = config;

Vue.config.productionTip = false;

new Vue({ store, render: (h) => h(App) }).$mount('#app');
