<template>
  <transition v-if="open" name="modal">
    <div class="vue-modal modal-mask photos-modal">
      <div class="vue-modal--wrapper">
        <div
          v-click-away="closeModal"
          class="vue-modal--container photos-modal--body"
        >
          <div class="photos-modal--header d-flex">
            <h4 class="modal-title">Choose photo</h4>
            <div class="d-flex">
              <rb-button
                class="btn-red"
                type="button"
                @click.native="passPhotos"
              >
                <svg class="rbi">
                  <use
                    xlink:href="/static/assets/images/rbi-icon.svg#rbi-clip"
                  ></use>
                </svg>
                <span>Attach</span>
              </rb-button>
              <button type="button" class="close" @click="closeModal">
                <svg class="rbi">
                  <use
                    xlink:href="/static/assets/images/rbi-icon.svg#rbi-close"
                  ></use>
                </svg>
              </button>
            </div>
          </div>
          <div class="photos-modal-body">
            <div v-if="sell" class="settings-alert">
              <p>
                <svg class="rbi">
                  <use
                    xlink:href="/static/assets/images/rbi-icon.svg#rbi-attention"
                  ></use>
                </svg>
                Вы можете отправить конкретную фотографию собеседнику только 1
                раз.
              </p>
            </div>
            <div v-show="showErrLimit" class="alert alert-danger">
              Limit reached
            </div>
            <div v-show="showPreloader" class="preloader center-block"></div>
            <div class="photos">
              <div
                v-for="photo in photos"
                :key="photo.photo_id"
                class="photos--item photos-modal--photo"
                :class="{ checked: photo.selected }"
                @click="selectPhoto(photo)"
              >
                <div class="img-wrap">
                  <img
                    :src="photo._photo"
                    class="obj-fit"
                    alt="Photo preview"
                  />
                  <div
                    class="checkbox-emit"
                    :class="{ checked: photo.selected }"
                  >
                    <svg class="rbi">
                      <use
                        xlink:href="/static/assets/images/rbi-icon.svg#rbi-check"
                      ></use>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="!photos.length" class="no-photo">
              <h2 v-if="isWoman">В приватном альбоме нет фото</h2>
              <h2 v-else>You have no photo</h2>
            </div>
            <hr v-show="showLimit < count" />
            <div v-show="showLimit < count" class="button-wrap">
              <rb-button @click.native="loadMore">
                Load ({{ defaultLimit }}) more</rb-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import RbButton from '../Button';

export default {
  components: {
    RbButton,
  },
  props: {
    userId: Number,
    limit: Number,
    open: Boolean,
    passedCount: Number,
    sell: Boolean,
  },
  data() {
    return {
      showPreloader: true,
      storedPhotos: [],
      showLimit: 30,
      defaultLimit: 30,
      showErrLimit: false,
      count: 0,
    };
  },
  computed: {
    selectedPhotos() {
      return this.storedPhotos.filter((i) => i.selected);
    },
    photos() {
      return this.storedPhotos.slice(0, this.showLimit);
    },
    isWoman() {
      return this.$store.getters.user.type === 'woman';
    },
    token() {
      return this.$store.getters.token.site_token;
    },
  },
  watch: {
    open(val) {
      val ? this.loadPhotos() : this.clearData();
    },
  },
  methods: {
    clearData() {
      this.storedPhotos = [];
      this.count = 0;
      this.showLimit = this.defaultLimit;
      this.showErrLimit = false;
    },
    loadMore() {
      this.showLimit += this.defaultLimit;
    },
    loadPhotosToSell() {
      this.showPreloader = true;
      this.$http
        .post('v1/photo/to-sell', {
          access_token: this.token,
          target_user_id: this.userId,
        })
        .then((r) => {
          if (r.body.status) {
            this.count += r.body.count;
            r.body.result.sort((a, b) => b.photo_id - a.photo_id);
            this.storedPhotos = this.storedPhotos.concat(
              r.body.result.map((item) => {
                return {
                  _photo: item.img_preview,
                  selected: false,
                  id: item.photo_id,
                };
              })
            );
          }
          this.showPreloader = false;
        });
    },
    loadAlbums() {
      this.showPreloader = true;
      this.$http
        .post('v1/photo/my-album-photo', {
          access_token: this.token,
          limit: 0,
          offset: 0,
          album_type: 'private',
          sort: 'DESC',
        })
        .then((response) => {
          this.count += response.body.count;
          response.body.result.sort((a, b) => b.photo_id - a.photo_id);
          this.storedPhotos = this.storedPhotos.concat(
            response.body.result.map((item) => {
              return {
                _photo: item.img_preview,
                selected: false,
                id: item.photo_id,
              };
            })
          );
          this.showPreloader = false;
        });
      // this.$http.post( 'v1/photo/my-album-photo', {
      // 	access_token: this.token,
      // 	limit: 0,
      // 	offset: 0,
      // 	album_type: 'public',
      // 	sort: 'DESC'
      // } ).then( response => {
      // 	this.count += response.body.count;
      // 	this.storedPhotos = this.storedPhotos.concat( response.body.result.map( item => {
      // 		return {
      // 			_photo: item.img_preview,
      // 			selected: false,
      // 			id: item.photo_id
      // 		}
      // 	} ) )
      // 	this.showPreloader = false;
      // } );
    },
    loadPhotos() {
      this.sell ? this.loadPhotosToSell() : this.loadAlbums();
    },
    passPhotos() {
      this.$emit('selected', this.selectedPhotos);
      this.closeModal();
    },
    closeModal() {
      this.$emit('close');
      this.selectedPhotos.forEach((i) => (i.selected = false));
      this.showPreloader = true;
    },
    selectPhoto(photo) {
      if (this.sell) {
        photo.selected = !photo.selected;
        this.passPhotos();
      } else {
        if (this.selectedPhotos.length + this.passedCount < this.limit) {
          photo.selected = !photo.selected;
        } else if (photo.selected) {
          photo.selected = false;
        } else {
          this.showErrLimit = true;
          photo.selected = false;
          return;
        }
        this.showErrLimit = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/_mixins';
@import '../../../assets/scss/variables-bootstrap';
@import '../../../assets/scss/vars';

.no-photo {
  display: flex;
  align-content: center;
  justify-content: center;
  padding: 30px;
}

.checkbox-emit {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  width: 20px;
  border-radius: 2px;
  line-height: 0;
  cursor: pointer;
  transition: 0.1s ease;
  opacity: 0;
  font-size: 25px;
  color: $white;

  &.checked {
    background: rgba($red, 0.5);
    opacity: 1;
  }

  &--icon {
    width: 15px;
    height: 15px;
    opacity: 0;
    fill: #fff;
    transition: opacity 0.1s ease;
  }
}

.btn-red {
  width: auto;
  padding: 10px 30px !important;
  font-size: 12px !important;
  .rbi {
    margin-right: 0.5em;
  }
}

.photos-modal {
  .alert {
    margin-top: 10px;
  }

  &--header {
    align-items: center;
    justify-content: space-between;

    .btn {
      .rbi {
        font-size: 18px;
      }
      span {
        &:last-child {
          margin-left: 0.5em;
        }
      }
    }

    .btn + .close {
      margin-left: 25px;
    }

    .btn-primary {
      line-height: 1;
      font-size: 13px;
      padding: 10px 20px;
    }
  }

  &--body {
    width: 920px;
    padding: 25px;

    .photos {
      display: flex;
      margin: 0 -3px;
      flex-wrap: wrap;

      &--item {
        position: relative;
        margin-top: 20px;
        cursor: pointer;
        user-select: none;
        width: 25%;
        padding: 0 3px;

        .img-wrap {
          position: relative;
          padding-bottom: 88%;
          &:after {
            position: absolute;
            bottom: -3px;
            width: 25%;
            height: 3px;
            left: 0;
            content: '';
            display: block;
            background: $red;
          }
          .obj-fit {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            object-fit: cover;
          }
        }

        .checkbox-emit {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
        }

        img {
          width: 100%;
        }
      }
    }
  }
}

.button-wrap {
  margin-top: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.alert {
  padding: 15px;
  border: 1px solid transparent;
  background-color: transparent;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;

  &-warning {
    border-color: #ffb236;
    color: #ffb236;
  }

  &-info {
    border-color: #2ca8ff;
    color: #2ca8ff;
  }
  &-danger {
    border-color: #ff3636;
    color: #ff3636;
  }
}

@media only screen and (max-width: 992px) {
  .photos-modal {
    &--body {
      width: 100%;
      .photos {
        &--item {
          width: 33.33%;
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .photos-modal {
    &--body {
      padding: 15px;
    }
  }
}

@media only screen and (max-width: 480px) {
  .photos-modal {
    &--body {
      width: 100%;
      .photos {
        &--item {
          width: 50%;
          margin-top: 10px;
        }
      }
    }

    .btn-red {
      padding: 10px !important;
      width: 45px !important;
      height: 45px !important;
      .rbi {
        font-size: 16px;
        margin: 0 !important;
      }
      span {
        display: none;
      }
    }
  }
}

.settings-alert {
  p {
    margin: 0;
    padding: 0;
  }
  .rbi {
    font-size: 25px;
    margin-right: 0.5em;
  }
}
</style>
