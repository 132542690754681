/**
 * Created by Garant on 14.05.2017.
 */

import { MAIL_MODAL } from './formsActions';

export const USER_ACTIONS = {
  methods: {
    previewSendMail(condition, user) {
      if (condition) {
        MAIL_MODAL.methods.toggleMailModal(user, this);
      }
    },
    previewSendGift(condition, id) {
      if (condition) {
        this.$store.dispatch('updateGiftModal', {
          user_id: id,
          open: true,
        });
      }
    },
    previewSendVirtualGift(condition, id) {
      if (condition) {
        this.$store.dispatch('updateVirtualGiftModal', {
          user_id: id,
          open: true,
        });
      }
    },
  },
};
