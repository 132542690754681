<template>
  <div>
    <h4 class="modal-title">Already have active chat</h4>
    <p class="text-muted">Leave it and go to another chat or stay?</p>
    <div class="d-flex buttons-block">
      <div>
        <button class="btn btn-primary">Stay</button>
      </div>
      <div>
        <button class="btn btn-success">New chat</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  computed: {},
  methods: {},
};
</script>

<style scoped lang="scss">
.modal-title {
  margin-top: 20px;
}

.text-muted {
  margin-top: 10px;
}

.buttons-block {
  margin-top: 20px;
  justify-content: space-between;
}

.btn {
  padding: 10px 20px;
  font-size: 13px;
}
</style>
