<template>
  <span class="zodiac-icon">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="31.998"
      height="31.998"
      viewBox="0 0 31.998 31.998"
    >
      <path
        d="M25.262 10.757c-2.213 0-4.164 1.088-5.394 2.74-.616-1.325-1.22-3.13-1.22-5.14 0-2.47-2.002-4.13-4.982-4.13-1.527 0-3.107.462-4.193 1.345-1.33-1.016-3.258-1.586-4.825-1.586-1.246 0-2.33.337-3.136.974C.822 5.506 0 6.538 0 8.358c0 .82.664 1.48 1.482 1.48s1.482-.66 1.482-1.48c0-.722.203-1.23 1.195-1.375 1.76-.264 3.776.883 3.928 1.375v12.104c0 .818.664 1.48 1.482 1.48s1.48-.662 1.48-1.48V8.358c0-.522 1.207-1.165 2.615-1.165.65 0 1.234.142 1.604.385.293.195.412.423.412.78 0 4.285 2.095 7.705 2.873 8.828-.006.104-.03.204-.03.31 0 1.057.265 2.04.696 2.93-.907 1.38-3.452 4.526-7.86 4.62-.818.02-1.468.694-1.45 1.516.017.808.677 1.45 1.48 1.45h.032c5.055-.107 8.215-3.2 9.738-5.205 1.14.882 2.55 1.43 4.1 1.43 3.717 0 6.738-3.02 6.738-6.736 0-3.718-3.02-6.743-6.736-6.743zm0 10.514c-2.08 0-3.773-1.692-3.773-3.773 0-2.08 1.692-3.773 3.772-3.773 2.082 0 3.775 1.693 3.775 3.773s-1.693 3.774-3.775 3.774z"
      />
    </svg>
  </span>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  computed: {},
  methods: {},
};
</script>

<style></style>
