<template>
  <div class="new-header-box">
    <div class="header-box__item">
      <div class="logo-wrapper" style="width: 80%">
        <div class="logo"></div>
      </div>
    </div>
    <div class="header-box__item controls-header">
      <!--      <div class="chat-time">-->
      <!--        <svg class="rbi">-->
      <!--          <use xlink:href="/static/assets/images/rbi-icon.svg#rbi-clock"></use>-->
      <!--        </svg>-->
      <!--        <span>{{mixinBeautyTime( chatTime )}}</span>-->
      <!--      </div>-->
      <div class="info-button">
        <svg
          class="rbi"
          style="height: 1.25em; width: 1.25em; cursor: pointer"
          @click="openVersionModal"
        >
          <use xlink:href="/static/assets/images/rbi-icon.svg#rbi-attention" />
        </svg>
      </div>
      <div class="chat-time">
        <div class="clock rbi" :class="{ start: chatStatus.active }">
          <span class="clock--short-hand"></span>
          <span class="clock--long-hand"></span>
          <span class="clock--dot"></span>
        </div>
        <span
          :class="{
            'status-start': chatStatus.tip === 'start',
            'status-stop': chatStatus.tip === 'spec-stop',
          }"
          >{{ chatStatus.text }}</span
        >
      </div>
      <div class="currency-wrap">
        <svg class="rbi">
          <use xlink:href="/static/assets/images/rbi-icon.svg#rbi-money" />
        </svg>
        <span class="amount" :class="{ 'low-credits': lowBalance }">{{
          userBalance
        }}</span>
      </div>
      <div class="toggle-sound">
        <button class="btn-reset" @click="toggleSound">
          <svg v-if="soundSettings.sound" class="rbi">
            <use xlink:href="/static/assets/images/rbi-icon.svg#rbi-volume" />
          </svg>

          <svg v-else class="rbi">
            <use
              xlink:href="/static/assets/images/rbi-icon.svg#rbi-volume_mute"
            />
          </svg>
        </button>
      </div>
      <div class="toggle-camera">
        <camera />
      </div>
    </div>
  </div>
</template>

<script>
import { BEAUTY_TIME } from '../../../mixins/man_util';
import { SOUND } from '../../../mixins/utils';
import Camera from '../video/camera';

export default {
  name: 'HeaderBox',
  components: { Camera },
  mixins: [BEAUTY_TIME, SOUND],
  props: {},
  data() {
    return {
      timer: 0,
    };
  },
  computed: {
    chatTime() {
      return this.$store.getters.chatTime;
    },
    userBalance() {
      return this.$store.getters.myBalance;
    },
    lowBalance() {
      return this.userBalance <= 10;
    },
    chatStatus() {
      if (
        this.currentDialog &&
        this.currentDialog.roomId !== null &&
        this.$store.getters.connectedToSocket
      ) {
        if (this.currentDialog.active)
          return {
            active: true,
            text: '',
            tip: 'current',
          };
        else if (this.currentDialog.roomStatus === 'stop') {
          return {
            active: false,
            text: '',
            tip: 'spec-stop',
          };
        } else {
          return {
            active: false,
            text: '',
            tip: 'spec-stop',
          };
        }
      } else {
        return {
          active: false,
          text: '',
          tip: 'spec-stop',
        };
      }
      /*else if (dialog.active) {
          if (!!dialog.chat_time) {
            return {
              active: true,
              text: 'Chat',
              tip: 'current',
            }
          } else {
            return {
              active: true,
              text: 'Active',
              tip: 'start',
            }
          }
        } else if (dialog.chat_status === 'pause') {
          return {
            active: false,
            text: 'Stop',
            tip: 'spec-stop',
          }
        }*/
    },
    currentDialog() {
      return this.$store.getters.dialogsCurrent;
    },
  },
  mounted() {
    setTimeout(() => {
      this.$http
        .post('v1/credit/balance', {
          access_token: this.$store.getters.session.site_token,
        })
        .then((response) => {
          if (response.body.status) {
            this.$store.dispatch('setMyBalance', response.body.credits);
          }
        });
    }, 1000);
  },
  methods: {
    openVersionModal() {
      this.$store.commit('updateVersionChangeModal', { open: true });
    },
  },
};
</script>

<style scoped>
.new-header-box {
  height: 100%;
  overflow: hidden;
  color: white;
  display: none;
}
.new-header-box .header-box__item {
  display: flex;
  justify-content: flex-start;
}
.controls-header {
  width: 80%;
}
.new-header-box .logo {
  margin: 5px 0 0 10px !important;
  height: 30px !important;
}
.header-box__item .chat-time,
.currency-wrap,
.toggle-sound,
.info-button,
.toggle-camera {
  width: 20%;
  display: flex;
  justify-content: center !important;
}
.info-button {
  opacity: 0.5;
}
.toggle-camera {
  padding-top: 5px;
}
.chat-time .rbi {
  margin: 0 !important;
}

@media screen and (max-width: 992px) {
  .new-header-box {
    display: flex;
    justify-content: space-between;
    max-height: unset;
    width: 100%;
    padding: 0 10px;
  }
}
@media screen and (max-width: 425px) and (max-height: 736px) {
  .new-header-box {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0 10px;
  }
}
@media screen and (max-width: 425px) and (min-height: 736px) {
  .new-header-box {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0 10px;
  }
}
@media (max-width: 321px) {
  .new-header-box {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0 10px;
  }
}
</style>
