<template>
  <div id="all-online" role="tabpanel" class="tab-pane active">
    <div class="sidebar__search">
      <div class="form-group">
        <input
          type="text"
          class="form-control findInOnline"
          placeholder="Name or ID"
          @keyup="type"
        />
      </div>
    </div>
    <div class="users-online-block">
      <SimpleBar
        data-simplebar-auto-hide="true"
        class="users-online-block-simplebar"
      >
        <div v-if="!needSearch">
          <woman-item
            v-for="(woman, key) in womanList"
            :key="key"
            :woman="woman"
          ></woman-item>
        </div>
        <div v-if="needSearch">
          <woman-item
            v-for="(woman, key) in womanlistFound"
            :key="key"
            :woman="woman"
          ></woman-item>
        </div>
      </SimpleBar>
    </div>
  </div>
</template>

<script>
import store from '../../../store/store';
import WomanItem from '../items/woman-item';

export default {
  scroll: null,
  name: 'OnlineWoman',
  store,
  components: {
    'woman-item': WomanItem,
  },
  data() {
    return {
      needSearch: false,
      womanlistFound: this.womanList,
    };
  },
  computed: {
    search() {
      return this.$store.getters.getManQ;
    },
    womanList() {
      return this.$store.getters.onlineWomanList;
    },
    newWomanList() {
      return this.womanlistFound;
    },
  },
  watch: {
    search(value) {
      this.womanlistFound = this.womanList;
      if (value === '') {
        this.needSearch = false;
        this.womanlistFound = this.$store.getters.onlineWomanList;
      } else {
        this.needSearch = true;
        this.womanlistFound = this.womanlistFound.filter(this.searchInArray);
      }
    },
  },
  beforeMount() {
    this.womanlistFound = this.womanList;
  },
  mounted() {
    setTimeout(() => {
      this.womanlistFound = this.womanList;
    }, 900);
  },
  methods: {
    type(data) {
      this.$store.commit('SET_MAN_Q', data.target.value);
    },
    searchInArray(womanItem) {
      let q = this.search.toString().toUpperCase();
      return (
        womanItem.id.toString().match(q) ||
        womanItem.profile.name.toString().toUpperCase().match(q)
      );
    },
  },
};
</script>

<style lang="scss">
@media screen and (max-width: 450px) {
  .sidebar__search {
    display: none;
  }
}
.users-online-block-simplebar {
  position: relative;
  height: 100%;
  overflow-x: hidden;
}

.ios-safari {
  .users-online-block-simplebar {
    height: 100%;
    overflow-x: hidden;
  }
  .isIos14Up {
    .users-online-block-simplebar {
      height: 100%;
      overflow-x: hidden;
    }
  }
}

.sidebar__content {
  position: relative;
  overflow: hidden;
  .tab-content,
  .tab-pane {
    position: relative;
    height: 100%;
    overflow: hidden;
  }

  .tab-pane {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
.users-online-block {
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
}
</style>
