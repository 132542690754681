<template>
  <div class="heading profile-new-woman">
    <div
      class="relative-profile"
      :class="{
        'remove-padding': isVideoChat,
        'gallery-opened': galleryDisplayed,
      }"
    >
      <div id="default" class="info-box d-flex justify-content-between">
        <div class="user-info">
          <div class="item photo-item">
            <div
              class="user-avatar user-avatar-underline profile-pointer"
              @click.prevent="openModal(user.id)"
            >
              <img
                class="user-avatar__img"
                :src="user.profile.avatarList.img_medium"
                :alt="user.profile.name + '\'s avatar'"
              />
              <span v-if="isOnline" class="is-online"></span>
              <span class="stroke hd hidden-lg hidden-md"></span>
            </div>
          </div>
          <div class="item name-item">
            <span
              class="name-box profile-pointer"
              @click.prevent="openModal(user.id)"
            >
              <span class="uname">
                {{ user.profile.name }}
                <span
                  v-show="user.status"
                  style="margin-left: 10px; font-size: 1rem"
                >
                  ID:{{ user.id }}
                </span>
              </span>

              <svg class="rbi" style="margin-left: 10px">
                <use
                  xlink:href="/static/assets/images/rbi-icon.svg#rbi-attention"
                ></use>
              </svg>
            </span>
            <blockquote class="custom-status">
              <p v-if="cutStatus">{{ cutStatus }}</p>
              <p v-else class="uid">ID:{{ user.id }}</p>
            </blockquote>
          </div>
        </div>
        <div class="clearfix hidden-lg hidden-md">
          <div class="dropdown user-info__dropdown">
            <button
              id="submenu"
              type="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <svg class="rbi">
                <use
                  xlink:href="/static/assets/images/rbi-icon.svg#rbi-more"
                ></use>
              </svg>
            </button>
            <ul
              class="dropdown-menu dropdown-buttons"
              aria-labelledby="submenu"
            >
              <li>
                <button
                  class="btn btn-common"
                  title="Send mail"
                  @click.prevent="sendMail"
                >
                  <svg class="rbi">
                    <use
                      xlink:href="/static/assets/images/rbi-icon.svg#rbi-message"
                    ></use>
                  </svg>
                </button>
              </li>
              <li>
                <button
                  class="btn btn-common"
                  :class="{ checked: isBookmark }"
                  title="bookmarkActionTitle"
                  @click.prevent="bookmarkAction"
                >
                  <span v-if="isBookmark" class="checked-icon">
                    <svg class="rbi">
                      <use
                        xlink:href="/static/assets/images/rbi-icon.svg#rbi-check"
                      ></use>
                    </svg>
                  </span>
                  <svg class="rbi">
                    <use
                      xlink:href="/static/assets/images/rbi-icon.svg#rbi-star"
                    ></use>
                  </svg>
                </button>
              </li>
              <li>
                <button
                  class="btn btn-common"
                  title="Send gift"
                  @click.prevent="sendGift"
                >
                  <svg class="rbi">
                    <use
                      xlink:href="/static/assets/images/rbi-icon.svg#rbi-gift"
                    ></use>
                  </svg>
                </button>
              </li>
              <li>
                <button
                  class="btn btn-common"
                  :disabled="winked"
                  :class="{ checked: winked }"
                  title="Send wink"
                  @click.prevent="sendWink"
                >
                  <span v-if="winked" class="checked-icon">
                    <svg class="rbi">
                      <use
                        xlink:href="/static/assets/images/rbi-icon.svg#rbi-check"
                      ></use>
                    </svg>
                  </span>
                  <svg class="rbi">
                    <use
                      xlink:href="/static/assets/images/rbi-icon.svg#rbi-emoji"
                    ></use>
                  </svg>
                </button>
              </li>
              <li>
                <button
                  class="btn btn-common"
                  :class="{ checked: isFavorite }"
                  :title="favoriteActionTitle"
                  @click="favoriteAction"
                >
                  <span v-if="isFavorite" class="checked-icon">
                    <svg class="rbi">
                      <use
                        xlink:href="/static/assets/images/rbi-icon.svg#rbi-check"
                      ></use>
                    </svg>
                  </span>
                  <svg class="rbi">
                    <use
                      xlink:href="/static/assets/images/rbi-icon.svg#rbi-heart"
                    ></use>
                  </svg>
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <Controls
        :chat-type="chatType"
        @display-gallery-change="galleryDisplayChangeHandler"
      />
    </div>
  </div>
</template>

<script>
import { GALLERY, PREVIEW_MODAL } from '../../../mixins/utils';
import Controls from '../chat-box/Controls';
import womanControlsMixin from '../../../mixins/womanControls.mixin';
export default {
  name: 'MainUserArea',
  components: {
    Controls,
  },
  mixins: [PREVIEW_MODAL, GALLERY, womanControlsMixin],
  props: ['chatType'],
  data() {
    return { galleryDisplayed: false };
  },
  computed: {
    isVideoChat() {
      return this.$store.getters.dialogs.current.user.chat_type === 'video';
    },
    user() {
      let dialogs = this.$store.getters.dialogs;
      if (dialogs.current !== null) {
        return dialogs.current.user;
      } else {
        return false;
      }
    },
    isOnline() {
      let online = this.$store.getters.appUser.all_online;
      for (let i = 0; i < online.length; i++) {
        if (online[i].id === this.$store.getters.dialogs.current.user.id) {
          return true;
        }
      }
      return false;
    },
    cutStatus() {
      return this.$store.getters.dialogs.current.user.status;
    },
  },
  methods: {
    galleryDisplayChangeHandler(e) {
      this.galleryDisplayed = e;
    },
  },
};
</script>

<style lang="scss">
@import '../../../assets/scss/vars';

.profile-new-woman {
  height: 100%;
  overflow: hidden;

  .clearfix {
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  .profile-pointer {
    cursor: pointer;
  }

  .relative-profile {
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .remove-padding {
    padding: 0 !important;
  }

  .gallery-opened {
    justify-content: space-between;
    .info-box {
      display: none !important;
    }
    .control-block {
      height: 100%;
      .control-wrap {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
      }
    }
  }

  @media screen and (max-width: 425px) {
    .control-block {
      padding: 0 5px !important;
    }
    .info-box {
      height: 50%;
      overflow: hidden;
      .user-info {
        padding: 0 !important;
        height: 100%;
        overflow: hidden;
      }
      .user-avatar {
        width: 75px !important;
        height: auto !important;
      }
      .item {
        height: 100%;
      }
    }
  }

  .user-info {
    display: flex;
    width: 100%;

    .item {
      + .item {
        flex: 1 1 100%;
        margin-left: 25px;
      }
    }
    .user-avatar {
      width: 100px;
      height: 100px;
    }
  }

  .custom-status {
    margin-top: 20px;
    border-radius: 0;
    max-height: 54px;
    padding: 10px 15px;
    font-weight: normal;
    line-height: 1.6;
    overflow: hidden;
  }

  .name-box {
    display: flex;
    align-items: center;
    line-height: 1;
    font-weight: 700;
    color: $black;
    cursor: pointer;
    font-size: 20px;

    .uname {
      &:first-letter {
        color: $red;
      }
    }

    .rbi {
      font-size: 14px;
      margin-right: 0.714em;
    }
  }

  .control-block {
    padding: 10px 5px;

    .gallery-wrapper {
      position: relative;
      margin-bottom: 5px;
    }
    .slider-gallery {
      overflow: hidden;
    }

    &.no-border {
      position: absolute;
      bottom: 0;
      left: 0;
      right: -1px;
      padding: 0 25px;
      opacity: 1;
      transition: 0.3s;
      background: rgba(255, 255, 255, 0);
      background: -moz-linear-gradient(
        top,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 1) 100%
      );
      background: -webkit-gradient(
        left top,
        left bottom,
        color-stop(0%, rgba(255, 255, 255, 0)),
        color-stop(100%, rgba(255, 255, 255, 1))
      );
      background: -webkit-linear-gradient(
        top,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 1) 100%
      );
      background: -o-linear-gradient(
        top,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 1) 100%
      );
      background: -ms-linear-gradient(
        top,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 1) 100%
      );
      /* background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);*/
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#ffffff', GradientType=0);

      .control-wrap {
        border-top: none;
      }
    }

    .control-wrap {
      /* padding: 25px 0;
      border-top: 1px solid $grey-two; */
      position: relative;
    }

    .slider-btn-wrap {
      position: absolute;
      right: 0;
      bottom: 0;
      display: flex;
      background: $white;

      .slider-btn {
        background: none;
        border: none;
        display: flex;
        font-size: 25px;
      }

      .button-prev {
        .rbi {
          transform: rotate(90deg);
        }
      }

      .button-next {
        .rbi {
          transform: rotate(-90deg);
        }
      }
    }
  }

  .dialog-box .scrollable {
    padding: 0 25px;
  }

  .swiper-slide {
    .image-wrap {
      cursor: pointer;

      &:after {
        content: '';
        display: block;
        position: absolute;
        height: 3px;
        width: 25%;
        background: $red;
        bottom: -3px;
        left: 0;
      }

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .control-buttons {
    justify-content: space-between;
    position: static;

    .btn-common {
      background: transparent;
      box-shadow: none;
      border: 1px solid rgba($black, 0.5);
      border-radius: 50%;
      width: 36px;
      height: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: rgba($black, 0.5);
      position: relative;
      transition: 0.3s;
      margin: 0 8px !important;

      .checked-icon {
        position: absolute;
        top: 0;
        right: 0;
        width: 10px;
        height: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &:hover {
        border: 1px solid $red;
        color: $red;
      }

      &.checked,
      &:disabled {
        border: 1px solid rgba($black, 0.2);
        color: rgba($black, 0.2) !important;
        background: none !important;
      }
      &:disabled {
        cursor: not-allowed;
      }
    }

    .rbi {
      font-size: 16px;
    }

    svg {
      width: 1em;
      height: 1em;
    }

    .btn:disabled {
      background: $grey-two;
      color: $white;
    }

    .btn-primary {
      border: none;
      background: $red;
    }

    .toggle-slider {
      background: none;
      color: $black;
      width: auto;
      height: auto;
      -webkit-border-radius: 0;
      -moz-border-radius: 0;
      border-radius: 0;

      .rbi {
        transition: 0.3s;
      }

      &:active {
        box-shadow: none;
      }

      &.open {
        .rbi {
          transform: scaleY(-1);
        }
      }
    }

    .rounded-box {
      font-size: 16px;

      .active {
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
        background: $white;
        color: $red;
      }
    }
  }

  .heading > .relative {
    padding-bottom: 0;

    &:hover {
      .control-block.no-border {
        opacity: 1;
      }
    }
  }
}
</style>
