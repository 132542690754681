<template>
  <li
    ref="invite"
    class="invites--item ml"
    style="cursor: pointer"
    @click.prevent="setTextChat"
  >
    <div class="invites__body">
      <p class="uname d-none d-lg-block">
        {{ woman.profile.name }} <span>ID:{{ woman.id }}</span>
      </p>
      <div class="invite__sender">
        <div class="invite__img" @click="setTextChat">
          <div
            :id="`circle-invite-${woman.id}`"
            class="circle-indicator d-block d-lg-none"
          ></div>
          <img
            :src="woman.profile.avatarList.img_small"
            class="user-avatar"
            :alt="woman.profile.name + '\'s avatar'"
          />
          <span v-if="isCameraActive" class="webcam d-none d-lg-block">
            <svg class="rbi">
              <use
                xlink:href="/static/assets/images/rbi-icon.svg#rbi-webcam"
              ></use>
            </svg>
          </span>
        </div>
        <div class="invite__sender_text">
          <p v-html="messageFromShort(invite.text)"></p>
        </div>
      </div>
      <div class="invite__controls d-none d-lg-flex">
        <!--<rb-button class="btn-white" :disabled="!isVideoInvite" @click.native="setVideoChat">
          <svg class="rbi">
            <use xlink:href="/static/assets/images/rbi-icon.svg#rbi-video"></use>
          </svg>
          <span>
          video<br>
          chat
          </span>

        </rb-button>
        <rb-button class="btn-white"
                   @click.native="setTextChat">
          <svg class="rbi">
            <use xlink:href="/static/assets/images/rbi-icon.svg#rbi-comment-text"></use>
          </svg>
          <span>
                      text<br>
          chat
          </span>

        </rb-button>-->
      </div>
      <div
        :id="`line-invite-${woman.id}`"
        class="life-indicator d-none d-lg-block"
      ></div>
    </div>

    <!--    <div class="invites__body">
          <div class="invite__img" @click="setTextChat">
            <div class="circle-indicator"
                 :class="'circle-' + invite.notificationId"></div>
            <img :src="woman.profile.avatarList.img_small"
                 class="user-avatar"
                 :alt="woman.profile.name + '\'s avatar'">
          </div>
          <div class="d-none d-lg-block invite__text">
            <p>{{ woman.profile.name }},<span>ID:{{ woman.id }}</span> </p>
            <p v-html="messageFromShort(invite.text)"></p>
          </div>
          <div class="d-none d-lg-flex invite__block-btn">
            <button class="invite__btn" v-if="isVideoInvite" @click="setVideoChat">

              <svg class="rbi">
                <use xlink:href="/static/assets/images/rbi-icon.svg#rbi-video"></use>
              </svg>
            </button>
            <button class="invite__btn"
                    @click="setTextChat">
              <svg class="rbi">
                <use xlink:href="/static/assets/images/rbi-icon.svg#rbi-comment-text"></use>
              </svg>
            </button>
          </div>
        </div>-->

    <!--            <div class="invites&#45;&#45;item__info visible-lg">
                    <p>{{ woman.profile.name }} | ID:{{ woman.id }}</p>
                </div>
                <div class="invites&#45;&#45;item__body">
                    <div class="img-container is-online"  :class="{'with-webcam':isCameraActive}" @click="setTextChat">
                        <div class="circle-indicator hidden-lg" :class="'circle-' + invite.notificationId"></div>
                        <img :src="woman.profile.avatarList.img_small"
                             class="user-avatar"
                             :alt="woman.profile.name + '\'s avatar'">
                        <span class="webcam-icon" v-show="isCameraActive"></span>
                    </div>
                    <div class="blockquote">
                        <div class="blockquote&#45;&#45;inner">
                            <p v-html="messageFromShort(invite.text)"></p>
                        </div>
                    </div>
                </div>
                <div class="invites&#45;&#45;item__buttons clearfix">
                    <button class="btn btn-primary" v-if="isVideoInvite" @click="setVideoChat">
                        <span>
                            <span><svg xmlns="http://www.w3.org/2000/svg" class="svg" version="1.1"  x="0px" y="0px" viewBox="0 0 37 33" xml:space="preserve" enable-background="new 0 0 37 33"><switch><foreignObject requiredExtensions="http://ns.adobe.com/AdobeIllustrator/10.0/" x="0" y="0" width="1" height="1"/><g><path d="M18.6 1C8.9 1 1 7.7 1 15.9c0 4 1.8 7.6 4.8 10.2l-1.6 6 8.4-2.2c1.9 0.6 3.9 0.9 6 0.9 9.7 0 17.6-6.7 17.6-14.9S28.3 1 18.6 1zM28.5 20.3c0 0.6-0.7 1-1.2 0.7L24 19.1v0.6c0 1.7-1.3 3-3 3h-8.8c-1.7 0-3-1.3-3-3v-6.7c0-1.7 1.3-3 3-3h8.8c1.7 0 3 1.3 3 3v0.6l3.4-1.9c0.5-0.3 1.2 0.1 1.2 0.7V20.3z"/></g></switch></svg></span>
                            Video chat
                         </span>
                    </button>
                    <button class="btn btn-primary" @click="setTextChat" :class="{'only-text-invite':!isVideoInvite}">
                        <span>
                            <span><svg style="enable-background:new 0 0 487.7 487.7" xmlns="http://www.w3.org/2000/svg" xml:space="preserve" viewBox="0 0 487.7 487.7" version="1.1" y="0px" x="0px"><path d="m437 10.8h-386c-27.9 0-50.5 22.6-50.5 50.5v276c0 27.9 22.6 50.5 50.5 50.5h11.2v81.4c0 6.6 8 9.9 12.6 5.2l86.6-86.6h276c27.9 0 50.5-22.6 50.5-50.5v-276c0-26.5-23-49.2-51-49.2zm-281 215c-14.5 0-26.3-11.8-26.3-26.3s11.8-26.3 26.3-26.3 26.3 11.8 26.3 26.3-11 26-26 26zm88 0c-14.5 0-26.3-11.8-26.3-26.3s11.8-26.3 26.3-26.3c14.5 0 26.3 11.8 26.3 26.3s-12 26-26 26zm87 0c-14.5 0-26.3-11.8-26.3-26.3s11.8-26.3 26.3-26.3 26.3 11.8 26.3 26.3-11 26-26 26z"/></svg></span>
                            Text chat
                        </span>
                    </button>
                </div>
                <div class="life-indicator" :class="'line-' + invite.notificationId"></div>-->
  </li>
</template>

<script>
import ProgressBar from 'progressbar.js';

export default {
  name: 'InviteItem',

  props: {
    inviteBase: Object,
  },
  data() {
    return {
      inviteDestroyTimeout: null,
      woman: {
        id: '',
        profile: {
          name: '',
          avatar: '',
          avatarList: {},
        },
      },
    };
  },
  computed: {
    invite() {
      return this.inviteBase.params;
    },
    circleClass() {
      return 'circle-' + this.invite.notificationId;
    },
    lineClass() {
      return 'line-' + this.invite.notificationId;
    },
    config() {
      return this.$store.getters.settings.chat;
    },
    cameraHd() {
      return this.woman.profile.camera === 'hd';
    },
    isCameraActive() {
      console.log(this.woman.profile.camera !== 'no');
      return this.woman.profile.camera !== 'no';
    },
    isVideoInvite() {
      return this.inviteBase.params.inviteType === 'videoInvite';
    },
  },
  watch: {
    inviteBase: function () {
      clearTimeout(this.inviteDestroyTimeout);
      this.createTimer();
    },
  },
  beforeMount() {
    let allOnline = this.$store.getters.onlineWomanList;
    let senderId = this.invite.senderId;
    let found = false;
    allOnline.forEach((woman) => {
      if (woman.id === senderId) {
        this.woman.profile = woman.profile;
        this.woman.profile.camera = woman.camera;
        this.woman.id = senderId;
        found = true;
      }
    });
    if (!found) {
      this.$http
        .post('v1/profile/profile', {
          access_token: this.$store.getters.session.site_token,
          target_user_id: senderId,
        })
        .then((data) => {
          if (data.body.status) {
            let womanData = data.body.result;
            this.woman.id = senderId;
            this.woman.profile = {
              avatarList: womanData.avatar,
              name: womanData.name,
              camera: womanData.camera,
            };
          } else {
            //                console.log('Can not get user profile');
          }
        });
    }
  },
  mounted() {
    this.createTimer();
  },
  methods: {
    setVideoChat() {
      this.$store.dispatch('setCurrentChat', this.woman);
      this.$store.dispatch('changeChatType', 'video');
      this.$store.dispatch('destroyInvites', this.inviteBase);
    },
    setTextChat() {
      this.$store.dispatch('setCurrentChat', this.woman);
      this.$store.dispatch('changeChatType', 'text');
      this.$store.dispatch('destroyInvites', this.inviteBase);
    },
    messageFromShort(text) {
      // eslint-disable-next-line no-undef
      return EmojiPicker.prototype.appendUnicodeAsImageToElement(
        this.$refs.invite,
        text
      );
    },
    createTimer() {
      this.inviteDestroyTimeout = setTimeout(() => {
        this.$store.dispatch('destroyInvites', this.inviteBase);
        this.$socket.emit('invite_close', {
          token: this.$store.getters.session.chat_token,
          notificationId: this.invite.notificationId,
        });
      }, this.config.invite_show_time_for_man * 1000);

      const progressLine = new ProgressBar.Line(
        `#line-invite-${this.woman.id}`,
        {
          strokeWidth: 2,
          easing: 'linear',
          duration: this.config.invite_show_time_for_man * 1000,
          trailColor: '#fff',
          trailWidth: 2,
          svgStyle: { width: '100%', height: '100%', 'border-radius': '2px' },
          from: { color: '#FFEA82' },
          to: { color: '#45b143' },
          step(state, bar) {
            bar.path.setAttribute('stroke', state.color);
          },
        }
      );
      progressLine.set(1);
      progressLine.animate(0);

      const progressCircle = new ProgressBar.Circle(
        `#circle-invite-${this.woman.id}`,
        {
          strokeWidth: 4,
          easing: 'linear',
          duration: this.config.invite_show_time_for_man * 1000,
          trailColor: '#fff',
          trailWidth: 4,
          svgStyle: {
            width: '100%',
            height: '100%',
            transform: {
              prefix: true,
              value: 'scaleX(-1)',
            },
          },
          from: { color: '#FFEA82' },
          to: { color: '#45b143' },
          step(state, bar) {
            bar.path.setAttribute('stroke', state.color);
          },
        }
      );
      progressCircle.set(1);
      progressCircle.animate(0);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/variables-bootstrap';
@import '../../../assets/scss/vars';

.invites {
  &__body {
    .uname {
      font-size: 14px;
      font-weight: 600;
      > span {
        font-size: 11px;
        font-weight: 400;
      }
    }
    .invite {
      &__sender {
        max-height: 60px;
        display: flex;
        margin-bottom: 15px;

        .webcam {
          position: absolute;
          z-index: 3;
          bottom: 0;
          right: 0;
          background: #ffffff;
          color: #000000;
          border-radius: 10px 10px 0 0;
          padding: 2px;
          font-size: 12px;
        }

        &_text {
          width: 100%;
          margin-left: 10px;
          padding: 10px;
          background: #ffffff;
          color: #000000;
          position: relative;

          p {
            -webkit-column-width: 400px;
            -moz-column-width: 400px;
            column-width: 400px;
            -webkit-column-count: 1;
            -moz-column-count: 1;
            column-count: 1;
            overflow: hidden;
            font-size: 12px;
            height: 100%;
            margin: 0;
          }

          &:after {
            content: '';
            border: 5px solid transparent;
            border-right: 5px solid #fff;
            display: block;
            position: absolute;
            left: -10px;
            top: 15px;
          }
        }
      }

      &__controls {
        display: flex;
        justify-content: space-between;
        margin-bottom: 15px;
      }

      &__img {
        flex: 0 0 60px;
        height: 60px;
        position: relative;

        .circle-indicator {
          position: relative;
          z-index: 1;
        }

        .user-avatar {
          position: absolute;
          height: auto;
        }
      }

      &__text {
        flex: 1 1 auto;
        padding: 0 10px;
        margin-top: 2px;
        -webkit-column-width: 400px;
        -moz-column-width: 400px;
        column-width: 400px;
        -webkit-column-count: 1;
        -moz-column-count: 1;
        column-count: 1;
        overflow: hidden;

        a {
          color: $white;
          display: inline-block;
          margin-bottom: 5px;
          font-weight: 700;
          text-decoration: underline;
        }

        p {
          font-size: 14px;
        }
      }

      &__block-btn {
        flex: 0 0 40px;
        display: flex;
        align-items: center;

        .invite__btn {
          background: $red;
          border: 1px solid $red;
          font-size: 20px;
          padding: 5px 10px;
          display: flex;
          text-align: center;
          align-items: center;
          justify-content: center;
          color: $white;
          width: 38px;
          height: 38px;
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          border-radius: 50%;
          cursor: pointer;
          transition: 0.3s;
          outline: none;

          &:hover {
            background: lighten($red, 5%);
          }

          &:focus {
            box-shadow: 0 0 0 2px $white;
          }
        }
      }
    }
  }
}

.invites {
  &--item {
    padding: 20px;
    line-height: 1;
    background: rgba(#000, 0.75);
    color: #ffffff;

    &.ml {
      .invites--item__info-resp {
        display: none;
      }
    }

    &.fml {
      .circle-indicator {
        display: none;
      }
    }

    &__info {
      font-weight: bold;
      margin-bottom: 10px;

      a {
        color: #ffffff;

        &:hover,
        &:focus,
        &:active {
          color: #ffffff;
          text-decoration: none;
        }
      }
    }

    &__info-resp {
      color: #3e3f42;
      margin-bottom: 15px;
    }

    &__body {
      display: flex;
      align-items: flex-start;
      margin-bottom: 10px;

      .circle-indicator {
        position: relative;
        z-index: 3;
      }

      .img-container {
        position: relative;
        flex: 0 0 60px;
        cursor: pointer;
      }

      img {
        max-width: 100%;
        border-radius: 50%;
        position: absolute;
        top: 2px;
        left: 2px;
        width: calc(100% - 4px);
        z-index: 1;
        height: auto;
      }

      .webcam-icon {
        position: absolute;
        bottom: -4px;
        right: -7px;
      }

      .blockquote {
        margin-left: 15px;
        flex: 1 1;

        &--inner {
          position: relative;
          max-height: 80px;
          padding: 5px 10px;
          border-radius: 8px;
          overflow: hidden;
          color: #83858b;
          line-height: 1.77;
          background-color: #f1f1f1;
          word-break: break-all;
          /* Non standard for WebKit */
          word-break: break-word;
          hyphens: auto;

          &:before {
            content: '';
            position: absolute;
            top: 8px;
            right: 100%;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 4px 4px 4px 0;
            border-color: transparent #f1f1f1 transparent transparent;
          }
        }
      }
    }

    &__buttons {
      margin-bottom: 10px;
    }

    .btn {
      padding: 8px 10px;
      width: 45%;
      line-height: 1;
      font-size: 12px;
      font-weight: 300;

      &:disabled {
        opacity: 0.5 !important;
        .rbi {
          color: $white;
        }
        &:hover {
          &:after {
            height: 100%;
            width: 100%;
          }
        }
      }

      > span {
        text-align: left;
      }

      .rbi {
        margin-right: 0.5em;
        font-size: 16px;
        color: $red;
      }
    }

    .pseudo-button {
      position: absolute;
      top: calc(100% - 7px);
      left: 0;
      width: 100%;
      display: none;
      background: $brand-primary;
      align-items: center;
      justify-content: center;
      height: 36px;
      color: #fff;
      border-radius: 6px;
      background: linear-gradient(to bottom, #ffb34e 0%, #ff7f2e 100%);

      svg {
        margin-right: 5px;
      }
    }
  }
}

@media only screen and (max-width: 991px) {
  .invites {
    &__body {
      display: flex;
      justify-content: space-between;
      max-height: 50px;
      overflow: hidden;

      .invite {
        &__img {
          flex: 0 0 50px;
          height: 50px;
          position: relative;

          .circle-indicator {
            position: relative;
            z-index: 1;
            width: 100%;
            height: 100%;
          }

          .user-avatar {
            width: calc(100% - 4px);
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            border-radius: 50%;
            position: absolute;
            top: 2px;
            left: 2px;
          }
        }

        &__text {
          flex: 1 1 auto;
          padding: 0 10px;
          margin-top: 2px;
          -webkit-column-width: 400px;
          -moz-column-width: 400px;
          column-width: 400px;
          -webkit-column-count: 1;
          -moz-column-count: 1;
          column-count: 1;
          overflow: hidden;

          a {
            color: $white;
            display: inline-block;
            margin-bottom: 5px;
            font-weight: 700;
            text-decoration: underline;
          }

          p {
            font-size: 14px;
          }
        }

        &__block-btn {
          flex: 0 0 40px;
          display: flex;
          align-items: center;

          .invite__btn {
            background: $red;
            border: 1px solid $red;
            font-size: 20px;
            padding: 5px 10px;
            display: flex;
            text-align: center;
            align-items: center;
            justify-content: center;
            color: $white;
            width: 38px;
            height: 38px;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            border-radius: 50%;
            cursor: pointer;
            transition: 0.3s;
            outline: none;

            &:hover {
              background: lighten($red, 5%);
            }

            &:focus {
              box-shadow: 0 0 0 2px $white;
            }
          }
        }
      }
    }
  }

  .invites {
    &--item {
      background: rgba($dark, 0.9);
      margin-bottom: 0;

      &.fml {
        padding: 10px;
        margin-bottom: 10px;
      }

      &.ml {
        padding: 5px;

        .img-container {
          height: 40px;
          width: 40px;
          flex: 0 0 40px;

          &:before,
          &:after {
            display: none;
          }

          .circle-indicator {
            height: 40px;
            width: 40px;
          }

          img {
            position: absolute;
            top: 2px;
            left: 2px;
            height: 36px;
            width: 36px;
            border-radius: 50%;
          }
        }

        .webcam-icon {
          display: none;
        }

        .blockquote {
          display: none;
        }
      }

      &.fml {
        .pseudo-button {
          display: flex;
        }
      }

      .blockquote {
        &--inner {
          margin-bottom: 15px;
        }
      }

      &__body {
        margin-bottom: 0;
      }

      &__buttons {
        display: none;
      }

      .life-indicator {
        display: none;
      }
    }
  }
}
</style>
