<template>
  <button
    class="man-camera-btn"
    type="button"
    :title="currentButtonAction"
    @click.prevent="cameraOn ? cameraOffHandler() : cameraOnHandler()"
  >
    <svg
      class="rbi"
      style="display: flex; justify-content: center; width: 100%"
      :title="currentButtonAction"
      :style="{
        fill: cameraOn ? '#64d400' + '!important' : '#ffffff' + '!important',
      }"
    >
      <use xlink:href="/static/assets/images/rbi-icon.svg#rbi-webcam" />
    </svg>
    <span style="display: flex; justify-content: center; width: 100%">
      <span v-if="!cameraOn">Turn on</span>
      <span v-else>Turn off</span>
    </span>
  </button>
</template>

<script>
// import RbButton from '../../shared/Button';
import { socket_url } from '../../../config/config-production';

export default {
  name: 'Camera',

  components: {
    //RbButton
  },
  data: () => ({
    disabledCameraButton: false,
    cameraInProcess: false,
  }),
  computed: {
    hasCurrentChat() {
      return this.$store.getters.dialogsCurrent !== null;
    },
    cameraOn() {
      return this.$store.getters.getUserCameraIsActive;
    },
    currentButtonAction() {
      return this.cameraOn ? 'Turn your camera off' : 'Turn your camera on';
    },
    isVideoChat() {
      return this.$store.getters.dialogs.current
        ? this.$store.getters.dialogs.current.user.chat_type === 'video'
        : false;
    },
    userBalance() {
      return this.$store.getters.myBalance;
    },
    connectedToStreamServer() {
      return this.$store.getters.getStreamServerConnected;
    },
    userId() {
      return this.$store.getters.user.id;
    },
    canTurnCameraOn() {
      const { price_text_chat, price_video_chat, price_man_video_stream } =
        this.$store.getters.settings.chat;
      const needMoney = this.isVideoChat
        ? price_video_chat + price_text_chat + price_man_video_stream
        : price_text_chat + price_man_video_stream;
      return this.userBalance >= needMoney;
    },
  },
  watch: {
    connectedToStreamServer: function (newVal, oldVal) {
      if (newVal !== oldVal && newVal === false) {
        this.$store.commit('setUserCameraActive', false);
      }
    },
  },
  methods: {
    cameraOffHandler() {
      this.$store.commit('setUserCameraActive', false);
    },
    cameraOnHandler() {
      if (!this.hasCurrentChat) {
        this.$store.commit('addAlert', {
          type: 'info',
          text: `Open any dialog to turn camera on.`,
          info: 'Camera',
        });
      }
      if (
        this.canTurnCameraOn &&
        this.connectedToStreamServer &&
        this.hasCurrentChat
      )
        this.$http
          .post(socket_url + '/api/check_stream_exists', {
            token: this.$store.getters.token.chat_token,
            userId: this.userId,
          })
          .then(async (res) => {
            if (res.body.exists === false) {
              this.$store.commit('setUserCameraActive', true);
            } else {
              const err = `Get user media fail: Already exists.`;
              throw new Error(err);
            }
          })
          .catch((err) => {
            this.$store.commit('updateDuplicateUserMediaModal', {
              open: true,
            });
            throw new Error(err);
          });
    },
  },
};
</script>

<style scoped>
.man-camera-btn {
  border: none;
  background: transparent;
  display: flex;
  flex-direction: column;
  justify-content: center;
  white-space: nowrap;
}
.man-camera-btn:active {
  outline: none !important;
  box-shadow: none !important;
}
.man-camera-btn:focus {
  outline: none !important;
  box-shadow: none !important;
}
.man-camera-btn:disabled {
  color: rgba(200, 200, 200, 0.65);
}
</style>
