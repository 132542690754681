<template>
  <span class="zodiac-icon"> - </span>
</template>

<script>
export default {
  name: 'UnsetZodiac',
};
</script>

<style></style>
