<template>
  <div>
    <form
      class="send-form man_sendbox stickySendBox"
      :class="{ 'no-invites': !invitesLen }"
      @submit.prevent.stop="sendMessage"
    >
      <div class="form-wrap">
        <div class="content_text">
          <p
            v-if="message.length > maxMessageLength"
            class="error_message"
            style="
              background-color: #fff;
              color: rgb(226, 25, 25);
              border: 1px solid #fff;
              border-radius: 20%;
              padding-left: 2px;
              padding-right: 2px;
            "
          >
            Maximum message length {{ maxMessageLength }} characters
          </p>
          <p
            v-if="message.length > 0"
            class="text_right"
            style="
              background-color: #fff;
              border-radius: 20%;
              padding-left: 2px;
              padding-right: 2px;
            "
            v-text="`${message.length}/${maxMessageLength}`"
          ></p>
        </div>

        <div class="content-editable">
          <div class="input-wrapper-man">
            <div class="input-buttons-wrapper">
              <div
                class="btn-attach attach-button"
                @click.prevent="attachOpen = true"
              >
                <div class="input-button-chat">
                  <div class="btn-black btn-send-form">
                    <svg class="rbi">
                      <use
                        xlink:href="/static/assets/images/rbi-icon.svg#rbi-clip"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>

            <content-editable
              ref="messageInput"
              v-model="message"
              class="text-input"
              :clear="clear"
              :placeholder="'Type message to start chat'"
              @focus-input="$emit('focus-input')"
              @blur-input="$emit('blur-input')"
              @submit="sendMessage($event)"
            ></content-editable>

            <div class="input-buttons-wrapper">
              <div class="send-button" @click="sendMessage($event)">
                <div class="btn">
                  <div class="btn-black btn-send-form">
                    <svg class="rbi d-block">
                      <use
                        xlink:href="/static/assets/images/rbi-icon.svg#rbi-paperplane"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <photo-upload
        :user-id="receiver"
        :open="attachOpen"
        @close="attachOpen = false"
      ></photo-upload>
    </form>
  </div>
</template>

<script>
import ContentEditable from '../../../shared/ContentEditable.vue';
import PhotoUpload from '../../../shared/modals/PhotoUpload.vue';

export default {
  name: 'ChatInput',
  components: {
    PhotoUpload,
    ContentEditable,
  },
  props: {},
  scroll: null,
  data() {
    return {
      isIphone: false,
      message: '',
      maxMessageLength: 240,
      placeholder: true,
      clear: false,
      attachOpen: false,
    };
  },
  computed: {
    currentUser() {
      return this.$store.getters.dialogs.current
        ? this.$store.getters.dialogs.current.user
        : null;
    },
    isCollocutorOnline() {
      if (this.currentUser) {
        const foundUser = this.$store.getters.online.filter(
          (user) => this.currentUser.id === user.id
        );
        if (foundUser.length > 0) {
          return true;
        } else return false;
      } else return false;
    },
    receiver() {
      return this.$store.getters.dialogs.current.user.id;
    },
    invitesLen() {
      return this.$store.getters.invites.length;
    },
    isActive() {
      return this.$store.getters.dialogs.current.active;
    },
    isVideoChat() {
      return this.$store.getters.dialogs.current.user.chat_type === 'video';
    },
    isTextChat() {
      return this.$store.getters.dialogs.current.user.chat_type === 'text';
    },
    isVideoDoubleChat() {
      return (
        this.$store.getters.dialogs.current.user.chat_type === 'videoDouble'
      );
    },
  },
  watch: {
    receiver() {
      this.clearMessage();
    },
  },
  mounted() {
    this.clear = !this.clear;
  },
  methods: {
    triggerInputBlur() {
      this.$refs.messageInput.triggerBlur();
    },
    sendMessage() {
      if (this.message.length > 0) {
        let typeMessage = 'text';

        if (this.isVideoChat) {
          typeMessage = 'video';
        }
        if (this.isTextChat) {
          typeMessage = 'text';
        }

        let message = this.message;
        if (message.length <= this.maxMessageLength) {
          this.message = '';
          this.clearMessage();

          if (
            this.$store.getters.dialogs.current.user.chat_status !== 'active' &&
            this.$store.getters.dialogs.current.messages &&
            this.$store.getters.dialogs.current.messages.length === 0
          ) {
            let receiver = this.receiver;
            let token = this.$store.getters.token.site_token;

            let msgObj = {
              receiver: receiver,
              text: message,
              type: typeMessage,
              token: token,
              isChatActive: this.isActive, // need to reactivate chat from node.js on disconnect
              isCompanionOnline: this.isCollocutorOnline, // not to start on node.js if offline
            };

            this.$socket.emit('message', msgObj);
            if (!this.isCollocutorOnline) {
              this.$store.commit('addAlert', {
                type: 'error',
                text: 'Collocutor is offline. Messages will not be delivered to offline users',
                info: 'load rec gifts',
              });
            }
          } else {
            let msgObj = {
              receiver: this.$store.getters.dialogs.current.user.id,
              text: message,
              type: typeMessage,
              token: this.$store.getters.token.site_token,
              isChatActive: this.isActive, // need to reactivate chat from node.js on disconnect
              isCompanionOnline: this.isCollocutorOnline, // not to start on node.js if offline
            };
            this.$socket.emit('message', msgObj);

            if (!this.isCollocutorOnline) {
              this.$store.commit('addAlert', {
                type: 'error',
                text: `${this.currentUser.profile.name} is offline. Messages will not be delivered to offline users`,
                info: 'Companion offline ',
              });
            }
          }
        }
      }
      // this.$refs.messageInput.$el.focus()
    },
    clearMessage() {
      this.clear = !this.clear;
    },
  },
};
</script>

<style lang="scss">
.send-form {
  width: 100%;
  z-index: 100;
  padding-left: 5px;
  padding-right: 5px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-bottom: calc(1em + env(safe-area-inset-bottom));
}

.form-wrap {
  z-index: 1000;
}

.input-active {
  .send-form {
    background: #ffffff;
  }
}

.form-wrap {
  width: 100% !important;
}

.input-wrapper-man {
  display: grid;
  grid-template-columns: 15% 70% 15%;
  height: 100%;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(23, 23, 23, 0.15);
  background-color: rgba(255, 255, 255, 1);
  padding: 5px;

  .text-input {
    height: 100%;
    width: 100%;

    border: 1px solid rgba(227, 227, 227, 0.35);
    border-radius: 5px 5px;
    padding: 0 5px;

    .message {
      font-size: 17px !important;
    }
  }
}

.input-wrapper-man {
  .input-buttons-wrapper {
    height: 100% !important;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    padding: 15px 0;
  }

  .send-button,
  .attach-button {
    height: 30px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    flex-direction: column;
    .btn {
      background: transparent;
      display: flex !important;
      justify-content: center !important;
      align-items: center;

      &:disabled svg {
        fill: #000000a8;
      }
      &:active {
        box-shadow: none !important;
      }
    }
  }

  .send-button {
    .rbi {
      color: #e21919 !important;
      width: 25px;
      height: 25px;
    }
  }

  .attach-button {
    .rbi {
      color: #e21919 !important;
      width: 17px;
      height: 17px;
    }
  }
}

.input-wrapper-man .btn-send-form {
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 450px) {
  .input-wrapper-man {
    .input-buttons-wrapper {
      padding: 7px 0;
    }
    grid-template-columns: 15% 70% 15%;
    .send-button,
    .attach-button {
      .btn {
        padding: 10px 0 !important;
      }
    }
  }
}

@media screen and (max-height: 425px) and (max-width: 992px) and (min-width: 425px) {
  .input-wrapper-man {
    .input-buttons-wrapper {
      padding: 2px 0;
    }
  }
}

.content_text {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /*min-height: 26px;*/
  p {
    margin: 0;
  }
  .error_message {
    font-size: 12px;
    color: red;
  }
  .text_right {
    margin: 0 0 0 auto;
    text-align: right;
    font-size: 12px;
  }
}
</style>
