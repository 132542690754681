<template>
  <div
    style="
      position: absolute;
      top: 0;
      width: 100%;
      display: flex;
      justify-content: flex-start;
      font-size: 1rem;
    "
  >
    <span
      v-if="(chatType === 3 || chatType === 4) && chatActivated"
      style="
        text-align: center;
        background: rgba(250, 44, 37, 0.47);
        color: white;
        padding: 5px;
        display: flex;
      "
    >
      {{ labelActiveText }}
      <span
        style="
          display: flex;
          flex-direction: column;
          justify-content: center;
          margin-left: 5px;
        "
      >
        <svg class="rbi">
          <use
            xlink:href="/static/assets/images/rbi-icon.svg#rbi-eye-watch"
          /></svg
      ></span>
    </span>
    <span
      v-else-if="chatType !== 0"
      style="
        text-align: center;
        background: rgba(0, 0, 0, 0.34);
        color: white;
        padding: 5px;
      "
      >{{ labelNotActiveText }}</span
    >
  </div>
</template>

<script>
export default {
  name: 'WatchIndicator',
  props: {
    chatType: { type: Number, default: 0 },
    chatActivated: { type: Boolean, default: false },
  },
  computed: {
    isWoman() {
      return this.$store.getters.user.type === 'woman';
    },
    collocutorName() {
      return this.$store.getters.dialogs.current
        ? this.$store.getters.dialogs.current.user.profile.name
        : '';
    },
    labelActiveText() {
      if (this.isWoman) {
        return 'Вас смотрят';
      } else {
        return `${this.collocutorName} is watching your broadcast.`;
      }
    },
    labelNotActiveText() {
      if (this.isWoman) {
        return 'Вас не смотрят';
      } else {
        return `${this.collocutorName}`;
      }
    },
  },
};
</script>

<style scoped></style>
