<template>
  <div class="attach-box">
    <div class="attach-box--inner d-flex">
      <rb-button
        v-show="photos.length < attachLimit"
        @click.prevent.native="modalOpen = true"
      >
        <svg class="rbi">
          <use xlink:href="/static/assets/images/rbi-icon.svg#rbi-clip"></use>
        </svg>
        <span>Attach</span>
      </rb-button>
    </div>
    <photos-modal
      :user-id="userId"
      :limit="attachLimit"
      :passed-count="photos.length"
      :open="modalOpen"
      @close="modalOpen = false"
      @selected="getSelectedPhotos($event)"
    ></photos-modal>
  </div>
</template>

<script>
import PhotosModal from '../PhotosModal.vue';
import RbButton from '../../Button';
export default {
  components: {
    PhotosModal,
    RbButton,
  },
  props: {
    clear: Number,
    userId: Number,
    photos: Array,
  },
  data() {
    return {
      modalOpen: false,
      attachLimit: 3,
    };
  },
  computed: {},
  watch: {
    photos(val) {
      this.$emit('attached', val);
    },
    clear() {
      this.photos = [];
    },
  },
  methods: {
    getSelectedPhotos(data) {
      this.photos = this.photos.concat(data);
    },
    unAttachPhoto(index) {
      this.photos.splice(index, 1);
    },
  },
};
</script>
