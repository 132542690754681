<template>
  <div
    id="dialogBox"
    class="dialog-box-new-woman"
    :class="{ 'dialog-box-active-input': inputActive }"
  >
    <div class="top-box">
      <AbsoluteVideoContainer
        v-show="
          (chatType !== 4 && chatType !== 2) ||
          (chatType === 4 && !isActive) ||
          (chatType === 2 && !isActive)
        "
        :chat-type="chatType"
      />

      <main-user-area
        v-if="chatType !== 4 && chatType !== 2 && hasCurrentChat"
        :chat-type="chatType"
      ></main-user-area>

      <!--      <controls
        v-if="chatType !== 2 && chatType !== 4 && hasCurrentChat"
        :chat-type="chatType"
      /> -->

      <VideoContainer
        v-show="(chatType === 2 || chatType == 4) && hasCurrentChat"
      />
    </div>

    <div v-if="currentChatMessages.length > 0" class="messages-box">
      <SimpleBar
        ref="simplebarMessages"
        data-simplebar-auto-hide="false"
        class="simplebar-messages"
        body-scroll-lock-ignore
      >
        <div>
          <div v-show="!noMoreMessages" class="load-more-messages-button">
            <rb-button
              style="height: 45px; width: 130px; padding: 0"
              @click.native="loadMoreHandler"
            >
              <div v-show="preloader">
                <div
                  style="width: 20px; height: 20px"
                  class="ph-mes__loader loader"
                ></div>
              </div>
              <div v-show="!preloader">Load 20 more</div>
            </rb-button>
          </div>
          <message-template
            v-for="message in messages"
            :key="message.key"
            :message="message"
            @msg="scrollToBottom($event)"
          />
          <div id="crutch_dialog"></div>
        </div>
      </SimpleBar>
    </div>
    <div
      v-else
      style="
        height: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        text-align: center;
        color: slategray;
      "
    >
      No messages
    </div>
  </div>
</template>

<script>
import RbButton from '../shared/Button';
import messageTemplate from './chat-box/items/message/template.vue';
import AbsoluteVideoContainer from '../shared/video/AbsoluteVideoContainer';
/*import Controls from './chat-box/Controls';*/
import VideoContainer from '../shared/video/VideoContainer';
import mainUserArea from './chat-box/main-user-area';

export default {
  name: 'DialogsArea',
  components: {
    messageTemplate,
    RbButton,
    AbsoluteVideoContainer,
    /*    Controls,*/
    VideoContainer,
    'main-user-area': mainUserArea,
  },
  computed: {
    isIphone() {
      return this.$store.getters.device.isIOS;
    },

    currentChat() {
      return this.$store.getters.dialogsCurrent;
    },

    currentChatMessages() {
      return this.currentChat.messages ? this.currentChat.messages : [];
    },

    currentChatMessagesLength() {
      return this.currentChatMessages.length;
    },

    isOnlineOnVideoServer() {
      if (
        this.$store.getters.activeUsersList.length > 0 &&
        this.hasCurrentChat
      ) {
        const data = this.$store.getters.activeUsersList;
        let isOnline = false;

        data.forEach((user) => {
          if (user.userId === this.collocutor) {
            isOnline = true;
          }
        });

        return isOnline;
      } else return false;
    },

    activeUsers() {
      return this.$store.getters.activeUsersList;
    },

    hasCurrentChat() {
      return this.$store.getters.dialogsCurrent !== null;
    },

    collocutor() {
      return this.$store.getters.dialogs.current
        ? this.$store.getters.dialogs.current.user.id
        : null;
    },

    collocutorCameraOn() {
      if (this.activeUsers && this.hasCurrentChat) {
        const user = this.activeUsers.find(
          (user) => user.userId === this.collocutor && user.userCameraActive
        );
        if (user) {
          return user.userCameraActive;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },

    connectedToStreamServer() {
      return this.$store.getters.getStreamServerConnected;
    },
    connectedToSocketIO() {
      return this.$store.getters.connectedToSocket === true;
    },

    cameraOn() {
      return this.$store.getters.getUserCameraIsActive;
    },

    chatType() {
      const dialog = this.$store.getters.dialogs.current;
      const userCameraOn = this.cameraOn;
      const collocutorCameraOn = this.collocutorCameraOn;
      let type;
      if (dialog) {
        if (!this.connectedToStreamServer || !this.connectedToSocketIO) {
          type = 1;
        } else {
          switch (this.$store.getters.dialogs.current.user.chat_type) {
            // Types:
            // 1 - standart text chat
            // 2 - man stream
            // 3 - woman stream
            // 4 - woman and man stream

            case 'video':
              if (collocutorCameraOn) {
                type = 4;
              } else if (userCameraOn) {
                // If woman camera on
                type = 3;
              } else type = 1;

              break;
            case 'text':
              if (collocutorCameraOn) {
                // Check man camera on
                type = 2;
              } else type = 1;
              break;
            default:
              type = 1;
              break;
          }
        }
      } else {
        type = 0;
      }
      return type;
    },

    inputActive() {
      return this.$store.getters.chatInputActive;
    },

    messages() {
      if (this.$store.getters.dialogs.current === null) {
        return [];
      } else {
        return this.$store.getters.dialogs.current.messages;
      }
    },

    preloader() {
      return this.$store.getters.loadingMoreMessages ? true : false;
    },
    noMoreMessages() {
      return this.$store.getters.dialogs.current.noMoreMessages;
    },
    user() {
      return this.$store.getters.dialogs.current.user.id;
    },
    firstMessageInDialog() {
      return this.$store.getters.dialogs.current.messages[0];
    },
    isActive() {
      return this.$store.getters.dialogs.current !== null;
    },
  },
  watch: {
    currentChatMessagesLength(newVal) {
      if (newVal !== 0) {
        setTimeout(() => {
          this.scrollToBottom();
        }, 600);
      }
    },
  },
  mounted() {
    if (
      this.currentChatMessagesLength &&
      this.currentChatMessagesLength !== 0
    ) {
      setTimeout(() => {
        this.scrollToBottom();
      }, 600);
    }
  },
  methods: {
    loadMoreHandler() {
      if (!this.preloader && !this.noMoreMessages) {
        const data = {
          user: this.user,
          firstMessageInDialog: this.firstMessageInDialog,
          limit: 20,
        };
        this.$store.dispatch('loadMoreMessages', data);
      }
    },
    scrollToBottom(element) {
      const e = element ? element : document.getElementById('crutch_dialog');

      if (
        this.$refs.simplebarMessages &&
        this.$refs.simplebarMessages.SimpleBar &&
        this.currentChat.messages.length
      ) {
        // remove error
        let container =
          this.$refs.simplebarMessages.SimpleBar.getScrollElement();
        let el;

        if (e) {
          if (this.preloader) {
            const divider = e.parentNode.querySelector('#msgDivider');
            el = divider;
          } else {
            el = e.parentNode.lastElementChild;
          }
        } else {
          if (this.preloader) {
            const divider =
              container.children[0].children[0].querySelector('#msgDivider');
            el = divider;
          } else {
            el = container.children[0].children[0].lastElementChild;
          }
        }
        /*if (e) {
         el = e.parentNode.lastElementChild
       } else {
         el = container.children[0].children[0].lastElementChild
       }*/
        if (el) {
          // eslint-disable-next-line no-undef
          $(container).scrollTo(el, 0);
        }
      }
    },
  },
};
</script>

<style lang="scss">
.dialog-box-new-woman {
  background: #ffffff;
  position: relative;
  display: flex;
  flex-direction: column;
  /* display: grid;
  grid-template-rows: 35% 65%;*/
  padding: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;

  .top-box {
    padding: 0.4em;
    width: 100%;
    // overflow: hidden;
  }

  @media screen and (max-width: 450px) {
    grid-template-rows: 40% 60%;
  }

  @media screen and (min-width: 992px) {
    .top-box {
      padding: 0.6em;
    }
    grid-template-rows: 40% 60% !important;
  }
}

.video-chat {
  .dialog-box-new-woman {
    .top-box {
      height: 65%;
    }
    @media screen and (max-width: 992px) {
      .top-box {
        height: 55% !important;
      }
    }
    @media screen and (max-height: 425px) and (max-width: 992px) and (min-width: 425px) {
      .top-box {
        height: 70% !important;
      }
    }
  }
}

@media screen and (max-width: 450px) {
  .dialog-box-active-input {
    grid-template-rows: 100% !important;
    .top-box {
      display: none;
    }
  }
}

/*.ios-safari {
  .dialog-box-active-input {
    grid-template-rows: 100% !important;
    .top-box {
      display: none;
    }
  }
}*/

.simplebar-messages {
  position: relative;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
}

.messages-box {
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.iphone-horizontal-controls {
  display: none;
}

@media screen and (max-width: 992px) {
  .chat-wrapper {
    border: none;
  }
}

@media screen and (max-height: 425px) and (max-width: 992px) and (min-width: 425px) {
  .iphone-horizontal-profile-box {
    display: none;
  }
  .iphone-horizontal-controls {
    display: block;
  }
}

@media screen and (max-width: 992px) {
  .iphoneChatBox {
    margin-bottom: 15vh !important;
  }
  .otherChatBox {
    /*height: 83vh;
  overflow-y: hidden;*/
  }
}
.load-more-messages-button {
  justify-content: center;
  display: flex;
  // margin-bottom: 20px;
}

@media screen and (max-height: 425px) and (max-width: 992px) and (min-width: 425px) {
  .load-more-messages-button {
    justify-content: center;
    display: flex;
    margin-bottom: 5px;
    margin-top: 5px;
  }
  .load-more-messages-button button {
    color: #000000;
    border-color: #000000;
    background: white;
    height: 7vh !important;
  }
}
</style>
