<template>
  <span class="zodiac-icon">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32.001"
      height="32.001"
      viewBox="0 0 32.001 32.001"
    >
      <path
        d="M29.824 14.867h-7.59c.7-6.977 4.605-9.606 4.834-9.754 1.017-.626 1.336-1.956.72-2.98-.626-1.027-1.962-1.35-2.99-.73-.27.16-6.146 3.85-6.937 13.464h-4.9C12.167 5.252 6.29 1.564 6.023 1.402c-1.02-.616-2.34-.29-2.966.72-.63 1.012-.317 2.348.69 2.99.183.118 4.132 2.735 4.835 9.755H2.176C.973 14.867 0 15.84 0 17.044c0 1.2.973 2.176 2.176 2.176H8.57c-.73 6.222-4.488 7.36-4.635 7.4-1.168.28-1.89 1.456-1.608 2.624.24.997 1.13 1.67 2.114 1.67.17 0 .34-.022.51-.062.31-.076 7.114-1.83 8.004-11.636h4.914c.89 9.807 7.694 11.56 8.004 11.636.17.04.34.06.505.06.967 0 1.836-.655 2.09-1.636.297-1.148-.4-2.33-1.546-2.646-.175-.05-3.936-1.188-4.666-7.41h7.57c1.203 0 2.177-.975 2.177-2.176 0-1.203-.973-2.177-2.176-2.177z"
      />
    </svg>
  </span>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  computed: {},
  methods: {},
};
</script>

<style></style>
