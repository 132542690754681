<template>
  <span class="zodiac-icon">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
    >
      <path
        d="M30.162 6.688c-2.404-3.18-6.688-4.528-10.425-3.28-1.597.536-2.883 1.512-3.827 2.792-.925-1.166-2.147-2.06-3.65-2.563-3.734-1.25-8.018.096-10.422 3.277-2.332 3.09-2.022 6.004-1.354 7.906.736 2.09 2.4 4.116 3.634 4.426.98.24 1.98-.348 2.227-1.332.215-.854-.208-1.723-.97-2.095-.31-.27-1.404-1.46-1.65-3.105-.188-1.24.155-2.413 1.043-3.588 1.444-1.91 4.104-2.752 6.326-2.01 1.98.664 3.07 2.452 3.07 5.032v15.07c0 1.015.82 1.834 1.836 1.834s1.836-.82 1.836-1.835V11.923c0-2.582 1.09-4.37 3.07-5.034 2.225-.742 4.882.1 6.325 2.01.89 1.176 1.23 2.35 1.044 3.587-.248 1.646-1.34 2.836-1.65 3.104-.762.374-1.186 1.24-.97 2.097.247.985 1.243 1.58 2.228 1.332 1.235-.312 2.896-2.34 3.634-4.427.668-1.904.978-4.82-1.354-7.905z"
      />
    </svg>
  </span>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  computed: {},
  methods: {},
};
</script>

<style></style>
