<template>
  <div class="rounded-box">
    <button class="btn btn-primary" @click="switchToTextInvite">
      <span v-if="!isStreamingCamera" style="color: white">
        <svg class="rbi">
          <use
            xlink:href="/static/assets/images/rbi-icon.svg#rbi-comment-text"
          ></use>
        </svg>
      </span>
      <span v-else style="color: white">
        <svg class="rbi">
          <use xlink:href="/static/assets/images/rbi-icon.svg#rbi-video"></use>
        </svg>
      </span>
    </button>
    <button
      class="btn btn-primary active"
      :disabled="!cameraOn"
      :class="{
        'man-is-watching-stream':
          (chatType === 3 || chatType === 4) && isActive,
      }"
      @click="switchToVideoInvite"
    >
      <span v-if="!isStreamingCamera">
        <svg class="rbi">
          <use xlink:href="/static/assets/images/rbi-icon.svg#rbi-webcam" />
        </svg>
      </span>
      <span v-else>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="svg"
          version="1.1"
          x="0px"
          y="0px"
          viewBox="0 0 90.5 81.5"
          xml:space="preserve"
          enable-background="new 0 0 90.5 81.5"
        >
          <switch>
            <g>
              <path
                d="M28.9 49.5c-6.1 0-11.1-5-11.1-11.1v-1.8h-5.4c-5.7 0-10.3 4.6-10.3 10.3v23c0 5.7 4.6 10.3 10.3 10.3h30.2c5.7 0 10.3-4.6 10.3-10.3V68l11.5 6.6c1.9 1.1 4.2-0.3 4.2-2.4V49.5H28.9z"
              />
              <path
                d="M22.6 37.3V9.6c0-2.1 2.3-3.5 4.2-2.4l11.5 6.6v-1.9c0-5.7 4.6-10.3 10.3-10.3h30.2c5.7 0 10.3 4.6 10.3 10.3v23c0 5.7-4.6 10.3-10.3 10.3H48.6c-5.7 0-10.3-4.6-10.3-10.3V33l-11.5 6.6C24.9 40.7 22.6 39.4 22.6 37.3L22.6 37.3zM22.6 37.3"
              />
            </g>
          </switch>
        </svg>
      </span>
    </button>
    <!--<button
      id="videochat"
      class="btn btn-primary"
      @click="switchToDoubleVideoChat"
      :class="{ active: !isVideoDoubleChat }"
      :disabled="!cameraOn"
    >
      <span>
        <svg class="rbi">
          <use
            xlink:href="/static/assets/images/rbi-icon.svg#rbi-double_video"
          ></use>
        </svg>
      </span>
    </button>-->
  </div>
</template>

<script>
export default {
  name: 'ChatTypeSwitch',
  props: {
    remoteVideo: { type: Boolean, default: false },
    chatType: { type: Number, default: 0 },
  },
  computed: {
    connectedToStreamServer() {
      return this.$store.getters.getStreamServerConnected;
    },
    user() {
      return this.$store.getters.dialogs.current
        ? this.$store.getters.dialogs.current.user
        : null;
    },
    isVideoDoubleChat() {
      return (
        this.$store.getters.dialogs.current &&
        this.$store.getters.dialogs.current.user.chat_type === 'videoDouble'
      );
    },
    isVideoChat() {
      return (
        this.$store.getters.dialogs.current &&
        this.$store.getters.dialogs.current.user.chat_type === 'video'
      );
    },
    isTextChat() {
      return (
        this.$store.getters.dialogs.current &&
        this.$store.getters.dialogs.current.user.chat_type === 'text'
      );
    },
    isActive() {
      return (
        this.$store.getters.dialogs.current &&
        this.$store.getters.dialogs.current.user.chat_status === 'active'
      );
    },
    cameraOn() {
      return this.$store.getters.getUserCameraIsActive;
    },
    isStreamingCamera() {
      if (this.$store.getters.activeUsersList.length > 0 && this.user) {
        const data = this.$store.getters.activeUsersList;
        let hasStream = false;

        data.forEach((user) => {
          if (user.userId === this.user.id && user.userCameraActive) {
            hasStream = true;
          }
        });

        return hasStream;
      } else return false;
    },
  },
  methods: {
    switchToVideoInvite() {
      /* if (!this.isActive && this.cameraOn && !this.isStreamingCamera && this.connectedToStreamServer) {
        this.$store.dispatch('changeChatType', 'video')
        this.$socket.emit('change_chat_type', {
          token: this.$store.getters.session.chat_token,
          new_chat_type: 'videoChat',
          roomId: this.$store.getters.dialogs.current.roomId,
        })
      }*/
    },
    switchToTextInvite() {
      /* if (!this.isActive) {
        this.$store.dispatch('changeChatType', 'text')
        this.$socket.emit('change_chat_type', {
          token: this.$store.getters.session.chat_token,
          new_chat_type: 'textChat',
          roomId: this.$store.getters.dialogs.current.roomId,
        })
      }*/
    },
  },
};
</script>

<style scoped>
.btn {
  cursor: initial;
}
.btn:disabled {
  background: #d9d9d9 !important;
  color: #ffffff !important;
}
.man-is-watching-stream {
  background-color: rgba(250, 44, 37, 0.76) !important;
  border: rgba(225, 207, 10, 0.87) !important;
  color: white !important;

  animation: glowing 1.5s infinite;
}

@keyframes glowing {
  0% {
    opacity: 0.4;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.4;
  }
}
</style>
