<template>
  <div class="modal fade money-error" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
      <div class="modal-content text-center">
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
        <h4 class="modal-title">Credits level is low</h4>
        <div class="modal-body">
          <p>You can choose your credit plan on credit page</p>
        </div>
        <a href="#" class="btn btn-success" title="Buy credits">
          Buy credits
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 639.5 524">
            <path
              class="st0"
              fill="#FFF"
              d="M639.5 101.5H538V0h-83v101.5H353.5v83H455v101.6h83V184.5h101.5"
            ></path>
            <g fill="#FFF">
              <path class="st0" d="M173.2 419.5h102.4v34.1H173.2z"></path>
              <path
                class="st0"
                d="M480.4 318v161.2c0 2.3-.8 4.3-2.5 6-1.7 1.7-3.7 2.5-6 2.5H45.2c-2.3 0-4.3-.8-6-2.5-1.7-1.7-2.5-3.7-2.5-6V317.1h385.4V214.7H36.7V155c0-2.3.8-4.3 2.5-6 1.7-1.7 3.7-2.5 6-2.5h271.3v-34.1H45.2c-11.7 0-21.8 4.2-30.1 12.5-8.4 8.4-12.5 18.4-12.5 30.1v324.3c0 11.7 4.2 21.8 12.5 30.1 8.4 8.4 18.4 12.5 30.1 12.5h426.7c11.7 0 21.8-4.2 30.1-12.5 8.4-8.4 12.5-18.4 12.5-30.1V318h-34.1z"
              ></path>
              <path class="st0" d="M70.8 419.5h68.3v34.1H70.8z"></path>
            </g>
          </svg>
        </a>
        <div>
          <small>New window will be opened</small>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BuyCreditModal',
  components: {},
  props: {},
  data() {
    return {};
  },
  computed: {},
  methods: {},
};
</script>

<style></style>
