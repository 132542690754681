<template>
  <RbButton
    class="btn-white woman-camera-button"
    type="button"
    :disabled="disabledCameraButton"
    :title="currentButtonAction"
    :class="{ 'camera-on': !cameraOn }"
    @click.native="cameraOn ? cameraOffHandler() : cameraOnHandler()"
  >
    <span>
      <svg v-show="!cameraInProcess" class="rbi">
        <use xlink:href="/static/assets/images/rbi-icon.svg#rbi-webcam" />
      </svg>
      <span v-if="!cameraOn">
        <span v-show="!cameraInProcess">Вкл</span>
        <span v-show="cameraInProcess">
          <camera-spinner></camera-spinner>
        </span>
      </span>
      <span v-else>
        <span v-show="!cameraInProcess">Выкл</span>
        <span v-show="cameraInProcess">
          <camera-spinner></camera-spinner>
        </span>
      </span>
    </span>
  </RbButton>
</template>

<script>
import RbButton from '../../shared/Button';
import CameraSpinner from '../items/camera-spinner';
import { socket_url } from '../../../config/config-production';

export default {
  name: 'Camera',

  components: { CameraSpinner, RbButton },
  data: () => ({
    currentButtonAction: 'Camera on/off',
    disabledCameraButton: false,
    cameraInProcess: false,
  }),
  computed: {
    hasCurrentChat() {
      return this.$store.getters.dialogsCurrent !== null;
    },
    cameraOn() {
      return this.$store.getters.getUserCameraIsActive;
    },
    connectedToStreamServer() {
      return this.$store.getters.getStreamServerConnected;
    },
    userId() {
      return this.$store.getters.user.id;
    },
  },

  watch: {
    connectedToStreamServer: function (newVal, oldVal) {
      if (newVal !== oldVal && newVal === false) {
        this.$store.commit('setUserCameraActive', false);
      }
    },
  },
  methods: {
    cameraOffHandler() {
      this.$store.commit('setUserCameraActive', false);
    },
    cameraOnHandler() {
      if (!this.hasCurrentChat) {
        this.$store.commit('addAlert', {
          type: 'info',
          text: `Сначала откройте диалог с кем-либо`,
          info: 'Camera',
        });
      }
      if (this.connectedToStreamServer && this.hasCurrentChat)
        this.$http
          .post(socket_url + '/api/check_stream_exists', {
            token: this.$store.getters.token.chat_token,
            userId: this.userId,
          })
          .then(async (res) => {
            if (res.body.exists === false) {
              this.$store.commit('setUserCameraActive', true);
            } else {
              const err = `Get user media fail: Already exists.`;
              console.error(err);
              throw new Error(err);
            }
          })
          .catch((err) => {
            this.$store.commit('updateDuplicateUserMediaModal', {
              open: true,
            });
            throw new Error(err);
          });
    },
  },
};
</script>

<style scoped>
@media screen and (max-width: 992px) {
  .woman-camera-button {
    width: 75px;
  }
}
</style>
