// :style="{ gridTemplateRows: iPhoneActiveGridWithOffset }"
<template>
  <div
    id="chatBox"
    ref="chatBox"
    class="chat-wrapper-new"
    :class="{
      'chat-wrapper-extended': hasCurrentChat,
      'white-box-start': !hasCurrentChat && !isIphone,
      'white-box-extended': !isIphone && hasCurrentChat,
      'input-active': inputActive,
      'input-active-horizontal': inputActive && isHorizontalIphone,
      isHorizontalIphone: isHorizontalIphone,
    }"
  >
    <header-box class="headerBoxWrap"></header-box>

    <template v-if="hasCurrentChat">
      <transition
        name="custom-classes-transition"
        enter-active-class="animated fadeIn"
      >
        <dialog-box
          v-show="showContent"
          ref="dialogBox"
          class="dialogBoxWrap"
        />
      </transition>

      <transition
        name="custom-classes-transition"
        enter-active-class="animated bounceInLeft"
      >
        <chat-input
          v-show="showContent"
          ref="inputBox"
          class="inputBoxWrap"
          @focus-input="focusInputHandler"
          @blur-input="blurInputHandler"
        />
      </transition>
    </template>

    <template v-if="!hasCurrentChat">
      <choose-user class="noUserBox" />
      <div></div>
    </template>
  </div>
</template>

<script>
import DialogBox from './dialog-box.vue';
import ChooseUser from './choose-user.vue';
import ChatInput from './chat-item/chat-input';
import HeaderBox from './header-box';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

export default {
  name: 'ChatBox',
  components: {
    HeaderBox,
    DialogBox,
    ChooseUser,
    ChatInput,
  },

  data: () => {
    return {
      showContent: false,
      emojiScrolled: false,
      windowHeight: window.innerHeight,
      windowWidth: window.innerWidth,
      windowStartHeight: 0,
      viewPortRatioValue: null,
    };
  },
  computed: {
    inputActive() {
      return this.$store.getters.chatInputActive;
    },

    isActive() {
      return this.currentChat ? this.currentChat.active : false;
    },

    iphoneVersion() {
      return this.$store.getters.iphoneVersion;
    },

    isHorizontalIphone() {
      return this.isIphone && this.windowHeight <= this.windowWidth;
    },

    hasCurrentChat() {
      return this.$store.getters.dialogsCurrent !== null;
    },

    isIphone() {
      return this.$store.getters.device.isIOS;
    },

    isIos14Up() {
      return this.$store.getters.isIos14Up;
    },
  },
  watch: {
    isHorizontalIphone() {
      if (this.$refs.inputBox) this.$refs.inputBox.triggerInputBlur();
      this.windowStartHeight = window.innerHeight;
      this.resetViewPort();
    },
  },
  async mounted() {
    if (this.$store.getters.iphoneVersion === 'not-an-ip-safari')
      window.addEventListener('resize', () => {
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
      });

    if (this.isIphone) {
      window.addEventListener('resize', () => {
        this.showContent = false;
        this.windowHeight = window.innerHeight;
        this.windowWidth = window.innerWidth;
        this.showContent = true;
      });
      window.addEventListener('blur', () => {
        if (this.$refs.inputBox) this.$refs.inputBox.triggerInputBlur();
      });
      window.addEventListener('focus', () => {
        if (this.$refs.inputBox) this.$refs.inputBox.triggerInputBlur();
      });
    }

    if (this.$refs.inputBox) this.$refs.inputBox.triggerInputBlur();

    setTimeout(() => {
      this.windowStartHeight = window.innerHeight;
      this.resetViewPort();
    }, 500);

    this.showContent = true;
  },
  beforeDestroy() {
    this.resetViewPort();
  },
  methods: {
    calculateViewPortRatioValue(windowHeight) {
      const h = windowHeight ? windowHeight : windowHeight.innerHeight;
      return parseFloat((h * 0.01).toPrecision(3));
      /*if (
        this.iphoneVersion !== 'not-an-ip-safari' &&
        !this.isHorizontalIphone
      ) {

        return windowHeight * 0.01;

        /!*else {
          let value;
          switch (this.iphoneVersion) {
            case 'iPhone6-6s-7-8':
            case 'iPhone6-6s-7-8-plus':
              value = 2.715;
              break;
            case 'iPhone5-5C-5S-SE-6-6s-7-8-display-zoom':
              value = 2.615;
              break;
            case 'iPhoneX':
              value = 2.9;
              break;
            case 'iPhone11':
              value = 2.95;
              break;
            case 'iPhone12-13-12-13-Pro':
              value = 3.5;
              break;
            case 'iPhone12-13-Pro-Max':
              value = 4.2;
              break;
            case 'iPhone-11-Pro-Max':
              value = 3.2;
              break;
            default:
              value = 2.615;
              break;
          }
          return value;

          /!*
          * let value;
          switch (this.iphoneVersion) {
            case 'iPhone6-6s-7-8':
              value = this.emojiScrolled ? 2.715 : 3.1;
              break;
            case 'iPhone6-6s-7-8-plus':
              value = this.emojiScrolled ? 2.715 : 3.205;
              break;
            case 'iPhone5-5C-5S-SE-6-6s-7-8-display-zoom':
              value = this.emojiScrolled ? 2.615 : 2.98;
              break;
            case 'iPhoneX':
              value = this.emojiScrolled ? 2.9 : 3.715;
              break;
            case 'iPhone11':
              value = this.emojiScrolled ? 2.95 : 4.0;
              break;
            case 'iPhone12-13-12-13-Pro':
              value = this.emojiScrolled ? 3.5 : 4.0;
              break;
            case 'iPhone12-13-Pro-Max':
              value = this.emojiScrolled ? 4.2 : 4.7;
              break;
            case 'iPhone-11-Pro-Max':
              value = this.emojiScrolled ? 3.2 : 4.25;
              break;
            default:
              value = this.emojiScrolled ? 2.615 : 2.98;
              break;
          }
          return value;
          *  *!/
        }*!/
      } else if (this.isHorizontalIphone) {
        return windowHeight * 0.01;
      } else {
        return windowHeight * 0.01;
      }*/
    },

    async windowScrollHandler() {
      window.removeEventListener('scroll', this.windowScrollHandler);
      this.emojiScrolled = true;
      setTimeout(() => {
        this.recalculateViewPort(document.body.clientHeight - 30);
        if (this.$refs.dialogBox) this.$refs.dialogBox.scrollToBottom();
      }, 200);
    },

    async scrollToBody() {
      return new Promise((resolve) => {
        setTimeout(() => {
          this.$scrollTo(document.body, 0, {
            onDone: () => {
              resolve(true);
            },
          });
        }, 500);
      });
    },

    recalculateViewPort(windowHeight) {
      let wHeight = windowHeight ? windowHeight : window.innerHeight;
      if (
        this.iphoneVersion === 'iPhoneX' ||
        this.iphoneVersion === 'iPhone12-13-Pro-Max'
      )
        wHeight = wHeight - 40;
      const vpValue = this.calculateViewPortRatioValue(wHeight);
      this.viewPortRatioValue = vpValue;
      document.documentElement.style.setProperty('--vh', `${vpValue}px`);
    },

    async resetViewPort() {
      let vh = parseFloat((this.windowStartHeight * 0.01).toPrecision(3));
      this.viewPortRatioValue = vh;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    },

    async focusInputHandler() {
      if (this.iphoneVersion === 'not-an-ip-safari') {
        this.$store.commit('setChatInputActive', true);
        setTimeout(() => {
          if (this.$refs.dialogBox) this.$refs.dialogBox.scrollToBottom();
        }, 500);
      } else {
        const targetScrollEl = this.$refs.dialogBox.$el.querySelector(
          '.simplebar-content-wrapper'
        );

        disableBodyScroll(targetScrollEl, {});

        if (!this.isIos14Up) return;

        this.$store.commit('setChatInputActive', true);

        this.showContent = false;

        if (this.isHorizontalIphone) {
          this.showContent = true;
          return this.scrollToBody();
        }

        setTimeout(() => {
          this.recalculateViewPort(window.innerHeight);

          this.scrollToBody().then(() => {
            this.showContent = true;

            setTimeout(() => {
              if (this.$refs.dialogBox) this.$refs.dialogBox.scrollToBottom();
              // window.addEventListener('touchend', this.scrollToBody);
              window.addEventListener('scroll', this.windowScrollHandler);
            }, 1000);
          });
        }, 500); // 500
      }
    },

    async blurInputHandler() {
      if (this.iphoneVersion === 'not-an-ip-safari') {
        this.$store.commit('setChatInputActive', false);
        setTimeout(() => {
          if (this.$refs.dialogBox) this.$refs.dialogBox.scrollToBottom();
        }, 500);
        return;
      }

      const targetScrollEl = this.$refs.dialogBox.$el.querySelector(
        '.simplebar-content-wrapper'
      );
      enableBodyScroll(targetScrollEl);

      if (!this.isIos14Up) return;

      this.$store.commit('setChatInputActive', false);

      if (this.isHorizontalIphone) {
        this.showContent = true;
        return this.scrollToBody();
      }

      await this.resetViewPort();

      // window.removeEventListener('touchend', this.scrollToBody);
      window.removeEventListener('scroll', this.windowScrollHandler);

      this.emojiScrolled = false;

      await this.scrollToBody();
      this.showContent = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.input-active {
  @media screen and (max-width: 450px) {
    background: white;
  }
}

@media screen and (min-width: 1024px) {
  .chat-wrapper-extended,
  .white-box-start {
    padding-top: 15px;
  }
}

.chat-wrapper-new {
  height: 100%;
  width: 100%;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  justify-content: space-around;

  .headerBoxWrap {
    height: 60px;
  }
  .dialogBoxWrap {
    background: white;
    padding-bottom: 10px;
  }
  .inputBoxWrap {
    z-index: 100;
  }

  @media screen and (min-width: 1024px) {
    .inputBoxWrap,
    .dialogBoxWrap,
    .noUserBox {
      border-left: 1px solid rgba(217, 217, 217, 0.25) !important;
      border-right: 1px solid rgba(217, 217, 217, 0.25) !important;
    }
  }

  @media screen and (max-height: 425px) and (max-width: 992px) and (min-width: 425px) {
    .headerBoxWrap {
      display: none;
    }
    .dialogBoxWrap {
      padding: 0;
    }
    .inputBoxWrap {
      background: white;
      z-index: 100;
      height: 100px;
    }
  }
}

.input-active-horizontal {
  background: white;
  justify-content: flex-start;
  padding-top: 2px;
  .dialogBoxWrap {
    display: none;
  }
}

@media screen and (max-width: 450px) {
  .input-active {
    grid-template-rows: 1fr 100px;
    .headerBoxWrap {
      display: none;
    }
  }
}

.ios-safari {
  .input-active {
    // grid-template-rows: 1fr 120px;
    .headerBoxWrap {
      display: none;
    }
  }
}
</style>
