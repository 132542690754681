/**
 * Created by Garant on 14.05.2017.
 */
/**
 * Created by Alex Shvets on 27.02.2017.
 */
import Vue from 'vue';
import UAParser from 'ua-parser-js';

const state = {
  chatInputActive: false,
  deviceWidth: 0,
  deviceHelpers: {
    iPhoneOffset: 0,
  },
  giftModal: {
    currentUser: {
      avatar: {},
    },
    preview: true,
    open: false,
    all: [],
    rec: [],
  },
  virtualGiftModal: {
    currentUser: {
      avatar: {},
    },
    preview: true,
    open: false,
    all: [],
    rec: [],
  },
  mailModal: {
    to: {},
    open: false,
  },
  dispatchModal: {
    open: false,
  },
  badConnectionModal: {
    open: false,
  },
  profileModal: {
    open: false,
    loaded: false,
    user: {},
  },
  lowCreditsModal: {
    open: false,
  },
  getUserMediaModal: {
    open: false,
  },
  duplicateUserMediaModal: {
    open: false,
  },
  versionChangeModal: {
    open: false,
  },
  unSupportedGetCamera: {
    open: false,
  },
  alertModal: {
    open: false,
    type: '', // prvChatEnd, goToPrivate, switchChat
    data: {},
  },
  alerts: [],
  gallery: {
    index: 0,
    open: false,
    photos: [],
    bought: false,
    photoID: '',
  },
  groups:{
    paymentsGroups:[]
  }
};

const getters = {
  parsedUserAgent() {
    const parser = new UAParser();
    return parser.getResult();
  },
  isIos14Up() {
    const parser = new UAParser();
    const result = parser.getResult();
    return (
      result &&
      result.os.name === 'iOS' &&
      !(parseFloat(result.os.version) < 14.0)
    );
  },
  iphoneVersion() {
    const isIp = !!window.navigator.userAgent.match(/iP(ad|od|hone)/i);
    const isSafari = /^((?!chrome|android|crios|fxios).)*safari/i.test(
      window.navigator.userAgent
    );
    if (!isIp || !isSafari) return 'not-an-ip-safari';

    /*var canvas = document.createElement('canvas');
    if (canvas) {
      var context =
        canvas.getContext('webgl') || canvas.getContext('experimental-webgl');
      if (context) {
        var info = context.getExtension('WEBGL_debug_renderer_info');
        if (info) {
          var renderer = context.getParameter(info.UNMASKED_RENDERER_WEBGL);
        }
      }
    }*/

    // IPhone 12, 13 Pro Max
    if (
      window.screen.height / window.screen.width == 926 / 428 &&
      window.devicePixelRatio == 3
    ) {
      return 'iPhone12-13-Pro-Max';
      // iPhone 11 Pro Max
    } else if (
      window.screen.height / window.screen.width == 896 / 414 &&
      window.devicePixelRatio == 3
    ) {
      return 'iPhone-11-Pro-Max';
      // iPhone 12 13, 12 13 Pro
    } else if (
      window.screen.height / window.screen.width == 844 / 390 &&
      window.devicePixelRatio == 3
    ) {
      return 'iPhone12-13-12-13-Pro';
      // iPhone 11
    } else if (
      window.screen.height / window.screen.width == 896 / 414 &&
      window.devicePixelRatio == 2
    ) {
      return 'iPhone11';
      // iPhone X
    } else if (
      window.screen.height / window.screen.width == 812 / 375 &&
      window.devicePixelRatio == 3
    ) {
      return 'iPhoneX';
      // iPhone 6+/6s+/7+ and 8+
    } else if (
      window.screen.height / window.screen.width == 736 / 414 &&
      window.devicePixelRatio == 3
    ) {
      return 'iPhone6-6s-7-8-plus';
      /*switch (renderer) {
        default:
          return 'iPhone6-6s-7-8-plus'
        case 'Apple A8 GPU':
          return 'iPhone 6 Plus'
        case 'Apple A9 GPU':
          return 'iPhone 6s Plus'
        case 'Apple A10 GPU':
          return 'iPhone 7 Plus'
        case 'Apple A11 GPU':
          return 'iPhone 8 Plus'
      }*/
      // iPhone 6+/6s+/7+ and 8+ in zoom mode
    } else if (
      window.screen.height / window.screen.width == 667 / 375 &&
      window.devicePixelRatio == 3
    ) {
      return 'iPhone6-6s-7-8-plus-display-zoom';
      /* switch (renderer) {
        default:
          return 'iPhone6-6s-7-8-plus-display-zoom'
        case 'Apple A8 GPU':
          return 'iPhone 6 Plus (display zoom)'
        case 'Apple A9 GPU':
          return 'iPhone 6s Plus (display zoom)'
        case 'Apple A10 GPU':
          return 'iPhone 7 Plus (display zoom)'
        case 'Apple A11 GPU':
          return 'iPhone 8 Plus (display zoom)'
      }*/
      // iPhone 6/6s/7 and 8
    } else if (
      window.screen.height / window.screen.width == 667 / 375 &&
      window.devicePixelRatio == 2
    ) {
      return 'iPhone6-6s-7-8';
      /* switch (renderer) {
        default:
          return 'iPhone6-6s-7-8'
        case 'Apple A8 GPU':
          return 'iPhone 6'
        case 'Apple A9 GPU':
          return 'iPhone 6s'
        case 'Apple A10 GPU':
          return 'iPhone 7'
        case 'Apple A11 GPU':
          return 'iPhone 8'
      }*/
      // iPhone 5/5C/5s/SE or 6/6s/7 and 8 in zoom mode
    } else if (
      window.screen.height / window.screen.width == 1.775 &&
      window.devicePixelRatio == 2
    ) {
      return 'iPhone5-5C-5S-SE-6-6s-7-8-display-zoom';
      /* switch (renderer) {
        default:
          return 'iPhone 5, 5C, 5S, SE or 6, 6s, 7 and 8 (display zoom)'
        case 'PowerVR SGX 543':
          return 'iPhone 5 or 5c'
        case 'Apple A7 GPU':
          return 'iPhone 5s'
        case 'Apple A8 GPU':
          return 'iPhone 6 (display zoom)'
        case 'Apple A9 GPU':
          return 'iPhone SE or 6s (display zoom)'
        case 'Apple A10 GPU':
          return 'iPhone 7 (display zoom)'
        case 'Apple A11 GPU':
          return 'iPhone 8 (display zoom)'
      }*/
      // iPhone 4/4s
    } else if (
      window.screen.height / window.screen.width == 1.5 &&
      window.devicePixelRatio == 2
    ) {
      return 'iPhone4-4s';
      /*   switch (renderer) {
        default:
          return 'iPhone 4 or 4s'
        case 'PowerVR SGX 535':
          return 'iPhone 4'
        case 'PowerVR SGX 543':
          return 'iPhone 4s'
      }*/
      // iPhone 1/3G/3GS
    } else if (
      window.screen.height / window.screen.width == 1.5 &&
      window.devicePixelRatio == 1
    ) {
      return 'iPhone1-3G-3GS';

      /* switch (renderer) {
        default:
          return 'iPhone 1, 3G or 3GS'
        case 'ALP0298C05':
          return 'iPhone 3GS'
        case 'S5L8900':
          return 'iPhone 1, 3G'
      }*/
    } else {
      return 'not-an-ip-safari';
    }
  },
  iPhoneOffset(state) {
    return state.deviceHelpers.iPhoneOffset;
  },
  chatInputActive(state) {
    return state.chatInputActive;
  },
  badConnectionModal(state) {
    return state.badConnectionModal;
  },
  deviceWidth(state) {
    return state.deviceWidth;
  },
  dispatchModal(state) {
    return state.dispatchModal;
  },
  registerModal(state) {
    return state.registerModal;
  },
  giftModal(state) {
    return state.giftModal;
  },
  virtualGiftModal(state) {
    return state.virtualGiftModal;
  },
  profileModal(state) {
    return state.profileModal;
  },
  lowCreditsModal(state) {
    return state.lowCreditsModal;
  },
  getUserMediaModal(state) {
    return state.getUserMediaModal;
  },
  duplicateUserMediaModal(state) {
    return state.duplicateUserMediaModal;
  },
  versionChangeModal(state) {
    return state.versionChangeModal;
  },
  alertModal(state) {
    return state.alertModal;
  },
  mailModal(state) {
    return state.mailModal;
  },
  alerts(state) {
    return state.alerts;
  },
  gallery(state) {
    return state.gallery;
  },
  userPaymentsGroup(state) {
    return state.groups.paymentsGroups;
  }
};

const mutations = {
  setChatInputActive(state, payload) {
    state.chatInputActive = payload;
  },
  setIphoneOffset(state, payload) {
    state.deviceHelpers.iPhoneOffset = parseInt(payload, 10);
  },
  updateBadConnectionModal(state, payload) {
    Object.assign(state.badConnectionModal, payload);
  },
  updateVersionChangeModal(state, payload) {
    Object.assign(state.versionChangeModal, payload);
  },
  updateGallery(state, payload) {
    Object.assign(state.gallery, payload);
    // TOGGLE_MODAL( payload.open )
  },
  setDeviceWidth(state, payload) {
    state.deviceWidth = payload;
  },
  updateGiftModal(state, payload) {
    Object.assign(state.giftModal, payload);
    // TOGGLE_MODAL( payload.open )
  },
  updateVirtualGiftModal(state, payload) {
    Object.assign(state.virtualGiftModal, payload);
    // TOGGLE_MODAL( payload.open )
  },
  updateLowCreditModal(state, payload) {
    Object.assign(state.lowCreditsModal, payload);
    // TOGGLE_MODAL( payload.open )
  },
  updateGetUserMediaModal(state, payload) {
    Object.assign(state.getUserMediaModal, payload);
    // TOGGLE_MODAL( payload.open )
  },
  updateDuplicateUserMediaModal(state, payload) {
    Object.assign(state.duplicateUserMediaModal, payload);
    // TOGGLE_MODAL( payload.open )
  },
  updateAlertModal(state, payload) {
    Object.assign(state.alertModal, payload);
    // TOGGLE_MODAL( payload.open )
  },
  updateProfileModal(state, payload) {
    Object.assign(state.profileModal, payload);
  },
  updateDispatchModal(state, payload) {
    Object.assign(state.dispatchModal, payload);
  },
  updateMailModal(state, payload) {
    Object.assign(state.mailModal, payload);
    // TOGGLE_MODAL( payload.open );
  },
  addAlert(state, payload) {
    const obj = {
      id: `${String(Math.random()).slice(2)}${Date.now()}`,
    };
    state.alerts.push(Object.assign(payload, obj));
  },
  removeAlert(state, payload) {
    let index = state.alerts.findIndex((i) => i.id === payload);
    state.alerts.splice(index, 1);
  },
  setUserGroups(state,payload) {
    console.log(state.groups,payload)
    state.groups.paymentsGroups = payload;
  }
};

const actions = {
  loadProfileInfo(context, payload) {
    Vue.http
      .post('v1/profile/profile', {
        target_user_id: payload.id,
        access_token: context.rootGetters.token.site_token,
      })
      .then(
        (r) => {
          if (r.body.status) {
            context.commit('updateProfileModal', {
              user: r.body.result,
              open: true,
              loaded: true,
            });
          } else {
            context.commit('updateProfileModal', {
              open: false,
              loaded: false,
            });
            context.commit('addAlert', {
              type: 'error',
              text: 'Some error happened during request execution. Please, contact our support',
              info: 'profile load',
            });
          }
        },
        () => {
          context.commit('updateProfileModal', {
            open: false,
            loaded: false,
          });
          context.commit('addAlert', {
            type: 'error',
            text: 'Some error happened during request execution. Please, contact our support',
            info: 'profile load',
          });
        }
      );
  },
  addAlert(context, payload) {
    context.commit('addAlert', payload);
  },
  updateGiftModal(context, payload) {
    let sort = function (a, b) {
      return a.name > b.name ? -1 : 1;
    };

    const access_token = context.rootGetters.token.site_token;

    Vue.http
      .post('v1/gift/load', {
        access_token,
      })
      .then(
        (r) => {
          if (r.body.status) {
            let gifts = r.body.result;
            let tmp = [];
            gifts.forEach((i) => {
              i.sumPrice = i.price + i.delivery_price;
              i.sumPriceEur = i.price_eur + i.delivery_price_eur;
            });
            tmp = tmp.concat(gifts);

            context.commit('updateGiftModal', {
              all: tmp.sort(sort),
              user_id: payload.user_id,
              open: true,
            });

            /*if (typeof r.body.result === 'object') {

          for (let k in r.body.result) {
            let item = r.body.result[k];
            item.forEach(i => {
              i.sumPrice = i.price + i.delivery_price
            })
            tmp = tmp.concat(item)
          }
          console.log(r.body.result)
          context.commit('updateGiftModal', {
            all: tmp.sort(sort),
            user_id: payload.user_id,
            open: true
          });
        }*/
          } else {
            this.$store.commit('addAlert', {
              type: 'error',
              text: 'Some error happened during request execution',
              info: 'load rec gifts',
            });
          }
        },
        () => {
          this.$store.commit('addAlert', {
            type: 'error',
            text: 'Some error happened during request execution',
            info: 'load rec gifts',
          });
        }
      );

    Vue.http
      .post('v1/gift/load/recommend', {
        access_token,
        target: payload.user_id,
      })
      .then(
        (r) => {
          if (r.body.status) {
            let gifts = r.body.result;
            console.log(r);
            let tmp = [];
            gifts.forEach((i) => {
              i.sumPrice = i.price + i.delivery_price;
              i.sumPriceEur = i.price_eur + i.delivery_price_eur;
            });
            tmp = tmp.concat(gifts);
            context.commit('updateGiftModal', {
              all: tmp.sort(sort),
              user_id: payload.user_id,
              open: true,
            });
          } else {
            this.$store.commit('addAlert', {
              type: 'error',
              text: 'Some error happened during request execution',
              info: 'load rec gifts',
            });
          }
        },
        () => {
          this.$store.commit('addAlert', {
            type: 'error',
            text: 'Some error happened during request execution',
            info: 'load rec gifts',
          });
        }
      );

    Vue.http
      .post('v1/profile/quick-search', {
        access_token,
        name_or_id: payload.user_id,
      })
      .then((response) => {
        context.commit('updateGiftModal', {
          currentUser: response.body.result[0],
          name_or_id: payload.user_id,
        });
      });
  },
  updateVirtualGiftModal({ commit }, payload) {
    let sort = function (a, b) {
      return a.name > b.name ? -1 : 1;
    };
    Vue.http
      .post('v1/virtual-gift/list', {
        access_token: window.localStorage['access-token'],
      })
      .then(
        (r) => {
          if (r.body.status) {
            //	if ( typeOf( r.body.result ) === 'object' ) {

            if (r.body.result && r.body.result.length > 0) {
              let tmp = [];
              for (var i = 0; i < r.body.result.length; i++) {
                tmp = tmp.concat(r.body.result[i]);
              }

              // let tmp = [];
              // for ( let k in r.body.result ) {
              // 	let item = r.body.result[ k ];
              // 	item.forEach( i => {
              // 		i.sumPrice = i.price + i.delivery_price
              // 	} )
              // 	tmp = tmp.concat( item )
              // }

              commit('updateVirtualGiftModal', {
                all: tmp.sort(sort),
                user_id: payload.user_id,
                open: payload.open,
              });
            }
          } else {
            this.$store.commit('addAlert', {
              type: 'error',
              text: this.$t('alerts.someErr'),
              info: 'load rec gifts',
            });
          }
        },
        () => {
          this.$store.commit('addAlert', {
            type: 'error',
            text: this.$t('alerts.someErr'),
            info: 'load rec gifts',
          });
        }
      );

    Vue.http
      .post('v1/profile/quick-search', {
        access_token: window.localStorage['access-token'],
        name_or_id: payload.user_id,
      })
      .then((response) => {
        commit('updateVirtualGiftModal', {
          currentUser: response.body.result[0],
          name_or_id: payload.user_id,
          open: payload.open,
        });
      });
  },
  loadMailUser(context, payload) {
    if (payload.to) {
      Vue.http
        .post('v1/profile/quick-search', {
          access_token: window.localStorage['access-token'],
          name_or_id: payload.to,
        })
        .then((response) => {
          context.commit('updateMailModal', {
            open: payload.open,
            to: response.body.result[0],
          });
        });
    } else {
      context.commit('updateMailModal', {
        open: payload.open,
        to: '',
      });
    }
  },
  loadUserGroups(context) {
    Vue.http
        .post('v1/user-settings/user-groups', {
          access_token: window.localStorage['access-token'],
        })
        .then((res) => {
          if (res.body.status) {
            context.commit('setUserGroups', res.body.result.group_pay);
          } else {
            context.dispatch('addAlert', {
              type: 'error',
              text: Vue.t('alerts.someErr'),
              info: 'get groups',
            });
          }
        })
        .catch(() => {
          context.dispatch('addAlert', {
            type: 'error',
            text: Vue.t('alerts.someErr'),
            info: 'get groups',
          });
        });
  }
};

export default {
  state,
  getters,
  mutations,
  actions,
};
