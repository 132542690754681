<template>
  <aside
    id="last-dialogs"
    :class="{ open: responsiveShowLastDialogs }"
    class="last-dialogs-box accordion-inner sidebar"
  >
    <div class="header-box__item">
      <!--      <div class="chat-time">-->
      <!--        <svg class="rbi">-->
      <!--          <use xlink:href="/static/assets/images/rbi-icon.svg#rbi-clock"></use>-->
      <!--        </svg>-->
      <!--        <span>{{mixinBeautyTime( chatTime )}}</span>-->
      <!--      </div>-->
      <div class="chat-time">
        <div class="clock rbi" :class="{ start: chatStatus.active }">
          <span class="clock--short-hand"></span>
          <span class="clock--long-hand"></span>
          <span class="clock--dot"></span>
        </div>
        <span
          :class="{
            'status-start': chatStatus.tip === 'start',
            'status-stop': chatStatus.tip === 'spec-stop',
          }"
          >{{ chatStatus.text }}</span
        >
      </div>
      <div class="currency-wrap">
        <svg class="rbi">
          <use xlink:href="/static/assets/images/rbi-icon.svg#rbi-money" />
        </svg>
        <span class="amount" :class="{ 'low-credits': lowBalance }">{{
          userBalance
        }}</span>
      </div>
      <div class="toggle-sound">
        <button class="btn-reset" @click="toggleSound">
          <svg v-if="soundSettings.sound" class="rbi">
            <use xlink:href="/static/assets/images/rbi-icon.svg#rbi-volume" />
          </svg>

          <svg v-else class="rbi">
            <use
              xlink:href="/static/assets/images/rbi-icon.svg#rbi-volume_mute"
            />
          </svg>
        </button>
      </div>
      <div class="toggle-camera"><camera /></div>
    </div>
    <div
      v-show="responsiveShowLastDialogs"
      class="sb-dummy right"
      @click="responsiveCloseLastDialogs"
    ></div>
    <div class="ovh white-box last-chat-box">
      <div class="sidebar__header">
        <h2 class="sidebar__header_h"><span>Last chat</span></h2>
        <button class="btn-close" @click="responsiveCloseLastDialogs">
          <svg class="rbi">
            <use
              xlink:href="/static/assets/images/rbi-icon.svg#rbi-close"
            ></use>
          </svg>
        </button>
      </div>
      <div class="sidebar__content">
        <SimpleBar
          data-simplebar-auto-hide="true"
          class="simplebar-last-dialogs"
        >
          <div>
            <dialog-item
              v-for="(dialog, key) in dialogList"
              :key="key"
              :woman="dialog"
            ></dialog-item>
          </div>
        </SimpleBar>
      </div>
    </div>
  </aside>
</template>

<script>
import store from '../../store/store';
import DialogItem from './items/dialog-item.vue';
import { BEAUTY_TIME } from '../../mixins/man_util';
import { SOUND } from '../../mixins/utils';
import Camera from './video/camera';

export default {
  scroll: null,
  store,
  name: 'LastDialogs',
  components: {
    Camera,
    'dialog-item': DialogItem,
  },
  mixins: [BEAUTY_TIME, SOUND],
  data() {
    return {
      timer: 0,
    };
  },
  computed: {
    dialogList() {
      return this.$store.getters.lastDialogs;
    },
    responsiveShowLastDialogs() {
      return this.$store.getters.settings.responsive.showLastDialogs;
    },
    lastDialogsCount() {
      return this.dialogList.length;
    },
    chatTime() {
      return this.$store.getters.chatTime;
    },
    userBalance() {
      return this.$store.getters.myBalance;
    },
    lowBalance() {
      return this.userBalance <= 10;
    },
    chatStatus() {
      if (this.currentDialog && this.currentDialog.roomId !== null) {
        if (this.currentDialog.active)
          return {
            active: true,
            text: '',
            tip: 'current',
          };
        else if (this.currentDialog.roomStatus === 'stop') {
          return {
            active: false,
            text: '',
            tip: 'spec-stop',
          };
        } else {
          return {
            active: false,
            text: '',
            tip: 'spec-stop',
          };
        }
      } else {
        return {
          active: false,
          text: '',
          tip: 'spec-stop',
        };
      }
      /*else if (dialog.active) {
          if (!!dialog.chat_time) {
            return {
              active: true,
              text: 'Chat',
              tip: 'current',
            }
          } else {
            return {
              active: true,
              text: 'Active',
              tip: 'start',
            }
          }
        } else if (dialog.chat_status === 'pause') {
          return {
            active: false,
            text: 'Stop',
            tip: 'spec-stop',
          }
        }*/
    },
    currentDialog() {
      return this.$store.getters.dialogsCurrent;
    },
  },
  mounted() {
    //            $('#messages-box').simplebar();
    setTimeout(() => {
      this.$http
        .post('v1/credit/balance', {
          access_token: this.$store.getters.session.site_token,
        })
        .then(
          (response) => {
            if (response.body.status) {
              this.$store.dispatch('setMyBalance', response.body.credits);
            } else {
              //                        console.log('Error - get my balance status false');
            }
          },
          () => {
            //                    console.log('Error - get my balance', error);
          }
        );
    }, 1000);
  },
  methods: {
    responsiveCloseLastDialogs() {
      this.$store.dispatch('setResponsiveShowLastDialogs', false);
    },
  },
};
</script>

<style lang="scss" scoped>
@media screen and (max-width: 450px) {
  .sidebar__search {
    display: none;
  }
}
.simplebar-last-dialogs {
  position: relative;
  height: 100%;
  overflow-x: hidden;
}

.ios-safari {
  .simplebar-last-dialogs {
    height: 100%;
    overflow-x: hidden;
  }
  .isIos14Up {
    .simplebar-last-dialogs {
      height: 100%;
      overflow-x: hidden;
    }
  }
}

.last-dialogs-box {
  height: 100%;
  position: relative;
  overflow: hidden;

  .last-chat-box,
  .sidebar__content {
    position: relative;
    height: 100%;
    overflow: hidden;
  }
}

.sidebar {
  height: 100%;
  width: 100%;
  overflow: hidden;
  @media screen and (min-width: 992px) {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .header-box__item {
      color: white;
      padding: 1rem;
      display: flex;
      justify-content: space-between;
    }

    .last-chat-box {
      /*  border-right: 1px solid #d9d9d9;*/
      // border-left: 1px solid #d9d9d9 !important;
      position: relative;
    }
  }
}

.sidebar__content {
  background: white;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.header-box__item .chat-time,
.currency-wrap,
.toggle-sound,
.toggle-camera {
  width: 25%;
  display: flex;
  justify-content: center !important;
}
.chat-time .rbi {
  margin: 0 !important;
}
.currency-wrap {
}
.toggle-sound {
}
@media screen and (max-width: 992px) {
  .header-box__item {
    display: none;
  }
}
.last-chat-box {
  /*border-left: 1px solid #d9d9d9;*/
}
</style>
