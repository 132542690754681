<template>
  <div class="alerts--box">
    <a-template
      v-for="alert in alerts"
      :key="alert.id"
      class="alerts--base d-flex"
      :class="alert.type === 'success' ? 'alerts--success' : 'alerts--error'"
      :alert="alert"
    >
    </a-template>
  </div>
</template>

<script>
import Template from './alerts/_template.vue';

export default {
  name: 'Alerts',
  components: {
    aTemplate: Template,
  },
  data() {
    return {};
  },
  computed: {
    alerts() {
      return this.$store.getters.alerts;
    },
  },
  methods: {},
};
</script>

<style lang="scss">
@import '../../assets/scss/variables-bootstrap';
@import '../../assets/scss/variables';
@import '../../assets/scss/vars';

.alerts {
  &--box {
    z-index: 9999;
    position: fixed;
    top: 0;
    left: 0;
  }

  &--success {
    .rbi {
      color: $green;
    }
  }

  &--error {
    .rbi {
      color: $red;
    }
  }

  &--base {
    align-items: center;
    color: #ffffff;
    margin-top: 5px;
    margin-left: 5px;
    padding: 15px 20px;
    width: auto;
    cursor: pointer;
    line-height: 1;
    animation-duration: 1s;
    animation-fill-mode: both;
    animation-name: zoomInDown;
    background-color: $dark;

    .rbi {
      font-size: 18px;
      margin-right: 10px;
      font-weight: 700;
    }
    p {
      margin: 0;
    }
  }

  @keyframes zoomInDown {
    from {
      opacity: 0;
      transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
      animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }

    60% {
      opacity: 1;
      transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
      animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    }
  }
}
</style>
