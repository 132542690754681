import Vue from 'vue';
import Vuex from 'vuex';
import utils from './modules/utils';
import video from './modules/video';
import registration from './modules/registration';
import configDev from '../config/config-develop';
import configProd from '../config/config-production';
import moment from 'moment';

import Logger from '../utils/Logger';

const production = configProd.isProduction;

const CONFIG = production ? configProd : configDev;

const logger = new Logger(production);

Vue.use(Vuex);

let compareTime = function (message1, message2) {
  return message1.time - message2.time;
};

const inBrowser = typeof window !== 'undefined';
const UA = inBrowser && window.navigator.userAgent.toLowerCase();
let isIOS = UA && /iphone|ipod|ios/.test(UA);
let isIpad =
  UA &&
  /Macintosh/i.test(navigator.userAgent) &&
  navigator.maxTouchPoints &&
  navigator.maxTouchPoints > 1;
let isOpera = UA && /opr/.test(UA);

export default new Vuex.Store({
  modules: {
    utils,
    video,
    registration,
  },
  state: {
    registered: false,
    app: {
      current_state: 'app-man',
      private_show: false,
      blob_url: '#',
      my_local_stream: null,
      media_server: CONFIG.site_url,
    },
    connectedToSocket: false,
    // soundNotification: true,
    settings: {
      user: {},
      site: {
        time_while_woman_is_new: 300,
        time_while_woman_is_online: 300,
        lang: 'en',
      },
      chat: {
        invite_show_time_for_man: 60, // time in seconds 60 default
        invite_show_time_for_woman: 20, // time in seconds 20 default
        price_man_send_photo: 5,
        price_man_send_letter: 10,
        price_man_video_stream: 1,
        price_text_chat: 1,
        price_video_chat: 1,
      },
      delivery: {
        run: false,
        prepared: false,
        ignored: [],
        templates: {
          active: [],
          all: [],
        },
        ignore_favorites: false,
        ignore_bookmarks: false,
        tab_open: 'active',
      },
      responsive: {
        showUserOnline: false,
        showLastDialogs: false,
      },
      sound: {
        id: 0,
        user_id: 0,
        language: 'en',
        sound: '',
        sound_new_inbox: '',
        sound_new_guest: '',
        sound_new_winks: '',
        sound_new_favorite: '',
        sound_chat: '',
        sound_chat_notification: '',
        sound_system_notification: '',
        notification_winks: '',
        notification_inbox: '',
        notification_guest: '',
        notification_favorite: '',
        email_system_notification: '',
        email_wink_notification: '',
        email_guest_notification: '',
        email_inbox_notification: '',
        email_favorite_notification: '',
      },
    },
    currentTypedMessage: '',
    currentEditingTemplate: '',
    chat_time: 0,
    man_woman_search_q: '',
    last_dialogs_loaded: false,
    loadingMoreMessages: false,

    user: {
      id: '',
      profile: {
        avatar: '#',
      },
      cameraProcess: false,
      wallet: {
        balance: 0,
      },
      streamId: null,
      type: 'non',
      current_typed_message: '',

      bookmarks: [],
      favorites: [],
      all_online: [],
    },
    all_bookmarks: [],
    all_favorites: [],
    invites: [],
    last_dialogs: [],
    winks: [],
    session: {
      site_token: '',
      chat_token: '',
    },
    rtc: false,
    ffmpeg: false,
    dialogs: {
      current: null,
      list: [],
      prevDialogUser: null,
    },
    balance: 0,
    device: {
      isIOS,
      isIpad,
      isOpera,
    },
    webrtcInitedForPrivate: false,
  },
  getters: {
    loadingMoreMessages(state) {
      return state.loadingMoreMessages;
    },
    lastDialogsLoaded(state) {
      return state.last_dialogs_loaded;
    },
    lastDialogsUnreadMessage(state) {
      return state.dialogs.list.filter((dialog) => {
        if (dialog) {
          return dialog.messages.filter((msg) => msg && !msg.reade).length;
        }
        return [];
      }).length;
    },
    initedWebRTC(state) {
      return state.webrtcInitedForPrivate;
    },
    getManQ(state) {
      return state.man_woman_search_q;
    },
    device(state) {
      return state.device;
    },
    getCurrentChatCanChangeType(state) {
      if (state.dialogs.current !== null) {
        return state.dialogs.current.user.can_change_type;
      } else {
        return true;
      }
    },
    invites(state) {
      return state.invites;
    },
    config(state) {
      return state.app.config;
    },
    registered(state) {
      return state.registered;
    },
    connectedToSocket(state) {
      return state.connectedToSocket;
    },
    state(state) {
      return state;
    },
    sex(state) {
      //two variables because of more exact calculations
      let male = state.user.type === 'man';
      let female = state.user.type === 'woman';
      return {
        isMan: male,
        isWoman: female,
      };
    },
    mediaServerUrl(state) {
      return state.app.media_server;
    },
    blobUrl(state) {
      return state.app.blob_url;
    },
    privateShow(state) {
      return state.app.private_show;
    },
    webSocket(state) {
      return state.webSocket;
    },
    getMyBalanceIterator(state) {
      return state.my_balance_iterator;
    },
    myBalance(state) {
      return state.balance;
    },
    chatTime(state) {
      return state.chat_time;
    },
    user(state) {
      return state.user;
    },
    message(state) {
      return state.dialogs.list;
    },
    settings(state) {
      return state.settings;
    },
    siteSettings(state) {
      return state.settings.site;
    },
    onlineWomanList(state) {
      return state.user.all_online;
    },
    delivery(state) {
      return state.settings.delivery;
    },
    bookmarks(state) {
      return state.user.bookmarks;
    },
    bookmarkWomanList(state) {
      return state.user.bookmarks;
    },
    favoriteWomanList(state) {
      return state.user.favorites;
    },
    favorites(state) {
      return state.user.favorites;
    },
    countAllOnline(state) {
      if (state.user.all_online === undefined) {
        return 0;
      } else {
        return state.user.all_online.length;
      }
    },
    last_dialogs(state) {
      return state.user.last_dialogs;
    },
    countAllFavorite(state) {
      if (state.user.favorites === undefined) {
        return 0;
      } else {
        return state.user.favorites.length;
      }
    },
    dialogs(state) {
      return state.dialogs;
    },
    countAllBookmark(state) {
      if (state.user.bookmarks === undefined) {
        return 0;
      } else {
        return state.user.bookmarks.length;
      }
    },
    currentChatUserId(state) {
      if (!state.dialogs.current) {
        return null;
      } else {
        return state.dialogs.current.user.id;
      }
    },
    dialogsCurrent(state) {
      return state.dialogs.current;
    },
    dialogsList(state) {
      return state.dialogs.list;
    },
    ignoredUsers(state) {
      return state.settings.delivery.ignored;
    },
    activeDeliveryTemplates(state) {
      return state.settings.delivery.templates.active;
    },
    allDeliveryTemplates(state) {
      return state.settings.delivery.templates.all;
    },
    whatDeliveryTabOpen(state) {
      return state.settings.delivery.tab_open;
    },
    appUser(state) {
      return state.user;
    },
    locale(state) {
      return state.settings.site.lang;
    },
    getCurrentTypedMessage(state) {
      return state.currentTypedMessage;
    },
    currentEditingTemplate(state) {
      return state.currentEditingTemplate;
    },
    token(state) {
      return state.session;
    },
    session(state) {
      return state.session;
    },
    lastDialogs(state) {
      return state.last_dialogs;
    },
    winks(state) {
      return state.winks;
    },
    online(state) {
      return state.user.all_online;
    },
    wallet(state) {
      return state.balance;
    },
    app(state) {
      return state.app;
    },
    rtc(state) {
      return state.rtc;
    },
    ffmpeg(state) {
      return state.ffmpeg;
    },
    /*notification( state ) {
     return state.soundNotification
     },*/
    getAllBookmarks(state) {
      return state.all_bookmarks;
    },
    getAllFavorites(state) {
      return state.all_favorites;
    },
    getMyLocalStream(state) {
      return state.app.my_local_stream;
    },
  },
  mutations: {
    CLEAN_DIALOGS_LIST(state) {
      state.dialogs.prevDialogUser = state.dialogs.current
        ? state.dialogs.current.user
        : null;

      state.dialogs.list = [];

      state.dialogs.current = null;
    },
    resetToTextChat(state, payload) {
      if (payload.woman) {
        const womanId = payload.woman;

        state.dialogs.list.forEach((dialog) => {
          if (dialog.user.id === womanId) {
            dialog.type = 'text';
            dialog.user.chat_type = 'text';
          }
        });
      }
    },
    resetToTextChatWoman(state, menArray) {
      if (menArray.length > 0) {
        menArray.forEach((manId) => {
          state.dialogs.list.forEach((dialog) => {
            if (dialog.user.id === manId) {
              dialog.type = 'text';
              dialog.user.chat_type = 'text';
            }
          });
        });
      }
    },
    setInfoMessage(state, messages) {
      state.dialogs.current.messages.push(messages);
    },
    stopChatTimers(state) {
      state.dialogs.list.map((dialog) => {
        if (dialog.roomId) {
          dialog.active = false;
          dialog.roomStatus = 'stop';
        }
      });
    },
    SET_INIT_WEBRTC(state, payload) {
      state.webrtcInitedForPrivate = payload;
    },
    TOGGLE_CAMERA_PROCESS(state) {
      state.user.cameraProcess = !state.user.cameraProcess;
    },
    SET_CURRENT_APP(state, type) {
      state.app.current_state = type;
    },
    setCurrentEditingTemplate(state, payload) {
      state.currentEditingTemplate = payload;
    },
    SET_SITE_SETTINGS(state, data) {
      state.settings.site.time_while_woman_is_new =
        data.time_while_woman_is_new;
      state.settings.site.time_while_woman_is_online =
        data.time_while_woman_is_online;
    },
    SET_MY_LOCAL_STREAM(state, localStream) {
      state.app.my_local_stream = localStream;
    },
    SET_ALL_BOOKMARKS(state, arrayId) {
      state.all_bookmarks = arrayId;
    },
    SET_ALL_FAVORITES(state, arrayId) {
      state.all_favorites = arrayId;
    },
    SET_RESPONSIVE_SHOW_USER_ONLINE(state, status) {
      state.settings.responsive.showUserOnline = status;
      if (status) {
        state.settings.responsive.showLastDialogs = false;
      }
    },
    SET_RESPONSIVE_SHOW_LAST_DIALOGS(state, status) {
      state.settings.responsive.showLastDialogs = status;
      if (status) {
        state.settings.responsive.showUserOnline = false;
      }
    },
    SET_WEBSOCKET_CONNECTION(state, connection) {
      state.webSocket = connection;
    },
    ADD_NEW_DIALOG(state, dialog) {
      let refDialog = null;
      state.dialogs.list.forEach((dialogItem) => {
        if (dialogItem && dialogItem.user.id == dialog.user.id) {
          refDialog = dialogItem;
        }
      });
      if (!refDialog) {
        state.dialogs.list.push(dialog);
      }
    },
    SET_CAMERA_STATUS(state, camera) {
      setTimeout(() => {
        state.user.camera = camera;
      }, 300);
    },
    SET_PREPARED_DELIVERY(state) {
      if (state.settings.delivery.templates.active.length >= 1) {
        state.settings.delivery.prepared = true;
      }
    },
    SET_FAVORITES_ONLINE(state, favorites) {
      state.user.favorites = favorites;
    },
    SET_BOOKMARKS_ONLINE(state, bookmarks) {
      state.user.bookmarks = bookmarks;
    },
    SET_ONLINE(state, online) {
      state.user.all_online = online;
    },
    ADD_MESSAGE(state, message) {
      state.dialogs.list.push(message);
    },
    SET_MY_BALANCE(state, balance) {
      state.balance = balance;
    },

    SET_ACTIVE_CHAT(state, user) {
      let dialogs = state.dialogs.list,
        refDialog;
      dialogs.forEach(function (dialog) {
        if (dialog && dialog.user.id === user.id) {
          refDialog = dialog;
        }
      });
      if (refDialog) {
        state.dialogs.current = refDialog;
        state.dialogs.current.user.can_change_type =
          state.user.type !== 'woman';
        refDialog.user.is_notification = false;
        /*clearInterval(state.chat_time_iterator);*/
        /* if (refDialog.user.chat_time) {
          state.chat_time = refDialog.user.chat_time;
        }*/
        if (
          refDialog.messages instanceof Array &&
          refDialog.messages.length > 0
        ) {
          refDialog.messages.forEach((message) => {
            if (message && !message.reade) {
              message.reade = true;
            }
          });
        }
        /*if (refDialog.user.chat_status === "active") {
          state.chat_time_iterator = window.setInterval(() => {
            state.chat_time++;
          }, 1000);
        } else {
          state.chat_time =
            refDialog.user.chat_time !== 0 &&
            refDialog.user.chat_time !== undefined
              ? refDialog.user.chat_time
              : 0;
        }*/
      } else {
        user.chat_time = 0;
        let object = {
          user: user,
          type: 'text',
          chat_start: 0,
          roomId: null,
          messages: [],
          active: false,
          last_active: Math.floor(new Date().getTime() / 1000),
          time_ranges: [],
        };
        state.dialogs.list.push(object);
        state.dialogs.current = object;
        /*clearInterval(state.chat_time_iterator);
        state.chat_time = 0;*/
      }
    },
    REMOVE_FROM_IGNORE(state, user) {
      let ignoreArray = state.settings.delivery.ignored;
      for (let i = 0; i < ignoreArray.length; i++) {
        if ('user_id' in user) {
          if (ignoreArray[i].id === user.user_id) {
            state.settings.delivery.ignored.splice(i, 1);
          }
        } else {
          if (ignoreArray[i].id === user.id) {
            state.settings.delivery.ignored.splice(i, 1);
          }
        }
      }
    },

    CHANGE_EXCLUDE_DELIVERY_FILTERS(state, payload) {
      switch (payload.filter) {
        case 'bookmarks':
          state.settings.delivery.ignore_bookmarks = payload.value;
          break;
        case 'favourites':
          state.settings.delivery.ignore_favorites = payload.value;
          break;
        default:
          break;
      }
    },
    ADD_TO_ALL_ONLINE(state, user) {
      state.user.all_online.push(user);
    },
    ADD_TO_ACTIVE_DELIVERY_TEMPLATES(state, template) {
      state.settings.delivery.templates.active.push(template);
    },

    REMOVE_FROM_ACTIVE_TEMPLATE(state, template) {
      let activeTemplates = state.settings.delivery.templates.active;
      for (let i = 0; i < activeTemplates.length; i++) {
        if (activeTemplates[i].id === template.id) {
          state.settings.delivery.templates.active.splice(i, 1);
          if (state.settings.delivery.templates.active.length === 0) {
            state.settings.delivery.prepared = false;
          }
        }
      }
    },
    SET_DELIVERY_IGNORE_CATEGORY(state, ignore) {
      state.settings.delivery.ignore_favorites = ignore.favorites;
      state.settings.delivery.ignore_bookmarks = ignore.bookmarks;
    },
    SET_DELIVERY_TAB(state, tab) {
      state.settings.delivery.tab_open = tab;
    },
    SEND_MESSAGE(state, message) {
      let found = false;
      for (let i = 0; i < state.dialogs.list.length; i++) {
        if (state.dialogs.list[i].user.id === message.receiver) {
          found = true;
          state.dialogs.current.messages.push(message);
        }
      }
      if (!found) {
        state.dialogs.current.messages.push(message);
        state.dialogs.list.push({
          user: state.dialogs.current.user,
          messages: state.dialogs.current.messages,
        });
      }
    },
    SET_CURRENT_CHAT_TYPE(state, type) {
      if (state.dialogs.current && state.dialogs.current.user)
        state.dialogs.current.user.chat_type = type;
      /*if (type === 'video' || type === 'text' || type === 'private') {
        if (state.dialogs.current !== null) {
          state.dialogs.current.user.chat_type_from =
            state.dialogs.current.user.chat_type
          state.dialogs.current.user.chat_type = type

          switch (type) {
            case 'private':
              state.app.current_state = 'app-private'
              break
            case 'video':
            case 'text':
              if (state.user.type === 'man') {
                state.app.current_state = 'app-man'
              }
              break
          }
        }
      } else {
        // console.log('Type not found');
      }*/
    },
    SET_USER(state, user) {
      state.user = user;
    },
    SET_CONNECTED_TO_SOCKET(state, connected) {
      state.connectedToSocket = connected;
    },
    SET_FAVORITES(state, favorites) {
      state.user.favorites = favorites;
    },
    SET_BOOKMARKS(state, bookmarks) {
      state.user.bookmarks = bookmarks;
    },
    SET_TOKEN(state, token) {
      state.session = token;
    },
    SET_CURRENT_TYPED_MESSAGE(state, message) {
      state.currentTypedMessage = message;
    },
    SET_LAST_DIALOGS(state, dialogs) {
      state.last_dialogs = dialogs;
    },
    SET_LAST_DIALOGS_LOADED(state, loaded) {
      state.last_dialogs_loaded = loaded;
    },
    SET_LOADING_MORE_MESSAGES(state, payload) {
      state.loadingMoreMessages = payload;
    },
    SEND_WINK(state, id) {
      state.winks.push({
        id: id,
      });
    },
    SEND_FAVORITES(state, user) {
      let found = false;
      state.user.favorites.forEach((item) => {
        if (item && item.id === user.id) {
          found = true;
        }
      });

      if (!found) {
        state.user.favorites.push(user);
      }
    },

    SEND_BOOKMARKS(state, user) {
      let found = false;
      for (let i = 0; i < state.user.bookmarks.length; i++) {
        if (state.user.bookmarks[i].id === user.id) {
          found = true;
        }
      }
      if (!found) {
        state.user.bookmarks.push(user);
      }
    },
    REMOVE_BOOKMARKS(state, bookmark) {
      for (let i = 0; i < state.user.bookmarks.length; i++) {
        if (state.user.bookmarks[i].id === bookmark.id) {
          state.user.bookmarks.splice(i, 1);
        }
      }
    },
    REMOVE_FAVORITES(state, user) {
      for (let i = 0; i < state.user.favorites.length; i++) {
        if (state.user.favorites[i].id === user.id) {
          state.user.favorites.splice(i, 1);
        }
      }
    },
    SET_CURRENT_CHAT(state, user) {
      if (state.last_dialogs_loaded) {
        const refDialog = state.dialogs.list.find(
          (item) => item.user && item.user.id === user.id
        );

        if (refDialog) {
          window.socketIo.emit(
            'chatOpenFetchIdAndStatus',
            { sender: state.user.id, receiver: user.id },
            (data) => {
              state.dialogs.current = refDialog;
              refDialog.user.is_notification = false;
              if (data) {
                refDialog.roomId = data.roomId;
                refDialog.active = data.status === 'active';
                refDialog.type = data.roomType.replace('Chat', '');
                refDialog.user.chatActivated = data.status === 'active';
                refDialog.user.chat_status = data.status;
                refDialog.user.chat_type = data.roomType.replace('Chat', '');
              }

              /* window.socketIo.emit(
                 'chatOpenFetchIdAndStatus',
                 { sender: state.user.id, receiver: user.id },
                 (data) => {

                 })*/

              if (
                refDialog.messages instanceof Array &&
                refDialog.messages.length > 0
              ) {
                refDialog.messages.forEach((message) => {
                  if (message && !message.reade) {
                    message.reade = true;
                  }
                });
              }

              state.invites.forEach((n) => {
                if (
                  refDialog !== null &&
                  'user' in refDialog &&
                  n.senderId === refDialog.user.id
                ) {
                  if (state.user.type === 'woman') {
                    refDialog.user.can_change_type = false;
                  } else {
                    refDialog.user.can_change_type = true;
                  }
                  if (n.params.inviteType === 'text') {
                    refDialog.user.chat_type = 'text';
                  }
                  if (n.params.inviteType === 'video') {
                    refDialog.user.chat_type = 'video';
                  }
                }
              });
            }
          );
        } else {
          let object = {
            user: user, // TODO: WHERE IS USER???
            type: 'text',
            chat_start: 0,
            roomId: null,
            messages: [],
            active: false,
            last_active: Math.floor(new Date().getTime() / 1000),
            time_ranges: [],
            noMoreMessages: true,
          };

          window.socketIo.emit(
            'chatOpenFetchIdAndStatus',
            { sender: state.user.id, receiver: user.id },
            (data) => {
              if (data) {
                object.roomId = data.roomId;
                object.active = data.status === 'active';
                object.type = data.roomType.replace('Chat', '');
                object.user.chatActivated = data.status === 'active';
                object.user.chat_status = data.status;
                object.user.chat_type = data.roomType.replace('Chat', '');
              }
              state.invites.forEach((Invite) => {
                if (
                  state.dialogs.current &&
                  Invite.params.senderId === state.dialogs.current.user.id &&
                  state.user.type === 'woman'
                ) {
                  object.user.can_change_type = false;
                } else {
                  object.user.can_change_type = true;
                }
              });

              state.dialogs.list.push(object);
              state.dialogs.current = object;
              state.invites.forEach((n) => {
                if (n.senderId === state.dialogs.current.user.id) {
                  if (n.params.inviteType === 'text') {
                    state.dialogs.current.user.chat_type = 'text';
                  }
                  if (n.params.inviteType === 'video') {
                    state.dialogs.current.user.chat_type = 'video';
                  }
                  /* if (n.params.inviteType === 'videoDoubleInvite') {
                    state.dialogs.current.user.chat_type = 'videoDouble'
                  } */
                }
              });
              setTimeout(() => {
                if (
                  object !== null &&
                  'user' in object &&
                  state.dialogs.current.user.id === object.user.id
                ) {
                  state.dialogs.current.user.can_change_type = true;
                }
                state.dialogs.list.forEach((d) => {
                  if (
                    object !== null &&
                    'user' in object &&
                    d.user.id === object.user.id
                  ) {
                    d.user.can_change_type = true;
                  }
                });
              }, 20 * 1000);
            }
          );

          /*clearInterval(state.chat_time_iterator);
          state.chat_time = 0;*/
        }
      }
    },
    SET_CURRENT_CHAT_FROM_INVITE(state, user) {
      let dialogs = state.dialogs.list,
        refDialog = dialogs.find((i) => {
          if (i !== undefined) {
            return i.user.id === user.id;
          } else {
            return false;
          }
        });
      /*
         dialogs.forEach( function ( dialog ) {
         if ( dialog && dialog.user.id == user.id ) {
         refDialog = dialog;
         }
         } );*/
      if (refDialog) {
        window.socketIo.emit(
          'chatOpenFetchIdAndStatus',
          { sender: state.user.id, receiver: user.id },
          (data) => {
            state.dialogs.current = refDialog;
            refDialog.user.is_notification = false;
            if (data) {
              refDialog.roomId = data.roomId;
              refDialog.active = data.status === 'active';
              refDialog.type = data.roomType.replace('Chat', '');
              refDialog.user.chatActivated = data.status === 'active';
              refDialog.user.chat_status = data.status;
              refDialog.user.chat_type = data.roomType.replace('Chat', '');
            }

            /* window.socketIo.emit(
                 'chatOpenFetchIdAndStatus',
                 { sender: state.user.id, receiver: user.id },
                 (data) => {

                 })*/

            if (
              refDialog.messages instanceof Array &&
              refDialog.messages.length > 0
            ) {
              refDialog.messages.forEach((message) => {
                if (message && !message.reade) {
                  message.reade = true;
                }
              });
            }

            state.invites.forEach((n) => {
              if (
                refDialog !== null &&
                'user' in refDialog &&
                n.senderId === refDialog.user.id
              ) {
                if (state.user.type === 'woman') {
                  refDialog.user.can_change_type = false;
                } else {
                  refDialog.user.can_change_type = true;
                }
                if (n.params.inviteType === 'text') {
                  refDialog.user.chat_type = 'text';
                }
                if (n.params.inviteType === 'video') {
                  refDialog.user.chat_type = 'video';
                }
              }
            });
          }
        );
      } else {
        let object = {
          user: user,
          type: 'text',
          chat_start: 0,
          roomId: null,
          messages: [],
          active: false,
          last_active: Math.floor(new Date().getTime() / 1000),
          time_ranges: [],
          noMoreMessages: true,
        };

        window.socketIo.emit(
          'chatOpenFetchIdAndStatus',
          { sender: state.user.id, receiver: user.id },
          (data) => {
            if (data) {
              object.roomId = data.roomId;
              object.active = data.status === 'active';
              object.type = data.roomType.replace('Chat', '');
              object.user.chatActivated = data.status === 'active';
              object.user.chat_status = data.status;
              object.user.chat_type = data.roomType.replace('Chat', '');
            }
            state.invites.forEach((Invite) => {
              if (
                state.dialogs.current &&
                Invite.params.senderId === state.dialogs.current.user.id &&
                state.user.type === 'woman'
              ) {
                object.user.can_change_type = false;
              } else {
                object.user.can_change_type = true;
              }
            });

            state.dialogs.list.push(object);
            state.dialogs.current = object;
            state.invites.forEach((n) => {
              if (n.senderId === state.dialogs.current.user.id) {
                if (n.params.inviteType === 'text') {
                  state.dialogs.current.user.chat_type = 'text';
                }
                if (n.params.inviteType === 'video') {
                  state.dialogs.current.user.chat_type = 'video';
                }
                /* if (n.params.inviteType === 'videoDoubleInvite') {
                  state.dialogs.current.user.chat_type = 'videoDouble'
                } */
              }
            });
            setTimeout(() => {
              if (
                object !== null &&
                'user' in object &&
                state.dialogs.current.user.id === object.user.id
              ) {
                state.dialogs.current.user.can_change_type = true;
              }
              state.dialogs.list.forEach((d) => {
                if (
                  object !== null &&
                  'user' in object &&
                  d.user.id === object.user.id
                ) {
                  d.user.can_change_type = true;
                }
              });
            }, 20 * 1000);
          }
        );

        /*clearInterval(state.chat_time_iterator);
          state.chat_time = 0;*/
      }
    },
    MAN_SEND_MESSAGE(state, message) {
      let currentDialog = state.dialogs.current,
        newDate = false,
        last_time = 0;
      currentDialog.messages = currentDialog.messages.sort(compareTime);
      if (currentDialog.messages.length > 0) {
        last_time =
          currentDialog.messages[currentDialog.messages.length - 1].time;
      } else {
        newDate = true;
      }

      let lastDate = new Date(last_time * 1000),
        todayDay = new Date();
      newDate =
        lastDate.getFullYear() !== todayDay.getFullYear() ||
        lastDate.getMonth() !== todayDay.getMonth() ||
        lastDate.getDate() !== todayDay.getDate();
      let messageObject = {
        recipient: false,
        text: message.text,
        time: Math.floor(Date.now() / 1000),
        new_date: newDate,
      };
      currentDialog.messages.push(messageObject);
      currentDialog.messages = currentDialog.messages.sort(compareTime);
    },
    TOGGLE_DELIVERY_STATUS(state) {
      if (state.settings.delivery.run) {
        state.settings.delivery.run = false;
      } else {
        state.settings.delivery.run = true;
      }
    },

    DESTROY_INVITES(state, invite) {
      for (let i = 0; i < state.invites.length; i++) {
        if (
          state.invites[i].params.notificationId ===
          invite.params.notificationId
        ) {
          state.invites.splice(i, 1);
        }
      }
    },
    SET_MY_BALANCE_ITERATOR(state, iterator) {
      state.my_balance_iterator = iterator;
    },

    SET_BLOB_URL(state, data) {
      let dialogs = state.dialogs.list;
      dialogs.forEach((dialog) => {
        if (dialog.user.id === data.user_id) {
          if (dialog.blob === undefined) {
            dialog.blob = data.blob;
            dialog.stream = data.stream;
          }
        }
      });
      if (state.dialogs.current !== null) {
        state.dialogs.current.blob = data.blob;
        state.dialogs.current.stream = data.stream;
      }
    },
    SET_DIALOG_VIDEO_TYPE(state, data) {
      let dialogs = state.dialogs.list;

      dialogs.forEach((dialog) => {
        if (dialog.user.id === data.user_id) {
          dialog.videoType = data.type; //ffmpeg or rtc
        }
      });
      if (data.type == 'rtc') {
        state.rtc = true;
        state.ffmpeg = false;
      } else if (data.type == 'ffmpeg') {
        state.rtc = false;
        state.ffmpeg = true;
      } else {
        state.rtc = false;
        state.ffmpeg = false;
      }
    },
    DESTROY_ALL_WATCHERS(state) {
      state.dialogs.all.forEach((dialog) => {
        if (dialog !== null && 'watcher' in dialog) {
          try {
            dialog.watcher.leave();
          } catch (ex) {
            // console.log('Can not leave webrtc connection');
          }
        }
      });
    },
    SET_NEW_WATCHER(state, data) {
      let dialogs = state.dialogs.list;
      dialogs.forEach((dialog) => {
        if (dialog.user.id === data.user_id) {
          dialog.watcher = new data.rtc();
          //Temporary disable. Need to full webrtc refactoring;
          dialog.watcher.session = {
            audio: false,
            video: true,
            oneway: true,
          };
          dialog.watcher.sdpConstraints.mandatory = {
            OfferToReceiveAudio: true,
            OfferToReceiveVideo: true,
          };
          dialog.watcher.onleave = () => {
            dialog.user.chat_type = 'text';
            if (state.user.type === 'man') {
              state.app.current_state = 'app-man';
            } else {
              state.app.current_state = 'app-woman';
            }
          };
          let p = false;
          if (state.app.current_state === 'app-private') {
            p = true;
          }
          dialog.watcher.extra = {
            user: {
              type: state.user.type,
              id: state.user.id,
            },
            private: p,
          };
        }
      });
    },
    SET_MAN_STREAM_ID(state, payload) {
      state.dialogs.current.user.streamId = payload.stream;
      state.dialogs.current.user.privateShow = payload.show;
    },
    DROP_CHAT_ITERATOR(state) {
      clearInterval(state.chat_time_iterator);
    },
    GO_TO_PRIVATE(state) {
      state.app.current_state = 'app-private';
      state.dialogs.current.user.chat_type = 'private';
    },

    ADD_TO_IGNORE_MANUAL(state, user) {
      state.settings.delivery.ignored.push(user);
    },

    SET_PHOTOS_TO_DIALOG(state, payload) {
      state.dialogs.current.user.photosArray = payload;
    },
    createDialog(state, payload) {
      state.dialogs.list.push(payload);
    },
    SET_MAN_Q(state, payload) {
      state.man_woman_search_q = payload;
    },

    DESTROY_CURRENT_WATCHER(state) {
      if (
        state.dialogs.current.watcher !== null &&
        state.dialogs.current.watcher
      ) {
        state.dialogs.current.watcher.onleave = () => {
          if (state.dialogs.current.watcher !== null) {
            state.dialogs.current.watcher.leave();
          } else {
            // console.log('Null watcher');
          }
        };
        state.dialogs.current.watcher.close();
      }
    },
    INIT_APP_CONFIG(state, payload) {
      state.app.config = payload;
    },
    addDeliveryIgnoredUser(state, payload) {
      state.settings.delivery.ignored.push(payload);
    },
    editDeliveryTemplate(state, payload) {
      let obj = state.settings.delivery.templates;
      for (let arr in obj) {
        let template = obj[arr].find((i) => i.id === payload.id);
        if (template) {
          template.template = payload.template;
        }
      }
    },
    chatRegistration(state) {
      state.registered = true;
    },

    setUserSettings(state, payload) {
      if (payload.type === 'full') {
        // full (if) and part (else) settings assign
        for (let key in payload.data) {
          if (payload.data[key] === 'on' || payload.data[key] === 'custom') {
            payload.data[key] = 1;
          } else if (payload.data[key] === 'off') {
            payload.data[key] = 0;
          }
        }
      }
      Object.assign(state.settings.sound, payload.data);
    },
  },
  actions: {
    socket_inviteTimeSettings({ state }, payload) {
      state.settings.chat = {
        ...state.settings.chat,
        invite_show_time_for_man: payload.settings.invite_show_time_for_man
          ? payload.settings.invite_show_time_for_man
          : 60,
        invite_show_time_for_woman: payload.settings.invite_show_time_for_woman
          ? payload.settings.invite_show_time_for_woman
          : 60,
      };
    },
    socket_priceSettings({ state }, payload) {
      const photoSend = payload.find(
        (price) => price.action === 'chat_photo_send_man'
      );
      const letterSend = payload.find(
        (price) => price.action === 'write_letter'
      );
      const manVideoStream = payload.find(
        (price) => price.action === 'private_chat'
      );
      const videoChat = payload.find((price) => price.action === 'video_chat');
      const textChat = payload.find((price) => price.action === 'text_chat');

      state.settings.chat = {
        ...state.settings.chat,
        price_man_send_photo: photoSend ? photoSend.price : 0,
        price_man_send_letter: letterSend ? letterSend.price : 0,
        price_man_video_stream: manVideoStream ? manVideoStream.price : 1,
        price_text_chat: textChat ? textChat.price : 1,
        price_video_chat: videoChat ? videoChat.price : 1,
      };
    },
    socket_settingsUpdated({ dispatch }, payload) {
      if (payload.type !== 'chat' && payload.updated)
        dispatch('getUserSettings');
    },
    getUserSettings({ commit }, payload) {
      Vue.http
        .post('v1/user-settings/get', {
          access_token: payload,
        })
        .then((r) => {
          if (r.body.status) {
            commit('setUserSettings', {
              type: 'full',
              data: r.body.result,
            });
          }
        });
    },
    loadMoreMessages({ state, commit }, payload) {
      window.socketIo.emit('loadMoreMessages', {
        token: state.session.site_token,
        userId: payload.user,
        lastMessage: payload.firstMessageInDialog,
        limit: 20,
      });
      commit('SET_LOADING_MORE_MESSAGES', true);
    },
    socket_loadMoreMessages({ state, commit }, payload) {
      if (payload && payload.status && payload.messages.length > 0) {
        const dialogIndex = state.dialogs.list.findIndex(
          (dialog) => dialog.user.id === payload.userId
        );

        if (dialogIndex !== -1) {
          if (!payload.moreMessages) {
            state.dialogs.list[dialogIndex].noMoreMessages = true;
          }
          const newMessages = payload.messages;
          state.dialogs.list[dialogIndex].messages.unshift({
            type: 'info',
            reade: true,
            action: 'message_load_divider',
            key: Date.now(),
          });
          newMessages.forEach((message) => {
            if (message.photoMan) {
              state.dialogs.list[dialogIndex].messages.unshift({
                reade: true,
                newDate: false,
                sender: message.sender,
                receiver: message.receiver,
                type: 'freephoto',
                photoMan: true,
                photo: +message.photoId,
                bought: true,
                key: message._id,
                time: message.created,
                sound: true, // for playing sound once, Changes in woman chat component
              });
            } else if (!message.photoMan && message.photoId.length > 0) {
              state.dialogs.list[dialogIndex].messages.unshift({
                reade: true,
                newDate: false,
                sender: message.sender,
                receiver: message.receiver,
                type: 'photo',
                photoMan: false,
                photo: +message.photoId,
                bought: false,
                key: message._id,
                time: message.created,
                sound: true, // for playing sound once, Changes in woman chat component
              });
            } else {
              state.dialogs.list[dialogIndex].messages.unshift({
                reade: true,
                newDate: false,
                sender: message.sender,
                receiver: message.receiver,
                type: 'message',
                key: message._id,
                sound: true, // for playing sound once, Changes in woman chat component
                time: message.created,
                text: message.text,
              });
            }
          });
        }
        setTimeout(() => {
          commit('SET_LOADING_MORE_MESSAGES', false);
        }, 1000);
      } else {
        commit('SET_LOADING_MORE_MESSAGES', false);
      }
    },
    socket_womanCamDisable({ commit }, payload) {
      commit('resetToTextChat', payload);
    },
    socket_edit_delivery_template({ commit }, payload) {
      if (payload.status) {
        commit('editDeliveryTemplate', {
          id: payload.template_id,
          template: payload.new_text,
        });
      } else {
        console.error('Ошибка при редактировании шаблона');
      }
    },
    socket_add_delivery_template({ state }, template) {
      if (template.status === true) {
        state.settings.delivery.templates.all.push({
          id: template.template._id,
          template: template.template.template,
          selected: false,
        });

        if (template.template.active) {
          state.settings.delivery.templates.active.push({
            id: template.template._id,
            template: template.template.template,
          });
        }
      }
    },
    socket_openChat({ state }, payload) {
      window.focus();
      if ('target_user_id' in payload && 'chat_type' in payload) {
        let foundDialog = false;
        state.dialogs.list.forEach((dialog) => {
          if (
            dialog &&
            'user' in dialog &&
            dialog.user.id === payload.target_user_id
          ) {
            dialog.user.chat_type = payload.chat_type;
            state.dialogs.current = dialog;
          }
        });
        if (foundDialog === false) {
          state.all_online.forEach((onlineUser) => {
            if (onlineUser && onlineUser.id === payload.target_user_id) {
              let refDialog = {
                active: false,
                chat_start: 0,
                last_active: onlineUser.last_active,
                messages: [],
                time_ranges: [],
                type: payload.chat_type,
                user: onlineUser,
              };
              state.dialogs.push(refDialog);
              state.dialogs.current = refDialog;
            }
          });
        }
      }
    },
    socket_add_active_delivery_template({ state }, payload) {
      if (payload.status) {
        if (payload.template.active) {
          state.settings.delivery.templates.active.push({
            id: payload.template._id,
            template: payload.template.template,
          });
        } else {
          let index = state.settings.delivery.templates.active.findIndex(
            (i) => i.id === payload.template._id
          );
          state.settings.delivery.templates.active.splice(index, 1);
        }
      }
    },
    socket_get_delivery_templates({ state }, templates) {
      if (templates.status && templates.templates.length) {
        templates.templates.forEach((i) => {
          state.settings.delivery.templates.all.push({
            id: i._id,
            template: i.template,
            selected: false,
          });
        });

        state.settings.delivery.templates.active = templates.templates
          .filter((i) => i.active)
          .map((i) => {
            return {
              id: i._id,
              template: i.template,
            };
          });
      }
    },

    socket_camera({ state }, result) {
      if (result.status) {
        setTimeout(() => {
          state.user.camera = result.desc;
          state.user.cameraProcess = !state.user.cameraProcess;
        }, 10);
      }
    },

    socket_notification({ state }, notificationObject) {
      if (notificationObject.type === 'invite') {
        window.focus();
        let dialog = null;
        state.dialogs.list.forEach((d) => {
          if (
            d &&
            'user' in d &&
            d.user.id === notificationObject.params.senderId
          ) {
            /*console.log('found dialog')*/
            dialog = d;
          }
        });
        if (dialog) {
          if (notificationObject.params.inviteType === 'text') {
            dialog.user.chat_type = 'text';
          } else if (notificationObject.params.inviteType === 'video') {
            dialog.user.chat_type = 'video';
          } else {
            return false;
          }
          if (state.user.type === 'woman') {
            dialog.user.can_change_type = false;
          } else {
            dialog.user.can_change_type = true;
          }
        }
        if (
          state.dialogs.current !== null &&
          state.dialogs.current.user.id === notificationObject.params.senderId
        ) {
          if (notificationObject.params.inviteType === 'text') {
            state.dialogs.current.user.chat_type = 'text';
          } else if (notificationObject.params.inviteType === 'video') {
            state.dialogs.current.user.chat_type = 'video';
          } else {
            return false;
          }
          if (state.user.type === 'woman') {
            state.dialogs.current.user.can_change_type = false;
          } else {
            state.dialogs.current.user.can_change_type = true;
          }
        }
        if (state.settings.sound.sound) {
          if (
            state.app.current_state !== 'app-private' &&
            state.user.type !== 'woman'
          ) {
            // console.log('New invite soun play'), // woman sounds added in component
            window.sounds.notification.track.play();
          }
        }
        if (
          state.user.type === 'man' &&
          state.dialogs.current !== undefined &&
          state.dialogs.current &&
          state.dialogs.current.user.chat_type === 'private'
        ) {
          window.socketIo.emit('invite_close', {
            token: state.session.chat_token,
            notificationId: notificationObject.params.notificationId,
          });
        } else {
          let refDialog = null;
          state.dialogs.list.forEach((dialog) => {
            if (
              dialog &&
              dialog.user.id === notificationObject.params.senderId &&
              dialog.user.chat_status === 'active' &&
              dialog.user.chat_type === 'private'
            ) {
              refDialog = dialog;
              if (state.user.type === 'woman') {
                refDialog.user.can_change_type = false;
              } else {
                refDialog.user.can_change_type = true;
              }
            }
          });
          if (!refDialog) {
            /*for (let i = 0; i < state.invites.length; i++) {}*/
            const foundIndex = state.invites.findIndex(
              (invite) =>
                invite.params.senderId === notificationObject.params.senderId
            );
            if (foundIndex !== -1) {
              state.invites.splice(foundIndex, 1, notificationObject);
              /*state.invites.push(notificationObject)*/
            } else {
              state.invites.push(notificationObject);
            }

            state.user.all_online.forEach((user) => {
              if (user && user.id == notificationObject.params.senderId) {
                user.is_notification = true;
              }
            });
          }
        }
      }
    },

    socket_invitesClose({ state }, data) {
      data.notifications.map((notification) => {
        for (let i = 0; i < state.invites.length; i++) {
          if (state.invites[i].params.notificationId === notification._id)
            state.invites.splice(state.invites[i], 1);
        }
      });
    },

    socket_chatActivationError({ commit, state }, data) {
      if (state.user.type !== 'man') {
        commit('addAlert', {
          type: 'error',
          text: data.message,
          info: `${data.manId}`,
        });
      }
    },

    stet_stream_id({ state }, payload) {
      state.user.streamId = payload;
    },

    socket_loadLastDialogsStatus({ state }, data) {
      if ('roomsData' in data && data.roomsData.length > 0) {
        const roomsData = data.roomsData;
        roomsData.forEach((roomData) => {
          state.dialogs.list.forEach((dialog) => {
            if (dialog.user.id === roomData.targetUserId && roomData.isActive) {
              dialog.active = true;
              dialog.roomStatus = 'active';
              dialog.user.chatActivated = true;
              dialog.user.chat_status = 'active';
            }
          });
        });
      }
    },

    socket_loadMessagesLastDialogs(
      { state, commit, dispatch },
      { data, status, messagesEmpty }
    ) {
      const lastDialogs = state.last_dialogs;

      if (!status && messagesEmpty) {
        commit('SET_LAST_DIALOGS_LOADED', true);
        return false;
      }

      if (data.length < 1 || lastDialogs.length < 1) {
        return false;
      }

      data.forEach((dialog) => {
        const { userId, moreMessages, messages, status } = dialog;

        if (!status || !messages || !messages.length > 1) return;

        const userData = lastDialogs.find((user) => user.id === userId);

        let object = {
          user: userData,
          type: 'text',
          chat_start: 0,
          roomId: null,
          messages: [],
          active: false,
          last_active: Math.floor(new Date().getTime() / 1000),
          time_ranges: [],
          noMoreMessages: !moreMessages,
        };

        messages.reverse();

        messages.forEach((message) => {
          if (message.photoMan) {
            object.messages.push({
              reade: true,
              newDate: false,
              sender: message.sender,
              receiver: message.receiver,
              type: 'freephoto',
              photoMan: true,
              photo: +message.photoId,
              bought: true,
              key: message._id,
              time: message.created,
              sound: true, // for playing sound once, Changes in woman chat component
            });
          } else if (!message.photoMan && message.photoId.length > 0) {
            object.messages.push({
              reade: true,
              newDate: false,
              sender: message.sender,
              receiver: message.receiver,
              type: 'photo',
              photoMan: false,
              photo: +message.photoId,
              bought: false,
              key: message._id,
              time: message.created,
              sound: true, // for playing sound once, Changes in woman chat component
            });
          } else {
            object.messages.push({
              reade: true,
              newDate: false,
              sender: message.sender,
              receiver: message.receiver,
              type: 'message',
              key: message._id,
              sound: true, // for playing sound once, Changes in woman chat component
              time: message.created,
              text: message.text,
            });
          }
        });
        state.dialogs.list.push(object);
      });

      commit('SET_LAST_DIALOGS_LOADED', true);

      if (state.dialogs.prevDialogUser) {
        const prevDialogIndex = data.findIndex(
          (dialog) => dialog.userId === state.dialogs.prevDialogUser.id
        );
        if (prevDialogIndex !== 1) {
          dispatch('setCurrentChat', state.dialogs.prevDialogUser);
          logger.info(
            'Found prev dialog',
            prevDialogIndex,
            state.dialogs.prevDialogUser
          );
        }
      }
    },

    socket_loadMessagesOnChatOpenWithId({ state, commit, getters }, data) {
      const userInstanceFromOnline = getters.online.find(
        (user) => user.id === parseInt(data.userId)
      );

      if (userInstanceFromOnline) {
        let object = {
          user: userInstanceFromOnline,
          type: 'text',
          chat_start: 0,
          roomId: null,
          messages: [],
          active: false,
          last_active: Math.floor(new Date().getTime() / 1000),
          time_ranges: [],
          noMoreMessages: !data.moreMessages,
        };

        data.messages.reverse();
        data.messages.forEach((message) => {
          if (message.photoMan) {
            object.messages.push({
              reade: true,
              newDate: false,
              sender: message.sender,
              receiver: message.receiver,
              type: 'freephoto',
              photoMan: true,
              photo: +message.photoId,
              bought: true,
              key: message._id,
              time: message.created,
              sound: true, // for playing sound once, Changes in woman chat component
            });
          } else if (!message.photoMan && message.photoId.length > 0) {
            object.messages.push({
              reade: true,
              newDate: false,
              sender: message.sender,
              receiver: message.receiver,
              type: 'photo',
              photoMan: false,
              photo: +message.photoId,
              bought: false,
              key: message._id,
              time: message.created,
              sound: true, // for playing sound once, Changes in woman chat component
            });
          } else {
            object.messages.push({
              reade: true,
              newDate: false,
              sender: message.sender,
              receiver: message.receiver,
              type: 'message',
              key: message._id,
              sound: true, // for playing sound once, Changes in woman chat component
              time: message.created,
              text: message.text,
            });
          }
        });
        state.dialogs.list.push(object);

        Vue.set(userInstanceFromOnline, 'objectFrom', 'app.vue');

        commit('SET_CURRENT_CHAT_FROM_INVITE', userInstanceFromOnline);
      }
    },

    socket_loadMessageHistory({ state }, data) {
      if (data.status) {
        let prettyMessages = [];
        let sender = null;
        let hasNewDate = false;
        data.messages.reverse();
        data.messages.forEach((message) => {
          if (message.sender === state.user.id) {
            sender = message.receiver;
          }
          if (message.receiver === state.user.id) {
            sender = message.sender;
          }
          let insertedNewDate = false;
          if (hasNewDate === false) {
            hasNewDate = true;
            insertedNewDate = true;
          }
          if (message.photoMan) {
            prettyMessages.push({
              reade: true,
              newDate: false,
              sender: message.sender,
              receiver: message.receiver,
              type: 'freephoto',
              photoMan: true,
              photo: +message.photoId,
              bought: true,
              key: message._id,
              time: message.created,
              sound: true, // for playing sound once, Changes in woman chat component
            });
          } else if (!message.photoMan && message.photoId.length > 0) {
            prettyMessages.push({
              reade: true,
              newDate: false,
              sender: message.sender,
              receiver: message.receiver,
              type: 'photo',
              photoMan: false,
              photo: +message.photoId,
              bought: false,
              key: message._id,
              time: message.created,
              sound: true, // for playing sound once, Changes in woman chat component
            });
          } else {
            prettyMessages.push({
              reade: true,
              newDate: insertedNewDate,
              sender: message.sender,
              receiver: message.receiver,
              type: 'message',
              key: message._id,
              sound: true, // for playing sound once, Changes in woman chat component
              time: message.created,
              text: message.text,
            });
          }
        });
        if (
          state.dialogs.current !== null &&
          state.dialogs.current.user.id === sender
        ) {
          state.dialogs.current.messages = prettyMessages;
        }

        /*state.dialogs.list.push()*/

        state.dialogs.list.forEach((dialog) => {
          if (dialog.user.id === sender) {
            dialog.messages = prettyMessages;
          }
        });
      }
    },
    socket_removeContact({ state }, contact) {
      state.dialogs.current = null;
      if (contact.status) {
        for (let i = 0; i < state.last_dialogs.length; i++) {
          if (state.last_dialogs[i].id === contact.contact_id) {
            state.last_dialogs.splice(i, 1);
          }
        }
      }
    },
    socket_add_user_to_ignore_list({ commit }, listUser) {
      if (listUser.status === true) {
        commit('addDeliveryIgnoredUser', listUser.user);
      }
    },
    socket_load_personal_config({ state }, config) {
      if (config.status) {
        let conf = config.config;
        let ignore = [];
        ignore = conf.delivery_ignore_list.map((item) => {
          return {
            id: item.id,
            name: item.name,
            avatar: item.avatar,
          };
        });

        state.settings.delivery.ignored = ignore;
        state.settings.delivery.ignore_bookmarks =
          conf.delivery_ignore_bookmarks;
        state.settings.delivery.ignore_favorites =
          conf.delivery_ignore_favorites;
      }
    },
    socket_remove_user_from_ignore_list({ state }, user) {
      if (user.status) {
        let ignoreArray = state.settings.delivery.ignored;
        for (let i = 0; i < ignoreArray.length; i++) {
          if (ignoreArray[i].id === user.user) {
            state.settings.delivery.ignored.splice(i, 1);
          }
        }
      }
    },

    socket_delivery_favorite_ignore({ dispatch }, result) {
      if (result.status) {
        dispatch('changeExcludeFilters', {
          filter: 'favourites',
          value: result.state,
        });
      }
    },

    socket_delivery_bookmark_ignore({ dispatch }, result) {
      if (result.status) {
        dispatch('changeExcludeFilters', {
          filter: 'bookmarks',
          value: result.state,
        });
      }
    },

    socket_deliveryInProcess({ state }, payload) {
      // console.log(payload);
      if (payload.status === false) {
        state.settings.delivery.run = false;
      }
    },

    socket_remove_delivery_template({ state }, result) {
      if (result.status) {
        let templateId = result.template._id;
        for (let i = 0; i < state.settings.delivery.templates.all.length; i++) {
          if (state.settings.delivery.templates.all[i].id == templateId) {
            state.settings.delivery.templates.all.splice(i, 1);
          }
        }
        for (
          let i = 0;
          i < state.settings.delivery.templates.active.length;
          i++
        ) {
          if (state.settings.delivery.templates.active[i].id == templateId) {
            state.settings.delivery.templates.active.splice(i, 1);
          }
        }
      }
    },

    socket_chatEvent({ state }, event) {
      if (event.event === 'chatDeactivation') {
        /*console.log('Deactivation', event.data.roomId)*/
        state.dialogs.list.map((dialog) => {
          if (
            dialog.roomId === event.data.room ||
            dialog.user.id === event.data.manId ||
            dialog.user.id === event.data.girlId
          ) {
            dialog.active = false;
            dialog.roomStatus = 'stop';
            dialog.user.chatActivated = false;
            dialog.user.chat_status = 'stop';
            if (state.user.type === 'woman') {
              let infoMessage = {
                type: 'info',
                action: 'end',
                reade: true,
                key: Date.now(),
                time: Math.floor(Date.now() / 1000),
                chatType: 'text',
              };
              dialog.messages.push(infoMessage);
            }
          }
        });
      }

      if (event.event === 'chatActivation') {
        const newChatType = event.data.chatType.replace('Chat', '');

        state.dialogs.list.forEach((dialog) => {
          if (dialog.roomId) {
            if (dialog.roomId === event.data.room) {
              if (state.user.type === 'woman' && !dialog.active) {
                let infoMessage = {
                  type: 'info',
                  action: 'start',
                  reade: true,
                  key: Date.now(),
                  time: Math.floor(Date.now() / 1000),
                  chatType: newChatType,
                };
                dialog.messages.push(infoMessage);
              }
              if (!dialog.active && state.settings.sound.sound) {
                window.sounds.newMessage.track.play();
              }
              dialog.active = true;
              dialog.roomStatus = 'active';
              dialog.type = newChatType;
              dialog.user.chat_type = newChatType;
              dialog.user.chatActivated = true;
              dialog.user.chat_status = 'active';
            }
          } else {
            if (
              dialog.user.id === event.data.manId ||
              dialog.user.id === event.data.girlId
            ) {
              /* console.log('Chat activation by ids.')*/
              dialog.roomId = event.data.room;
              if (state.user.type === 'woman' && !dialog.active) {
                let infoMessage = {
                  type: 'info',
                  action: 'start',
                  reade: true,
                  key: Date.now(),
                  time: Math.floor(Date.now() / 1000),
                  chatType: newChatType,
                };
                dialog.messages.push(infoMessage);
              }

              if (!dialog.active && state.settings.sound.sound) {
                window.sounds.newMessage.track.play();
              }

              dialog.active = true;
              dialog.roomStatus = 'active';
              dialog.type = newChatType;
              dialog.user.chat_type = newChatType;
              dialog.user.chatActivated = true;
              dialog.user.chat_status = 'active';
            }
          }
        });
      }

      if (event.event === 'chatChangeTypeEvent') {
        /*console.log('chatChangeTypeEvent', event.data.room)*/
        state.dialogs.list.map((dialog) => {
          if (dialog.roomId === event.data.room) {
            const newChatType = event.data.chatType.replace('Chat', '');
            /*if (state.user.type === 'woman') {
              let infoMessage = {
                type: 'info',
                action: 'change',
                reade: true,
                key: Date.now(),
                time: Math.floor(Date.now() / 1000),
                chatType: newChatType,
              }
              dialog.messages.push(infoMessage)
            }*/
            dialog.type = newChatType;
            dialog.user.chat_type = newChatType;
          }
        });
      }
      /*const test = state.dialogs.list.filter((dialog)=>{})*/
    },

    socket_registrationResponse({ commit }, response) {
      if (response.status) {
        //console.log('Chat session established');

        commit('chatRegistration');
      } else {
        commit('addAlert', {
          type: 'error',
          text: `Sorry. We couldn't sign you in. Please try again. `,
          info: 'reg error ',
        });
        commit('addAlert', {
          type: 'error',
          text: `This page will be reloaded automatically after 5 seconds.`,
          info: 'reg error ',
        });
        /*window.location = state.app.config.site_url*/
        setTimeout(() => {
          window.location.reload();
        }, 5000);
      }
    },

    socket_newLogout({ state }) {
      window.socketIo.disconnect();
      window.location = state.app.config.site_url;
    },

    socket_photoBuy({ state }, payload) {
      let dialog = state.dialogs.list.find(
        (i) => i.user.id === payload.target_user_id
      );
      if (dialog) {
        dialog.messages.forEach((i) => {
          if (i.photo === payload.photo_id) {
            i.bought = true;
          }
        });
      }
    },

    socket_message({ commit, state }, message) {
      if (message.status === false) {
        if (message.desc === 'no-money') {
          commit('updateLowCreditModal', {
            open: true,
          });
        }
        return;
      }

      let count = 0;
      state.last_dialogs.forEach((lDialog) => {
        if (
          lDialog.id === message.receiver &&
          message.receiver !== state.user.id
        ) {
          state.last_dialogs.unshift(...state.last_dialogs.splice(count, 1));
          // console.log('Receive to top');
        } else if (
          lDialog.id === message.sender &&
          message.sender !== state.user.id
        ) {
          state.last_dialogs.unshift(...state.last_dialogs.splice(count, 1));
          // console.log('Sender to top');
        } else {
          count++;
        }
      });

      if (state.app.current_state !== 'app-private') {
        if (state.settings.sound.sound) {
          const dialog = state.dialogs.list.find(
            (dialog) => dialog.user && dialog.user.id === message.sender
          );
          if (message.sender === state.user.id) {
            window.sounds.wMessage.track.play();
          } else {
            if (dialog && dialog.active) {
              window.sounds.newMessage.track.play();
            }
          }
        }
      }

      if (message.status || typeof message.status === 'undefined') {
        let refDialog = null,
          newDate = false,
          last_time = 0,
          companion;

        if (message.data) {
          companion =
            +message.data.receiver === state.user.id
              ? +message.data.sender
              : +message.data.receiver;
        } else {
          companion =
            +message.receiver === state.user.id
              ? +message.sender
              : +message.receiver;
        }

        refDialog = state.dialogs.list.find(
          (item) => item.user.id === companion
        );

        let collectData;

        if (refDialog) {
          if (refDialog.messages.length) {
            last_time = refDialog.messages[refDialog.messages.length - 1].time;
          } else {
            newDate = true;
          }
        } else {
          collectData = new Promise((resolve, reject) => {
            let refUser;
            refUser = state.user.all_online.find(
              (user) => user.id === companion
            );

            if (!refUser) {
              Vue.http
                .post('v1/profile/profile', {
                  access_token: state.session.site_token,
                  target_user_id: companion,
                })
                .then(
                  (result) => {
                    let body = result.body;
                    if (body.status) {
                      let data = body.result;
                      refUser = {
                        id: data.user_id,
                        chat_type: 'text',
                        chat_time: 0,
                        chat_status: 'stop',
                        profile: {
                          avatarList: data.avatar,
                          name: data.name,
                          birthday: data.birthday,
                        },
                        camera: data.camera,
                        count_message: 0,
                        created_at: data.created_at,
                        isNew: data.isNew,
                        is_notification: false,
                        last_active: data.last_active,
                        status: data.status,
                        webCamStream: null,
                      };

                      newDate = true;
                      resolve({
                        user: refUser,
                        dialog: {
                          active: false,
                          roomId: message.room,
                          chat_start: 0,
                          last_active: refUser.last_active,
                          messages: [],
                          time_ranges: [],
                          type: 'text',
                          user: refUser,
                        },
                      });
                    }
                  },
                  () => {
                    reject('error while getting user profile');
                  }
                );
            }
            if (refUser) {
              refDialog = {
                active: false,
                roomId: message.room,
                chat_start: 0,
                last_active: refUser.last_active,
                messages: [],
                time_ranges: [],
                type: 'text',
                user: refUser,
              };

              resolve({
                user: refUser,
                dialog: refDialog,
              });

              newDate = true;
            }
          });
        }
        let finish = new Promise((resolve, reject) => {
          if (collectData) {
            collectData
              .then((r) => {
                refDialog = r.dialog;

                commit('createDialog', refDialog);
                resolve(true);
              })
              .catch((e) => {
                reject(e);
              });
          } else {
            resolve(true);
          }
        });
        finish
          .then(() => {
            let lastDate = new Date(last_time * 1000),
              todayDay = new Date();

            newDate =
              lastDate.getFullYear() !== todayDay.getFullYear() ||
              lastDate.getMonth() !== todayDay.getMonth() ||
              lastDate.getDate() !== todayDay.getDate();
            let messageObject = null;
            let reade = false;
            if (
              state.dialogs.current &&
              state.dialogs.current.user.id &&
              refDialog.user &&
              refDialog.user.id === state.dialogs.current.user.id
            ) {
              reade = true;
            }

            if (message.photoId) {
              //photoID
              if (message.photoSell) {
                let infoMessage = {
                  type: 'photo',
                  reade: true,
                  photo: +message.photoId,
                  bought: false,
                  key: message._id,
                  time: Math.floor(Date.now() / 1000),
                  receiver: message.receiver,
                  sender: message.sender,
                  chatType: refDialog.type,
                  sound: true, // for playing sound once, Changes in woman chat component
                };
                refDialog.messages.push(infoMessage);
              } else if (message.photoMan) {
                let infoMessage = {
                  type: 'freephoto',
                  reade: true,
                  photo: +message.photoId,
                  bought: true,
                  key: message._id,
                  time: Math.floor(Date.now() / 1000),
                  receiver: message.receiver,
                  sender: message.sender,
                  chatType: refDialog.type,
                  sound: true, // for playing sound once, Changes in woman chat component
                };
                refDialog.messages.push(infoMessage);
              }
            } else {
              if ('messageObject' in message) {
                messageObject = {
                  type: 'message',
                  key: message.messageObject._id,
                  room: message.messageObject.room,
                  isInvite: message.messageObject.freeInvite,
                  receiver: message.messageObject.receiver,
                  sender: message.messageObject.sender,
                  time: Math.floor(Date.now() / 1000),
                  text: message.messageObject.text,
                  newDate,
                  reade,
                  sound: true, // for playing sound once, Changes in woman chat component
                };
              } else if (!message.data) {
                messageObject = {
                  type: 'message',
                  key: message._id,
                  isInvite: message.freeInvite,
                  room: message.room,
                  receiver: message.receiver,
                  sender: message.sender,
                  time: Math.floor(Date.now() / 1000),
                  text: message.text,
                  sessionError: message.sessionError,
                  newDate,
                  reade,
                  sound: true, // for playing sound once, Changes in woman chat component
                };
              } else {
                messageObject = {
                  type: 'message',
                  key: message._id,
                  room: message.room,
                  isInvite: message.freeInvite,
                  receiver: message.data.receiver,
                  sender: message.data.sender,
                  time: Math.floor(Date.now() / 1000),
                  text: message.data.text,
                  sessionError: message.sessionError,
                  newDate,
                  reade,
                  sound: true, // for playing sound once, Changes in woman chat component
                };
              }
              refDialog.roomId = messageObject.room;
              refDialog.messages.push(messageObject);

              /*if (
                !refDialog.user.chatActivated &&
                message.chatStartMessage &&
                state.user.type === 'woman'
              ) {
                refDialog.user.chatActivated = true

                let infoMessage = {
                  type: 'info',
                  action: 'start',
                  reade: true,
                  key: Date.now(),
                  time: Math.floor(Date.now() / 1000),
                  chatType: refDialog.type,
                }
                refDialog.messages.push(infoMessage)
              }*/
            }
          })
          .catch((e) => console.log(e));
      }
    },

    getLastDialog({ commit, state }, messages) {
      let currentChatFiltered = [];
      let lastMessage = null;
      let newMessages = [];
      if (state.dialogs.current) {
        currentChatFiltered = state.dialogs.current
          ? state.dialogs.current.messages.filter(
              (message) => message.type !== 'info'
            )
          : [];
        lastMessage = currentChatFiltered[currentChatFiltered.length - 1];
        newMessages = lastMessage
          ? messages.filter((message) => message.created > lastMessage.time)
          : [];
      } else {
        newMessages = messages;
      }

      // state.dialogs.current.messages
      let lastDate =
        moment.unix(lastMessage ? lastMessage.time : '').format('LL') || '';
      newMessages.forEach((message) => {
        if (message.status || typeof message.status === 'undefined') {
          let refDialog = null;
          let newDate = false;
          let companion = message.data
            ? +message.data.receiver === state.user.id
              ? +message.data.sender
              : +message.data.receiver
            : +message.receiver === state.user.id
            ? +message.sender
            : +message.receiver;

          refDialog = state.dialogs.list.find((i) => {
            if (i !== undefined) {
              return i.user.id === companion;
            } else {
              return false;
            }
          });
          let collectData;

          if (refDialog) {
            if (!refDialog.messages.length) {
              newDate = lastDate !== moment.unix(message.created).format('LL');
              lastDate = moment.unix(message.created).format('LL');
            }
          } else {
            collectData = new Promise((resolve, reject) => {
              let refUser;
              refUser = state.user.all_online.find(
                (user) => user.id === companion
              );
              if (!refUser) {
                Vue.http
                  .post('v1/profile/profile', {
                    access_token: state.session.site_token,
                    target_user_id: companion,
                  })
                  .then(
                    (result) => {
                      let body = result.body;
                      if (body.status) {
                        let data = body.result;
                        refUser = {
                          id: data.user_id,
                          chat_type: 'text',
                          chat_time: 0,
                          chat_status: 'stop',
                          profile: {
                            avatarList: data.avatar,
                            name: data.name,
                            birthday: data.birthday,
                          },
                          camera: data.camera,
                          count_message: 0,
                          created_at: data.created_at,
                          isNew: data.isNew,
                          is_notification: false,
                          last_active: data.last_active,
                          status: data.status,
                          webCamStream: null,
                        };

                        newDate = true;
                        resolve({
                          user: refUser,
                          dialog: refDialog,
                        });
                      }
                    },
                    () => {
                      reject('error while getting user profile');
                    }
                  );
              }
              if (refUser) {
                refDialog = {
                  active: false,
                  chat_start: 0,
                  last_active: refUser.last_active,
                  messages: [],
                  time_ranges: [],
                  type: 'text',
                  user: refUser,
                };

                resolve({
                  user: refUser,
                  dialog: refDialog,
                });

                newDate = true;
              }
            });
          }
          const finish = new Promise((resolve, reject) => {
            if (collectData) {
              collectData
                .then((r) => {
                  refDialog = r.dialog;

                  commit('createDialog', refDialog);
                  resolve(true);
                })
                .catch((e) => {
                  reject(e);
                });
            } else {
              resolve(true);
            }
          });
          finish
            .then(() => {
              let messageObject = null;
              let reade = false;
              if (
                state.dialogs.current &&
                state.dialogs.current.user.id &&
                refDialog.user.id === state.dialogs.current.user.id
              ) {
                reade = true;
              }

              if (message.photoId || message.photoSell) {
                //photoID
                let infoMessage = {
                  type: 'photo',
                  reade: true,
                  photo: +message.photoId,
                  bought: false,
                  key: message._id,
                  time: message.created,
                  receiver: message.receiver,
                  sender: message.sender,
                  chatType: refDialog.type,
                  sound: true, // for playing sound once, Changes in woman chat component
                };
                refDialog.messages.push(infoMessage);
              } else {
                if ('messageObject' in message) {
                  messageObject = {
                    type: 'message',
                    key: Date.now(),
                    isInvite: message.messageObject.freeInvite,
                    receiver: message.messageObject.receiver,
                    sender: message.messageObject.sender,
                    time: message.created,
                    text: message.messageObject.text,
                    newDate,
                    reade,
                    sound: true, // for playing sound once, Changes in woman chat component
                  };
                } else if (!message.data) {
                  messageObject = {
                    type: 'message',
                    key: message._id,
                    isInvite: message.messageObject
                      ? message.messageObject.freeInvite
                      : message.freeInvite,
                    receiver: message.receiver,
                    sender: message.sender,
                    time: message.created,
                    text: message.text,
                    newDate,
                    reade,
                    sound: true, // for playing sound once, Changes in woman chat component
                  };
                } else {
                  messageObject = {
                    type: 'message',
                    key: message._id,
                    isInvite: message.freeInvite,
                    receiver: message.data.receiver,
                    sender: message.data.sender,
                    time: message.created,
                    text: message.data.text,
                    newDate,
                    reade,
                    sound: true, // for playing sound once, Changes in woman chat component
                  };
                }
                refDialog.messages.push(messageObject);

                if (
                  !refDialog.user.chatActivated &&
                  message.chatStartMessage &&
                  state.user.type === 'woman'
                ) {
                  refDialog.user.chatActivated = true;

                  let infoMessage = {
                    type: 'info',
                    action: 'start',
                    reade: true,
                    key: message._id,
                    time: message.created,
                    chatType: refDialog.type,
                  };
                  refDialog.messages.push(infoMessage);
                }
              }
            })
            .catch((e) => console.log(e));
        }
      });
    },

    changePayIteratorForNewChatType({ commit }, woman_id) {
      commit('CHANGE_PAY_ITERATOR_FOR_NEW_CHAT_TYPE', woman_id);
    },
    addMessage({ commit }, message) {
      commit('ADD_MESSAGE', message);
    },
    setActiveChat({ commit }, user) {
      commit('SET_ACTIVE_CHAT', user);
    },
    setLastDialogsLoaded({ commit }, loaded) {
      commit('SET_LAST_DIALOGS_LOADED', loaded);
    },
    removeFromIgnoreArray({ commit }, user) {
      commit('REMOVE_FROM_IGNORE', user);
    },
    changeExcludeFilters({ commit }, payload) {
      commit('CHANGE_EXCLUDE_DELIVERY_FILTERS', payload);
    },
    removeFromActiveDeliveryTemplates({ commit }, template) {
      commit('REMOVE_FROM_ACTIVE_TEMPLATE', template);
    },
    addToActiveTemplate({ commit }, template) {
      commit('ADD_TO_ACTIVE_DELIVERY_TEMPLATES', template);
    },
    setIgnoreCategory({ commit }, ignore) {
      commit('SET_DELIVERY_IGNORE_CATEGORY', ignore);
    },
    setDeliveryOpenTab({ commit }, tab) {
      commit('SET_DELIVERY_TAB', tab);
    },
    sendMessage({ commit }, message) {
      commit('SEND_MESSAGE', message);
    },
    changeChatType({ commit }, type) {
      commit('SET_CURRENT_CHAT_TYPE', type);
    },
    setCurrentAppUser({ commit }, user) {
      commit('SET_USER', user);
    },
    setConnectedToSocket({ commit }, connected) {
      commit('SET_CONNECTED_TO_SOCKET', connected);
    },
    setFavorites({ commit }, favorites) {
      commit('SET_FAVORITES', favorites);
    },
    setBookmarks({ commit }, bookmarks) {
      commit('SET_BOOKMARKS', bookmarks);
    },
    setToken({ commit }, token) {
      commit('SET_TOKEN', token);
    },
    setOnlineList({ commit }, online) {
      commit('SET_ONLINE', online);
    },
    setCurrentMessage({ commit }, message) {
      commit('SET_CURRENT_TYPED_MESSAGE', message);
    },
    setLastDialogs({ commit }, dialogs) {
      commit('SET_LAST_DIALOGS', dialogs);
    },
    setCurrentChat({ commit }, woman) {
      commit('SET_CURRENT_CHAT', woman);
    },
    manSendMessage({ commit }, message) {
      commit('MAN_SEND_MESSAGE', message);
    },
    setMyBalance({ commit }, balance) {
      commit('SET_MY_BALANCE', balance);
    },
    destroyInvites({ commit }, invite) {
      commit('DESTROY_INVITES', invite);
    },
    addNewDialog({ commit }, dialog) {
      commit('ADD_NEW_DIALOG', dialog);
    },
    setMyBalanceIterator({ commit }, iterator) {
      commit('SET_MY_BALANCE_ITERATOR', iterator);
    },
    setResponsiveShowUserOnline({ commit }, status) {
      commit('SET_RESPONSIVE_SHOW_USER_ONLINE', status);
    },
    setResponsiveShowLastDialogs({ commit }, status) {
      commit('SET_RESPONSIVE_SHOW_LAST_DIALOGS', status);
    },
    setAllBookmarks({ commit }, arrayId) {
      commit('SET_ALL_BOOKMARKS', arrayId);
    },
    setAllFavorites({ commit }, arrayId) {
      commit('SET_ALL_FAVORITES', arrayId);
    },
  },
});
