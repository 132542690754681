<template>
  <aside
    class="last-dialogs-box wmn sidebar sidebar-last-dialogs-woman"
    :class="{ open: responsiveShowLastDialogs }"
  >
    <div class="header-box__item">
      <div class="chat-time">
        <div class="clock rbi" :class="{ start: chatStatus.active }">
          <span class="clock--short-hand"></span>
          <span class="clock--long-hand"></span>
          <span class="clock--dot"></span>
        </div>
        <span
          style="margin-left: 5px"
          :class="{
            'status-start': chatStatus.tip === 'start',
            'status-stop': chatStatus.tip === 'spec-stop',
          }"
          >{{ chatStatus.text }}</span
        >
      </div>
      <div class="wmn_toggle toggle-sound">
        <button class="btn-reset" @click="toggleSound">
          <svg v-if="soundSettings.sound" class="rbi">
            <use xlink:href="/static/assets/images/rbi-icon.svg#rbi-volume" />
          </svg>
          <svg v-else class="rbi">
            <use
              xlink:href="/static/assets/images/rbi-icon.svg#rbi-volume_mute"
            />
          </svg>
        </button>
      </div>
      <div class="">
        <camera></camera>
      </div>
    </div>
    <div
      v-show="responsiveShowLastDialogs"
      class="sb-dummy right"
      @click="responsiveCloseLastDialogs"
    ></div>
    <div class="ovh white-box">
      <div class="sidebar__header">
        <h2 class="sidebar__header_h"><span>Последние чаты</span></h2>
        <button class="btn-close" @click="responsiveCloseLastDialogs">
          <svg class="rbi">
            <use
              xlink:href="/static/assets/images/rbi-icon.svg#rbi-close"
            ></use>
          </svg>
        </button>
      </div>
      <div class="sidebar__content">
        <div class="tab-content">
          <div class="tab-pane" :class="{ active: tabActiveAll }">
            <div class="dialogs-simplebar">
              <SimpleBar
                data-simplebar-auto-hide="true"
                class="simplebar-right-side"
              >
                <div>
                  <chat-invite
                    v-for="item in invites"
                    :key="item.params.senderId"
                    :invite="item"
                  ></chat-invite>
                </div>
                <div>
                  <man-dialog
                    v-for="(dialog, key) in dialogs"
                    :key="key"
                    :woman="dialog"
                  ></man-dialog>
                </div>
              </SimpleBar>
            </div>
          </div>
        </div>
      </div>
    </div>
  </aside>
</template>

<script>
import store from '../../store/store';
import ManUserDialogItem from '../man/items/dialog-item';
import invite from './items/chat-invites.vue';
import { BEAUTY_TIME } from '../../mixins/woman_util';
import { SOUND, VIDEO_SIZE } from '../../mixins/utils';
import Camera from './video/camera';

export default {
  scrollDef: null,
  scrollInvites: null,
  name: 'RightSide',
  store,
  components: {
    Camera,
    'man-dialog': ManUserDialogItem,
    'chat-invite': invite,
  },
  mixins: [BEAUTY_TIME, SOUND, VIDEO_SIZE],

  data() {
    return {
      tabActiveAll: true,
    };
  },
  computed: {
    responsiveShowLastDialogs() {
      return this.$store.getters.settings.responsive.showLastDialogs;
    },
    visible() {
      return true;
    },
    invites() {
      return this.$store.getters.invites;
    },
    dialogs() {
      return this.$store.getters.lastDialogs;
    },
    lastDialogsCount() {
      return this.$store.getters.lastDialogs.length;
    },
    hasUnreadMessages() {
      let allDialogs = this.$store.getters.dialogs.list;
      let hasMessages = false;
      for (let i = 0; i < allDialogs.length; i++) {
        if (
          allDialogs[i] !== undefined &&
          'user' in allDialogs[i] &&
          allDialogs[i].user.count_message > 0
        ) {
          if (hasMessages === false) {
            hasMessages = 0;
            hasMessages += 1;
          } else {
            hasMessages += 1;
          }
        }
      }
      return hasMessages;
    },
    cameraInProcess() {
      return this.$store.getters.user.cameraProcess;
    },
    ifSound() {
      return this.$store.getters.notification;
    },
    camera() {
      return this.$store.getters.getUserCameraIsActive === true;
    },
    chatTime() {
      return this.$store.getters.chatTime;
    },
    chatStatus() {
      if (this.currentDialog) {
        if (this.currentDialog.active)
          return {
            active: true,
            text: 'Чат',
            tip: 'current',
          };
        else {
          return {
            active: false,
            text: 'Стоп',
            tip: 'spec-stop',
          };
        }
      } else {
        return {
          active: false,
          text: '',
          tip: 'spec-stop',
        };
      }
    },
    currentDialog() {
      return this.$store.getters.dialogsCurrent;
    },
  },
  watch: {
    invites(v) {
      if (!v.length) this.tabActiveAll = true;
    },
    responsiveShowLastDialogs(v) {
      if (v) this.tabActiveAll = true;
    },
  },
  mounted() {},
  beforeMount() {},

  methods: {
    responsiveCloseLastDialogs() {
      this.$store.dispatch('setResponsiveShowLastDialogs', false);
    },
    soundOff() {
      this.$store.commit('SOUND_OFF', {});
    },
    soundOn() {
      this.$store.commit('SOUND_ON', {});
    },
  },
};
</script>

<style lang="scss">
.sidebar-last-dialogs-woman {
  height: 100%;
  width: 100%;
  overflow: hidden;
  @media screen and (min-width: 992px) {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .header-box__item {
      color: white;
      padding: 1rem;
      display: flex;
      justify-content: space-between;
    }

    .white-box {
      /*  border-right: 1px solid #d9d9d9;*/
      position: relative;
    }
  }
  .sidebar__content {
    background: white;
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .simplebar-right-side {
    height: 100%;
    overflow-x: hidden;
  }
  .dialogs-simplebar,
  .invites-simplebar {
    height: 100%;
    overflow: hidden;
  }
  .header-box__item {
    color: white;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
  }
  .header-box__item .chat-time,
  .currency-wrap,
  .toggle-sound {
    width: 33.333%;
    display: flex;
    justify-content: center !important;
  }
  .chat-time .rbi {
    margin: 0 !important;
  }
  .currency-wrap {
  }
  .toggle-sound {
  }
  @media screen and (max-width: 992px) {
    .header-box__item {
      display: none;
    }
  }
  .last-chat-box {
    /*border-left: 1px solid #d9d9d9;*/
  }
}
</style>
