<template>
  <transition name="modal">
    <div
      v-show="modal.open"
      class="vue-modal modal-mask pr-m"
      :class="{ loading: !loaded }"
      @click="closeModal($event.target, false)"
    >
      <div class="vue-modal--wrapper">
        <div v-show="!loaded" class="pr-m--loader">
          <div class="loader"></div>
        </div>
        <div v-show="loaded" class="vue-modal--container pr-m--body">
          <div class="modal-header">
            <h4 class="modal-title d-flex">
              <span>{{ user.name }}, {{ mixinGetAge(user.birthday) }}</span>
              <span class="d-flex"
                ><small>({{ date }})</small>
                <zodiac-tpl
                  v-if="user.birthday !== null"
                  :timestamp="user.birthday"
                ></zodiac-tpl
              ></span>
            </h4>
            <button
              type="button"
              class="close"
              @click="closeModal(false, true)"
            >
              <svg class="rbi">
                <use
                  xlink:href="/static/assets/images/rbi-icon.svg#rbi-close"
                ></use>
              </svg>
            </button>
          </div>
          <div class="modal-body">
            <div class="pr-m--content">
              <dl class="pr-m--content__item">
                <dt>
                  <span class="def">Country:</span>
                </dt>
                <dd>{{ noData(user.address_country) }}</dd>
              </dl>
              <dl class="pr-m--content__item">
                <dt>
                  <span class="def">City:</span>
                </dt>
                <dd>{{ noData(user.address_city) }}</dd>
              </dl>
              <dl class="pr-m--content__item">
                <dt>
                  <span class="def">Marital:</span>
                </dt>
                <dd>{{ noData(profile.family_status) }}</dd>
              </dl>
              <dl class="pr-m--content__item">
                <dt>
                  <span class="def">Children:</span>
                </dt>
                <dd>{{ noData(profile.children) }}</dd>
              </dl>
              <dl class="pr-m--content__item">
                <dt>
                  <span class="def">Religion:</span>
                </dt>
                <dd>{{ noData(profile.religion) }}</dd>
              </dl>

              <dl class="pr-m--content__item">
                <dt>
                  <span class="def">Occupation:</span>
                </dt>
                <dd>{{ noData(profile.profession) }}</dd>
              </dl>
              <dl class="pr-m--content__item">
                <dt>
                  <span class="def">Interests:</span>
                </dt>
                <dd>{{ noData(profile.interests) }}</dd>
              </dl>
              <dl class="pr-m--content__item">
                <dt>
                  <span class="def">Goals:</span>
                </dt>
                <dd>{{ noData(goals) }}</dd>
              </dl>
              <div class="bdt">
                <div class="bdt-wrap">
                  <div class="grd d-flex">
                    <dl class="pr-m--content__item full">
                      <dt>
                        <span class="def">About me:</span>
                      </dt>
                      <dd>{{ noData(profile.about_me) }}</dd>
                    </dl>
                    <dl class="pr-m--content__item full">
                      <dt>
                        <span class="def"
                          >{{
                            sex.isMan ? 'Type of man' : 'Type of woman'
                          }}:</span
                        >
                      </dt>
                      <dd>
                        {{
                          sex.isMan
                            ? noData(profile.about_man)
                            : noData(profile.about_woman_text)
                        }}
                      </dd>
                    </dl>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import zodiacTpl from './parts/zodiac/_template.vue';
import { GET_AGE, PREVIEW_MODAL } from '../../../mixins/utils';
import moment from 'moment';

export default {
  name: 'ProfileModal',
  components: {
    zodiacTpl,
  },
  mixins: [GET_AGE, PREVIEW_MODAL],

  data() {
    return {};
  },
  computed: {
    token() {
      return this.$store.getters.token;
    },
    date() {
      return moment(this.user.birthday * 1000).format('Do MMM, YYYY');
    },
    goals() {
      let tmp = [
        {
          k: 'Friendship',
          v: this.profile.target_friendship,
        },
        {
          k: 'Communication',
          v: this.profile.target_communication,
        },
        {
          k: 'Journey',
          v: this.profile.target_journey,
        },
        {
          k: 'Flirtation',
          v: this.profile.target_flirtation,
        },
        {
          k: 'Meeting',
          v: this.profile.target_meeting,
        },
        {
          k: 'Family',
          v: this.profile.target_family,
        },
        {
          k: 'Children',
          v: this.profile.target_children,
        },
      ];

      return tmp
        .filter((item) => {
          return item.v;
        })
        .map((item) => item.k)
        .join(', ');
    },
    sex() {
      return this.$store.getters.sex;
    },
    modal() {
      return this.$store.getters.profileModal;
    },
    loaded() {
      return this.modal.loaded;
    },
    profile() {
      return this.user.profile_additional_info || {};
    },
    user() {
      return this.modal.user;
    },
  },
  watch: {
    loaded(v) {
      if (v) this.makeGuest();
    },
  },
  methods: {
    closeModal(e) {
      // eslint-disable-next-line no-undef
      if (!$(e).closest('.pr-m--body').length || !e) {
        this.$store.commit('updateProfileModal', {
          open: false,
          user: {},
          loaded: false,
        });
      }
    },
    makeGuest() {
      this.$http.post('v1/guest/make', {
        access_token: this.token.site_token,
        target_user_id: this.user.user_id,
      });
    },
  },
};
</script>

<style lang="scss">
@import '../../../assets/scss/variables-bootstrap';
@import '../../../assets/scss/vars';

.pr-m {
  &.loading {
    align-items: center;
  }

  &--body {
    width: 950px;
    max-width: 100%;

    .bdt {
      margin-top: 15px;
      padding: 0 15px;
      width: 100%;
      &-wrap {
        border-top: 1px solid $grey-two;
        padding-top: 15px;
        .grd {
          margin: 0 -15px;
        }
      }
    }

    .zodiac-icon {
      margin-left: 6px;

      svg {
        fill: $brand-primary;
        height: 14px;
        width: 14px;
      }
    }

    .modal-title {
      align-items: baseline;

      span {
        display: block;

        + span {
          margin-left: 5px;
        }
      }

      .d-flex {
        display: flex !important;
        align-items: baseline;
      }

      .small {
        display: block;
      }
    }
  }

  &--content {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;

    &__item {
      display: flex;
      align-items: flex-start;
      flex: 0 0 33.33%;
      padding: 0 15px;

      .icon + .def {
        margin-left: 5px;
      }

      .icon,
      .def {
        display: block;
      }

      &.full {
        flex: 0 0 50%;
        flex-direction: column;
        dd {
          margin-left: 1em;
        }
      }

      dt {
        display: flex;
        align-items: center;
        flex: 0 0 auto;
        font-size: 14px;
        &:before {
          content: '';
          display: inline-block;
          width: 6px;
          min-width: 6px;
          height: 6px;
          background: $red;
          margin-right: 0.5em;
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          border-radius: 50%;
        }
      }

      dd {
        word-break: break-all;
        /* Non standard for WebKit */
        word-break: break-word;
        hyphens: auto;
        font-size: 14px;
        margin-left: 0.5em;
      }
    }
  }

  .modal-header {
    border: none;
    margin-bottom: 15px;
    color: #000;
    padding-bottom: 15px !important;
    border-bottom: 1px solid $grey-two;

    small {
      color: #969696;
    }
    .close {
      opacity: 1;
    }
  }

  .modal-header,
  .modal-body {
    padding: 0;
  }

  h4 {
    > span {
      &:first-letter {
        color: $red;
      }
    }
  }
}

@media only screen and (max-width: 992px) {
  .pr-m {
    .vue-modal--wrapper,
    &--body {
      width: 100%;
    }
  }
  .pr-m--body .bdt-wrap .grd {
    flex-wrap: wrap;
  }
  .pr-m--content__item {
    flex: 0 0 50%;
    &.full {
      flex: 0 0 100%;
    }
  }
}

@media only screen and (max-width: 768px) {
  .pr-m {
    .vue-modal--wrapper,
    &--body {
      height: 100%;
    }

    .modal-body {
      max-height: calc(100% - 40px);
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;
      margin: -0px -5px;
    }

    .loader {
      position: absolute;
      top: calc(50% - 30px);
      left: calc(50% - 30px);
    }

    &--body {
      border-radius: 0;
      padding: 15px;

      .zodiac-icon {
        svg {
          height: 12px;
          width: 12px;
        }
      }
    }

    &--content {
      margin: 0;
    }
  }
  .pr-m--content__item {
    flex: 0 0 100%;
    &.full {
      flex: 0 0 100%;
    }
  }
}

@media only screen and (max-width: 480px) {
  .pr-m {
    h4 {
      font-size: 15px;
    }
  }
}
</style>
