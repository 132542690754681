<template>
  <aside
    id="users-online"
    class="accordion-inner sidebar"
    :class="{ open: responsiveShowUserOnline }"
  >
    <div class="header-box__item">
      <div class="logo-wrapper" style="width: 100%">
        <div class="logo"></div>
      </div>
      <div
        class="info-button"
        style="
          width: 20%;
          display: flex;
          flex-direction: column;
          justify-content: center;
        "
      >
        <svg
          class="rbi"
          style="height: 1.1em; width: 1.1em; cursor: pointer"
          @click="openVersionModal"
        >
          <use xlink:href="/static/assets/images/rbi-icon.svg#rbi-attention" />
        </svg>
      </div>
    </div>
    <div
      v-show="responsiveShowUserOnline"
      class="sb-dummy left"
      @click="responsiveCloseUserOnline"
    ></div>
    <div class="ovh online-box">
      <div class="sidebar__header">
        <h2 class="sidebar__header_h"><span>Girls online</span></h2>
        <button class="btn-close" @click="responsiveCloseUserOnline">
          <svg class="rbi">
            <use
              xlink:href="/static/assets/images/rbi-icon.svg#rbi-close"
            ></use>
          </svg>
        </button>
      </div>
      <div class="sidebar__content">
        <!-- Nav tabs -->
        <div class="sidebar__nav">
          <ul class="nav nav-tabs" role="tablist">
            <li role="presentation" class="active" @click="currentTab = 0">
              <a
                href="#all-online"
                aria-controls="all-online"
                role="tab"
                data-toggle="tab"
                title="All users"
              >
                All
              </a>
            </li>
            <li role="presentation" @click="currentTab = 1">
              <a
                href="#bookmarks"
                aria-controls="bookmarks"
                role="tab"
                data-toggle="tab"
                title="Bookmarks"
              >
                <svg class="rbi">
                  <use
                    xlink:href="/static/assets/images/rbi-icon.svg#rbi-star"
                  ></use>
                </svg>
                <span>({{ allBookmark }})</span>
              </a>
            </li>
            <li role="presentation" @click="currentTab = 2">
              <a
                href="#favourites"
                aria-controls="favourites"
                role="tab"
                data-toggle="tab"
                title="Favourites"
              >
                <svg class="rbi">
                  <use
                    xlink:href="/static/assets/images/rbi-icon.svg#rbi-heart"
                  ></use>
                </svg>
                <span>({{ allFavorite }})</span>
              </a>
            </li>
          </ul>
        </div>
        <!-- Tab panels -->
        <div class="tab-content">
          <online-woman v-if="currentTab === 0"></online-woman>
          <bookmark-woman v-else-if="currentTab === 1"></bookmark-woman>
          <favorite-woman v-else-if="currentTab === 2"></favorite-woman>
        </div>
      </div>
    </div>
  </aside>
</template>

<script>
import OnlineWoman from './lists/online-woman.vue';
import FavoriteWoman from './lists/favorite-woman.vue';
import BookmarkWoman from './lists/bookmark-woman.vue';

export default {
  name: 'LeftSide',
  components: {
    'online-woman': OnlineWoman,
    'favorite-woman': FavoriteWoman,
    'bookmark-woman': BookmarkWoman,
  },
  data() {
    return {
      currentTab: 0,
    };
  },
  computed: {
    responsiveShowUserOnline() {
      return this.$store.getters.settings.responsive.showUserOnline;
    },
    allCount() {
      return this.$store.getters.countAllOnline;
    },
    allBookmark() {
      return this.$store.getters.countAllBookmark;
    },
    allFavorite() {
      return this.$store.getters.countAllFavorite;
    },
  },
  methods: {
    responsiveCloseUserOnline() {
      this.$store.dispatch('setResponsiveShowUserOnline', false);
    },
    openVersionModal() {
      this.$store.commit('updateVersionChangeModal', { open: true });
    },
  },
};
</script>

<style lang="scss" scoped>
.sidebar {
  height: 100%;
  width: 100%;
  overflow: hidden;
  @media screen and (min-width: 992px) {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .header-box__item {
      color: white;
      padding: 1rem;
      display: flex;
      justify-content: space-between;
    }

    .online-box {
      /*  border-right: 1px solid #d9d9d9;*/
      // border-right: 1px solid #d9d9d9 !important;
      position: relative;
    }
  }
}

.sidebar__content {
  background: white;
  display: flex;
  flex-direction: column;
  height: 100%;
}

@media screen and (max-width: 992px) {
  .header-box__item {
    display: none;
  }
  .tab-content-bookmarks {
    margin-top: 45px;
  }
}
</style>
