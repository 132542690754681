<template>
  <div
    id="dialogBox"
    class="dialog-box-new"
    :class="{ 'dialog-box-active-input': inputActive }"
  >
    <div class="top-box">
      <AbsoluteVideoContainer
        v-show="!isActive || chatType === 2 || (chatType === 1 && cameraOn)"
      />

      <profile-box
        v-if="(chatType === 1 || chatType === 2) && hasCurrentChat"
        :woman="currentChatUser"
      ></profile-box>

      <!--      <controls
        v-if="isIphone && (chatType === 1 || chatType === 2) && hasCurrentChat"
        :woman="currentChatUser"
      ></controls> -->

      <video-container
        v-show="chatType === 3 || chatType === 4"
      ></video-container>
    </div>

    <div v-if="currentChatMessages.length > 0" class="messages-box">
      <SimpleBar
        ref="simplebarMessages"
        data-simplebar-auto-hide="false"
        class="simplebar-messages"
      >
        <div v-show="!noMoreMessages" class="load-more-messages-button">
          <rb-button
            style="height: 45px; width: 130px; padding: 0; margin-top: 5px"
            @click.native="loadMoreHandler"
          >
            <div v-show="preloader">
              <div
                style="width: 20px; height: 20px"
                class="ph-mes__loader loader"
              ></div>
            </div>
            <div v-show="!preloader">Load 20 more</div>
          </rb-button>
        </div>

        <message
          v-for="message in currentChatMessages"
          :key="message.key"
          :message="message"
          :user="currentChat.user"
          @msg="scrollToBottom($event)"
        ></message>
        <div id="crutch_dialog"></div>
      </SimpleBar>
    </div>
    <div
      v-else
      style="
        height: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        text-align: center;
        color: slategray;
      "
    >
      No messages
    </div>
  </div>
</template>

<script>
import MessageTemplate from './chat-item/message/_template.vue';
import RbButton from '../../shared/Button';
/* import Controls from './Controls'; */
import AbsoluteVideoContainer from '../../shared/video/AbsoluteVideoContainer';
import VideoContainer from '../../shared/video/VideoContainer';
import ProfileBox from './profile-box';

export default {
  name: 'DialogBox',
  components: {
    /*    Controls, */
    AbsoluteVideoContainer,
    VideoContainer,
    'profile-box': ProfileBox,
    RbButton,
    message: MessageTemplate,
  },
  computed: {
    inputActive() {
      return this.$store.getters.chatInputActive;
    },
    isIphone() {
      return this.$store.getters.device.isIOS;
    },
    isActive() {
      return this.currentChat ? this.currentChat.active : false;
    },
    hasCurrentChat() {
      return this.$store.getters.dialogsCurrent !== null;
    },
    ua() {
      return window.navigator ? window.navigator.userAgent : null;
    },
    currentChat() {
      return this.$store.getters.dialogsCurrent;
    },
    currentChatMessages() {
      return this.currentChat.messages ? this.currentChat.messages : [];
    },
    currentChatMessagesLength() {
      return this.currentChatMessages.length;
    },
    token() {
      return this.$store.getters.token;
    },
    preloader() {
      return this.$store.getters.loadingMoreMessages ? true : false;
    },
    noMoreMessages() {
      return this.$store.getters.dialogs.current.noMoreMessages;
    },
    user() {
      return this.$store.getters.dialogs.current.user.id;
    },
    currentChatUser() {
      return this.currentChat ? this.currentChat.user : null;
    },
    firstMessageInDialog() {
      return this.$store.getters.dialogs.current.messages[0];
    },
    connectedToStreamServer() {
      return this.$store.getters.getStreamServerConnected;
    },
    cameraOn() {
      return this.$store.getters.getUserCameraIsActive;
    },

    connectedToSocketIO() {
      return this.$store.getters.connectedToSocket === true;
    },
    isWebCam() {
      if (
        this.$store.getters.activeUsersList.length > 0 &&
        this.hasCurrentChat &&
        this.currentChat
      ) {
        const data = this.$store.getters.activeUsersList;
        let hasStream = false;

        data.forEach((user) => {
          if (
            user.userId === this.currentChat.user.id &&
            user.userCameraActive
          ) {
            hasStream = true;
          }
        });

        return hasStream;
      } else return false;
    },
    isOnlineOnVideoServer() {
      if (
        this.$store.getters.activeUsersList.length > 0 &&
        this.hasCurrentChat &&
        this.currentChat
      ) {
        const data = this.$store.getters.activeUsersList;
        let isOnline = false;

        data.forEach((user) => {
          if (user.userId === this.currentChat.user.id) {
            isOnline = true;
          }
        });

        return isOnline;
      } else return false;
    },
    chatType() {
      const dialog = this.$store.getters.dialogs.current;
      const userCameraOn = this.cameraOn;
      const collocutorCameraOn = this.isWebCam;
      let type;
      if (dialog) {
        if (!this.connectedToStreamServer || !this.connectedToSocketIO) {
          type = 1;
        } else {
          switch (this.$store.getters.dialogs.current.user.chat_type) {
            // Types:
            // 1 - standart text chat
            // 2 - man stream
            // 3 - woman stream
            // 4 - woman and man stream

            case 'video':
              if (
                userCameraOn &&
                collocutorCameraOn &&
                this.isOnlineOnVideoServer
              ) {
                // If man camera on
                type = 4;
              } else if (collocutorCameraOn) {
                type = 3;
              } else {
                type = 1;
              }

              break;
            case 'text':
              if (userCameraOn && this.isOnlineOnVideoServer) {
                // If man camera on
                type = 2;
              } else {
                type = 1;
              }

              break;
            default:
              type = 1;
              break;
          }
        }
      } else {
        type = 0;
      }
      return type;
    },
  },

  watch: {
    currentChatMessagesLength(newVal) {
      if (newVal !== 0) {
        setTimeout(() => {
          this.scrollToBottom();
        }, 600);
      }
    },
  },

  mounted() {
    if (
      this.currentChatMessagesLength &&
      this.currentChatMessagesLength !== 0
    ) {
      setTimeout(() => {
        this.scrollToBottom();
      }, 600);
    }
    // console.log('SB: ', this.$refs.sbNew);
    // this.scroll = new SimpleBar(this.$refs.simplebar, {});
    /*
    setTimeout(() => {
      const crutch = document.getElementById('crutch_dialog');
      if (!crutch) return;
      this.scrollToBottom(crutch);
    }, 600);*/
  },

  methods: {
    loadMoreHandler() {
      if (!this.preloader && !this.noMoreMessages) {
        const data = {
          user: this.user,
          firstMessageInDialog: this.firstMessageInDialog,
          limit: 20,
        };
        this.$store.dispatch('loadMoreMessages', data);
      }
    },
    scrollToBottom(element) {
      const e = element ? element : document.getElementById('crutch_dialog');

      if (
        this.$refs.simplebarMessages &&
        this.$refs.simplebarMessages.SimpleBar &&
        this.currentChat.messages.length
      ) {
        // remove error
        let container =
          this.$refs.simplebarMessages.SimpleBar.getScrollElement();
        let el;

        if (e) {
          if (this.preloader) {
            const divider = e.parentNode.querySelector('#msgDivider');
            el = divider;
          } else {
            el = e.parentNode.lastElementChild;
          }
        } else {
          if (this.preloader) {
            const divider =
              container.children[0].children[0].querySelector('#msgDivider');
            el = divider;
          } else {
            el = container.children[0].children[0].lastElementChild;
          }
        }
        /*if (e) {
         el = e.parentNode.lastElementChild
       } else {
         el = container.children[0].children[0].lastElementChild
       }*/
        if (el) {
          // eslint-disable-next-line no-undef
          $(container).scrollTo(el, 0);
        }
      }
    },
  },
};
</script>

<style lang="scss">
.isHorizontalIphone {
  .top-box {
    .info-box {
      display: none !important;
    }
  }
}

.dialog-box-new {
  background: #ffffff;
  position: relative;
  display: flex;
  flex-direction: column;
  // grid-template-rows: 30% 70%;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;

  .top-box {
    padding: 0.4em;
    width: 100%;
    // overflow: hidden;
  }

  @media screen and (max-width: 450px) {
    grid-template-rows: 40% 60%;
  }

  @media screen and (min-width: 992px) {
    .top-box {
      padding: 0.6em;
    }
    grid-template-rows: 40% 60% !important;
  }
}

.video-chat {
  .dialog-box-new {
    .top-box {
      height: 65%;
    }
    @media screen and (max-width: 992px) {
      .top-box {
        height: 55% !important;
      }
    }
    @media screen and (max-height: 425px) and (max-width: 992px) and (min-width: 425px) {
      .top-box {
        height: 70% !important;
      }
    }
  }
}

@media screen and (max-width: 450px) {
  .dialog-box-active-input {
    grid-template-rows: 100% !important;
    .top-box {
      display: none;
    }
  }
}

/*.ios-safari {
  .dialog-box-active-input {
    grid-template-rows: 100% !important;
    .top-box {
      display: none;
    }
  }
}*/

.simplebar-messages {
  position: relative;
  width: 100%;
  height: 100%;
  //overflow-x: hidden;
  overflow-y: auto;
  overflow-y: overlay;
  -webkit-overflow-scrolling: touch;
}

@media screen and (min-width: 992px) {
  .simplebar-messages {
    padding-right: 10px;
  }
}

.messages-box {
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.iphone-horizontal-controls {
  display: none;
}

.load-more-messages-button {
  justify-content: center;
  display: flex;
}
</style>
