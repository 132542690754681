<template>
  <!--<div class="chat-user" :class="{notification: user.is_notification, active: isActive}" @click="setActiveChat">-->
  <!--<div :class="{'is-new': isNew, 'is-online': isOnline}">-->
  <!--<div>-->
  <!--<img :src="user.profile.avatarList.img_small" :alt="user.profile.name + '\'s avatar'">-->
  <!--</div>-->
  <!--</div>-->
  <!--<div style="margin-top: 17px">-->
  <!--<span class="uname">-->
  <!--{{user.profile.name}}-->
  <!--</span>-->
  <!--<span class="uid">-->
  <!--ID: {{user.id}}-->
  <!--</span>-->
  <!--</div>-->
  <!--</div>-->

  <div
    class="user-item"
    :class="{ notification: user.is_notification, active: isActive }"
    @click="setActiveChat"
  >
    <div class="user-avatar">
      <img
        class="user-avatar__img"
        :src="user.profile.avatarList.img_small"
        :alt="user.profile.name + '\'s avatar'"
      />
      <span v-if="isOnline" class="is-online"></span>
      <span v-if="isNew" class="is-new">new</span>
      <span class="is-notify">
        <svg class="rbi">
          <use
            xlink:href="/static/assets/images/rbi-icon.svg#rbi-attention"
          ></use></svg
      ></span>
    </div>
    <div class="user-detail">
      <span class="uname" :title="user.profile.name">
        {{ user.profile.name }}
      </span>
      <span class="uid"> ID:{{ user.id }} </span>
      <span v-if="isStreamingCamera" class="webcam">
        <svg class="rbi">
          <use xlink:href="/static/assets/images/rbi-icon.svg#rbi-webcam"></use>
        </svg>
      </span>
    </div>
  </div>
</template>

<script>
import store from '../../store/store';
export default {
  name: 'ManUserOnlineItem',
  store,
  components: {},
  props: {
    user: Object,
  },
  data() {
    return {};
  },
  computed: {
    isActive() {
      return (
        this.$store.getters.dialogs.current !== null &&
        this.$store.getters.dialogs.current.user.id === this.user.id
      );
    },
    isOnline() {
      return true;
    },
    isNew() {
      return false;
    },
    isStreamingCamera() {
      if (this.$store.getters.activeUsersList.length > 0) {
        const data = this.$store.getters.activeUsersList;
        let hasStream = false;

        data.forEach((user) => {
          if (user.userId === this.user.id && user.userCameraActive) {
            hasStream = true;
          }
        });

        return hasStream;
      } else return false;
    },
  },
  methods: {
    setActiveChat() {
      this.$store.dispatch('setCurrentChat', this.user);
      this.$store.dispatch('setResponsiveShowUserOnline', false);
      this.$store.commit('SET_MAN_Q', '');
      document.querySelector('.findInOnline').value = '';
    },
  },
};
</script>

<style>
.chat-user {
  margin-top: 5px;
}
</style>
