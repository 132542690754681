<template>
  <transition v-if="open" name="modal">
    <div class="vue-modal modal-mask photos-modal">
      <div class="vue-modal--wrapper">
        <div
          v-click-away="closeModal"
          class="vue-modal--container photos-modal--body"
        >
          <div class="photos-modal--header d-flex">
            <h4 class="modal-title">Choose photo</h4>

            <div class="d-flex">
              <button type="button" class="close" @click="closeModal">
                <svg class="rbi">
                  <use
                    xlink:href="/static/assets/images/rbi-icon.svg#rbi-close"
                  ></use>
                </svg>
              </button>
            </div>
          </div>
          <div class="photos-modal-body">
            <div class="photo-upload-container">
              <image-uploader
                :preview="true"
                :max-width="1024"
                :max-height="1024"
                :quality="0.7"
                :class-name="['fileinput', { 'fileinput--loaded': hasImage }]"
                :debug="0"
                do-not-resize="gif"
                :auto-rotate="false"
                :capture="false"
                output-format="blob"
                @input="setImage"
              >
                <label
                  slot="upload-label"
                  class="fileinput-photo-pick"
                  for="fileInput"
                >
                  <figure>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="30"
                      height="30"
                      viewBox="0 0 32 32"
                    >
                      <path
                        class="path1"
                        d="M9.5 19c0 3.59 2.91 6.5 6.5 6.5s6.5-2.91 6.5-6.5-2.91-6.5-6.5-6.5-6.5 2.91-6.5 6.5zM30 8h-7c-0.5-2-1-4-3-4h-8c-2 0-2.5 2-3 4h-7c-1.1 0-2 0.9-2 2v18c0 1.1 0.9 2 2 2h28c1.1 0 2-0.9 2-2v-18c0-1.1-0.9-2-2-2zM16 27.875c-4.902 0-8.875-3.973-8.875-8.875s3.973-8.875 8.875-8.875c4.902 0 8.875 3.973 8.875 8.875s-3.973 8.875-8.875 8.875zM30 14h-4v-2h4v2z"
                      ></path>
                    </svg>
                  </figure>
                  <span class="upload-caption">{{
                    hasImage ? 'Replace' : 'Click to upload'
                  }}</span>
                </label>
              </image-uploader>
            </div>
            <div class="photo-pass-btn-wrapper">
              <div class="price-attachment" style="color: silver">
                Photo attachment costs
                {{ pricePhotoSend }} credits.
              </div>

              <rb-button
                v-show="hasImage"
                class="btn-red"
                type="button"
                @click.native="passPhoto"
              >
                <svg class="rbi">
                  <use
                    xlink:href="/static/assets/images/rbi-icon.svg#rbi-clip"
                  ></use>
                </svg>

                <span>Send</span>
              </rb-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import RbButton from '../Button';
import ImageUploader from 'vue-image-upload-resize';

export default {
  components: {
    RbButton,
    ImageUploader,
  },
  props: {
    userId: Number,
    limit: Number,
    open: Boolean,
    passedCount: Number,
  },
  data() {
    return {
      showPreloader: true,
      hasImage: false,
      image: null,
    };
  },
  computed: {
    isWoman() {
      return this.$store.getters.user.type === 'woman';
    },
    token() {
      return this.$store.getters.token.site_token;
    },
    isVideoChat() {
      return this.$store.getters.dialogs.current.user.chat_type === 'video';
    },
    pricePhotoSend() {
      return this.$store.getters.settings.chat.price_man_send_photo;
    },
  },
  methods: {
    clearData() {
      this.storedPhotos = [];
      this.count = 0;
      this.showLimit = this.defaultLimit;
      this.showErrLimit = false;
    },
    passPhoto() {
      if (this.hasImage && this.image) {
        if (this.$store.getters.myBalance >= this.pricePhotoSend) {
          let formData = new FormData();
          formData.append('access_token', this.$store.getters.token.site_token);
          formData.append('woman_id', this.userId);
          formData.append('blob', this.image);
          formData.append('is_blob', 1);

          this.$http
            .post('v1/chat/photo-send-man', formData)
            .then((res) => {
              if (res.body.status) {
                this.$socket.emit('message', {
                  token: this.$store.getters.token.site_token,
                  receiver: this.userId,
                  text: 'Изображение',
                  photoSell: false,
                  photoId: res.body.photo_id,
                  photoMan: true,
                  type: this.isVideoChat ? 'video' : 'text',
                });
                this.$store.commit('addAlert', {
                  type: 'success',
                  text: `Photo successfully sent.`,
                  info: 'photo send ok',
                });
              } else {
                this.$store.commit('addAlert', {
                  type: 'error',
                  text: `Something went wrong during photo sending.`,
                  info: 'sending photo error',
                });
              }
            })
            .catch(() => {
              this.$store.commit('addAlert', {
                type: 'error',
                text: `Something went wrong during photo sending.`,
                info: 'sending photo error',
              });
            });
          this.closeModal();
        } else {
          this.openCreditsModal();
        }
      }
    },
    setImage: function (output) {
      this.hasImage = true;
      this.image = output;
    },
    closeModal() {
      this.$emit('close');
      this.hasImage = false;
      this.image = null;
      this.showPreloader = true;
    },
    openCreditsModal() {
      this.$store.commit('updateLowCreditModal', {
        open: true,
      });
    },
  },
};
</script>

<style lang="scss">
@import '../../../assets/scss/_mixins';
@import '../../../assets/scss/variables-bootstrap';
@import '../../../assets/scss/vars';

.price-attachment {
  font-size: 14px;
  letter-spacing: 2px;
}
.price-attachment .price-text {
  font-size: 2rem;
}
.price-attachment .rbi {
  margin-left: 5px;
  margin-right: 3px;
  font-size: 2rem;
}

.photo-upload-container {
  display: flex;
  justify-content: center;
  margin-top: 15px;
}

.photo-upload-container img {
  width: 400px;
}

@media screen and (max-width: 425px) {
  .photo-upload-container img {
    width: 65vw;
  }
}

.fileinput {
  display: none !important;
}
.fileinput-photo-pick {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  text-align: center;
  cursor: pointer;
}

.photo-pass-btn-wrapper {
  display: flex;
  justify-content: space-between;
}

.photos-modal-body .no-photo {
  display: flex;
  align-content: center;
  justify-content: center;
  padding: 30px;
}

.checkbox-emit {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  width: 20px;
  border-radius: 2px;
  line-height: 0;
  cursor: pointer;
  transition: 0.1s ease;
  opacity: 0;
  font-size: 25px;
  color: $white;

  &.checked {
    background: rgba($red, 0.5);
    opacity: 1;
  }

  &--icon {
    width: 15px;
    height: 15px;
    opacity: 0;
    fill: #fff;
    transition: opacity 0.1s ease;
  }
}

.btn-red {
  width: auto;
  padding: 10px 30px !important;
  font-size: 12px !important;
  .rbi {
    margin-right: 0.5em;
  }
}

.photos-modal {
  .alert {
    margin-top: 10px;
  }

  &--header {
    align-items: center;
    justify-content: space-between;

    .btn {
      .rbi {
        font-size: 18px;
      }
      span {
        &:last-child {
          margin-left: 0.5em;
        }
      }
    }

    .btn + .close {
      margin-left: 25px;
    }

    .btn-primary {
      line-height: 1;
      font-size: 13px;
      padding: 10px 20px;
    }
  }

  &--body {
    width: 920px;
    padding: 25px;

    .photos {
      display: flex;
      margin: 0 -3px;
      flex-wrap: wrap;

      &--item {
        position: relative;
        margin-top: 20px;
        cursor: pointer;
        user-select: none;
        width: 25%;
        padding: 0 3px;

        .img-wrap {
          position: relative;
          padding-bottom: 88%;
          &:after {
            position: absolute;
            bottom: -3px;
            width: 25%;
            height: 3px;
            left: 0;
            content: '';
            display: block;
            background: $red;
          }
          .obj-fit {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            object-fit: cover;
          }
        }

        .checkbox-emit {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
        }

        img {
          width: 100%;
        }
      }
    }
  }
}

.button-wrap {
  margin-top: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.alert {
  padding: 15px;
  border: 1px solid transparent;
  background-color: transparent;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;

  &-warning {
    border-color: #ffb236;
    color: #ffb236;
  }

  &-info {
    border-color: #2ca8ff;
    color: #2ca8ff;
  }
  &-danger {
    border-color: #ff3636;
    color: #ff3636;
  }
}

@media only screen and (max-width: 992px) {
  .photos-modal {
    .vue-modal--container {
      max-width: 100%;
    }

    &--body {
      width: 100%;
      .photos {
        &--item {
          width: 33.33%;
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .photos-modal {
    &--body {
      padding: 15px;
    }
  }
}

@media only screen and (max-width: 480px) {
  .photos-modal {
    &--body {
      width: 100%;
      .photos {
        &--item {
          width: 50%;
          margin-top: 10px;
        }
      }
    }

    .btn-red {
      padding: 10px !important;
      width: 85px !important;
      height: 40px !important;
      .rbi {
        font-size: 16px;
        margin: 0 !important;
      }
      span {
        margin-left: 5px;
      }
    }
  }
}

.settings-alert {
  p {
    margin: 0;
    padding: 0;
  }
  .rbi {
    font-size: 25px;
    margin-right: 0.5em;
  }
}
</style>
