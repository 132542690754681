<template>
  <aside
    v-show="invites.length"
    class="invites--wrap ml"
    :class="{ open: open }"
  >
    <div class="invites--box dis-row">
      <div class="dis-cell">
        <SimpleBar ref="simplebar" class="invites-simplebar">
          <ul class="list-unstyled inv">
            <invite-item
              v-for="invite in invites"
              :key="invite.params.senderId"
              :invite-base="invite"
            ></invite-item>
          </ul>
        </SimpleBar>
      </div>
    </div>
    <div class="dis-row invites--btn__wrap">
      <div class="invites--btn d-flex" @click="openInvites">
        <span class="d-flex invites--btn__text">
          <p>Invitations</p>
          <span class="count">{{ invites.length }}</span>
        </span>
        <span class="icon d-none d-lg-block">
          <span>
            <svg class="rbi" :class="{ 'flip-top': !open }">
              <use xlink:href="/static/assets/images/rbi-icon.svg#rbi-arrow" />
            </svg>
          </span>
        </span>
        <div class="d-flex d-lg-none icon-responsive">
          <div class="icon-responsive--circle circle-base hidden-lg"></div>
          <div class="icon-responsive--static">
            <svg class="rbi">
              <use xlink:href="/static/assets/images/rbi-icon.svg#rbi-chat" />
            </svg>
          </div>
          <span class="icon-responsive--label">{{ invites.length }}</span>
        </div>
      </div>
    </div>
  </aside>
</template>

<script>
// import ProgressBar from 'progressbar.js';
import InviteItem from './items/invite-item.vue';

export default {
  name: 'InviteList',
  scroll: null,
  progressCircle: null,
  components: {
    'invite-item': InviteItem,
  },
  data() {
    return {
      open: false,
    };
  },
  computed: {
    invites() {
      return this.$store.getters.invites;
    },
  },
  watch: {
    invites(val) {
      if (val.length === 0) {
        this.open = false;
      }
    },
  },
  mounted() {
    // this.scroll = new old() - simplebar(this.$refs.old - simplebar);
  },
  methods: {
    //            openInvites(){
    //                console.log('krasota');
    //                this.open = true;
    //            },
    openInvites() {
      if (this.invites.length > 0) {
        this.open = !this.open;
        /* $('.invites-old-simplebar-container').old-simplebar();
					 $('.invites-old-simplebar-container').old-simplebar('getContentElement');*/
      }
    },
  },
};
</script>

<style lang="scss">
@import '../../assets/scss/variables-bootstrap';
@import '../../assets/scss/vars';

.inv {
  margin-bottom: 0;
  border-top: 5px solid $red;
}

.invites-simplebar {
  height: 100%;
  overflow-x: hidden;
}

.invites {
  &--wrap {
    position: fixed;
    bottom: 0;
    right: 0;
    width: 300px;
    z-index: 100;
    &.open {
      .invites--box {
        padding-bottom: 40px;
        display: block;
      }

      .invites--btn__wrap {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 100%;
      }
    }

    .blockquote {
      .emojione {
        width: 16px;
        height: 16px;
      }
    }
  }

  &--box {
    display: none;
  }

  &--btn {
    height: 40px;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 10px;
    color: #fff;
    line-height: 1;
    pointer-events: all;
    cursor: pointer;
    background-color: $black;
    user-select: none;

    &__text {
      align-items: center;
      p {
        margin: 0;
      }
    }

    svg {
      height: 16px;
      width: 16px;
      fill: currentColor;
    }

    p + span {
      margin-left: 5px;
    }

    .count {
      display: block;
      height: 18px;
      width: 18px;
      text-align: center;
      padding: 3px 0;
      border-radius: 50%;
      background-color: $red;
      font-size: 12px;
    }
  }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
}

@media only screen and (max-width: 1200px) {
  .invites--wrap {
    width: 240px;
  }
}

@media only screen and (max-width: 991px) {
  .invites--wrap {
    display: none;
  }

  .invites {
    &--wrap {
      width: 60px;
      &.open {
        &.fml {
          width: 320px;

          .invites--btn {
            justify-content: flex-end;
          }

          .icon-responsive {
            flex: 0 0 30px;
          }
        }
      }
    }

    &--btn {
      background-color: $dark;
      color: #bababa;

      &:before {
        display: none !important;
      }

      &__text {
        display: none !important;
      }

      .icon-responsive {
        position: relative;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        &--circle {
          height: 30px;
          width: 30px;
          border: 1px solid $white;
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          border-radius: 50%;
        }

        &--label {
          position: absolute;
          top: -3px;
          right: -3px;
          padding: 2px 3px;
          color: #fff;
          background-color: $brand-primary;
          border-radius: 7px;
          font-size: 11px;
          height: 15px;
          min-width: 15px;
          text-align: center;
        }

        &--static {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          line-height: 0;
        }
      }
    }
  }
}
</style>
