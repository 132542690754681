<template>
  <transition name="modal">
    <div
      v-if="modal.open"
      class="vue-modal modal-mask alert-modal"
      @click="closeModal($event.target)"
    >
      <div class="vue-modal--wrapper">
        <div class="vue-modal--container alert-modal--container">
          <button type="button" class="close" @click="closeModal(false)">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15.642 15.642">
              <path
                fill-rule="evenodd"
                d="M8.882 7.82l6.54-6.54c.294-.293.294-.768 0-1.06-.292-.294-.767-.294-1.06 0l-6.54 6.54L1.28.22C.987-.073.512-.073.22.22c-.294.293-.294.768 0 1.06l6.54 6.542-6.54 6.54c-.293.293-.293.768 0 1.06.147.147.338.22.53.22s.384-.072.53-.22l6.54-6.54 6.542 6.54c.147.147.338.22.53.22s.384-.072.53-.22c.293-.292.293-.767 0-1.06l-6.54-6.54z"
              />
            </svg>
          </button>
          <div class="modal-body alert-modal--body">
            <component :is="modal.type"></component>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import prvChatEnd from './parts/alert/prvChatEnd.vue';
import goToPrivate from './parts/alert/goToPrivate.vue';
import switchChat from './parts/alert/switchChat.vue';

export default {
  name: 'AlertModal',
  components: {
    prvChatEnd,
    goToPrivate,
    switchChat,
  },
  data() {
    return {};
  },
  computed: {
    modal() {
      return this.$store.getters.alertModal;
    },
  },
  methods: {
    closeModal(e) {
      // eslint-disable-next-line no-undef
      if (!$(e).closest('.vue-modal--container').length || !e) {
        this.$store.commit('updateAlertModal', {
          open: false,
          type: '',
        });
      }
    },
  },
};
</script>

<style lang="scss">
.alert-modal {
  &--container {
    width: 320px;
    position: relative;
  }

  h4 {
    font-weight: bold;
    color: #656565;
  }

  .close {
    position: absolute;
    top: 20px;
    right: 20px;
  }

  &--body {
    text-align: center;
  }

  .btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
    border: none !important;
    padding: 6px 20px;

    span + span {
      margin-left: 10px;
    }

    svg {
      height: 20px;
      width: 20px;
      fill: currentColor;
    }
  }
}
</style>
