<template>
  <div v-if="user" class="control-block">
    <div class="control-wrap">
      <div class="gallery-wrapper">
        <div
          v-show="displayGallery"
          class="slider-btn-wrap"
          :class="{
            'gallery-controls-video-chat':
              !isTextChat || (isTextChat && isStreamingCamera),
          }"
        >
          <button v-show="sliderInitialized" class="slider-btn button-prev">
            <svg class="rbi">
              <use
                xlink:href="/static/assets/images/rbi-icon.svg#rbi-arrow"
              ></use>
            </svg>
          </button>
          <button v-show="sliderInitialized" class="slider-btn button-next">
            <svg class="rbi">
              <use
                xlink:href="/static/assets/images/rbi-icon.svg#rbi-arrow"
              ></use>
            </svg>
          </button>
        </div>
        <div
          v-show="displayGallery && photos.length"
          class="slider-gallery"
          :class="{
            'gallery-video-chat': isStreamingCamera,
          }"
        >
          <div v-show="!sliderInitialized" class="preload-wrap">
            <div class="preloader"></div>
          </div>
          <div class="slider-out">
            <div
              class="gallery-swiper-new"
              :class="{ invisible: !sliderInitialized }"
            >
              <div id="photos-container" class="swiper-wrapper">
                <a
                  v-for="(item, i) in photos"
                  :key="i"
                  class="swiper-slide"
                  href="#"
                  @click.prevent="takePhotos(true, photos, i)"
                >
                  <div class="image-wrap">
                    <img
                      :src="item.img_preview"
                      :alt="user.profile.name + '\'s photo'"
                    />
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="control-buttons">
        <div
          class="item sidebar-controls"
          :class="{ 'remote-video-controls-wrapper': remoteVideo }"
        >
          <button
            class="btn-open online-list-btn"
            data-target="#users-online"
            @click="responsiveShowUserOnline"
          >
            <template v-if="false">
              <!--hide countes, add styles to button-->
              <span class="counter">({{ manOnlineCount }})</span>
            </template>
            <span class="controls-label">Онлайн</span>
            <span class="icon">
              <svg class="rbi flip-right">
                <use
                  xlink:href="/static/assets/images/rbi-icon.svg#rbi-arrow"
                ></use>
              </svg>
            </span>
          </button>
          <ChatTypeSwitch
            :remote-video="remoteVideo"
            :chat-type="chatType"
          ></ChatTypeSwitch>
          <button
            class="btn-open last-chats-button"
            @click="responsiveShowLastDialogs"
          >
            <span class="controls-label">Чаты</span>
            <span class="icon">
              <svg class="rbi flip-left">
                <use
                  xlink:href="/static/assets/images/rbi-icon.svg#rbi-arrow"
                ></use>
              </svg>
              <span class="counter">
                <span
                  v-if="lastDialogsUnreadMessage"
                  class="unread label label-primary"
                  >{{ lastDialogsUnreadMessage }}</span
                >
              </span>
            </span>
          </button>
        </div>
        <div class="item sub-controls">
          <button
            class="btn btn-common"
            title="Send mail"
            @click.prevent="sendMail"
          >
            <svg class="rbi">
              <use
                xlink:href="/static/assets/images/rbi-icon.svg#rbi-message"
              ></use>
            </svg>
          </button>
          <button
            class="btn btn-common"
            title="Send gift"
            @click.prevent="sendGift"
          >
            <svg class="rbi">
              <use
                xlink:href="/static/assets/images/rbi-icon.svg#rbi-gift"
              ></use>
            </svg>
          </button>
          <button
            class="btn btn-common"
            :class="{ checked: isBookmark }"
            title="bookmarkActionTitle"
            @click.prevent="bookmarkAction"
          >
            <span v-if="isBookmark" class="checked-icon">
              <svg class="rbi">
                <use
                  xlink:href="/static/assets/images/rbi-icon.svg#rbi-check"
                ></use>
              </svg>
            </span>
            <svg class="rbi">
              <use
                xlink:href="/static/assets/images/rbi-icon.svg#rbi-star"
              ></use>
            </svg>
          </button>
          <button
            class="btn btn-common"
            :disabled="winked"
            :class="{ checked: winked }"
            title="Send wink"
            @click.prevent="sendWink"
          >
            <span v-if="winked" class="checked-icon">
              <svg class="rbi">
                <use
                  xlink:href="/static/assets/images/rbi-icon.svg#rbi-check"
                ></use>
              </svg>
            </span>
            <svg class="rbi">
              <use
                xlink:href="/static/assets/images/rbi-icon.svg#rbi-emoji"
              ></use>
            </svg>
          </button>
          <button
            class="btn btn-common"
            :class="{ checked: isFavorite }"
            :title="favoriteActionTitle"
            @click="favoriteAction"
          >
            <span v-if="isFavorite" class="checked-icon">
              <svg class="rbi">
                <use
                  xlink:href="/static/assets/images/rbi-icon.svg#rbi-check"
                ></use>
              </svg>
            </span>
            <svg class="rbi">
              <use
                xlink:href="/static/assets/images/rbi-icon.svg#rbi-heart"
              ></use>
            </svg>
          </button>
          <button
            class="btn btn-primary"
            title="Toggle Gallery"
            @click="displayGallery = !displayGallery"
          >
            <svg v-if="!displayGallery" class="rbi">
              <use
                xlink:href="/static/assets/images/rbi-icon.svg#rbi-gallery-new"
              />
            </svg>
            <svg v-else class="rbi">
              <use xlink:href="/static/assets/images/rbi-icon.svg#rbi-close" />
            </svg>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  LAST_DIALOGS,
  AGE,
  IS_ONLINE,
  IS_BOOKMARK,
  IS_FAVORITE,
} from '../../../mixins/man_util';
import { GALLERY, PREVIEW_MODAL, VIDEO_SIZE } from '../../../mixins/utils';
import { USER_ACTIONS } from '../../../mixins/userActions';
import ChatTypeSwitch from './ChatTypeSwitch';
import womanControlsMixin from '../../../mixins/womanControls.mixin';

export default {
  name: 'Controls',
  components: { ChatTypeSwitch },
  mixins: [
    LAST_DIALOGS,
    AGE,
    IS_ONLINE,
    IS_BOOKMARK,
    IS_FAVORITE,
    GALLERY,
    USER_ACTIONS,
    PREVIEW_MODAL,
    VIDEO_SIZE,
    womanControlsMixin,
  ],
  props: {
    remoteVideo: { type: Boolean, default: false },
    chatType: { type: Number, default: 0 },
  },
  data: () => ({
    winked: false,
    showGallery: false,
    windowWidth: window.innerWidth,
  }),
  computed: {
    manOnlineCount() {
      return this.$store.getters.countAllOnline;
    },
    lastDialogsUnreadMessage() {
      return this.$store.getters.lastDialogsUnreadMessage;
    },

    user() {
      return this.$store.getters.dialogs.current
        ? this.$store.getters.dialogs.current.user
        : null;
    },
    isVideoDoubleChat() {
      return (
        this.$store.getters.dialogs.current &&
        this.$store.getters.dialogs.current.user.chat_type === 'videoDouble'
      );
    },
    isVideoChat() {
      return (
        this.$store.getters.dialogs.current &&
        this.$store.getters.dialogs.current.user.chat_type === 'video'
      );
    },
    isTextChat() {
      return (
        this.$store.getters.dialogs.current &&
        this.$store.getters.dialogs.current.user.chat_type === 'text'
      );
    },
    isActive() {
      return (
        this.$store.getters.dialogs.current &&
        this.$store.getters.dialogs.current.user.chat_status === 'active'
      );
    },
    cameraOn() {
      return this.$store.getters.getUserCameraIsActive;
    },
    isStreamingCamera() {
      if (this.$store.getters.activeUsersList.length > 0) {
        const data = this.$store.getters.activeUsersList;
        let hasStream = false;

        data.forEach((user) => {
          if (user.userId === this.user.id && user.userCameraActive) {
            hasStream = true;
          }
        });

        return hasStream;
      } else return false;
    },
  },
  watch: {
    user(v) {
      this.canChangeChatTypeData = true;
      this.winked = false;
      this.photos = [];
      this.displayGallery = false;
      this.sliderInitialized = false;
      this.askProfile(v.id);
    },
    displayGallery(display) {
      this.$emit('display-gallery-change', display);
    },
  },
  mounted() {
    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth;
    });
    this.canChangeChatTypeData = true;
    this.winked = false;
    this.photos = [];
    this.displayGallery = false;
    this.sliderInitialized = false;
    this.askProfile(this.user.id);
  },
  methods: {
    switchToDoubleVideoChat() {
      /*if (!this.isActive && this.cameraOn) {
        this.$store.dispatch('changeChatType', 'videoDouble')
        this.$socket.emit('change_chat_type', {
          token: this.$store.getters.session.chat_token,
          new_chat_type: 'videoDoubleChat',
          roomId: this.$store.getters.dialogs.current.roomId,
        })
      }*/
    },
    switchToVideoInvite() {
      if (!this.isActive && this.cameraOn && !this.isStreamingCamera) {
        this.$store.dispatch('changeChatType', 'video');
        this.$socket.emit('change_chat_type', {
          token: this.$store.getters.session.site_token,
          new_chat_type: 'video',
          collocutor: this.$store.getters.dialogs.current.user.id,
          roomId: this.$store.getters.dialogs.current.roomId,
        });
      }
    },
    switchToTextInvite() {
      if (!this.isActive) {
        this.$store.dispatch('changeChatType', 'text');
        this.$socket.emit('change_chat_type', {
          token: this.$store.getters.session.site_token,
          new_chat_type: 'text',
          collocutor: this.$store.getters.dialogs.current.user.id,
          roomId: this.$store.getters.dialogs.current.roomId,
        });
      }
    },
    responsiveShowUserOnline() {
      this.$store.dispatch('setResponsiveShowUserOnline', true);
    },
    responsiveShowLastDialogs() {
      this.$store.dispatch('setResponsiveShowLastDialogs', true);
    },
  },
};
</script>

<style lang="scss" scoped>
.gallery-video-chat {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.gallery-video-chat-controls {
  z-index: 30;
  opacity: 0.7;
}
.slider-btn:focus {
  border: none !important;
  outline: none !important;
}
.slider-btn-wrap {
  opacity: 0.7;
  z-index: 30;
}
/*.chat-type-switch {
  display: flex;
}
@media screen and (max-width: 992px) {
  .chat-type-switch {
    display: none;
  }
}*/

@media (min-width: 767px) and (max-width: 992px) {
  .last-chats-button {
    flex-direction: row-reverse !important;
  }
}

@media (max-width: 767px) {
  .last-chats-button {
    flex-direction: column-reverse !important;
  }
}

.icon {
  color: #000000 !important;
  border: #b6b6b6 !important;
  background: white !important;
  box-shadow: inset 0 0 1px 1px rgb(204 204 204) !important;
}

.remote-video-controls-wrapper .icon {
  color: #000000;
  border: #b6b6b6;
  background: white;
  box-shadow: inset 0 0 1px 1px rgb(204 204 204);
  opacity: 0.7;
}

@media screen and (max-width: 992px) {
  .remote-video-controls-wrapper .controls-label {
    color: transparent;
  }
}
</style>
