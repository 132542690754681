class Logger {
  constructor(enabled = true) {
    this._enabled = enabled;
  }
  trace(message, ...additionalMessages) {
    if (this._enabled)
      console.trace(
        `%cApp trace:`,
        'color: #e21919; font-style: italic; background: #e3e3e3; padding: 3px; border-radius: 5px',
        message,
        ...additionalMessages
      );
  }
  info(message, ...additionalMessages) {
    if (this._enabled)
      console.log(
        `%cApp info:`,
        'color: #e21919; font-style: italic; background: #e3e3e3; padding: 3px; border-radius: 5px',
        message,
        ...additionalMessages
      );
  }
  error(message, ...additionalMessages) {
    if (this._enabled)
      console.error(
        `%cApp Error:`,
        'color: #e21919; font-style: italic; background: #e3e3e3; padding: 3px; border-radius: 5px',
        message,
        ...additionalMessages
      );
  }
  debug(message, ...additionalMessages) {
    if (this._enabled)
      console.log(
        `%cApp debug:`,
        'color: blue; font-style: italic; background: #e3e3e3; padding: 3px; border-radius: 5px',
        message,
        ...additionalMessages
      );
  }
}

export default Logger;
