<template>
  <div class="msg-line-new photo-message photo-message-free">
    <div
      class="usr-message"
      :class="{
        recipient: !isReceiver,
        sender: isReceiver,
        invite: message.isInvite,
      }"
    >
      <span>
        <img v-if="isReceiver" :src="myAvatar" :alt="user.profile.name" />
        <img
          v-if="!isReceiver"
          :src="collocutor.profile.avatarList.img_small"
          :alt="user.profile.name"
        />
      </span>
      <div
        class="ph-mes"
        @click="openPreview([formattedPhoto], 0, message.bought, message.photo)"
      >
        <div>
          <p class="ph-mes__attach d-flex">
            <span class="icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 51.619 51.619"
              >
                <path
                  d="M50.14 19.206c-.39-.39-1.023-.39-1.414 0L21.432 46.5c-2.012 2.012-4.697 3.12-7.563 3.12-2.868 0-5.554-1.108-7.565-3.12s-3.12-4.697-3.12-7.564c0-2.866 1.108-5.552 3.12-7.563L33.598 4.078c2.897-2.896 7.445-2.72 10.58.413 3.132 3.134 3.31 7.683.413 10.58L18.95 40.71c-1.432 1.434-3.766 1.435-5.202 0-1.434-1.434-1.434-3.768 0-5.202l17.37-17.37c.39-.39.39-1.022 0-1.413s-1.024-.39-1.415 0l-17.37 17.368c-2.213 2.214-2.213 5.816 0 8.03 2.217 2.217 5.82 2.213 8.032 0l25.64-25.64c3.704-3.704 3.526-9.468-.413-13.407-3.938-3.938-9.703-4.117-13.407-.413L4.89 29.958c-2.39 2.39-3.705 5.577-3.705 8.978 0 3.4 1.315 6.59 3.705 8.98 2.39 2.39 5.577 3.704 8.98 3.704 3.4 0 6.588-1.315 8.977-3.705L50.14 20.62c.39-.39.39-1.024 0-1.414z"
                ></path>
              </svg>
            </span>
            <span>Attached</span>
          </p>
          <div class="ph-mes__preview">
            <!-- <div class="ph-mes__seen" :class="{ confirm: message.bought }">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35.63 34.76">
                <g data-name="Layer 2">
                  <circle class="cls-2" cx="19.16" cy="18.58" r="11.38" />
                  <path
                    class="cls-3"
                    d="M17.39 22.68l-3.58-3.37 1.2-1.2 3.58 3.37-1.2 1.2z"
                  />
                  <path
                    class="cls-3"
                    d="M16.68 21l6.5-6.75 1.224 1.18-6.5 6.748z"
                  />
                </g>
              </svg>
            </div>-->
            <img
              v-show="!preloader"
              class="ph-mes__img"
              :src="photo.small"
              alt="Attached photo"
              @load="preloader = false"
            />
            <div v-show="preloader" class="ph-mes__loader--wrap">
              <div class="ph-mes__loader preloader"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { GALLERY } from '../../../../../mixins/utils';

export default {
  mixins: [GALLERY],
  props: {
    message: Object,
    /*user: Object,*/
  },
  data() {
    return {
      preloader: true,
      photo: {
        large: '',
        small: '',
      },
    };
  },
  computed: {
    formattedPhoto() {
      return {
        img_origin: this.photo.large,
        img_preview: this.photo.small,
      };
    },
    token() {
      return this.$store.getters.token;
    },
    user() {
      return this.$store.getters.appUser;
    },
    collocutor() {
      return this.$store.getters.dialogs.current.user;
    },
    isReceiver() {
      return this.message.sender === this.user.id;
    },
    myAvatar() {
      return this.user.profile.avatarList.img_small;
    },
    bought() {
      return this.message.bought;
    },
  },
  watch: {
    bought(v) {
      if (v) this.getPhoto();
    },
  },
  mounted() {
    this.getPhoto();
    this.$emit('msg', this.$el);
  },
  methods: {
    getPhoto() {
      this.preloader = true;
      Object.assign(this.photo, {
        small: '',
        large: '',
      });
      this.$http
        .post('v1/photo/get-by-id-free', {
          access_token: this.token.site_token,
          photo_id: this.message.photo,
        })
        .then((r) => {
          if (r.body.status) {
            Object.assign(this.photo, r.body.photo_preview);
          }
        });
    },
  },
};
</script>

<style lang="scss">
.photo-message-free {
  .sender {
    padding-right: 10px;
  }
}
.ph-mes__loader {
  width: 50px;
  height: 50px;
  border-width: 2px;
  &:before,
  &:after {
    border-width: 2px;
  }
}
</style>
