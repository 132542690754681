<template>
  <transition v-if="modal.open" name="modal">
    <div class="vue-modal modal-mask vmgifts">
      <div class="vue-modal--wrapper">
        <div
          v-click-away="closeModal"
          class="vue-modal--container vmgifts--container"
          :class="{ wide: preview }"
        >
          <div class="modal-header">
            <h4 v-if="preview" class="modal-title">Подарки</h4>
            <rb-button
              v-else
              class="btn-outline go-back"
              type="button"
              @mouseup.native="goBack"
            >
              <svg class="rbi flip-left">
                <use
                  xlink:href="/static/assets/images/rbi-icon.svg#rbi-arrow"
                ></use>
              </svg>
              <span style="line-height: 100%">Назад</span>
            </rb-button>
            <div class="modal-control">
              <button
                type="button"
                class="close"
                @click="closeModal(false, true)"
              >
                <svg class="rbi">
                  <use
                    xlink:href="/static/assets/images/rbi-icon.svg#rbi-close"
                  ></use>
                </svg>
              </button>
            </div>
          </div>
          <div v-show="preview" class="modal-body">
            <div class="vmgifts--main">
              <div class="vmgifts--main__head d-flex align-items-center">
                <ul class="nav nav-tabs" role="tablist">
                  <li
                    role="presentation"
                    class="active"
                    @mouseup="toggleTab(true)"
                  >
                    <a
                      href="#mgift-rec"
                      aria-controls="mgift-rec"
                      role="tab"
                      data-toggle="tab"
                    >
                      <span>Все подарки</span>
                      <span v-show="data.rec.length" class="text-muted"
                        >({{ data.rec.length }})</span
                      >
                    </a>
                  </li>
                </ul>
              </div>
              <div class="vmgifts--main__body">
                <div class="tab-content">
                  <div id="mgift-rec" role="tabpanel" class="tab-pane active">
                    <SimpleBar class="simplebar-vg">
                      <div class="vgwrapper">
                        <div
                          v-for="category in data.all"
                          :key="category.category"
                          class="vg_category"
                        >
                          <virtual-gifts-slider
                            :category-name="category.category"
                            :gifts="category.gifts"
                            :update="preview"
                            @selected="selectGift"
                          ></virtual-gifts-slider>
                        </div>
                        <rb-button
                          v-show="showLoadBtn"
                          @mouseup.native="loadMore"
                          >load more {{ defaultLimit }}</rb-button
                        >
                      </div>
                    </SimpleBar>
                  </div>
                  <div
                    v-show="isSearching && !displayedGifts.length"
                    class="alert alert-info"
                  >
                    Ничего не найдено
                  </div>
                  <div
                    v-show="isSearching && !search.result.length"
                    class="alert alert-warning"
                  >
                    Ничего не найдено
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-show="!preview" class="mgifts--single">
            <div class="modal-body">
              <div class="mgifts--single__calc">
                <gift :gift="chosenData" :extended="true"></gift>
              </div>
              <form class="mgifts--single__form" @submit.prevent="sendGift">
                <div class="form-group d-flex align-items-center">
                  <p style="margin: 0">Кому:</p>
                  <div class="usr-selection">
                    <div class="small-img-wrap">
                      <img :src="user.avatar.img_small" :alt="user.name" />
                    </div>

                    <span>{{ user.name }}</span>
                  </div>
                </div>
                <div
                  class="form-group"
                  :class="{ 'has-error': err.first('giftComment') }"
                >
                  <textarea
                    v-model="comment"
                    class="form-control"
                    name="giftComment"
                    placeholder="Сообщение"
                  ></textarea>
                  <div v-show="err.first('giftComment')" class="error">
                    <i class="fa fa-warning"></i>
                    <span>{{ err.first('giftComment') }}</span>
                  </div>
                </div>
                <div class="clearfix">
                  <rb-button class="btn-black" type="submit">
                    Отправить
                  </rb-button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import Gift from './parts/VirtualGiftModalItem.vue';
import { Validator } from 'vee-validate';
import moment from 'moment';
import RbButton from '../Button';
import VirtualGiftsSlider from './parts/VirtualGiftsSlider';

export default {
  validator: null,
  components: {
    gift: Gift,
    VirtualGiftsSlider,
    RbButton,
  },
  data() {
    return {
      showOptions: false,
      sortValue: 'name/0',
      searchValue: '',
      activeTab: true, // true - recommend, false - all
      isSearching: false,
      search: {
        result: [],
      },
      err: [],
      preview: true,
      incorrectAmount: false,
      chosenData: {
        amount: 1,
      },
      categories: [],
      category: '',
      comment: '',
      limit: 15,
      defaultLimit: 15,
      isFav: false,
    };
  },
  computed: {
    /**
     * показыть ли кнопку "загрузить еще"
     *
     * @return {boolean}
     */
    showLoadBtn() {
      return this.isSearching
        ? this.displayedData.length < this.search.result.length
        : this.displayedData.length < this.displayedGifts.length;
    },
    /**
     * возвращает отображаемые данные: результаты поиска или список подарков
     *
     * @return {array}
     */
    displayedData() {
      if (this.sortValue === 'name/0') {
        return this.isSearching
          ? this.search.result.slice(0, this.limit).sort((a, b) => {
              return a.number_in_queue > b.number_in_queue ? -1 : 1;
            })
          : this.displayedGifts.slice(0, this.limit);
      }
      return this.isSearching
        ? this.search.result.slice(0, this.limit)
        : this.displayedGifts.slice(0, this.limit);
    },
    /**
     * возвращает доступные для отображения подарки
     *
     * @return {Array}
     */
    displayedGifts() {
      if (this.activeTab) {
        return this.data.rec
          .filter((i) => {
            return this.category === '' ? true : i.category === this.category;
          }, this)
          .sort((a, b) => {
            return a.number_in_queue > b.number_in_queue ? -1 : 1;
          });
      } else {
        return this.data.all
          .filter((i) => {
            return this.category === '' ? true : i.category === this.category;
          }, this)
          .sort((a, b) => {
            return a.number_in_queue > b.number_in_queue ? -1 : 1;
          });
      }
    },

    modal() {
      return this.$store.getters.virtualGiftModal;
    },
    fav() {
      return this.$store.getters.favorites;
    },
    /**
     * получить список подарков для всех вкладок
     * @return {{rec: (Array), all: (Array)}}
     */
    data() {
      let all = [],
        birthday = {
          category: 'День рождения',
          gifts: [],
        },
        friendship = {
          category: 'Дружба',
          gifts: [],
        },
        romance = {
          category: 'Романтика',
          gifts: [],
        },
        halloween = {
          category: 'Хэллоуин',
          gifts: [],
        },
        xxl = {
          category: 'XXL',
          gifts: [],
        },
        christmas = {
          category: 'Рождество',
          gifts: [],
        },
        valentines_day = {
          category: 'День св. Валентина',
          gifts: [],
        },
        easter = {
          category: 'Пасха',
          gifts: [],
        },
        patricks_day = {
          category: 'День св. Патрика',
          gifts: [],
        };

      for (let i = 0; i < this.modal.all.length; i++) {
        if (
          moment(new Date()).format('DDMM') ===
          moment(this.user.birthday * 1000).format('DDMM')
        ) {
          if (
            this.modal.all[i].category === 'birthday' &&
            +this.modal.all[i].active === 1
          ) {
            birthday.gifts.push(this.modal.all[i]);
          }
        }
        if (
          this.modal.all[i].category === 'friendship' &&
          +this.modal.all[i].active === 1
        ) {
          friendship.gifts.push(this.modal.all[i]);
        }
        if (
          this.modal.all[i].category === 'romance' &&
          +this.modal.all[i].active === 1
        ) {
          romance.gifts.push(this.modal.all[i]);
        }
        if (
          this.modal.all[i].category === 'halloween' &&
          +this.modal.all[i].active === 1
        ) {
          halloween.gifts.push(this.modal.all[i]);
        }
        for (let j = 0; j < this.fav.length; j++) {
          if (this.fav[j].user_id === this.user.user_id) {
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.isFav = this.fav[j].user_id === this.user.user_id;
            if (
              this.modal.all[i].category === 'xxl' &&
              +this.modal.all[i].active === 1
            ) {
              xxl.gifts.push(this.modal.all[i]);
            }
          }
        }
        if (
          this.modal.all[i].category == 'christmas' &&
          +this.modal.all[i].active == 1
        ) {
          christmas.gifts.push(this.modal.all[i]);
        }
        if (
          this.modal.all[i].category == 'valentines_day' &&
          +this.modal.all[i].active == 1
        ) {
          valentines_day.gifts.push(this.modal.all[i]);
        }
        if (
          this.modal.all[i].category == 'easter' &&
          +this.modal.all[i].active == 1
        ) {
          easter.gifts.push(this.modal.all[i]);
        }
        if (
          this.modal.all[i].category == 'patricks_day' &&
          +this.modal.all[i].active === 1
        ) {
          patricks_day.gifts.push(this.modal.all[i]);
        }
      }
      if (birthday.gifts.length > 0) {
        birthday.gifts.sort((a, b) => {
          return a.number_in_queue > b.number_in_queue ? -1 : 1;
        });
        all.push(birthday);
      }
      if (friendship.gifts.length > 0) {
        friendship.gifts.sort((a, b) => {
          return a.number_in_queue > b.number_in_queue ? -1 : 1;
        });
        all.push(friendship);
      }
      if (romance.gifts.length > 0) {
        romance.gifts.sort((a, b) => {
          return a.number_in_queue > b.number_in_queue ? -1 : 1;
        });
        all.push(romance);
      }
      if (halloween.gifts.length > 0) {
        halloween.gifts.sort((a, b) => {
          return a.number_in_queue > b.number_in_queue ? -1 : 1;
        });
        all.push(halloween);
      }
      if (xxl.gifts.length > 0) {
        xxl.gifts.sort((a, b) => {
          return a.number_in_queue > b.number_in_queue ? -1 : 1;
        });
        all.push(xxl);
      }
      if (christmas.gifts.length > 0) {
        christmas.gifts.sort((a, b) => {
          return a.number_in_queue > b.number_in_queue ? -1 : 1;
        });
        all.push(christmas);
      }
      if (valentines_day.gifts.length > 0) {
        valentines_day.gifts.sort((a, b) => {
          return a.number_in_queue > b.number_in_queue ? -1 : 1;
        });
        all.push(valentines_day);
      }
      if (easter.gifts.length > 0) {
        easter.gifts.sort((a, b) => {
          return a.number_in_queue > b.number_in_queue ? -1 : 1;
        });
        all.push(easter);
      }
      if (patricks_day.gifts.length > 0) {
        patricks_day.gifts.sort((a, b) => {
          return a.number_in_queue > b.number_in_queue ? -1 : 1;
        });
        all.push(patricks_day);
      }

      return {
        all: all,
        rec: this.modal.rec,
      };
    },
    /**
     * возвращает текущего пользователя, которому будет отправляться подарок
     *
     * @return {state.giftModal.currentUser|{avatar}|*|currentUser} {object}
     */
    user() {
      return this.modal.currentUser;
    },
    open() {
      return this.modal.open;
    },

    virtualGiftsComment() {
      return this.$store.getters.siteSettings.virtual_gift_comment;
    },
    /**
     * возвращает стоимость выбранных для покупки подарков в кредитах
     *
     * @return {number}
     */
    sum() {
      return this.chosenData.sumPrice * this.chosenData.amount;
    },
    isAuthenticated() {
      return this.$store.getters.isAuthenticated;
    },
    sex() {
      return this.$store.getters.sex;
    },
  },
  watch: {
    /**
     * Очистка состояния компонента при переключении между табами
     *
     * @param v
     */
    activeTab() {
      this.clearParams();
      this.searchValue = '';
      this.sortValue = 'name/0';
    },
    /**
     * Запуск поиска по названию
     *
     * @param v {string} - название подарка
     */
    searchValue(v) {
      if (v.length) {
        this.searching(v);
        this.isSearching = true;
      } else {
        this.isSearching = false;
        this.clearParams();
      }
    },
    /**
     * Очистка параметров при смене режима просмотра модалки
     *
     * @param v {boolean}
     */
    preview(v) {
      this.clearParams();
      if (v)
        this.chosenData = {
          amount: 1,
        };
      this.incorrectAmount = false;
      this.category = '';
      this.searchValue = '';
      this.sortValue = 'name/0';
    },
    /**
     * Запуск сортировки результатов
     *
     * @param val {string}
     */
    sortValue(val) {
      this.sorting(val);
    },
    /**
     * Валидация комментария к подарку
     *
     * @param v {string}
     */
    comment(v) {
      this.validator.validate('giftComment', v).catch(() => {});
    },
  },
  created() {
    this.validator = new Validator();
    this.validator.attach({
      name: 'giftComment',
      rules: 'max:255',
    });
    this.$set(this, 'err', this.validator.errors);
  },
  methods: {
    /**
     * Закрыть модалку с очисткой состояния
     */
    closeModal() {
      this.comment = '';
      this.category = '';
      this.searchValue = '';
      this.sortValue = 'name/0';
      this.chosenData = {
        amount: 1,
      };
      this.activeTab = true;
      this.preview = true;
      this.incorrectAmount = false;
      this.$store.commit('updateVirtualGiftModal', {
        open: false,
        user_id: 0,
        all: [],
        rec: [],
      });
      this.clearParams();
    },
    /**
     * Сбросить параметр котображения кол-ва результатов на вкладке
     */
    clearParams() {
      this.limit = this.defaultLimit;
    },
    /**
     * сортировка данных по типу и порядку
     *
     * @param sort {array}
     */
    sorting(sort) {
      let tmp = sort.split('/');
      const info = {
        name: tmp[0] || 'price',
        ask: tmp[1] === 'true',
      };
      let sortFunc = function (a, b) {
        if (info.ask) {
          return a[info.name] > b[info.name] ? 1 : -1;
        }
        return a[info.name] > b[info.name] ? -1 : 1;
      };
      if (this.isSearching) {
        this.search.result.sort(sortFunc);
      } else {
        if (this.activeTab) {
          // 	  for(let i = 0; i < this.data.rec.length; i++){
          //   this.data.rec[i].name = this.data.rec[i].name.trim()
          // }
          this.data.rec.sort(sortFunc);
        } else {
          // for(let i = 0; i < this.data.all.length; i++){
          //   this.data.all[i].name = this.data.all[i].name.trim()
          // }
          this.data.all.sort(sortFunc);
        }
      }
    },
    /**
     * переключить таб
     *
     * @param v {boolean} true - recommend, false - all
     */
    toggleTab(v) {
      this.activeTab = v;
    },
    /**
     * поиск по имени подарка
     *
     * @param val {string}
     */
    searching(val) {
      val = val.toLowerCase();
      this.search.result = this.displayedGifts.filter((item) => {
        return item.name.toLowerCase().includes(val.toLowerCase());
      });
    },
    /**
     * выбрать подарок для отправки
     *
     * @param item {object}
     */
    selectGift(item) {
      this.chosenData = Object.assign({}, item, {
        amount: 1,
      });
      this.preview = false;
    },
    /**
     * Обработчик изменения суммы
     *
     * @param num {number}:  1 || -1
     */
    changeSum(num) {
      if (this.chosenData.amount === 1 && num < 0) {
        this.incorrectAmount = true;
      } else {
        this.incorrectAmount = false;
        this.chosenData.amount += num;
      }
    },
    /**
     * вернуться в режим пре-просмотра
     */
    goBack() {
      this.preview = true;
    },
    /**
     * инициализирвать скроллбары
     */
    /**
     * провалидировать комментарий и отправить подарок
     */
    sendGift() {
      this.validator
        .validateAll({
          giftComment: this.comment,
        })
        .then((success) => {
          if (success && !this.error) {
            this.sending();
          }
        })
        .catch(() => {});
    },
    /**
     * фукция отправки подарка
     */
    sending() {
      this.$http
        .post('v1/virtual-gift/send', {
          access_token: window.localStorage['access-token'],
          receiver_user_id: this.user.user_id,
          virtual_gift_id: this.chosenData.id,
          comment: this.comment,
        })
        .then(
          (r) => {
            if (r.body.status) {
              this.$store.commit('addAlert', {
                type: 'success',
                text: 'Подарок отправлен',
              });
              this.closeModal();
            } else {
              let code = r.body.code;
              if (
                r.body.desc ===
                'Receiver is not a favorite OR does not spent any credits for sender<br>'
              ) {
                this.$store.commit('addAlert', {
                  type: 'error',
                  text: 'Вам нужно cначала пообщаться  с мужчиной',
                  info: 'gift sending failed',
                });
              } else if (
                code === 10161 ||
                code === 10025 ||
                !r.body.status ||
                !r.body.status ||
                r.body.desc === 'Not unique virtual gift this month<br>'
              ) {
                this.$store.commit('addAlert', {
                  type: 'error',
                  text: 'Исчерпан лимит отправки подарков данному пользователю',
                  info: 'gift sending failed',
                });
              }
            }
          },
          () => {
            this.$store.commit('addAlert', {
              type: 'error',
              text: 'Что-то пошло не так во время выполнения запроса',
              info: 'gift sending failed',
            });
          }
        );
    },
    /**
     * показ бОльшего кол-ва данных на табе
     */
    loadMore() {
      this.limit += this.defaultLimit;
    },
  },
};
</script>

<style lang="scss">
@import '../../../assets/scss/vars';

.vue-modal--wrapper {
  width: 100%;
}

textarea.form-control {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 1px solid #d9d9d9 !important;
}

.vmgifts .modal-header {
  padding: 20px 20px 15px;
  border-bottom: 0;
}

.nav-tabs > li {
  margin-bottom: 0;
}

.mgifts {
  &--single {
    .modal-body {
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: space-between;
    }
    &__form {
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: space-between;

      .usr-selection {
        display: flex;
        align-items: center;

        .small-img-wrap {
          padding: 3px;
          position: relative;
          margin-right: 10px;

          &:before {
            position: absolute;
            content: '';
            display: block;
            width: 100%;
            height: 100%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) rotate(45deg);
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            border-radius: 50%;
            border: 1px solid transparent;
            border-right-color: $black;
          }

          img {
            width: 45px;
            height: 45px;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            border-radius: 50%;
          }
        }
      }

      textarea.form-control {
        width: 100%;
        max-height: 100%;
        @media screen and (max-width: 992px) {
          overflow-y: scroll;
        }
      }
    }
  }
}

.simplebar-vg {
  height: 100%;
  overflow-x: hidden;
}

.vgwrapper {
  padding-right: 15px;
}

.vmgifts {
  max-height: 100%;
  overflow: hidden !important;
  @media screen and (min-width: 992px) {
    max-height: unset;
    overflow: auto !important;
  }
  .vue-modal--wrapper {
    position: relative;
    height: 100%;
    overflow: hidden;

    @media screen and (min-width: 992px) {
      height: auto;
    }

    .vue-modal--container,
    .modal-body,
    .vmgifts--main,
    .vmgifts--main__body,
    .tab-content,
    .tab-pane {
      position: relative;
      height: 100%;
      overflow: hidden;
    }

    .vue-modal--container,
    .vmgifts--main {
      display: flex;
      flex-direction: column;
      @media screen and (min-width: 992px) {
        justify-content: space-between;
      }
    }

    .vue-modal--container {
      padding: 10px !important;
    }
    .modal-body {
      padding: 5px;
    }
  }
  .nav-tabs {
    margin-bottom: 30px;
    width: 100%;

    li {
      width: 100%;
    }
  }

  &--container {
    width: 700px;
    max-width: 100%;
    padding: 0;

    &.wide {
      width: 1030px;
    }
  }

  &--single {
    &__calc {
    }
  }

  .mgifts {
    &--item {
      &.extended {
        align-items: center;
        justify-content: center;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .vmgifts {
    .mgifts {
      &--item {
        &.extended {
          align-items: center;
          justify-content: center;

          .mgifts--item__img {
            display: flex;
            align-items: center;
            justify-content: center;

            img {
              height: auto !important;
              width: 50% !important;
            }
          }
        }
      }
    }
  }
}
</style>
