<template>
  <span class="zodiac-icon">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
    >
      <path
        d="M1.63 13.91c1.704 0 2.79-1.314 3.663-2.376.873-1.057 1.455-1.698 2.18-1.698.383 0 .69.412 1.357 1.574.775 1.35 1.944 3.386 4.486 3.386 2.623 0 3.706-2.06 4.42-3.422.312-.596.703-1.336.947-1.336.422 0 1.055 1.006 1.516 1.737.866 1.373 1.942 3.086 3.987 3.086 1.994 0 3.48-1.662 4.797-3.13.473-.53 1.177-1.313 1.514-1.494.84-.064 1.502-.768 1.502-1.625 0-.898-.73-1.63-1.63-1.63-1.51 0-2.63 1.25-3.813 2.573-.64.716-1.832 2.045-2.373 2.045-.283-.067-.918-1.078-1.225-1.562-.867-1.376-2.054-3.262-4.276-3.262-2.215 0-3.188 1.854-3.833 3.08-.724 1.38-.995 1.677-1.532 1.677-.56 0-.905-.437-1.658-1.746C10.88 8.43 9.81 6.57 7.474 6.57c-2.316 0-3.693 1.67-4.697 2.89-.342.412-.91 1.104-1.163 1.188-.892.01-1.614.737-1.614 1.63 0 .902.73 1.632 1.63 1.632zM30.37 17.55c-1.51 0-2.63 1.25-3.813 2.572-.64.717-1.832 2.046-2.373 2.046-.283-.067-.918-1.077-1.225-1.562-.867-1.377-2.054-3.262-4.276-3.262-2.215 0-3.188 1.854-3.833 3.08-.724 1.38-.995 1.676-1.532 1.676-.56 0-.905-.438-1.658-1.746C10.88 19 9.812 17.14 7.475 17.14c-2.315 0-3.692 1.67-4.696 2.89-.343.413-.91 1.103-1.164 1.187-.894.01-1.616.736-1.616 1.63 0 .9.73 1.63 1.63 1.63 1.704 0 2.79-1.313 3.663-2.374.873-1.058 1.455-1.7 2.18-1.7.383 0 .69.414 1.357 1.575.775 1.35 1.944 3.386 4.486 3.386 2.623 0 3.706-2.06 4.42-3.42.312-.597.703-1.337.947-1.337.422 0 1.055 1.006 1.516 1.736.866 1.375 1.942 3.086 3.987 3.086 1.994 0 3.48-1.663 4.797-3.132.473-.527 1.177-1.312 1.514-1.494.84-.064 1.502-.768 1.502-1.625 0-.902-.73-1.63-1.63-1.63z"
      />
    </svg>
  </span>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  computed: {},
  methods: {},
};
</script>

<style></style>
