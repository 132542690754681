<template>
  <div class="choose-user">
    <!--responsive-->
    <div class="no-user-header visible-xs visible-sm">
      <div class="user-info" style="">
        <div class="item" style="justify-content: center; display: flex">
          <div class="avatar-replace">?</div>
        </div>
        <div class="item" style="justify-content: center; display: flex">
          <span class="name-box">
            <span class="uname">Choose a woman</span>
          </span>
        </div>
      </div>
      <div class="control-block">
        <div class="control-wrap">
          <div class="control-buttons">
            <!--changed sidebar controls-->
            <div class="sidebar-controls">
              <button class="btn-open" @click="responsiveShowUserOnline">
                <template v-if="false">
                  <!--remove counter displaying, add styles on button-->
                  <span class="counter">({{ womanOnlineCount }})</span>
                </template>
                Women
                <span class="icon">
                  <svg class="rbi flip-right">
                    <use
                      xlink:href="/static/assets/images/rbi-icon.svg#rbi-arrow"
                    ></use>
                  </svg>
                </span>
              </button>
              <div class="rounded-box">
                <button class="btn btn-danger" disabled>
                  <svg class="rbi">
                    <use
                      xlink:href="/static/assets/images/rbi-icon.svg#rbi-comment-text"
                    ></use>
                  </svg>
                </button>
                <button class="btn btn-primary" disabled>
                  <span>
                    <svg class="rbi">
                      <use
                        xlink:href="/static/assets/images/rbi-icon.svg#rbi-video"
                      ></use>
                    </svg>
                  </span>
                </button>
              </div>
              <button
                class="btn-open"
                data-target="#last-dialogs"
                @click="responsiveShowLastDialogs"
              >
                <span class="icon">
                  <svg class="rbi flip-left">
                    <use
                      xlink:href="/static/assets/images/rbi-icon.svg#rbi-arrow"
                    ></use>
                  </svg>
                  <span class="counter">
                    <span
                      v-if="lastDialogsUnreadMessage"
                      class="unread label label-primary"
                      >{{ lastDialogsUnreadMessage }}</span
                    >
                  </span>
                </span>

                Chats
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="no-user-caption">
      <p style="font-size: 1.2rem">No messages</p>
    </div>
  </div>
</template>

<script>
import { LAST_DIALOGS } from '../../../mixins/man_util';

export default {
  name: 'ChooseUser',
  mixins: [LAST_DIALOGS],
  computed: {
    womanOnlineCount() {
      return this.$store.getters.onlineWomanList.length;
    },
    lastDialogsCount() {
      return this.$store.getters.lastDialogs.length;
    },
    lastDialogsUnreadMessage() {
      return this.$store.getters.lastDialogsUnreadMessage;
    },
  },
  methods: {
    responsiveShowUserOnline() {
      this.$store.dispatch('setResponsiveShowUserOnline', true);
    },
    responsiveShowLastDialogs() {
      this.$store.dispatch('setResponsiveShowLastDialogs', true);
    },
  },
};
</script>

<style scoped>
.choose-user {
  padding: 10px;
  background: #ffffff;
  overflow: hidden;
}
.no-user-caption {
  overflow: hidden;
  height: 100%;
  width: 100%;
  justify-content: center;
  flex-direction: column;
}
</style>
