/* eslint-disable no-undef */
import store from '../store/store';
import Swiper, { Navigation } from 'swiper';

let getScrollBarWidth = function () {
  let outer = $('<div>')
      .css({ visibility: 'hidden', width: 100, overflow: 'scroll' })
      .appendTo('body'),
    widthWithScroll = $('<div>')
      .css({ width: '100%' })
      .appendTo(outer)
      .outerWidth();
  outer.remove();
  return 100 - widthWithScroll;
};

export const GET_AGE = {
  methods: {
    mixinGetAge(timestamp) {
      let birthday = new Date(timestamp * 1000),
        ageDifMs = Date.now() - birthday.getTime(),
        ageDate = new Date(ageDifMs);
      return Math.abs(ageDate.getFullYear() - 1970);
    },
  },
};

export const GALLERY = {
  data() {
    return {
      photos: [],
      displayGallery: false,
      sliderInitialized: false,
      gallery: null,
    };
  },
  watch: {
    displayGallery(v) {
      if (v) this.initSwiper();
    },
  },
  methods: {
    askProfile(id) {
      this.$http
        .post('v1/profile/profile', {
          access_token: this.$store.getters.token.site_token,
          target_user_id: id,
        })
        .then((r) => {
          r.body.status ? (this.photos = r.body.result.photos) : '';
        });
    },
    initSwiper() {
      // this.cachePhotos();

      this.gallery = new Swiper('.gallery-swiper-new', {
        slidesPerView: 5,
        spaceBetween: 0,
        modules: [Navigation],
        navigation: {
          nextEl: '.button-next',
          prevEl: '.button-prev',
        },
        /* paginationClickable: true,
         updateOnImagesReady: true,
         observer: true,*/
        on: {
          /*   init: function () {
             self.gallery.slideTo(1, 0);
             self.gallery.slidePrev(0);
           },*/
          imagesReady: () => {
            this.sliderInitialized = true;
          },
        },
      });

      /* self.gallery.on('imagesReady', () => {
        self.sliderInitialized = true;
        /!* self.gallery.slideTo(1, 0);
        self.gallery.slidePrev(0);*!/
      });*/
    },
    cachePhotos() {
      let cache = [],
        arr = this.photos.slice(0, 6);
      arr.forEach((i) => {
        let tmp = new Image();
        tmp.src = i.img_origin;
        cache.push(tmp);
      }, this);
    },
    takePhotos(condition, photos, index) {
      if (condition) {
        this.$store.commit('updateGallery', {
          photos,
          index,
          open: true,
        });
      }
    },
    openPreview(photos, index, bought, photoID) {
      this.$store.commit('updateGallery', {
        photos,
        index,
        open: true,
        bought,
        photoID,
      });
    },
  },
};

export const TOGGLE_MODAL = function (open) {
  if (open) {
    $('body').css({
      overflow: 'hidden',
      'padding-right': getScrollBarWidth(),
    });
  } else if (open === false) {
    $('body').css({
      overflow: 'auto',
      'padding-right': '0',
    });
  }
};

export const CONTACTS = {
  methods: {
    mixinIsFavoriteCurrent() {
      let favoritesList = store.getters.favorites;
      let found = false;
      favoritesList.forEach(function (favorite) {
        if (favorite.id === store.getters.dialogs.current.user.id) {
          found = true;
        }
      });
      return found;
    },

    mixinIsFavoriteTargetUserId(targetUserId) {
      let favoritesList = store.getters.favorites;
      let found = false;
      favoritesList.forEach(function (favorite) {
        if (favorite.id === targetUserId) {
          found = true;
        }
      });
      return found;
    },

    mixinIsBookmarkCurrent() {
      let bookmarksList = store.getters.bookmarks;
      let found = false;
      bookmarksList.forEach(function (bookmark) {
        if (bookmark.id === store.getters.dialogs.current.user.id) {
          found = true;
        }
      });
      return found;
    },

    mixinIsBookmarkTargetUserId(targetUserId) {
      let bookmarksList = store.getters.bookmarks;
      let found = false;
      bookmarksList.forEach(function (bookmark) {
        if (bookmark.id === targetUserId) {
          found = true;
        }
      });
      return found;
    },
  },
};

export const CHAT_INFO = {
  methods: {
    mixinGetChatTime() {
      let totalTime = 0;
      /** @namespace store.dialogs */
      let time_range = store.getters.dialogs.current.time_range;
      for (let i = 0; i < time_range.length; i++) {
        if (time_range[i].stop) {
          totalTime += time_range[i].stop - time_range[i].start;
        }
      }

      let minutes = Math.floor(totalTime / 60);
      let seconds = totalTime - minutes * 60;
      let hours = Math.floor(totalTime / 3600);

      if (seconds < 10) {
        seconds = '0' + seconds;
      }
      if (minutes < 10) {
        minutes = '0' + minutes;
      }
      if (hours < 10) {
        hours = '0' + hours;
      }

      return hours + ':' + minutes + ':' + seconds;
    },
  },
};

export const EMOJI_CONVERT = {
  data() {
    return {
      TEXT_NODE: 3, //from plugin
      ELEMENT_NODE: 1,
      TAGS_BLOCK: ['p', 'div', 'pre', 'form'],
    };
  },
  methods: {
    getUnicodeFromHTML(htmlString) {
      var lines = [];
      var line = [];

      var flush = function () {
        lines.push(line.join(''));
        line = [];
      };

      let self = this;

      var sanitizeNode = function (node) {
        if (node.nodeType === self.TEXT_NODE) {
          line.push(node.nodeValue);
        } else if (node.nodeType === self.ELEMENT_NODE) {
          var tagName = node.tagName.toLowerCase();
          var isBlock = self.TAGS_BLOCK.indexOf(tagName) !== -1;

          if (isBlock && line.length) flush();

          if (tagName === 'img') {
            var alt = node.getAttribute('alt') || '';
            if (alt) {
              line.push(alt);
            }
            return;
          } else if (tagName === 'br') {
            flush();
          }

          var children = node.childNodes;
          for (var i = 0; i < children.length; i++) {
            sanitizeNode(children[i]);
          }

          if (isBlock && line.length) flush();
        }
      };

      let parent = document.createElement('div');
      parent.innerHTML = htmlString;

      let children = parent.childNodes;
      for (var i = 0; i < children.length; i++) {
        sanitizeNode(children[i]);
      }

      if (line.length) flush();

      var returnValue = lines.join('\n');
      return EmojiPicker.prototype.colonToUnicode(returnValue);
    },
  },
};

export const PREVIEW_MODAL = {
  methods: {
    openModal(id) {
      this.$store.commit('updateProfileModal', {
        open: true,
        loaded: false,
      });

      this.$store.dispatch('loadProfileInfo', {
        id,
      });
    },
    noData(val) {
      return !val || val === 'no' ? 'Not filled' : val;
    },
  },
};

export const VIDEO_SIZE = {
  // requires and #wmn-prev-container for webcam preview, #video - for main video container
  // requires this.mutatedPositioning - init in every component. Because of unique situations of every side (woman|man) and unable to pass parameter to v-resize directive
  data() {
    return {
      videoHeight: 0,
      videoRatio: [16, 9], // 16*9
    };
  },
  methods: {
    definePositioning() {
      let tmp = document.getElementById('wmn-prev-container');
      let vid = document.getElementById('video');

      if (this.mutatedPositioning) {
        if (tmp && vid) {
          let prv = {
            el: tmp,
            width: tmp.offsetWidth,
            height: tmp.offsetHeight,
          };

          let container = {
            el: vid,
            width: vid.offsetWidth,
            height: vid.offsetHeight,
            left: $(vid).offset().left,
            top: $(vid).offset().top,
          };
          prv.el.style.position = 'fixed';
          prv.el.style.top =
            container.top + container.height - prv.height + 'px';
          prv.el.style.left =
            container.left + container.width - prv.width + 'px';
          prv.el.style.right = 'auto';
        }
      } else {
        if (tmp) {
          tmp.style.position = 'absolute';
          tmp.style.top = '0';
          tmp.style.left = 'auto';
          tmp.style.right = '0px';
        }
      }
    },
    calcVideoHeight(setDefault) {
      this.videoHeight =
        (this.$refs['vid-container'].clientWidth * this.videoRatio[1]) /
          this.videoRatio[0] +
        'px';
      this.$nextTick(() => {
        this.definePositioning(setDefault);
      });
    },
    calcVideoTick(setDefault) {
      this.$nextTick(() => {
        this.calcVideoHeight(setDefault);
      });
    },
    fetchVideoRatio() {
      return false; // disable calculating
    },
  },
};

export const SOUND = {
  computed: {
    soundSettings() {
      return this.$store.getters.settings.sound;
    },
    token() {
      return this.$store.getters.session.site_token;
    },
  },
  methods: {
    toggleSound() {
      let tmp = !this.soundSettings.sound;
      this.$store.commit('setUserSettings', {
        data: {
          sound: tmp,
        },
      });
      this.$http
        .post('v1/user-settings/set', {
          access_token: this.token,
          sound: tmp ? 'on' : 'off',
        })
        .then((r) => {
          if (r.body.status) {
            this.$store.commit('setUserSettings', {
              type: 'full',
              data: r.body.result,
            });
            this.$socket.emit('updateSettings', {
              token: this.$store.getters.session.chat_token,
              type: 'chat',
            });
          }
        });
    },
  },
};
export const PHOTO_SRC = {
  methods: {
    /**
     * получить урл фотографий.
     *
     * Остаток от доS3времен
     *
     * @param url
     * @return {*}
     */
    setPhotoSource(url) {
      return url;
    },
  },
};