<template>
  <transition name="modal">
    <div
      v-if="modal.open"
      class="vue-modal modal-mask credits-modal bad-connection-modal"
      @click="closeModal($event.target)"
    >
      <div class="vue-modal--wrapper">
        <div class="vue-modal--container credits-modal--container">
          <button type="button" class="close" @click="closeModal(false)">
            <svg class="rbi">
              <use
                xlink:href="/static/assets/images/rbi-icon.svg#rbi-close"
              ></use>
            </svg>
          </button>
          <div class="modal-body credits-modal--body">
            <h4>
              <img
                src="/static/assets/images/warning_icon.png"
                alt="Warning icon"
                style="margin-bottom: 20px"
              />
            </h4>
            <h4 class="modal-title">Video chat is unavailable</h4>
            <p class="text-muted">
              Something went wrong during attempt to set video-connection.
            </p>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
/* eslint-disable no-undef */
export default {
  name: 'BadConnectionModal',
  data() {
    return {};
  },
  computed: {
    open() {
      return this.modal.open;
    },
    modal() {
      return this.$store.getters.badConnectionModal;
    },
    sex() {
      return this.$store.getters.sex;
    },
    app() {
      return this.$store.getters.app;
    },
  },
  methods: {
    closeModal(e) {
      if (!$(e).closest('.mail-modal--body').length || !e) {
        this.$store.commit('updateBadConnectionModal', {
          open: false,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.credits-modal {
  &--container {
    width: 320px;
    position: relative;
  }

  h4 {
    font-weight: bold;
    color: #656565;
  }

  .close {
    position: absolute;
    top: 20px;
    right: 20px;
  }

  &--body {
    text-align: center;
    p {
      margin: 20px 0;
    }
  }

  .btn-success {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
    border: none !important;
    padding: 6px 20px;

    span + span {
      margin-left: 10px;
    }

    svg {
      height: 20px;
      width: 20px;
      fill: currentColor;
    }
  }
}
</style>
